import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { API_BASE_URL, Assets_URL } from "../../../Apicongfig";
import axios from "axios";
import { toast } from "react-toastify";
import { FaArrowRight } from "react-icons/fa";
import ParticipantCardProfile from "../../Profile/ParticipantCardProfile";
import { useTranslation } from "react-i18next";
const Participant = ({
  data,
  fromMeeting,
  meeting,
  disabled,
  guides,
  handleShow,
  handleHide,
  showProfile,
  refreshedData
}) => {
  const [participants, setParticipants] = useState([]); // participants.
  const [image, setImage] = useState(null);
  const [t] = useTranslation("global");

  useEffect(() => {
    setParticipants(data);
  }, [data]);

  const guideEmails = new Set(guides?.map((guide) => guide.email));
  console.log("guides", guides);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleItemClick = async (index, item) => {
    console.log("index-->", index);
    console.log("id-->", item);
    setSelectedIndex(index === selectedIndex ? null : index);

    const newAttendanceStatus = item?.attandance ? 0 : 1;
    const reuestBODY = {
      first_name: item.first_name,
      last_name: item.last_name,
      email: item.email,
      post: item.post,
      attandance: newAttendanceStatus,
      isCreator: item.isCreator,
      _method: "put",
    };
    const requestURL = `${API_BASE_URL}/participants/${item.id}`;

    try {
      const response = await axios.post(requestURL, reuestBODY, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      // Update the state with the updated participant data
      const updatedParticipant = response?.data?.data;

      setParticipants((prevParticipants) =>
        prevParticipants.map((participant) =>
          participant.id === updatedParticipant.id
            ? updatedParticipant
            : participant
        )
      );
      await refreshedData()
      // const updatedParticipant = response?.data?.data;
      // setParticipants((prevParticipants) => {
      //   const updatedParticipants = [...prevParticipants];
      //   const participantIndex = updatedParticipants.findIndex(
      //     (p) => p.id === updatedParticipant.id
      //   );
      //   if (participantIndex !== -1) {
      //     updatedParticipants[participantIndex] = updatedParticipant;
      //   }
      //   return updatedParticipants;
      // });
    } catch (error) {
      console.log("FAILED TO UPDATE THE ATTENDANCE", error);
    }
  };

  const groupedParticipants =
    data?.reduce((acc, participant) => {
      if (guideEmails.has(participant.email)) {
        return acc; // Skip guides and creators
      }

      if (participant.team_names?.length > 0) {
        participant.team_names.forEach((team) => {
          if (!acc[team]) acc[team] = [];
          acc[team].push(participant);
        });
      } else {
        if (!acc["NoTeam"]) acc["NoTeam"] = [];
        acc["NoTeam"].push(participant);
      }

      return acc;
    }, {}) || {}; // Fallback to an empty object if `data` is undefined

  // Split participants with teams and without teams
  const participantsWithTeams = Object?.entries(groupedParticipants).filter(
    ([teamName]) => teamName !== "NoTeam"
  );
  const participantsWithoutTeams = groupedParticipants["NoTeam"] || [];

  const [id, setId] = useState(null);
  const handleShowProfile = (userId) => {
    console.log("id", userId);
    handleShow();
    setId(userId);
  };
  return (
    <>
      {showProfile ? (
        <div>
          <ParticipantCardProfile userId={id} handleHide={handleHide} />
        </div>
      ) : (
        <div className="row">
                  {participants.map((item, index) => {
                    if(item.isCreator === 1){
                      return null;
                    }
                    return (
                      <div className="col-md-3" key={index}>
                        <Card
                          className="participant-card position-relative"
                          style={{
                            border:
                              item?.attandance === 1
                                ? "1px solid green"
                                : "1px solid red",
                            cursor: "pointer",
                            marginTop: "4rem",
                            borderRadius: "26px",
                          }}
                          onClick={() => {
                            if (disabled) {
                              return;
                            }
                            handleItemClick(index, item);
                          }}
                        >
                          <Card.Body
                            style={{
                              padding: "20px 0px 20px 0",
                            }}
                          >
                            <div className="d-flex justify-content-center">
                              <div className="participant-card-position">
                                <div className="profile-logo">
                                  {item.participant_image.startsWith(
                                    "users/"
                                  ) ? (
                                    <Card.Img
                                      className="user-img"
                                      src={`${Assets_URL}/${item?.participant_image}`}
                                    />
                                  ) : item.participant_image ? (
                                    <Card.Img
                                      className="user-img"
                                      src={item.participant_image}
                                    />
                                  ) : (
                                    <Card.Img
                                      className="user-img"
                                      src="/Assets/avatar.jpeg"
                                    />
                                  )}
                                  <Card.Img
                                    className="logout-icon"
                                    src="/Assets/Avatar_company.svg"
                                    height="20px"
                                    width="20px"
                                    alt="tektime"
                                  />
                                </div>
                              </div>
                            </div>

                            <Card.Title className="text-center mt-4 card-heading">
                              {item.first_name} {item.last_name}
                            </Card.Title>
                            <Card.Subtitle className="mb-2 card-subtext">
                              {item.post} {item?.team_names?.length > 0 && "-"} &nbsp;
                              {item?.team_names?.map((item) => item).join(", ")}
                            </Card.Subtitle>
                            {item?.contribution && (
                              <>
                                <Card.Subtitle className="mb-3 mt-3 card-subtext">
                                  {item?.contribution}
                                </Card.Subtitle>
                              </>
                            )}
                            {item?.user_id && (
                              <div
                                className="visiting-card-link"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents click from bubbling up to Card
                                  handleShowProfile(item?.id);
                                }}
                              >
                                {t("viewVisitingCard")} &nbsp; <FaArrowRight />
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                </div>
      )}
    </>
  );
};

export default Participant;
