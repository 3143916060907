import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Header } from "antd/es/layout/layout";
import { useMeetings } from "../../../context/MeetingsContext";
import moment from "moment";
import { useTabs } from "../../../context/TabContext";
import AddDestination from "./AddDestination";
import { useDestinationTabs } from "../../../context/DestinationTabContext";
import CurrentDestinations from "./CurrentDestinations";
import CompletedDestinations from "./CompletedDestinations";
import { useDestinations } from "../../../context/DestinationsContext";
import NewDestinations from "./NewDestinations";

function DestinationTabs() {
  const { searchTerm } = useOutletContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { resetHeaderTitle } = useHeaderTitle();

  React.useEffect(() => {
    resetHeaderTitle();
  }, []);
  const [t] = useTranslation("global");

  const { activeTab, setActiveTab } = useDestinationTabs();

  const {
    allDestinations,
    allCompletedDestinations,
    getDestinations,
    getAllCompletedDestinations,
  } = useDestinations();
  console.log("all destinations->current", allDestinations);
  console.log("allCompletedDestinations", allCompletedDestinations);
  useEffect(() => {
    // setActiveTab("Current Destinations");
    // getDestinations();
    // getDestinations();
    const fetchDestinations = async () => {
      try {
        setActiveTab("Current Destinations");

        // First API call
        await getDestinations();
      } catch (error) {
        console.error("Error fetching destinations:", error);
      }
    };

    fetchDestinations();
  }, []);

  // Function to determine if all meetings for a destination are closed
  const isAllMeetingsClosed = (meetings) => {
    return meetings.every(
      (meeting) => meeting.status === "closed" || meeting.status === "abort"
    );
  };

  const isAllMeetingsClosedActive = (meetings) => {
    return meetings.every(
      (meeting) => meeting.status === "closed" || meeting?.status === "abort"
    );
  };



  // Filter out destinations where all meetings are closed
  const activeDestinations = allDestinations?.filter((destination) => {
    return (
      destination.meetings.length > 0 &&
      // destination?.total_meetings > 0 ||
      !isAllMeetingsClosedActive(destination.meetings)
    );
  });
  console.log("activeDestinations", activeDestinations);

  // Filter out destinations where all meetings are closed
  const completedDestinations = allDestinations?.filter(
    (destination) =>
      destination.meetings.length > 0 &&
      isAllMeetingsClosed(destination.meetings)
  );
  console.log("completedDestinations", completedDestinations);

  const activeDestinationWithZeroMeetings = allDestinations?.filter((destination) => destination?.meetings?.length === 0);
  console.log('activeDestinationWithZeroMeetings', activeDestinationWithZeroMeetings);
  const newDestinationCount = activeDestinationWithZeroMeetings?.length;
  console.log('newDestinationCount', newDestinationCount);
  // / Filter out destinations with only one meeting in draft status
  const filteredDestinations = activeDestinations.filter((item) => {
    const hasOnlyDraftMeeting =
      item?.meetings?.length === 1 && item?.meetings[0]?.status === "draft";
    return !hasOnlyDraftMeeting;
  });
  const currentDestinationCount = filteredDestinations?.length;
  const completedDestinationCount = completedDestinations?.length;

  const tabsRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  // const [activeTab, setActiveTab] = useState("tab2");

  const handleScroll = () => {
    if (tabsRef.current) {
      setIsSticky(window.scrollY > 170);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filteredCurrentDestinations, setFilteredCurrentDestinations] =
    useState(activeDestinations);
  const [filteredNewDestinations, setFilteredNewDestinations] =
    useState(activeDestinationWithZeroMeetings);
  const [filteredCompletedDestinations, setFilteredCompletedDestinations] =
    useState(completedDestinations);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // setIsMyMeetingsSelected(true);
    // setIsClosedMyMeetingsSelected(true);
    const now = moment();
    let filtered = [...activeDestinations];

    // Further filter the meetings based on status and user_id
    // filtered = filtered.filter(
    //   (meeting) =>
    //     meeting.status === "active" || meeting.status === "in_progress"
    //   //  &&  meeting.user_id === userId
    // );
    // let myClosedMeetings = allClosedMeetings.filter(
    //   (meeting) => meeting.user_id === userId
    // );
    let myClosedDestinations = [...completedDestinations];

    let myNewDestinations = [...activeDestinationWithZeroMeetings]
    if (searchTerm) {
      filtered = filtered.filter((destination) =>
        destination.destination_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      myNewDestinations = myNewDestinations.filter((destination) =>
        destination.destination_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      myClosedDestinations = myClosedDestinations.filter((destination) =>
        destination.destination_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }
    // setDraftMeetings(draftMeetings);
    setFilteredCurrentDestinations(filtered);
    setFilteredNewDestinations(myNewDestinations);
    setFilteredCompletedDestinations(myClosedDestinations);
  }, [searchTerm, allDestinations, allCompletedDestinations]);
  return (
    <>
      <div className="destination-tabs-container container-fluid px-2">
        <div
          ref={tabsRef}
          className={`tabs-header ${isSticky ? "sticky" : ""}`}
        >
          <h4 className="meeting-title">{t("destination.title")}</h4>
          <small style={{padding:"15px 14px"}}>{t("The destination is the end result you want your client to achieve.")}</small>
          <div className={`container-fluid ${isSticky ? "" : "py-1 px-3"}`}>


          <div className="row px-3">
            <div className="col-lg-11 col-md-10 col-12 destination-tab-row tabs-destinations" style={{ borderBottom: "2px solid #F2F2F2" }}>
              <div className="tabs">
                <div className="d-flex">
                  <button
                    className={`tab ${activeTab === "New Destinations" ? "active" : ""}`} onClick={() => setActiveTab("New Destinations")}
                  >
                    {t("destination.newDestinationTab")}
                    <span
                      className={
                        activeTab === "New Destinations"
                          ? "future"
                          : "draft"
                      }
                    >
                      {newDestinationCount}
                    </span>
                  </button>
                  <button
                    className={`tab ${activeTab === "Current Destinations" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("Current Destinations")}
                  >
                    {t("destination.currentDestinationTab")}
                    <span
                      className={
                        activeTab === "Current Destinations"
                          ? "future"
                          : "draft"
                      }
                    >
                      {currentDestinationCount}
                    </span>
                  </button>
                  <button
                    className={`tab ${activeTab === "Completed Destinations" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("Completed Destinations")}
                  >
                    {t("destination.completedDestinationTab")}
                    <span
                      className={
                        activeTab === "Completed Destinations"
                          ? "future"
                          : "draft"
                      }
                    >
                      {completedDestinationCount}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className={`col-lg-1 col-md-2 col-12 p-0 d-flex  ${isSticky ? "sticky-button" : "justify-content-end"}`}>
              <button
                className={`btn moment-btn`}
                // style={{ padding: isSticky ? "11px 28px 11px 23px" : ' 0px 25px 0px 20px' }}
                onClick={() => setOpen(true)}
              >
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 14.75V1.25M1.25 8H14.75"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                &nbsp;{t("meeting.buttons.create")}
              </button>
              {/* </Link> */}
            </div>
          </div>
          </div>


          {open && (
            <AddDestination
              show={open}
              handleClose={handleClose}
              currentItem={null}
            />
          )}
        </div>
        <div className="content">
          {activeTab === "New Destinations" && (
            <div>
              {
                <NewDestinations
                  allCurrentDestinations={filteredNewDestinations}
                />
              }
            </div>
          )}
          {activeTab === "Current Destinations" && (
            <div>
              {
                <CurrentDestinations
                  allCurrentDestinations={filteredCurrentDestinations}
                />
              }
            </div>
          )}
          {activeTab === "Completed Destinations" && (
            <div>
              <CompletedDestinations
                allDestinations={filteredCompletedDestinations}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DestinationTabs;
