import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { API_BASE_URL } from "../Components/Apicongfig";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import { useSteps } from "./Step";
import { useMeetings } from "./MeetingsContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSolutionFormContext } from "./CreateSolutionContext";
// import { useSteps } from "./Step";

const CreateMeetingContext = createContext();

export const useDestinations = () => useContext(CreateMeetingContext);

export const FormProvider = ({ children }) => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [meeting, setMeeting] = useState(null);
  const [inputGroups, setInputGroups] = useState([]);
  const {solutionSteps, steps, updateSteps,solutionType,solutionNote,solutionAlarm,solutionShareBy } = useSteps();
  const [participants, setParticipants] = useState([]);
  const [checkId, setCheckId] = useState(null);
  const [meetingData, setMeetingData] = useState({});
  const [stepBtnDisabled, setStepBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [stepsData, setStepsData] = useState([]);
  const [singleGuestData, setSingleGuestData] = useState({});
  const [token, setToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [call, setCall] = useState(false);
  const [destinationId, setDestinationId] = useState(null);
  const [destinationUniqueId, setDestinationUniqueId] = useState(null);
  console.log("call", call);
  console.log("isDuplicate", isDuplicate);
  console.log("isUpdated", isUpdated);
  const {setSolution} = useSolutionFormContext();
  const { getMeetings } = useMeetings();
  const [timeUnitsTotal, setTimeUnitsTotal] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [addParticipant, setAddParticipant] = useState(false);
  const [fromDestination, setFromDestination] = useState(false);
  const [fromDestinationName, setFromDestinationName] = useState(null);
  const [callDestination, setCallDestination] = useState(false);
  console.log("fromDestination", fromDestination);
  const [isCompleted, setIsCompleted] = useState(false);

  const [teamAdded, setTeamAdded] = useState(false);
  console.log("teamAdded", teamAdded);


  const [selectedTab, setSelectedTab] = useState(null);
  console.log('selectedTab', selectedTab);

  const [updatedButton, setUpdatedButton] = useState(false);
  const [quit, setQuit] = useState(false);

  const [formState, setFormState] = useState({
    selectedOption: null,
    title: "",
    date: "",
    start_time: "",
    description: "",
    type: "",
    priority: "",
    alarm: false,
    autostart: false,
    playback: "Manual",
    prise_de_notes: "Manual",
    objective: "",
    participants: [],
    id: null,
    repetition: false,
    repetition_number: 1,
    repetition_frequency: "Daily",
    repetition_end_date: null,
    selected_days: [],
    teams: [],
    moment_privacy: "private",
    moment_privacy_teams: [],
    moment_password: null,
    location: null,
    city: null,
    country: null,
    street: null,
    zip: null,
    room_details: null,
    phone: null,
    share_by: null,
  });

  // const handleCloseModal = () => {
  //   setOpen(false);
  //   navigate('/meeting')
  //   setIsUpdated(false);
  // };
  const handleCloseModal = () => {
    console.log("clicked", formState);
    setOpen(false);
    setCall((prev) => !prev);
    setCallDestination((prev) => !prev);
    setMeeting(null);
    setCheckId(null);
    setAddParticipant(false);
    setFromDestination(false);
    setFromDestinationName(null);
    // navigate("/meeting");
    setIsUpdated(false);
    setIsDuplicate(false);
    setFormState({
      selectedOption: null,
      title: "",
      date: "",
      start_time: "",
      description: "",
      type: "",
      priority: "",
      alarm: false,
      autostart: false,
      playback: "Manual",
      prise_de_notes: "Manual",
      objective: "",
      participants: [],
      id: null,
      repetition: false,
      repetition_number: 1,
      repetition_frequency: "Daily",
      repetition_end_date: null,
      selected_days: [],
      teams: [],
      moment_privacy: "private",
      moment_privacy_teams: [],
      moment_password: null,
      location: null,
      city: null,
      country: null,
      street: null,
      zip: null,
      room_details: null,
      phone: null,
      share_by: null,
    });
    updateSteps([]);
    setInputGroups([]);
    console.log("clicked after empty", formState);
  };
  // Alternatively, use useEffect to log the updated state
  useEffect(() => {
    console.log("Form state updated:", formState);
  }, [formState]);

  const handleShow = () => {
    updateSteps([]);
    setOpen(true);
  };

  useEffect(() => {
    console.log("steps state updated:", steps);
  }, [steps]);
  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      setToken(accessToken);
      setIsLoggedIn(sessionStorage.getItem("is_logged_in") === "true");
      setIsLoggedIn(true);
    }
  }, [token, open]);

  const getMeeting = async (checkId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/meetings/${checkId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        setMeeting(response?.data?.data);
        setMeetingData(response.data.data);
        setFormState(response.data.data);
        setStepsData(response.data?.data);
        setLoading(false);
        setParticipants(response.data?.data?.participants);

        const stepsData = response.data?.data?.steps;
        setInputGroups(stepsData);
        // Calculate cumulative time for each time unit
        const totals = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        // Calculating cumulative time of all steps
        const cumulativeTime = stepsData?.reduce(
          (totalTime, step) => totalTime + step.time,
          0
        );
        // setTime(cumulativeTime);
        stepsData?.forEach((step) => {
          switch (step.time_unit) {
            case "days":
              totals.days += step.time;
              break;
            case "hours":
              totals.hours += step.time;
              break;
            case "minutes":
              totals.minutes += step.time;
              break;
            case "seconds":
              totals.seconds += step.time;
              break;
            default:
              break;
          }
        });

        setTimeUnitsTotal(totals);
      }
    } catch (error) {
      console.log("error while getting steps", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputBlur = useCallback(
    async (newformstate, options = {}) => {
      setLoading(true);
      const formFields = [
        formState.selectedOption,
        formState.priority,
        formState.title,
        formState.description,
      ];
      const hasText = formFields?.some((field) => {
        if (typeof field === "string") {
          return field?.trim() !== "";
        }
        return field !== null;
      });

      if (hasText) {
        const { updatedButton, quitAndUpdate } = options;

        if (isDuplicate || isUpdated) {

          console.log("updatedButton", updatedButton);
          console.log("quitAndUpdate", quitAndUpdate);

          if (quitAndUpdate) {
            const updateResponse = await updateMeeting(newformstate, updatedButton, quitAndUpdate);
            return updateResponse;
          } else {
            const updateResponse = await updateMeeting(newformstate);
            return updateResponse;
          }
        } else if (checkId === null) {

          const draftResponse = await handleDraft(); // Save as draft if no id exists
          return draftResponse;
        } else {
          const updateResponse = await updateDraft(newformstate); // Update draft if an ID exists
          return updateResponse;
        }
      }
      setLoading(false); // Hide loader
    },
    [formState, checkId, isDuplicate]
  );

  const handleDraft = async () => {
    const slides = [];
    let prevCount = 0;
    for (let i = 0; i < inputGroups.length; i++) {
      const currentStep = inputGroups[i];
      const counts = [prevCount, prevCount + currentStep.count2];

      slides.push({
        value: currentStep.title,
        counts: counts,
      });

      prevCount = counts[1];
    }

    const repetitionFields = formState.repetition
      ? {
        repetition_number: formState.repetition_number,
        repetition_frequency: formState.repetition_frequency,
        repetition_end_date: formState.repetition_end_date,
        selected_days: formState.selected_days,
      }
      : {
        repetition_number: 1,
        repetition_frequency: "Daily",
        repetition_end_date: null,
        selected_days: [],
      };

    const inputData = {
      objective: fromDestination ? fromDestinationName : formState.objective,
      type: formState.type,
      title: formState.title,
      date: formState.date,
      start_time: formState.start_time,
      description: formState.description,
      steps: inputGroups,
      alarm: formState.alarm,
      autostart: formState.autostart,
      total_time: formState.time,
      prise_de_notes: formState.prise_de_notes,
      playback: formState?.playback,
      priority: formState.priority,
      participants: formState.participants ? formState.participants : [],
      teams: formState.teams,
      meeting_id: formState.meetingId,
      // status: "draft",
      status: addParticipant ? formState?.status : "draft",
      // status: addParticipant ? "in_progress" : "draft",
      repetition: formState.repetition || false,
      ...repetitionFields,
      moment_privacy: formState.moment_privacy,
      moment_password:
        formState.moment_privacy === "password" ? formState.password : null,
      moment_privacy_teams: [],
      location: formState.location,
      city: formState.city,
      country: formState.country,
      street: formState.street,
      zip: formState.zip,
      room_details: formState.room_details,
      phone: formState.phone,
      share_by: formState.share_by,
      timezone: userTimeZone,
      solution_steps:solutionSteps || [],
      solution_type: solutionType || null,
      solution_note: solutionNote || "Manual",
      solution_alarm: solutionAlarm || false,
      solution_shareby: solutionShareBy || null

    };

    try {
      const response = await axios.post(`${API_BASE_URL}/meetings`, inputData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.status) {
        toast.success(t("messages.draftSaved"));
        setLoading(false);
        setCheckId(response.data.data?.id);
        setMeetingData(response.data?.data);
        setInputGroups(response.data.data?.steps || []);
        setParticipants(response.data.data?.participants || []);
      }
      return response;
    } catch (error) {
      setLoading(false);
      console.error("Failed to save draft:", error);
      // toast.error(t("messages.draftSaveError"));
    }
  };
  const updateDraft = async (newformstate) => {
    const {
      objective,
      type,
      title,
      description,
      date,
      start_time,
      priority,
      alarm,
      autostart,
      prise_de_notes,
      playback,
      time,
      teams,
      repetition,
      repetition_number,
      repetition_frequency,
      repetition_end_date,
      participants,
      selected_days,
      moment_privacy,
      moment_privacy_teams,
      moment_password,
      location,
      city,
      country,
      street,
      zip,
      room_details,
      phone,
      share_by,
    } = formState;
    const repetitionFields = repetition
      ? {
        repetition_number,
        repetition_frequency,
        repetition_end_date,
        selected_days,
      }
      : {
        repetition_number: 1,
        repetition_frequency: "Daily",
        repetition_end_date: null,
        selected_days: [],
      };

    const formFields = [objective, type, title, description, date, start_time];
    const hasText = formFields?.some((field) => field?.trim() !== "");
    if (!hasText) {
      toast.error("Veuillez d'abord remplir les champs ci-dessus");
      return;
    }

    const cleanedParticipants =
      newformstate?.participants?.map(
        ({
          emailInput,
          filteredSuggestions,
          showAdditionalFields,
          ...rest
        }) => ({
          ...rest,
          meeting_id: checkId || null,
        })
      ) || [];

    const inputData = {
      ...meetingData,
      objective: fromDestination ? fromDestinationName : objective,
      // objective,
      type,
      title,
      date,
      start_time,
      description,
      priority,
      alarm,
      autostart,
      playback,
      prise_de_notes,
      total_time: time,
      participants: cleanedParticipants,
      // teams: teams,
      teams: teams?.map((team) => team.id) || [],
      timezone: userTimeZone,
      steps: inputGroups,
      // status: isDuplicate || isUpdated ? "active" : "draft",
      status:
        isDuplicate || isUpdated
          ? "active"
          : addParticipant
            ? formState?.status || newformstate?.status
            : "draft",
      repetition: repetition || false,
      ...repetitionFields,
      moment_privacy,
      moment_privacy_teams:
        moment_privacy === "team" &&
          moment_privacy_teams?.length &&
          typeof moment_privacy_teams[0] === "object"
          ? moment_privacy_teams.map((team) => team.id)
          : moment_privacy_teams || [], // Send as-is if IDs are already present
      moment_password: moment_privacy === "password" ? moment_password : null,
      location: location,
      city: city,
      country: country,
      street: street,
      zip: zip,
      room_details: room_details,
      phone: phone,
      share_by: share_by,
      _method: "put",
      meeting_id: checkId,
      add_team: teams?.length > 0 && selectedTab !== "tab7" ? true : false,
    };

    if (isDuplicate) {
      inputData.duplicate = true;
    }
    //
    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${checkId}`,
        inputData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const allSteps = response.data.data.steps.sort((a, b) => a.id - b.id);
        setInputGroups(allSteps);
        setParticipants(response.data.data.participants || []);

        // if (isAddStepButtonClicked) {
        //   setInputGroups((prev) => [
        //     ...prev,
        //     {
        //       order_no: inputGroups.length + 1,
        //       step: inputGroups.length + 1,
        //       title: "",
        //       count1: 0,
        //       count2: 0,
        //       time: 0,
        //     },
        //   ]);
        // }
        setLoading(false);
        setMeetingData({
          ...response.data.data,
          steps: allSteps,
          participants: response.data.data.participants || [],
        });
      } else {
        setLoading(false);
        // toast.error("Failed to update meeting.");
      }

      return response;
    } catch (error) {
      setLoading(false);
      console.error("Failed to update draft:", error);
      toast.error(t("messages.draftSaveError"));
    } finally {
      setStepBtnDisabled(false);
      setLoading(false); // Hide loader after the API call is complete
    }
  };
  const updateMeeting = async (newformstate, updatedButton, quitAndUpdate) => {
    console.log('button', updatedButton)
    const {
      objective,
      type,
      title,
      description,
      date,
      start_time,
      priority,
      alarm,
      autostart,
      prise_de_notes,
      playback,
      time,
      teams,
      repetition,
      repetition_number,
      repetition_frequency,
      repetition_end_date,
      participants,
      selected_days,
      moment_privacy,
      moment_privacy_teams,
      moment_password,
      location,
      city,
      country,
      street,
      zip,
      room_details,
      phone,
      share_by,
    } = formState;
    const repetitionFields = repetition
      ? {
        repetition_number,
        repetition_frequency,
        repetition_end_date,
        selected_days,
      }
      : {
        repetition_number: 1,
        repetition_frequency: "Daily",
        repetition_end_date: null,
        selected_days: [],
      };

    const formFields = [objective, type, title, description, date, start_time];
    const hasText = formFields?.some((field) => field?.trim() !== "");
    if (!hasText) {
      toast.error("Veuillez d'abord remplir les champs ci-dessus");
      return;
    }

    const cleanedParticipants =
      newformstate?.participants?.map(
        ({
          emailInput,
          filteredSuggestions,
          showAdditionalFields,
          ...rest
        }) => ({
          ...rest,
          meeting_id: checkId || null,
        })
      ) || [];

    const inputData = {
      ...meetingData,
      objective,
      type,
      title,
      date,
      start_time,
      description,
      priority,
      alarm,
      autostart,
      playback,
      prise_de_notes,
      total_time: time,
      participants: cleanedParticipants,
      // teams: teams,
      teams: teams?.map((team) => team.id) || [],
      // steps: inputGroups,
      steps: newformstate?.steps || inputGroups,
      status: isDuplicate || isUpdated ? "active" : "draft",
      repetition: repetition || false,
      ...repetitionFields,
      moment_privacy,
      moment_privacy_teams:
        moment_privacy === "team" &&
          moment_privacy_teams?.length &&
          typeof moment_privacy_teams[0] === "object"
          ? moment_privacy_teams.map((team) => team.id)
          : moment_privacy_teams || [], // Send as-is if IDs are already present

      moment_password: moment_privacy === "password" ? moment_password : null,
      timezone: userTimeZone,
      location: location,
      city: city,
      country: country,
      street: street,
      zip: zip,
      room_details: room_details,
      phone: phone,
      share_by: share_by,
      _method: "put",
      meeting_id: checkId,
      add_team: teams?.length > 0 && selectedTab !== "tab7" ? true : false,
      update_meeting: updatedButton ? true : false
    };


    if (isUpdated) {
      inputData.update = true;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${checkId}`,
        inputData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const allSteps = response.data.data.steps.sort((a, b) => a.id - b.id);
        setInputGroups(allSteps);
        setParticipants(response.data.data.participants || []);

        // if (isAddStepButtonClicked) {
        //   setInputGroups((prev) => [
        //     ...prev,
        //     {
        //       order_no: inputGroups.length + 1,
        //       step: inputGroups.length + 1,
        //       title: "",
        //       count1: 0,
        //       count2: 0,
        //       time: 0,
        //     },
        //   ]);
        // }
        // setIsDuplicate(false)
        // setIsUpdated(false)
        // setCheckId(null)
        setLoading(false);
        setMeetingData({
          ...response.data.data,
          steps: allSteps,
          participants: response.data.data.participants || [],
        });
        // if (formState.repetition) {
        //   toast.success(t("messages.repetitionEnabled"));
        // }

      } else {
        setLoading(false);
        // toast.error("Failed to update meeting.");
      }

      return response;
    } catch (error) {
      setLoading(false);
      console.error("Failed to update draft:", error);
    } finally {
      setStepBtnDisabled(false);
      setLoading(false); // Hide loader after the API call is complete
    }
  };
  const GetSingleGuestData = async (GuestId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/participants/${GuestId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setSingleGuestData(response?.data?.data?.participant);
        setLoading(false);
      }
    } catch (error) {
      console.log("error while getting steps", error);
    } finally {
      setLoading(false);
    }
  };
  const deleteMeeting = async (meetingId) => {
    if (meetingId) {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/meetings/${meetingId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success(t("draftDeletedToast"));

          setFormState({
            objective: "",
            type: "",
            title: "",
            date: "",
            start_time: "",
            description: "",
            steps: [],
            alarm: 0,
            autostart: false,
            prise_de_notes: "Manual",
            playback: "Manual",
            time: 0,
            participants: [],
            teams: [],
            repetition: false,
            repetition_number: 1,
            repetition_frequency: "Daily",
            repetition_end_date: null,
            selected_days: [],
            moment_privacy: "private",
            moment_privacy_teams: [],
            moment_password: "",
            location: "",
            city: "",
            country: "",
            street: "",
            room_details: "",
            phone: "",
            zip: "",
            share_by: null,
          });
          navigate("/meeting");
          setStepsData([]);
          setMeetingData(null);
          setInputGroups([]);
          setParticipants([]);
          setCheckId(null);
          setMeeting(null);
          setAddParticipant(false);
          setFromDestination(false);
          setFromDestinationName(null);
          handleCloseModal();
          setLoading(false);
          await getMeetings();
        } else {
          toast.error("Failed to delete meeting.");
        }
      } catch (error) {
        toast.error(t(error.message));
      }
    } else {
      setFormState({
        objective: "",
        type: "",
        title: "",
        date: "",
        start_time: "",
        description: "",
        steps: [],
        alarm: 0,
        autostart: false,
        prise_de_notes: "Manual",
        playback: "Manual",
        time: 0,
        participants: [],
        teams: [],
        repetition: false,
        repetition_number: 1,
        repetition_frequency: "Daily",
        repetition_end_date: null,
        selected_days: [],
        moment_privacy: "private",
        moment_privacy_teams: [],
        moment_password: "",
        location: "",
        city: "",
        country: "",
        street: "",
        zip: "",
        room_details: "",
        phone: "",
        share_by: null,
      });
      updateSteps([]);
      setMeeting(null);
      setCheckId(null);
      handleCloseModal();
      setInputGroups([]);
      setAddParticipant(false);
      setFromDestination(false);
      setFromDestinationName(null);
      await getMeetings();
    }
  };
  const saveDraft = async (newformstate) => {
    if (checkId) {
      const {
        objective,
        type,
        title,
        description,
        date,
        start_time,
        priority,
        alarm,
        autostart,
        prise_de_notes,
        playback,
        time,
        teams,
        repetition,
        repetition_number,
        repetition_frequency,
        repetition_end_date,
        participants,
        selected_days,
        moment_privacy,
        moment_privacy_teams,
        moment_password,
        location,
        city,
        country,
        street,
        zip,
        room_details,
        phone,
        share_by,
      } = formState;

      console.log(formState, "formStateformStateformStateformState");
      const repetitionFields = repetition
        ? {
          repetition_number,
          repetition_frequency,
          repetition_end_date,
          selected_days,
        }
        : {
          repetition_number: 1,
          repetition_frequency: "Daily",
          repetition_end_date: null,
          selected_days: [],
        };

      const formFields = [
        objective,
        type,
        title,
        description,
        date,
        start_time,
      ];
      const hasText = formFields?.some((field) => field?.trim() !== "");
      if (!hasText) {
        toast.error("Veuillez d'abord remplir les champs ci-dessus");
        return;
      }

      const cleanedParticipants =
        newformstate?.participants?.map(
          ({
            emailInput,
            filteredSuggestions,
            showAdditionalFields,
            ...rest
          }) => ({
            ...rest,
            meeting_id: checkId || null,
          })
        ) || [];

      const inputData = {
        ...meeting,
        objective,
        type,
        title,
        date,
        start_time,
        description,
        priority,
        alarm,
        autostart,
        playback,
        prise_de_notes,
        total_time: time,
        participants: cleanedParticipants,
        // teams: teams,
        teams: teams?.map((team) => team.id) || [],
        timezone: userTimeZone,
        steps: inputGroups,
        status: "draft",
        repetition: repetition || false,
        ...repetitionFields,
        moment_privacy,
        moment_privacy_teams:
          moment_privacy === "team" &&
            moment_privacy_teams?.length &&
            typeof moment_privacy_teams[0] === "object"
            ? moment_privacy_teams.map((team) => team.id)
            : moment_privacy_teams || [], // Send as-is if IDs are already present
        moment_password: moment_privacy === "password" ? moment_password : null,
        location: location,
        city: city,
        country: country,
        street: street,
        zip: zip,
        room_details: room_details,
        phone: phone,
        share_by: share_by,
        _method: "put",
        meeting_id: checkId,
      };
      //
      try {
        const response = await axios.post(
          `${API_BASE_URL}/meetings/${checkId}`,
          inputData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          const allSteps = response.data.data.steps.sort((a, b) => a.id - b.id);
          setLoading(false);
          setInputGroups(allSteps);
          setParticipants(response.data.data.participants || []);
          setMeetingData({
            ...response.data.data,
            steps: allSteps,
            participants: response.data.data.participants || [],
          });

          setFormState({
            objective: "",
            type: "",
            title: "",
            date: "",
            start_time: "",
            description: "",
            steps: [],
            alarm: false,
            autostart: false,
            playback: "Manual",
            prise_de_notes: "Manual",
            time: 0,
            participants: [],
            teams: [],
            repetition: false,
            repetition_number: 1,
            repetition_frequency: "Daily",
            repetition_end_date: null,
            selected_days: [],
            moment_privacy: "private",
            moment_privacy_teams: [],
            moment_password: "",
            location: "",
            city: "",
            country: "",
            street: "",
            zip: "",
            room_details: "",
            phone: "",
            share_by: null,
          });
          setCheckId(null);
          updateSteps([]);
          handleCloseModal();
          setMeeting(null);
          await getMeetings();
        } else {
          toast.error("Failed to update meeting.");
        }
      } catch (error) {
        console.error("Failed to update draft:", error);
        // toast.error(t("messages.draftSaveError"));
        handleCloseModal();
      } finally {
        setStepBtnDisabled(false);
        setLoading(false); // Hide loader after the API call is complete
        updateSteps([]);
        setCheckId(null);
        setMeeting(null);
        setInputGroups([]);
      }
    } else {
      const slides = [];
      let prevCount = 0;
      for (let i = 0; i < inputGroups.length; i++) {
        const currentStep = inputGroups[i];
        const counts = [prevCount, prevCount + currentStep.count2];

        slides.push({
          value: currentStep.title,
          counts: counts,
        });

        prevCount = counts[1];
      }

      const repetitionFields = formState.repetition
        ? {
          repetition_number: formState.repetition_number,
          repetition_frequency: formState.repetition_frequency,
          repetition_end_date: formState.repetition_end_date,
          selected_days: formState.selected_days,
        }
        : {
          repetition_number: 1,
          repetition_frequency: "Daily",
          repetition_end_date: null,
          selected_days: [],
        };

      const inputData = {
        objective: fromDestination ? fromDestinationName : formState.objective,
        type: formState.type,
        title: formState.title,
        date: formState.date,
        start_time: formState.start_time,
        description: formState.description,
        steps: inputGroups,
        alarm: formState.alarm,
        autostart: formState.autostart,
        total_time: formState.time,
        prise_de_notes: formState.prise_de_notes,
        playback: formState?.playback,
        priority: formState.priority,
        participants: formState.participants ? formState.participants : [],
        teams: formState.teams,
        meeting_id: formState.meetingId,
        // status: "draft",
        status: addParticipant ? formState?.status : "draft",
        // status: addParticipant ? "in_progress" : "draft",
        repetition: formState.repetition || false,
        ...repetitionFields,
        moment_privacy: formState.moment_privacy,
        moment_password:
          formState.moment_privacy === "password" ? formState.password : null,
        moment_privacy_teams: [],
        location: formState.location,
        city: formState.city,
        country: formState.country,
        street: formState.street,
        zip: formState.zip,
        room_details: formState.room_details,
        phone: formState.phone,
        share_by: formState.share_by,
        timezone: userTimeZone,
      };

      try {
        const response = await axios.post(
          `${API_BASE_URL}/meetings`,
          inputData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status) {
          toast.success(t("messages.draftSaved"));
          setLoading(false);
          setFormState({
            objective: "",
            type: "",
            title: "",
            date: "",
            start_time: "",
            description: "",
            steps: [],
            alarm: false,
            autostart: false,
            playback: "Manual",
            prise_de_notes: "Manual",
            time: 0,
            participants: [],
            teams: [],
            repetition: false,
            repetition_number: 1,
            repetition_frequency: "Daily",
            repetition_end_date: null,
            selected_days: [],
            moment_privacy: "private",
            moment_privacy_teams: [],
            moment_password: "",
            location: "",
            city: "",
            country: "",
            street: "",
            zip: "",
            room_details: "",
            phone: "",
            share_by: null,
          });
          setCheckId(null);
          updateSteps([]);
          setMeeting(null);
          handleCloseModal();
          await getMeetings();
        }
        return response;
      } catch (error) {
        handleCloseModal();
        setLoading(false);
        console.error("Failed to save draft:", error);
      }
    }
  };

  const userid = sessionStorage.getItem("user_id");

  const onSuccess = (response) => {
    console.log("response", response);
    const { code } = response;
    console.log("code:", code);
    axios
      .post(
        `${API_BASE_URL}/auth/google`,
        {
          code: code,
          user_id: userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(async (response) => {
        console.log("API Response:", response.data);
        if (response.data) {
          // setOpen(false);
          // setActiveTab("Evènements programmés");
          setIsLoggedIn(true);
          setLoading(false);
          await updateMeetingPage();
          sessionStorage.setItem("is_logged_in", "true");
          sessionStorage.setItem("access_token", response?.data?.access_token);
          sessionStorage.setItem(
            "refresh_token",
            response?.data?.refresh_token
          );
          localStorage.setItem("access_token", response?.data?.access_token);
          localStorage.setItem("refresh_token", response?.data?.refresh_token);

          const expiresIn = response.data?.expires_in; // e.g., 3598 seconds
          const expirationTime = Date.now() + expiresIn * 1000; // current time + expires_in in milliseconds
          sessionStorage.setItem("token_expiration_time", expirationTime);
          localStorage.setItem("token_expiration_time", expirationTime);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const updateMeetingPage = async () => {
    const {
      objective,
      type,
      title,
      description,
      date,
      start_time,
      priority,
      alarm,
      autostart,
      prise_de_notes,
      playback,
      time,
      teams,
      repetition,
      repetition_number,
      repetition_frequency,
      repetition_end_date,
      // participants,
      selected_days,
      moment_privacy,
      moment_privacy_teams,
      moment_password,
      location,
      city,
      country,
      street,
      zip,
      room_details,
      phone,
      share_by,
    } = formState;
    setLoading(true);
    setIsCompleted(true);
    // if (
    //   value.trim() === "" ||
    //   title.trim() === "" ||
    //   date.trim() === "" ||
    //   startTime.trim() === ""
    // ) {
    //   toast.error(t("messages.emptyFields"));
    //   setLoading(false);

    //   return;
    // }
    // CREATOR
    let participantArrayWithCreatorAdded = [
      ...participants,
      {
        first_name: JSON.parse(sessionStorage.getItem("user")).name,
        last_name: JSON.parse(sessionStorage.getItem("user")).last_name,
        email: JSON.parse(sessionStorage.getItem("user")).email,
        post: JSON.parse(sessionStorage.getItem("user")).post,
        isCreator: true,
      },
    ];

    // setButtonDisabled(true);

    // Check for duplicate step names
    // const stepNames = meetingData?.steps?.map((step) => step.title);
    // const duplicateStepNames = stepNames?.filter(
    //   (stepName) =>
    //     stepNames.indexOf(stepName) !== stepNames.lastIndexOf(stepName)
    // );
    // if (duplicateStepNames.length > 0) {
    //   toast.error(t("messages.stepNames"));
    //   setButtonText("Valider");

    //   setButtonDisabled(false);
    //   setLoading(false);

    //   return;
    // }

    // if (steps?.length === 0) {
    //   // toast.error(t("messages.emptySteps"));
    //   // setButtonText("Valider");
    //   // setButtonDisabled(false);
    //   setLoading(false);
    //   return;
    // }
    // Parse the start time string
    const [hour, minute] = meetingData?.start_time?.split(":").map(Number);

    // Add one hour to the hour component
    let endHour = hour + 1;

    // If end hour is greater than or equal to 24, subtract 24
    if (endHour >= 24) {
      endHour -= 24;
    }

    // Format the end time as a string
    const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;

    console.log("endTime str->", endTimeStr);

    const repetitionFields = formState.repetition
      ? {
        repetition_number: formState.repetition_number,
        repetition_frequency: formState.repetition_frequency,
        repetition_end_date: formState.repetition_end_date,
        selected_days: formState.selected_days,
      }
      : {
        repetition_number: 1,
        repetition_frequency: "Daily",
        repetition_end_date: null,
        selected_days: [],
      };

    const inputData = {
      ...meeting,
      objective,
      type,
      title,
      date,
      start_time: `${start_time}`,
      end_time: endTimeStr,
      description,
      priority,
      alarm,
      autostart,
      prise_de_notes,
      timezone: userTimeZone,
      total_time: time,
      steps: steps,
      participants: meetingData?.participants,
      teams: teams?.map((team) => team.id) || [],
      // teams:  teams,
      repetition: repetition || false,
      ...repetitionFields,
      moment_privacy,
      // moment_privacy_teams:
      // moment_privacy === "team" ? moment_privacy_teams : [],
      moment_privacy_teams:
        moment_privacy === "team" &&
          moment_privacy_teams?.length &&
          typeof moment_privacy_teams[0] === "object"
          ? moment_privacy_teams.map((team) => team.id)
          : moment_privacy_teams || [], // Send as-is if IDs are already present
      moment_password: moment_privacy === "password" ? moment_password : null,
      location: location,
      city: city,
      country: country,
      street: street,
      zip: zip,
      room_details: room_details,
      phone: phone,
      share_by: share_by,
      // access_token: token,
      // user_id: userid,
      // status: "active",
      // status: addParticipant ? "in_progress" : "active",
      status: addParticipant
        ? formState?.status
        : formState?.type === "Special"
          ? "closed"
          : formState?.type === "Law"
            ? "closed"
            : "active",
      update_meeting: true,
      _method: "put",
    };
    console.log("update meeting validator-->", inputData);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${checkId}`,
        inputData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        setSolution(null)
        const data = response?.data?.data
        sessionStorage.setItem("meetingId", response.data?.data?.id);

        // setMeetingId(response.data?.data?.id);
        setMeetingData(response.data?.data);
        if (isDuplicate) {
          toast.success(t("meetingDuplicateMsg"));
        } else if (isUpdated) {
          toast.success(t("meetingUpdatedMsg"));
        } else {
          toast.success(t("messages.activeSaved"));

        }
        setIsCompleted(false);
        if (isDuplicate || isUpdated) {
          if (data?.type === "Special" || data?.type === "Law") {
            navigate(`/present/invite/${data?.id}`, { state: { data, from: "meeting" } });
          }
          else {
            navigate(`/invite/${data?.id}`, { state: { data, from: "meeting" } });
          }
        } else {
          if (data?.type === "Special" || data?.type === "Law") {
            navigate(`/present/invite/${data?.id}`, { state: { data, from: "meeting" } });
          } else {
            navigate(`/invite/${data?.id}`, { state: { data, from: "meeting" } });
          }

        }
        handleCloseModal();

        // setButtonDisabled(false);
        // navigate("/meeting");
        // setOpen(true);
        updateSteps([]);
        setStepsData([]);
        setLoading(false);

        setFormState({
          selectedOption: null,
          title: "",
          date: "",
          start_time: "",
          description: "",
          type: "",
          priority: "",
          alarm: false,
          playback: "Manual",
          autostart: false,
          prise_de_notes: "Manual",
          objective: "",
          participants: [],
          id: null,
          repetition: false,
          repetition_number: 1,
          repetition_frequency: "Daily",
          repetition_end_date: null,
          selected_days: [],
          teams: [],
          moment_privacy: "private",
          moment_password: null,
          location: null,
          city: null,
          country: null,
          street: null,
          zip: null,
          room_details: null,
          phone: null,
          share_by: null,
        });
        setMeeting(null);
        setCheckId(null);
        setIsUpdated(false);
        setIsDuplicate(false);
        setInputGroups([]);
        await getMeetings();
      }
    } catch (error) {
      // console.log("error", error);
      setLoading(false);
      setIsCompleted(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const recurrentMeetingAPI = async () => {
    if (!formState.repetition) return

    try {
      const payload = {
        meeting_id: checkId,
      };
      const response = await axios.post(
        `${API_BASE_URL}/recurring-meeting`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response?.status) {
        console.log("recurrency_meeting", response);
        if (formState.repetition) {
          toast.success(t("messages.repetitionEnabled"));

        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onFailure = (response) => {
    console.error("Google Login Failed:", response);
    // handleRedirectToSignup();
  };

  const loggedInUserMail = sessionStorage.getItem("email");
  const login = useGoogleLogin({
    onSuccess,
    onFailure,
    scope:
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    flow: "auth-code",
    hint: loggedInUserMail,
    access_type: "offline",
    prompt: "select_account", // Ensures the user selects or creates an account
    onNonOAuthError: (response) => {
      console.error("Non-OAuth Error:", response);
      // Handle non-OAuth-related errors
    },
  });

  const validate = () => {
    if (token === null && meeting?.location === "Google Meet") {
      login();
    } else {
      updateMeetingPage();
      recurrentMeetingAPI()
    }
  };
  // const validate = () => {
  //   if (token === null) {
  //     login();
  //   } else {
  //     updateMeetingPage();
  //   }
  // };
  const validateAndUpdate = () => {
    if (token === null && meeting?.location === "Google Meet") {
      login();
    } else {
      updateMeetingPage();
      recurrentMeetingAPI();
    }
  };

  const updateGuest = async (GuestId) => {
    const inputData = {
      ...singleGuestData,
      _method: "put",
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/participants/${GuestId}`,
        inputData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        setLoading(false);
        setSingleGuestData({});
        console.log(response.status);
      }
      return response;
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <CreateMeetingContext.Provider
      value={{
        formState,
        setFormState,
        handleInputBlur,
        checkId,
        setCheckId,
        loading,
        getMeeting,
        meeting,
        setMeeting,
        stepsData,
        validate,
        validateAndUpdate,
        deleteMeeting,
        GetSingleGuestData,
        singleGuestData,
        setSingleGuestData,
        saveDraft,
        open,
        handleShow,
        handleCloseModal,
        updateGuest,
        setIsDuplicate,
        isDuplicate,
        setIsUpdated,
        isUpdated,
        setIsCompleted,
        isCompleted,
        timeUnitsTotal,
        addParticipant,
        setAddParticipant,
        call,
        setCall,
        destinationId,
        setDestinationId,
        destinationUniqueId,
        setDestinationUniqueId,

        fromDestination,
        setFromDestination,
        fromDestinationName,
        setFromDestinationName,
        setCallDestination,
        callDestination,

        setTeamAdded,
        teamAdded,

        recurrentMeetingAPI,

        setSelectedTab,
        selectedTab,

        setUpdatedButton,
        updatedButton,

        quit, setQuit

      }}
    >
      {children}
    </CreateMeetingContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(CreateMeetingContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};
