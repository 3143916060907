import React, { useEffect, useRef, useState } from "react";
import {
  NavLink,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, Assets_URL } from "../Apicongfig";
import { useDraftMeetings } from "../../context/DraftMeetingContext";
import { CiLogout, CiUser } from "react-icons/ci";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { useTabs } from "../../context/TabContext";
import { useSidebarContext } from "../../context/SidebarContext";

function MobileSidebar({
  isSidebarOpen,
  toggleSidebar,
  onLogout,
  open,
  handleClose,
}) {
  console.log("open", open);
  const { activeTab, setActiveTab } = useTabs();
  const { user } = useUser();
  const [t, i18n] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const isActiveContract = [
    `/contract`,
    "/ModifierContract",
    "/ContractLinkEntreprises",
    "/ContractToTeam",
    `/readContract/${params.id}`,
    `/ModifierContract/${params.id}`,
    `/CopyContract/${params.id}`,
    `/CopyClosedContract/${params.id}`,
  ].includes(location.pathname);

  const isActiveEntreprises = [
    `/Enterprises`,
    "/ModifierEnterprises",
    "/EntreprisesToTeam",
    `/ModifierEnterprises/${params.id}`,
  ].includes(location.pathname);

  const isActiveTeam = [
    `/Team`,
    `/ModifierTeam/${params.id}`,
    `/users/${params.id}`,
    `/ModifierUser/${params.id}`,
  ].includes(location.pathname);

  const isActiveSupport = [`/customer-support`].includes(location.pathname);

  const isActiveMeeting =
    location.pathname.startsWith("/meeting") ||
    location.pathname.startsWith("/graph") ||
    location.pathname.startsWith("/view") ||
    location.pathname.startsWith("/copy") ||
    location.pathname.startsWith("/d") ||
    location.pathname.startsWith("/Play") ||
    location.pathname.startsWith("/presentation") ||
    location.pathname.startsWith("/validateMeeting") ||
    location.pathname.startsWith("/meetings/drafts") ||
    location.pathname.startsWith("/present/invite") ||
    location.pathname.startsWith("/invite");

  const isActiveAction = location.pathname.startsWith("/action");

  const isActiveSolution = location.pathname.startsWith("/solution");

  const isActiveGuest = [
    "/Invities",
    `/invitiesToMeeting/${params.id}`,
    `/participantToAction/${params.id}`,
    `/updateParticipant/${params.id}`,
  ].includes(location.pathname);

  // Check if the current route matches either `/newsletter/terms-and-conditions`
  // or `destination/:unique_id/:id`
  const hideSidebar =
    location.pathname === "/newsletter/terms-and-conditions" ||
    /^\/destination\/[^/]+\/[^/]+$/.test(location.pathname);

  const handleLogout = () => {
    if (onLogout) {
      onLogout();
    }
    //toast.success(t("header.logoutSuccess"));
    navigate("/");
  };
  const role = sessionStorage.getItem("type");

  // const { profileImage, setProfileImage } = useHeaderTitle();
  const [profileImage, setProfileImage] = useState(null);
  console.log("profileImage", profileImage);
  const { updateLanguage } = useDraftMeetings();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    console.log("lang", lang);
    updateLanguage(lang);
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const { show } = useSidebarContext();
  const [show, setShow] = useState(true);
  const closeSidebar = () => {
    setShow(false); // Close the sidebar
  };
  console.log("show-->", show);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const dropdownRef = useRef(null);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userID = sessionStorage.getItem("user_id");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/users/${userID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status === 200) {
          // console.log("response in header", response);
          setProfileImage(response.data.data?.image);
        }
      } catch (error) {
        // console.log(error?.message);
      }
    };
    getUser();
  }, [userID, profileImage]);
  const iconStyle = {
    fill: " #5088ee",
    width: "50px",
    height: "50px",
    background: "azure",
    borderRadius: "50%",
    cursor: "pointer",
  };

  // Don't render the sidebar if the route matches the specified conditions
  if (hideSidebar) {
    return null; // Return null to not render the sidebar
  }
  return (
    <>
      <div
        className={`sidebar1`}
        id="sidebar"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="container-fluid ">
          <div className="text-center p-4">
            <div className="profile-logo">
              {profileImage ? (
                <img
                  className="user-img"
                  // src={Assets_URL + "/" + profileImage}
                  // src={
                  //   user.image
                  //     ? Assets_URL + "/" + user.image
                  //     : Assets_URL + "/" + profileImage
                  // }
                  src={
                    profileImage?.startsWith("users/")
                      ? Assets_URL + "/" + profileImage
                      : // : item?.image?.startsWith("users/")
                        // ? Assets_URL + "/" + item.image
                        profileImage
                  }
                  alt="profile"
                  // onErrorCapture={(e) => {
                  //   e.target.onerror = null;
                  //   e.target.src = "/Assets/avatar.jpeg";
                  // }}
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <svg
                  className="user-img"
                  onClick={toggleDropdown}
                  style={iconStyle}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                </svg>
              )}

              <img
                className="logout-icon"
                src="/Assets/Avatar_company.svg"
                height="12px"
                width="12px"
                alt="tektime"
              />
            </div>
            {/* {isDropdownOpen && (
              <div className="dr" ref={dropdownRef}>
                <div className="dropdown-arrow-up"></div>

                <div
                  className="dropdown-item"
                  onClick={() => {
                    navigate("/profile");
                    closeDropdown();
                  }}
                >
                  <div
                    style={{
                      borderBottom: " 1px solid #D5D5D5",
                      width: "100%",
                      textAlign: "left",
                      paddingBottom: " 9px",
                    }}
                  >
                    <span role="img" aria-label="Profile">
                      <img src="/Assets/sidebar_profile.svg" alt="profile" />
                    </span>{" "}
                    <span className="dropdown-profile">Profile</span>
                  </div>
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    handleLogout();
                    closeDropdown();
                  }}
                >
                  <span role="img" aria-label="Logout" className="me-3">
                    <CiLogout />
                  </span>{" "}
                  <span className="dropdown-logout">{t("sidebar.logout")}</span>
                </div>
              </div>
            )} */}
          </div>
          <div className="side-btn">
            <div
              style={{
                position: "absolute",
                top: isDropdownOpen ? "25%" : "25%",
                // : role === "User" ? "40%"
                width: "100%",
              }}
              // className={isDropdownOpen ? 'dropdown-content' : ''}
            >
              {role !== "SuperAdmin" &&
                role !== "Admin" &&
                role !== "User" &&
                role !== "Invitee" && (
                  <NavLink
                    className={`nav-link ${isActiveContract ? "active" : ""}`}
                    activeClassName={role === "MasterAdmin" ? "active" : ""}
                    to={"/contract"}
                    activeStyle={{ color: "#0026B1" }}
                    onClick={() => setDropdownOpen(false)}
                  >
                    <button>
                      {isActiveContract ? (
                        <img
                          src="/Assets/sidebar_subscription_active.svg"
                          alt="subscription"
                        />
                      ) : (
                        <img
                          src="/Assets/sidebar_subscription.svg"
                          alt="subscription"
                        />
                      )}
                      <span>{t("sidebar.subscriptions")}</span>
                    </button>
                  </NavLink>
                )}
              {role !== "Admin" && role !== "User" && role !== "Invitee" && (
                <NavLink
                  className={`nav-link ${isActiveEntreprises ? "active" : ""}`}
                  activeClassName={role === "SuperAdmin" ? "active" : ""}
                  to={"/Enterprises"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => setDropdownOpen(false)}
                >
                  <button>
                    {isActiveEntreprises ? (
                      <img
                        src="/Assets/sidebar_enterprise_active.svg"
                        alt="enterprise"
                      />
                    ) : (
                      <img
                        src="/Assets/sidebar_enterprise.svg"
                        alt="enterprise"
                      />
                    )}
                    <span>{t("sidebar.enterprises")}</span>
                  </button>
                </NavLink>
              )}
              {role !== "User" && role !== "Invitee" && (
                <NavLink
                  className={`nav-link ${isActiveTeam ? "active" : ""}`}
                  activeClassName="active"
                  to={"/Team"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => setDropdownOpen(false)}
                >
                  <button>
                    {isActiveTeam ? (
                      <img src="/Assets/sidebar_team_active.svg" alt="team" />
                    ) : (
                      <img src="/Assets/sidebar_team.svg" alt="team" />
                    )}

                    <span>{t("sidebar.teams")}</span>
                  </button>
                </NavLink>
              )}

              {
                <NavLink
                  className={`nav-link ${isActiveGuest ? "active" : ""}`}
                  activeClassName={role === "User" ? "active" : ""}
                  to="/Invities"
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => {
                    setDropdownOpen(false);
                    handleClose();
                  }}
                >
                  <button>
                    {isActiveGuest ? (
                      <img
                        src="/Assets/sidebar_active_destination.svg"
                        alt="destination"
                      />
                    ) : (
                      <img
                        src="/Assets/sidebar_destination.svg"
                        alt="destination"
                      />
                    )}

                    <span>{t("sidebar.guests")}</span>
                  </button>
                </NavLink>
              }
              {/* {
                <NavLink
                  className={`nav-link ${isActiveSolution ? "active" : ""}`}
                  activeClassName="active"
                  to={"/solution"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => {
                    setDropdownOpen(false);
                    // setActiveTab("tab2");
                  }}
                >
                  <button>
                    {isActiveSolution ? (
                      <img
                        src="/Assets/sidebar-solution-active.svg"
                        alt="solution"
                      />
                    ) : (
                      <img
                        src="/Assets/sidebar-solution-active.svg"
                        alt="action"
                      />
                    )}

                    <span>{t("sidebar.solution")}</span>
                  </button>
                </NavLink>
              } */}
              {
                <NavLink
                  className={`nav-link ${isActiveMeeting ? "active" : ""}`}
                  activeClassName="active"
                  to={"/meeting"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => {
                    setDropdownOpen(false);
                    handleClose();
                    setActiveTab("tab2");
                  }}
                >
                  <button>
                    {isActiveMeeting ? (
                      <img
                        src="/Assets/sidebar_meeting_active.svg"
                        alt="meeting"
                      />
                    ) : (
                      <img src="/Assets/sidebar_meeting.svg" alt="meeting" />
                    )}

                    <span>{t("sidebar.meetings")}</span>
                  </button>
                </NavLink>
              }
              {
                <NavLink
                  className={`nav-link ${isActiveAction ? "active" : ""}`}
                  activeClassName="active"
                  to={"/action"}
                  activeStyle={{ color: "#0026B1" }}
                  onClick={() => {
                    setDropdownOpen(false);
                    // setActiveTab("tab2");
                  }}
                >
                  <button>
                    {isActiveAction ? (
                      <img
                        src="/Assets/sidebar-action-active.svg"
                        alt="action"
                      />
                    ) : (
                      <img src="/Assets/sidebar-action.svg" alt="action" />
                    )}

                    <span>{t("sidebar.action")}</span>
                  </button>
                </NavLink>
              }
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                width: "100%",
                textAlign: "center",
              }}
              onClick={() => handleLogout()}
            >
              {/* <img
                src="/Assets/tektime-sidebar-logo.svg"
                alt="tektime"
                width={"40px"}
              /> */}
              <span role="img" aria-label="Logout">
                <CiLogout size={30} color="white" />
              </span>{" "}
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
export default MobileSidebar;
