import React, { createContext, useContext, useState } from 'react';

const StepContext = createContext();

export const useSteps = () => useContext(StepContext);

export const StepProvider1 = ({ children }) => {
  const [steps, setSteps] = useState([]);
  const [solutionSteps,setSolutionSteps] = useState([]);
  const[solutionType,setSolutionType] = useState(null);
  const[solutionNote,setSolutionNote] = useState('Manual');
  const[solutionShareBy,setSolutionShareBy] = useState(null);
  const[solutionAlarm,setSolutionAlarm] = useState(false);
  const[solutionTitle,setSolutionTitle] = useState(null);

  const updateSteps = (newSteps) => {
    setSteps(newSteps);
  };

  const updateSolutionSteps = (newSolutionSteps) => {
    setSolutionSteps(newSolutionSteps);
  };

  return (
    <StepContext.Provider value={{ steps, updateSteps, solutionSteps, updateSolutionSteps,setSolutionType,solutionType,setSolutionNote,solutionNote,setSolutionShareBy,solutionShareBy,setSolutionAlarm,solutionAlarm,solutionTitle,setSolutionTitle}}>
      {children}
    </StepContext.Provider>
  );
};
