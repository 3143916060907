import React from "react";
import Heros from "../Components/Elements/landingPages/Heros";
// import Brands from "../Component/Elements/landingPages/Brands";
import Whatistektime from "../Components/Elements/landingPages/Whatistektime";
import Trackmoment from "../Components/Elements/landingPages/Trackmoment";
import Participantsandassign from "../Components/Elements/landingPages/Participantsandassign";
import Givingservices from "../Components/Elements/landingPages/Givingservices";
import Pricingandplans from "../Components/Elements/landingPages/Pricingandplans";
import Whychooseus from "../Components/Elements/landingPages/Whychooseus";
import Features from "../Components/Elements/landingPages/Features";
import Streamllineyourmeeting from "../Components/Elements/landingPages/Streamllineyourmeeting";
import Testimonials from "../Components/Elements/landingPages/Testimonials";
import Percentage from "../Components/Elements/landingPages/Percentage";
import UseCases from "../Components/Elements/landingPages/UseCases";
import Limpactsocial from "../Components/Elements/landingPages/Limpactsocial";
function Home() {
  return (
    <div>
      <div>
        <video className="video-bg" autoPlay muted loop>
          <source src="https://tektime-storage.s3.eu-north-1.amazonaws.com/tektime-home.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="hero-conmponent">
        <Heros />
        {/* <Brands /> */}
        <Whatistektime />
        <Percentage />
        <Trackmoment />
        <Participantsandassign />
        <Givingservices />
        <Limpactsocial />
        <UseCases />
        <Pricingandplans
          heading="Our Pricing and plans"
          mainheading={
            <>
              We offer a comprehensive meeting{" "}
              <br className="d-none d-md-block" /> platform designed for your
              needs.
            </>
          }
        />
        <Whychooseus />
        <Features />
        <Streamllineyourmeeting />
        <Testimonials />
      </div>
    </div>
  );
}

export default Home;
