import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GoPlusCircle } from "react-icons/go";
import { AiFillDelete } from "react-icons/ai";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa";
import CounterContainer from "./PlayMeeting/components/CounterContainer";
import { useCounterContext } from "./context/CounterContext";
import { useTranslation } from "react-i18next";
import { MdOutlineModeEdit } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";
import { image_upload_handler_callback, optimizeEditorContent } from "./Chart";
import ConfirmationModal from "../../Utils/ConfirmationModal"; // Adjust the import path as needed
import ShowIF from "../../Utils/ShowIF";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useDraftMeetings } from "../../../context/DraftMeetingContext";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { getUserRoleID } from "../../Utils/getSessionstorageItems";
import Select from "react-select";
import Spreadsheet from "react-spreadsheet";
import { read, utils } from "xlsx";
// import ReactToggle from "react-toggle";
import ReactToggle from "react-toggle";
import "react-toggle/style.css";


const Play = ({ props }) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [storedTranscript, setStoredTranscript] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const editorRef = useRef(null);
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    meetingData,
    savedTime,
    negativeTimes,
    activeStepIndex,
    setNextActiveStep,
    setPreviousActiveStep,
    stepDelay,
    setStepDelay,
  } = useCounterContext();
  const [inputData, setInputData] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [stepTitle1, setStepTitle1] = useState("");
  const currentDate = new Date();
  const inputDate = new Date(inputData.date);
  const currentDateTime = moment(currentDate);
  const inputDateTime = moment(inputDate);
  const [stepsState, setStepsState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [stepNotes, setStepNotes] = useState([]);
  const [decision, setDecision] = useState([]);
  const [real_start_time, setRealStartTime] = useState("");
  const [buttonText, setButtonText] = useState(t("Close"));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showStepContentEditor, setShowStepContentEditor] = useState(false);
  // REACT QUILL EDITORS for Notes and Decisions:
  const [notesEditor, setNotesEditor] = useState({
    value: "",
    showEditor: false,
  });
  const [decisionEditor, setDecisionEditor] = useState({
    value: "",
    showEditor: false,
  });
  const [planDActionEditor, setPlanDActionEditor] = useState({
    showEditor: false,
  });
  const [isAutomatic, setIsAutomatic] = useState(false);
  const [isAutomaticNext, setIsAutomaticNext] = useState(false);
  const [previousSteps, setPreviousSteps] = useState([]);
  const [myStepNoteId, setMyStepNoteId] = useState(null);
  const { updateLanguage, language } = useDraftMeetings();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "You have unsaved changes. Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Handle Select field changes
  const handleSelectChange = (selectedOption, field) => {
    const updatedDecision = [...decision];
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDateTime = new Date().toLocaleString("en-US", { timeZone: userTimeZone });
    const [date, time] = currentDateTime?.split(", ");

    updatedDecision[activeStepIndex] = {
      ...updatedDecision[activeStepIndex],
      [field]: selectedOption.value,
      creation_date: new Date().toISOString().split("T")[0],
      creation_time: time,  // The time part

    };
    setDecision(updatedDecision);
  };

  // Handle Textarea changes
  const handleTextChange = (event) => {
    const updatedDecision = [...decision];
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDateTime = new Date().toLocaleString("en-US", { timeZone: userTimeZone });
    const [date, time] = currentDateTime?.split(", ");
    updatedDecision[activeStepIndex] = {
      ...updatedDecision[activeStepIndex],
      decision: event.target.value,
      creation_date: new Date().toISOString().split("T")[0],
      creation_time: time,  // The time part

    };
    setDecision(updatedDecision);
  };

  // const [nextStep,setNextStep] = useState(null)
  const [estimateTime, setEstimateTime] = useState(null);
  const [estimateDate, setEstimateDate] = useState(null);
  const [excelData, setExcelData] = useState(null);



  const getMeeting = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/meetings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const meetingData = response.data?.data;

        const inProgressIndex = meetingData?.steps?.findIndex(
          (step) => step?.step_status === "in_progress"
        );
        console.log("inProgressIndex", inProgressIndex);

        if (meetingData?.prise_de_notes === "Automatic") {
          setIsAutomatic(true);
        } else {
          setIsAutomatic(false);
        }
        // if (meetingData?.playback === "automatic") {
        //   setIsAutomaticNext(true);
        // } else {
        //   setIsAutomaticNext(false);
        // }
        setPreviousSteps(meetingData?.steps);
        setStepNotes(meetingData?.steps?.map((step) => step?.note));
        setDecision(meetingData?.steps?.map((step) => step?.decision));
        setStepData(meetingData?.steps);
        setTableData(meetingData?.plan_d_actions || []);
        setInputData(meetingData);


        // setEmailCampaign(meetingData?.email_campaigns);
        setLoading(false);
        // Set the CurrentStepIndex based on in-progress step or default to 0
        if (inProgressIndex !== -1) {
          setCurrentStepIndex(inProgressIndex);
        }
        // const negativeTimes = response?.data?.data?.steps?.map((step) => {
        //   if (step.negative_time) return parseInt(step.negative_time);
        // });
        // // console.log("negativeTimes", negativeTimes);
        // setNegativeTimes(negativeTimes);
        const estimate_time = response?.data?.data?.estimate_time;
        if (estimate_time) {
          // Split the date and time correctly
          const [date, timeWithMilliseconds] = estimate_time.split("T");

          // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
          const timeOnly = timeWithMilliseconds.split(".")[0];

          // Format the time to "10h37"
          const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

          console.log(formattedTime); // Output: 10h37

          setEstimateTime(formattedTime);
          setEstimateDate(date);
        }

        return meetingData;
      }
    } catch (error) {
      setLoading(false);
      return null;
    }
  };



  const getMeetingById = async () => {

    try {
      const response = await axios.get(`${API_BASE_URL}/meetings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const meetingData = response.data?.data;
        if (meetingData?.prise_de_notes === "Automatic") {
          setIsAutomatic(true);
        } else {
          setIsAutomatic(false);
        }
        // if (meetingData?.playback === "automatic") {
        //   setIsAutomaticNext(true);
        // } else {
        //   setIsAutomaticNext(false);
        // }
        setPreviousSteps(meetingData?.steps);
        setStepNotes(meetingData?.steps?.map((step) => step?.note));
        setDecision(meetingData?.steps?.map((step) => step?.decision));
        setStepData(meetingData?.steps);
        setTableData(meetingData?.plan_d_actions || []);
        setInputData(meetingData);
        // setEmailCampaign(meetingData?.email_campaigns);

        const estimate_time = response?.data?.data?.estimate_time;
        if (estimate_time) {
          // Split the date and time correctly
          const [date, timeWithMilliseconds] = estimate_time.split("T");

          // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
          const timeOnly = timeWithMilliseconds.split(".")[0];

          // Format the time to "10h37"
          const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

          console.log(formattedTime); // Output: 10h37

          setEstimateTime(formattedTime);
          setEstimateDate(date);
        }

        return meetingData;
      }
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  if (!browserSupportsSpeechRecognition) {
    alert("This browser does not support speech recognition.");
  }
  useEffect(() => {
    getMeeting();
    getMeetingById();
  }, [id]);

  useEffect(() => {
    const initialState = stepData?.map((step) => ({
      remainingTime: step.time * 60, // Initialize with the duration in seconds
      isPlaying: false,
      pausedTime: null,
    }));
    setStepsState(initialState);
  }, [stepData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setStepsState((prevState) => {
        if (!Array.isArray(prevState)) {
          // If not, initialize it as an empty array
          prevState = [];
        }
        const updatedState = [...prevState];
        const currentStep = updatedState[currentStepIndex];

        // Automatically play when navigating to a step
        if (inputData && currentStep && !currentStep.isPlaying) {
          updatedState[currentStepIndex] = {
            ...currentStep,
            isPlaying: true,
            pausedTime: Date.now(),
          };
        }
        if (
          currentStep &&
          currentStep.isPlaying &&
          currentStep.remainingTime > 0
        ) {
          updatedState[currentStepIndex] = {
            ...currentStep,
            remainingTime: currentStep.remainingTime,
          };
        }
        return updatedState;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStepIndex]);

  const [delay, setDelay] = useState("");

  useEffect(() => {
    if (currentDateTime.isSame(inputDateTime, "day")) {
      const startTime = moment(inputData.start_time, "HH:mm");
      const timeDifferenceInMillis = Math.abs(currentDateTime - startTime);
      const duration = moment.duration(timeDifferenceInMillis);

      if (currentDateTime.isSame(startTime)) {
        setDelay("");
      } else {
        const formattedTime = `${t(
          `The meeting started with`
        )} \n${duration.minutes()} minutes ${currentDateTime.isAfter(startTime)
          ? `${t("delay")}`
          : `${t("in advance")}`
          }.`;

        if (duration.asMinutes() < 1) {
          setDelay("");
        } else {
          setDelay(formattedTime);
        }
      }
    }
  }, [inputData.start_time]);

  useEffect(() => {
    if (inputData && inputData.steps && inputData.steps.length > 0) {
      if (
        inputData.steps[currentStepIndex] &&
        inputData.steps[currentStepIndex].time
      ) {
      }
      setStepTitle1(
        currentStepIndex < inputData?.steps?.length
          ? inputData?.steps[currentStepIndex]?.title || ""
          : ""
      );
    }
  }, [inputData, currentStepIndex]);

  useEffect(() => {
    const fetchExcel = async () => {
      try {
        // Fetch Excel data if applicable
        const currentStep = inputData?.steps[currentStepIndex];
        if (currentStep && currentStep?.editor_type === "Excel" && currentStep.file) {
          const fileResponse = await axios.get(
            `${Assets_URL}/${currentStep.file}`,
            { responseType: "arraybuffer" }
          );
          console.log("file response", fileResponse);

          const fileData = fileResponse?.data;
          const workbook = read(fileData, { type: "buffer" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          if (worksheet) {
            const jsonSheetData = utils.sheet_to_json(worksheet, { header: 1 });
            const formattedData = jsonSheetData?.map((row, rowIndex) =>
              row.map((cell) => ({
                value: cell,
                readOnly: rowIndex === 0,
              }))
            );

            setExcelData(formattedData); // Set Excel data after fetching
          } else {
            console.warn("Worksheet is null or undefined");
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchExcel()
  }, [inputData, currentStepIndex])
  const [playMeetingTime, setPlayMeetingTime] = useState("");
  const [endMeetingTime, setEndMeetingTime] = useState("");

  useEffect(() => {
    setPlayMeetingTime(moment().format("HH:mm"));
    return () => {
      setEndMeetingTime(moment().format("HH:mm"));
    };
  }, []);

  // --------------------------------------Recording-------------------------------------
  const [audioBlob, setAudioBlob] = useState(null);
  const [existingAudioBlob, setExistingAudioBlob] = useState(null);
  console.log("existingAudioBlob", existingAudioBlob);
  const [hasStartedRecording, setHasStartedRecording] = useState(false);
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  // const [audioUrl, setAudioUrl] = useState(null);

  const fetchExistingAudio = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/audio-route/${id}`, {
        // responseType: 'blob', // Ensure you receive the audio as a Blob
      });
      console.log("response", response);
      setExistingAudioBlob(response?.data?.data?.voice_notes); // Save the existing audio blob
      // setAudioUrl(response?.data?.data?.voice_blob);
    } catch (error) {
      console.error("Error fetching existing audio:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchExistingAudio(); // Fetch the existing audio if the meeting is in progress
    }
  }, [id, currentStepIndex]);

  // setAudioUrl(newAudioUrl); // Store the Blob URL in state
  const fetchAudioBlob = async (audioUrl) => {
    try {
      const response = await fetch(audioUrl);
      console.log("fetch blob audio response", response);
      if (!response.ok) {
        throw new Error("Failed to fetch the existing audio");
      }
      const audioBlob = await response.blob(); // Convert the response to a Blob
      console.log("Fetched audio blob:", audioBlob);
      return audioBlob;
    } catch (error) {
      console.error("Error fetching audio blob:", error);
      return null;
    }
  };
  // Appending new audio to existing audio
  // const appendAudio = async (existingAudioBlobOrUrl, newBlob) => {
  // const existingAudioBlob = await fetchAudioBlob(existingAudioBlobOrUrl);
  //   if (!existingAudioBlob) {
  //     console.error("No existing audio to append to.");
  //     return newBlob; // Return just the new blob if there's no existing audio
  //   }

  //   // If still no valid existing audio blob, return the new blob
  //   if (!existingAudioBlob) {
  //     console.error("No existing audio to append to.");
  //     return newBlob;
  //   }

  //   // Convert both blobs into ArrayBuffer for concatenation
  //   const existingAudioBuffer = await existingAudioBlob.arrayBuffer();
  //   const newAudioBuffer = await newBlob.arrayBuffer();

  //   const combinedBuffer = new Uint8Array(
  //     existingAudioBuffer.byteLength + newAudioBuffer.byteLength
  //   );

  //   combinedBuffer.set(new Uint8Array(existingAudioBuffer), 0);
  //   combinedBuffer.set(new Uint8Array(newAudioBuffer), existingAudioBuffer.byteLength);

  //   // Return the combined blob
  //   return new Blob([combinedBuffer], { type: 'audio/webm' });
  // };
  const appendAudio = async (existingAudioUrl, newBlob) => {
    const existingAudioBlob = await fetchAudioBlob(existingAudioUrl);
    if (!existingAudioBlob) {
      console.error("No existing audio to append to.");
      return newBlob; // Return just the new blob if there's no existing audio
    }

    const existingAudioBuffer = await existingAudioBlob.arrayBuffer();
    const newAudioBuffer = await newBlob.arrayBuffer();

    const combinedBuffer = new Uint8Array(
      existingAudioBuffer.byteLength + newAudioBuffer.byteLength
    );

    combinedBuffer.set(new Uint8Array(existingAudioBuffer), 0);
    combinedBuffer.set(
      new Uint8Array(newAudioBuffer),
      existingAudioBuffer.byteLength
    );

    return new Blob([combinedBuffer], { type: "audio/webm" });
  };

  // useEffect(() => {
  //   if (isAutomatic && !hasStartedRecording) {
  //     // Start recording automatically
  //     recorderControls.startRecording();
  //     setHasStartedRecording(true);
  //   }
  // }, [isAutomatic, recorderControls, hasStartedRecording]);
  useEffect(() => {
    if (isAutomatic && !hasStartedRecording) {
      recorderControls.startRecording();
      setHasStartedRecording(true);
    }
  }, [isAutomatic, recorderControls, hasStartedRecording]);
  const addAudioElement = async (blob) => {
    // setIsLoading(true);
    setButtonDisabled(true);

    let combinedAudioBlob;
    // Append the new recording to the existing audio

    if (existingAudioBlob) {
      console.log("existingAudioBLob", existingAudioBlob);
      combinedAudioBlob = await appendAudio(existingAudioBlob, blob);
    } else {
      combinedAudioBlob = blob;
    }

    // Create a Blob URL
    const newAudioUrl = URL.createObjectURL(combinedAudioBlob);
    console.log("newAudioUrl", newAudioUrl);
    console.log("blob", combinedAudioBlob);
    const formData = new FormData();
    const file = new File([combinedAudioBlob], "user_audio.webm", {
      type: "audio/webm",
    });
    formData.append("voice_notes", file);
    formData.append("voice_blob", newAudioUrl);
    formData.append("meeting_id", id);
    axios
      .post(`${API_BASE_URL}/audio-route`, formData)
      .then(async (response) => {
        // setIsLoading(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handlePlayPause = (index) => {
    const updatedStepsState = [...stepsState];
    const currentStep = updatedStepsState[index];

    if (currentStep.isPlaying) {
      // Pausing the timer
      const pausedTime = new Date().getTime();
      const pausedDurationInSeconds = Math.floor(
        (pausedTime - currentStep.pausedTime) / 1000
      );

      updatedStepsState[index] = {
        ...currentStep,
        isPlaying: false,
        remainingTime: Math.max(
          currentStep.remainingTime - pausedDurationInSeconds,
          0
        ),
        pausedTime,
      };
    } else {
      // Resuming the timer
      updatedStepsState[index] = {
        ...currentStep,
        isPlaying: true,
        pausedTime: new Date().getTime(),
      };
    }
    setStepsState(updatedStepsState);
  };

  const [isNext, setIsNext] = useState(false);

  const [exitCurrentTime, setExitCurrentTime] = useState(null);
  const [exitCurrentDate, setExitCurrentDate] = useState(null);
  const [nextStepDelay, setNextStepDelay] = useState(null);

  const handlenextPage = async (val) => {
    if (inputData && currentStepIndex < inputData?.steps?.length - 1) {
      setShowNoteModal(false);
      setIsNext(true);
      handlePlayPause(currentStepIndex, false);
      setButtonDisabled(true);

      const currentStep = inputData?.steps[currentStepIndex];
      console.log("currentStep-> when go the next step", currentStep);
      const nextStep = inputData?.steps[currentStepIndex + 1];
      console.log("nextStep when go to next step", nextStep);
      const stepId = currentStep?.id;
      const myNextStepId = nextStep?.id;

      const optimizedEditorContent = await optimizeEditorContent(
        currentStep?.editor_content
      );
      const endTime = new Date();
      const currentTime = new Date();
      const formattedCurrentDate = currentTime.toISOString().split("T")[0];

      // Get the user's time zone
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Convert endTime to a date string in the format "4/8/2024"
      const formattedEndDate = endTime.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        timeZone: userTimeZone,
      });

      // Convert currentDateTime to a string in the user's local time zone
      const localEndTime = endTime.toLocaleString("en-GB", {
        timeZone: userTimeZone,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      setExitCurrentTime(localEndTime);
      // Convert current time to a string in the user's local time zone
      const localCurrentTime = currentTime.toLocaleString("en-GB", {
        timeZone: userTimeZone,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      const postData = {
        ...currentStep,
        editor_content: optimizedEditorContent || "",
        savedTime:
          currentStep?.savedTime === 0 ? 0 : savedTime != 0 ? savedTime : 0,
        negative_time:
          savedTime === 0
            ? negativeTimes[activeStepIndex] !== 0
              ? negativeTimes[activeStepIndex]
              : 0
            : 0,
        // savedTime: currentStep?.delay ? 0 : savedTime == 0 ? 0 : savedTime,
        // negative_time:
        //   currentStep?.negative_time === "99"
        //     ? "99"
        //     : savedTime === 0
        //     ? negativeTimes[activeStepIndex] !== 0
        //       ? negativeTimes[activeStepIndex]
        //       : 0
        //     : 0,
        step_status: "completed",
        totalstepnotes: stepNotes[currentStepIndex],
        totaldecision: decision.join(" "),
        note: stepNotes[currentStepIndex],
        original_note: stepNotes[currentStepIndex],
        status: "active",
        url: inputData.steps[currentStepIndex].url
          ? inputData.steps[currentStepIndex].url
          : null,
        meeting_id: id,
        decision: decision[currentStepIndex],
        actions: tableData ? tableData : [],
        // end_time: currentStep?.negative_time === "99" ?  localEndTime : currentStep?.end_time,
        // end_date: formattedEndDate,
        end_time: currentStep?.end_time ? currentStep?.end_time : localEndTime,
        end_date: currentStep?.end_date
          ? currentStep?.end_date
          : formattedEndDate,
        next_step_id: myNextStepId,
        delay: currentStep?.negative_time === "99" ? stepDelay?.delay : null,
        // time_seconds:currentStep?.negative_time === "99" ? difference : null,
        real_time: localEndTime,
        real_date: formattedEndDate,
      };
      delete postData.time_taken;

      try {
        const response = await axios.post(
          `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status) {
          await getMeetingById();
          setTableData(response.data?.data?.planDActions); // Set the new actions for the current step
          // setStepDelay(response?.data?.data?.delay)
          //store next step delay
          setNextStepDelay(response?.data?.next_step_delay);
          const estimate_time = response?.data?.data?.estimate_time;
          if (estimate_time) {
            // Split the date and time correctly
            const [date, timeWithMilliseconds] = estimate_time.split("T");

            // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
            const timeOnly = timeWithMilliseconds.split(".")[0];

            // Format the time to "10h37"
            const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

            console.log(formattedTime); // Output: 10h37

            setEstimateTime(formattedTime);
            setEstimateDate(date);
          }
          // const date = estimate_time?.split['T'][0]
          // const timeOnly = estimate_time.split('T')[1].split('.')[0];
          // const formattedTime = timeOnly.slice(0, 5).replace(':', 'h');
          // console.log(formattedTime); // Output: 10h37
          // setEstimateTime(formattedTime)
          // setEstimateDate(date)
        }
      } catch (error) {
        // toast.error(error.response?.data?.message);
      }

      try {
        const nextStepId = nextStep.id;
        const stepResponse = await axios.get(
          `${API_BASE_URL}/steps/${nextStepId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (stepResponse.status) {
          const nextStepData = stepResponse.data.data;
          const payload = {
            ...nextStepData,
            step_status: "in_progress",
            // new_current_time: localEndTime,
            // new_current_date: formattedCurrentDate,
            current_time: nextStep.current_time
              ? nextStep.current_time
              : localEndTime,
            current_date: nextStep.current_date
              ? nextStep.current_date
              : formattedCurrentDate,
            real_time: localEndTime,
            real_date: formattedEndDate,
          };

          delete payload.time_taken;

          // Make the second API call with the retrieved step data
          const response = await axios.post(
            `${API_BASE_URL}/play-meetings/steps/${nextStepId}/step-note-and-action`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          );

          if (response.status) {
            const step = response?.data?.data;
            // Additional logic for handling the response
            await getMeetingById();
            const estimate_time = response?.data?.data?.estimate_time;
            if (estimate_time) {
              // Split the date and time correctly
              const [date, timeWithMilliseconds] = estimate_time.split("T");

              // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
              const timeOnly = timeWithMilliseconds.split(".")[0];

              // Format the time to "10h37"
              const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

              console.log(formattedTime); // Output: 10h37

              setEstimateTime(formattedTime);
              setEstimateDate(date);
            }
          }
        }
      } catch (error) {
        console.log("Error updating next step status:", error);
      }
      setIsNext(false);
      setCurrentStepIndex((prevIndex) => prevIndex + 1);
      setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
      setButtonDisabled(false);
      setNextActiveStep();
    }
    return;
  };

  // const [isPrevious, setIsPrevious] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  // const previousPage = async () => {
  //   if (currentStepIndex > 0) {
  //     console.log("negativeTime", negativeTimes);
  //     console.log("savedTime", savedTime);
  //     setIsPrevious(true);
  //     handlePlayPause(currentStepIndex, false);
  //     const currentStep = inputData?.steps[currentStepIndex];
  //     console.log(
  //       "currentStepIndex->on previous step clicking",
  //       currentStepIndex
  //     );
  //     console.log("currentStep-> when click previous", currentStep);

  //     const previousStep = inputData.steps[currentStepIndex - 1];
  //     console.log("previousStep when click previous button", previousStep);
  //     const optimizedEditorContent = await optimizeEditorContent(
  //       currentStep?.editor_content
  //     );
  //     const stepId = currentStep.id;

  //     const endTime = new Date();
  //     // Get the user's time zone
  //     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     // Convert currentDateTime to a string in the user's local time zone
  //     const localEndTime = endTime.toLocaleString("en-GB", {
  //       timeZone: userTimeZone,
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //       hour12: false,
  //     });

  //     const currentTime = new Date();

  //     const localCurrentTime = currentTime.toLocaleString("en-GB", {
  //       timeZone: userTimeZone,
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //       hour12: false,
  //     });

  //     const postData = {
  //       ...currentStep,
  //       // end_time: currentStep?.delay ? localEndTime : currentStep?.end_time,
  //       editor_content: optimizedEditorContent ? optimizedEditorContent : null,
  //       totalstepnotes: stepNotes[currentStepIndex],
  //       note: stepNotes[currentStepIndex],
  //       totaldecision: decision.join(" "),
  //       savedTime: currentStep?.delay ? 0 : savedTime == 0 ? 0 : savedTime,
  //       negative_time:
  //         currentStep?.negative_time === "99"
  //           ? "99"
  //           : savedTime === 0
  //           ? negativeTimes[activeStepIndex] !== 0
  //             ? negativeTimes[activeStepIndex]
  //             : 0
  //           : 0,
  //       // savedTime: savedTime != 0 ? savedTime : 0,
  //       // negative_time:
  //       //   savedTime === 0
  //       //     ? negativeTimes[activeStepIndex] !== 0
  //       //       ? negativeTimes[activeStepIndex]
  //       //       : 0
  //       //     : 0,

  //       status: "active",
  //       step_status: "paused",
  //       meeting_id: id,
  //       url: inputData.steps[currentStepIndex].url
  //         ? inputData.steps[currentStepIndex].url
  //         : null,
  //       decision: decision[currentStepIndex],
  //       actions: tableData ? tableData : [],
  //       current_time: currentStep?.current_time
  //         ? currentStep.current_time
  //         : exitCurrentTime,
  //       current_date: currentStep?.current_date
  //         ? currentStep.current_date
  //         : exitCurrentDate,

  //       // delay: currentStep?.negative_time === "99" ? newDelayFormatted : null,
  //       // time_seconds:currentStep?.negative_time === "99" ? difference : null,
  //       end_time: currentStep?.delay ? localEndTime : currentStep?.end_time,
  //       delay: currentStep?.negative_time === "99" ? stepDelay?.delay : null,

  //       // delay: currentStep?.delay ? inputData?.step[currentStepIndex]?.delay : currentStep?.delay
  //     };

  //     try {
  //       const token = sessionStorage.getItem("token");
  //       const response = await axios.post(
  //         `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
  //         postData,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: ` Bearer ${token}`,
  //           },
  //         }
  //       );
  //       if (response.status) {
  //         await getMeetingById();
  //         const createdActions = response.data.data.planDActions; // Assuming the response contains the new actions
  //         setTableData(createdActions); // Set the new actions for the current step
  //         const estimate_time = response?.data?.data?.estimate_time;
  //         if (estimate_time) {
  //           // Split the date and time correctly
  //           const [date, timeWithMilliseconds] = estimate_time.split("T");

  //           // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
  //           const timeOnly = timeWithMilliseconds.split(".")[0];

  //           // Format the time to "10h37"
  //           const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

  //           console.log(formattedTime); // Output: 10h37

  //           setEstimateTime(formattedTime);
  //           setEstimateDate(date);
  //         }
  //       }
  //     } catch (error) {
  //       setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
  //     }

  //     const formattedCurrentDate = currentTime.toISOString().split("T")[0];
  //     // Fetch the previous step by ID
  //     try {
  //       const previousStepId = previousStep.id;
  //       const stepResponse = await axios.get(
  //         `${API_BASE_URL}/steps/${previousStepId}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //           },
  //         }
  //       );

  //       if (stepResponse.status) {
  //         const previousStepData = stepResponse.data.data;
  //         console.log("previousStepData", previousStepData);
  //         // setPreviousStep(previousStepData)
  //         const nextStepData = {
  //           ...previousStepData,
  //           step_status: "in_progress",
  //           new_current_time: localEndTime,
  //           new_current_date: formattedCurrentDate,
  //           previous_status: 1,
  //           current_time: previousStep.current_time
  //             ? previousStep.current_time
  //             : localEndTime,
  //           current_date: previousStep.current_date
  //             ? previousStep.current_date
  //             : formattedCurrentDate,
  //         };

  //         // Make the second API call with the retrieved step data
  //         const response = await axios.post(
  //           `${API_BASE_URL}/play-meetings/steps/${previousStepId}/step-note-and-action`,
  //           nextStepData,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //             },
  //           }
  //         );

  //         if (response.status) {
  //           const step = response?.data?.data;
  //           await getMeetingById();
  //           const estimate_time = response?.data?.data?.estimate_time;
  //           if (estimate_time) {
  //             // Split the date and time correctly
  //             const [date, timeWithMilliseconds] = estimate_time.split("T");

  //             // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
  //             const timeOnly = timeWithMilliseconds.split(".")[0];

  //             // Format the time to "10h37"
  //             const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

  //             console.log(formattedTime); // Output: 10h37

  //             setEstimateTime(formattedTime);
  //             setEstimateDate(date);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.log("Error updating next step status:", error);
  //     }
  //     // try {
  //     //   const previousStepId = previousStep.id;
  //     //   const nextStepData = {
  //     //     // ...previousStep,
  //     //     step_status: "in_progress",
  //     //     // new_current_time: previousStep?.negative_time === "99" ? localEndTime: null,
  //     //     // new_current_date: previousStep?.negative_time === "99" ? formattedCurrentDate : null,
  //     //     new_current_time: localEndTime,
  //     //     new_current_date: formattedCurrentDate,
  //     //     // negative_time: "99",
  //     //     // savedTime:0
  //     //     // // current_time: localCurrentTime,
  //     //     // savedTime: previousStep?.delay ? 0 : savedTime == 0 ? 0 : savedTime,
  //     //     // negative_time:
  //     //     // previousStep?.negative_time === "99"
  //     //     //     ? "99"
  //     //     //     : savedTime === 0
  //     //     //     ? negativeTimes[activeStepIndex] !== 0
  //     //     //       ? negativeTimes[activeStepIndex]
  //     //     //       : 0
  //     //     //     : 0,
  //     //     current_time: previousStep.current_time
  //     //       ? previousStep.current_time
  //     //       : localEndTime,
  //     //     current_date: previousStep?.current_date
  //     //       ? previousStep.current_date
  //     //       : formattedCurrentDate,
  //     //   };
  //     //   const response = await axios.post(
  //     //     `${API_BASE_URL}/play-meetings/steps/${previousStepId}/step-note-and-action`,
  //     //     nextStepData,
  //     //     {
  //     //       headers: {
  //     //         "Content-Type": "application/json",
  //     //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //     //       },
  //     //     }
  //     //   );
  //     //   if (response.status) {
  //     //     const step = response?.data?.data;
  //     //     // setStepDelay(step?.delay)
  //     //     // getMeeting()
  //     //     // setExitCurrentDate(step?.current_date);
  //     //     // setExitCurrentTime(step?.current_time);
  //     //   }
  //     // } catch (error) {
  //     //   console.log("Error updating next step status:", error);
  //     // }

  //     // }
  //     setCurrentStepIndex((prevIndex) => prevIndex - 1);
  //     // setTableData([]);
  //     setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
  //     setIsPrevious(false);
  //     setPreviousActiveStep();
  //   }
  // };

  // const [teams, setTeams] = useState([]);
  // console.log("teams", teams);
  // useEffect(() => {
  //   const getTeams = async () => {
  //     const token = sessionStorage.getItem("token");
  //     try {
  //       setLoading(true);

  //       const response = await axios.get(`${API_BASE_URL}/teams`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
  //       if (response.status === 200) {
  //         const filterredActiveTeams = response?.data?.data?.filter(
  //           (team) => team.status === "active"
  //         );
  //         if (getUserRoleID() === 3) {
  //           // setAllTeams(response?.data?.data);
  //           const teams = response.data.data.filter(
  //             (team) => team?.enterprise?.id === response?.data?.enterprise?.id
  //           );
  //           setTeams(teams);
  //         } else {
  //           // setAllTeams(response?.data?.data);
  //           const teams = response.data.data.filter(
  //             (team) => team?.enterprise?.id === response?.data?.enterprise?.id
  //           );
  //           setTeams(teams);
  //         }
  //       }
  //     } catch (error) {
  //       toast.error(t(error?.response?.data?.errors[0] || error?.message));
  //       // console.log("error message", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   getTeams();
  // }, []);

  // FUNCTION TO SAVE THE EDITOR CONTENT of the current step.

  const saveEditorContent = async (editorContent) => {
    const _OPTIMIZED_EDITOR_CONTENT = await optimizeEditorContent(
      editorContent
    );
    const stepId = inputData?.steps[currentStepIndex]?.id;
    const URL = `${API_BASE_URL}/play-meetings/steps/${stepId}`;
    const postData = {
      ...inputData.steps[currentStepIndex],
      editor_content: _OPTIMIZED_EDITOR_CONTENT,
    };
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(URL, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${token}`,
        },
      });
      if (response.status) {
        // toast.success(response.data?.message);
        toast.success("Content saved successfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const [lastStepNote, setLastStepNote] = useState(null);
  const saveDataonEnd = async (val) => {
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    // setShowNextCounter(false);
    const currentStep = inputData?.steps[currentStepIndex];
    const stepId = currentStep.id;
    const endTime = new Date();
    const currentTime = new Date();
    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("timezone", userTimeZone);
    // Convert currentDateTime to a string in the user's local time zone
    const localEndTime = endTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    // Format end_date to dd/mm/yyyy
    const formatDate = (date) => {
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    };
    const formattedEndDate = formatDate(endTime);
    const postData = {
      ...currentStep,
      // savedTime: savedTime == 0 ? 0 : savedTime,
      savedTime: savedTime != 0 ? savedTime : 0,

      // negative_time:
      //   negativeTimes[activeStepIndex] != 0
      //     ? negativeTimes[activeStepIndex]
      //     : 0,
      negative_time:
        savedTime === 0
          ? negativeTimes[activeStepIndex] !== 0
            ? negativeTimes[activeStepIndex]
            : 0
          : 0,
      totalstepnotes: stepNotes[currentStepIndex],
      note: stepNotes[currentStepIndex],
      original_note: stepNotes[currentStepIndex],
      totaldecision: decision.join(" "),
      decision: decision[currentStepIndex],
      url: inputData.steps[currentStepIndex].url
        ? inputData.steps[currentStepIndex].url
        : null,
      status: "active",
      step_status: "completed",
      meeting_id: id,
      actions: tableData ? tableData : [],
      end_time: localEndTime,
      end_date: formattedEndDate,
      delay: currentStep?.negative_time === "99" ? stepDelay?.delay : null,
      real_time: localEndTime,
      real_date: formattedEndDate,
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        // setLastStepNote(response.data.data?.note);
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        // return response?.data?.data?.note;
        return response?.data?.data;
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const closeMeeting = async () => {
    const responseNotes = await saveDataonEnd();
    const updatedStepData = [...stepData]; // Copy the stepData array
    // updatedStepData[updatedStepData.length - 1].note = responseNotes; // Update the note for the last step
    updatedStepData[updatedStepData.length - 1] = responseNotes;
    setIsLoading(true);
    setButtonDisabled(true);
    localStorage.setItem("lastURL", "/play");
    // Parse the start time string
    const [hour, minute] = meetingData?.start_time?.split(":").map(Number);

    // Add one hour to the hour component
    let endHour = hour + 1;

    // If end hour is greater than or equal to 24, subtract 24
    if (endHour >= 24) {
      endHour -= 24;
    }

    // Format the end time as a string
    const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;

    console.log("endTime str->", endTimeStr);
    const updatedDatWithClosingTime = {
      ...meetingData,
      real_end_time: moment().format("HH:mm:ss"),
      real_start_time: real_start_time,
      _method: "put",
      status: "closed",
      plan_d_actions: tableData ? tableData : [],
      // steps: updatedStepData,
      steps: updatedStepData?.map((step) => ({
        ...step,
        step_status: "completed",
      })),
      step_decisions: decision.filter((decision) => decision != ""),
      end_time: endTimeStr,
      moment_privacy_teams:
        meetingData?.moment_privacy_teams?.map((item) => item.id) || [],
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}`,
        updatedDatWithClosingTime,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      // console.log("error", error);
    }
    // -------------------------------------------------- NOW UPDATE STATUS OF MEETING ----------------------------------------------
    const realEndTime = moment().format("HH:mm:ss");
    try {
      const postData = {
        real_end_time: realEndTime,
        status: "closed",
        _method: "put",
      };
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}/status`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      // console.log("meeting status api", response);
      if (response.status) {
        // console.log("meeting status changed successfully", response.data);
        const data = response?.data?.data
        setButtonDisabled(false);
        setIsLoading(false);
        navigate(`/present/invite/${data?.id}`, { state: { data, from: "meeting" } });
      }
    } catch (error) {
      console.log("error ", error);
      setIsLoading(false);
    }
  };

  const updateMeetingStatus = async () => {
    recorderControls.stopRecording();
    const realEndTime = moment().format("HH:mm:ss");
    // const userConfirmed = window.confirm(t("confirmation"));

    // if (!userConfirmed) {
    //   return;
    // }
    const responseNotes = await saveDataonEnd();
    SpeechRecognition.stopListening();
    const updatedStepData = [...stepData]; // Copy the stepData array
    // updatedStepData[updatedStepData.length - 1].note = responseNotes; // Update the note for the last step
    updatedStepData[updatedStepData.length - 1] = responseNotes;
    try {
      const postData = {
        ...meetingData,

        // real_end_time: realEndTime,
        abort_end_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: "abort",
        _method: "put",
        plan_d_actions: tableData,
        // step_notes: stepNotes,
        step_notes: null,
        step_decisions: decision,
        steps: updatedStepData?.map((step) => ({
          ...step,
          step_status: "abort",
          status: "abort",
        })),
        moment_privacy_teams:
          meetingData?.moment_privacy_teams?.map((item) => item?.id) || [],
      };
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}/status`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        console.log("meeting status changed successfully", response.data);
        // toast.success(response.data?.message);
        navigate("/meeting");
        //
      }
    } catch (error) {
      // console.log("error ", error);
    }
  };

  // useEffect(() => {
  //   const getEmailCampaign = async () => {
  //     try {
  //       const response = await axios.get(`${API_BASE_URL}/email-campaigns`, {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       });
  //       if (response.status) {
  //         setEmailCampaign(response.data?.data);
  //       }
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };
  //   getEmailCampaign();
  // }, []);
  const GradientSvg = (
    <svg height="0px">
      <defs>
        <linearGradient id="your-unique-id" x1="1" y1="0" x2="1" y2="1">
          <stop offset="10%" stopColor="#F2E358" />
          <stop offset="90%" stopColor="#CB690F" />
        </linearGradient>
      </defs>
    </svg>
  );
  const GradientSvg2 = (
    <svg height="0px">
      <defs>
        <linearGradient id="your-unique-id2" x1="1" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor="#F25861" />
          <stop offset="90%" stopColor="#CB0F1A" />
        </linearGradient>
      </defs>
    </svg>
  );
  const GradientSvg3 = (
    <svg height="0px">
      <defs>
        <linearGradient id="your-unique-id1" x1="1" y1="0" x2="1" y2="1">
          <stop offset="10%" stopColor="#CB0C17" />
          <stop offset="90%" stopColor="#5AAFD6" />
        </linearGradient>
      </defs>
    </svg>
  );

  const handleTableDataChange = (e, index) => {
    const { name, value } = e.target;
    setTableData((prevTableData) =>
      prevTableData?.map((rowData, i) =>
        i === index
          ? {
            ...rowData,
            [name]: value,
            // participant_id: newArray,
            step_id: inputData.steps[currentStepIndex].id,
            status: "Todo",
          }
          : rowData
      )
    );
  };

  const handleButtonClick = () => {
    if (Array.isArray(tableData) && tableData.length > 0) {
      setTableData([
        ...tableData,
        {
          order: 0,
          action: "",
          action_days: 0,
          participant_id: "",
          step_id: inputData.steps[currentStepIndex].id,
          status: "Todo",
        },
      ]);
    } else {
      setTableData([
        {
          order: 0,
          action: "",
          action_days: 0,
          participant_id: "",
          step_id: inputData.steps[currentStepIndex].id,
          status: "Todo",
        },
      ]);
    }
  };

  const handleButtonDelete = async (index) => {
    const actionToBeDeleted = tableData[index];
    const id = actionToBeDeleted.id;
    //Send API Call only if the action is already saved in the database.
    const foundInDatabase = actionToBeDeleted.id; // If the action is already saved in the database, it will have an id.
    //----API CALL TO DELETE ACTION
    if (foundInDatabase) {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/planDactions/${id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.clear();
        console.log("response", response);
      } catch (error) {
        console.log("error", error);
        return;
      }
    }

    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  function addIframesToLinks(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const links = Array.from(doc.querySelectorAll("a"));

    links.forEach((linkElement) => {
      const linkURL = linkElement.getAttribute("href");
      const iframe = createIframeForLink(linkURL);
      // Replace the link element with the iframe
      linkElement.parentNode.replaceChild(iframe, linkElement);
    });

    return doc.documentElement.outerHTML;
  }
  function createIframeForLink(linkURL) {
    const iframe = document.createElement("iframe");
    iframe.src = linkURL;
    iframe.width = "100%";
    // iframe.height = "500px";
    iframe.title = "Embedded Content";
    iframe.style.scrollSnapType = "none";
    iframe.style.border = "none";
    return iframe;
  }

  // Show preview of Links in Iframe:
  useEffect(() => {
    if (inputData && inputData?.steps && inputData?.steps?.length > 0) {
      // const originialHtml = inputData.steps[currentStepIndex].editor_content;
      const originialHtml =
        inputData?.steps[currentStepIndex]?.editor_content === null
          ? ""
          : inputData?.steps[currentStepIndex]?.editor_content;
      const modifiedHtml = addIframesToLinks(originialHtml);
      setInputData((prevData) => ({
        ...prevData,
        steps: prevData.steps?.map((step, index) => {
          if (index === currentStepIndex) {
            return {
              ...step,
              editor_content: modifiedHtml,
            };
          }
          return step;
        }),
      }));
    }

  }, [currentStepIndex]);

  const handleIncrementCount = (index) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, i) =>
        i === index
          ? {
            ...rowData,
            // action_days: Math.min(parseFloat(rowData.action_days) + 1, 5),
            action_days: Math.min(parseFloat(rowData.action_days) + 1, 100),
          }
          : rowData
      )
    );
  };

  const handleDecrementCount = (index) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, i) =>
        i === index
          ? {
            ...rowData,
            action_days: Math.max(parseFloat(rowData.action_days) - 1, 0),
          }
          : rowData
      )
    );
  };
  const [stepNoteEditor, setStepEditor] = useState({
    value: "",
    showEditor: false,
  });
  const [show, setShow] = useState({
    value: "",
    showEditor: false,
  });

  // ================>TEXT EDITORS TOGGLE FUNCTIONS: <====================
  const handleDecisionEditorToggle = () => {
    setNotesEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setDecisionEditor((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
      };
    });
    setShowStepContentEditor(false);
  };

  const [completedStepNoteEditor, setCompletedStepNoteEditor] = useState(false);

  const handleNotesEditorToggle = () => {
    setDecisionEditor((prev) => ({ ...prev, showEditor: false })); // Close the decision editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the all notes editor if it's open

    setNotesEditor((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
      };
    });
    setShowStepContentEditor(false);
  };

  // Function to handle eye button click
  const handlePlanDActionEditor = () => {
    setDecisionEditor((prev) => ({ ...prev, showEditor: false }));
    setNotesEditor((prev) => ({ ...prev, showEditor: false }));
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close step notes editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the all notes editor if it's open
    setPlanDActionEditor((prev) => ({
      ...prev,
      showEditor: !prev.showEditor,
    }));

    // If closing the editor, update entered data
    if (planDActionEditor.showEditor) {
      const enteredDataString = tableData?.map((rowData) => ({
        action: rowData.action,
        action_days: rowData.action_days,
      }));
      // setEnteredData(enteredDataString);
    }
    setShowStepContentEditor(false);
  };

  const handleStepContentEditor = async () => {
    if (showStepContentEditor === true) {
      const optimizedEditorContent = optimizeEditorContent(
        inputData.steps[currentStepIndex]?.editor_content
      );
      await saveEditorContent(optimizedEditorContent);
    }
    setDecisionEditor((prev) => ({ ...prev, showEditor: false }));
    setNotesEditor((prev) => ({ ...prev, showEditor: false }));
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false }));
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the all notes editor if it's open
    setShowStepContentEditor((prev) => !prev);
  };

  const [editorContent, setEditorContent] = useState(true);
  const handleShow = async () => {
    setNotesEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setDecisionEditor((prev) => ({ ...prev, showEditor: false })); // Close the decision editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the plandAction editor if it's open
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
    setShow((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
      };
    });
    setShowStepContentEditor(false);
    const currentStep = inputData.steps[currentStepIndex];
    console.log("currentStep", currentStep);
    const stepId = currentStep.id;
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );

    const postData = {
      ...currentStep,
      editor_content: optimizedEditorContent || "",
      savedTime: savedTime == 0 ? 0 : savedTime,
      negative_time:
        negativeTimes[activeStepIndex] != 0
          ? negativeTimes[activeStepIndex]
          : 0,
      file: currentStep.file || "",
      url: currentStep.url || "",
      totalstepnotes: stepNotes[currentStepIndex],
      totaldecision: decision.join(" "),
      note: stepNotes[currentStepIndex],
      status: "active",
      meeting_id: id,
      decision: decision[currentStepIndex],
      actions: tableData ? tableData : [],
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        getMeeting();
        setTableData(response.data.data?.planDActions); // Set the new actions for the current step
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
      }
    } catch (error) {
      // toast.error(error.response?.data?.message);
    }
  };

  const [stepNote, setStepNote] = useState("");
  const [myId, setMyId] = useState(null);
  const [myStep, setMyStep] = useState({});
  const handleStepEditor = (id) => {
    setMyId(id);
    const getStep = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/steps/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status) {
          setStepNote(response?.data?.data?.note);
          setMyStepNoteId(response?.data?.data?.id);
        }
      } catch (error) {
        console.log("error while fetching step", error);
      }
    };
    getStep();
    // setShow((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setNotesEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setDecisionEditor((prev) => ({ ...prev, showEditor: false })); // Close the decision editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open

    setStepEditor((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
        // showEditor: false,
      };
    });
    setShowStepContentEditor(false);
  };

  const getStep = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/steps/${myId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status) {
        setMyStep(response?.data?.data);
        setStepNote(response?.data?.data?.note);
        setMyStepNoteId(response?.data?.data?.id);
      }
    } catch (error) {
      console.log("error while fetching step", error);
    }
  };
  const [myNextStep, setMyNextStep] = useState(null);
  const [isExitManual, setIsExitManual] = useState(false);
  const [isExitAuto, setIsExitAuto] = useState(false);
  const getNextStep = async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/steps/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status) {
        const step = response?.data?.data;
        setMyNextStep(step);
        // setMyStep(response?.data?.data);
        // setStepNote(response?.data?.data?.note);
        // setMyStepNoteId(response?.data?.data?.id);
      }
    } catch (error) {
      console.log("error while fetching step", error);
    }
  };
  useEffect(() => {
    getNextStep();
  }, []);
  useEffect(() => {
    getStep();
  }, [myId, myStepNoteId]);

  const [isUpdated, setIsUpdated] = useState(false);
  const updateStepNote = async () => {
    setEditorContent(true);
    setIsUpdated(true);
    const currentStep = inputData.steps[currentStepIndex];

    console.log("mystep", myStep);
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const postDataNote = {
      ...myStep,
      actions: meetingData?.steps?.plan_d_actions || null,
      // editor_content: optimizedEditorContent ? optimizedEditorContent : null,
      // savedTime: savedTime == 0 ? 0 : savedTime,
      // negative_time:
      //   negativeTimes[activeStepIndex] != 0
      //     ? negativeTimes[activeStepIndex]
      //     : 0,
      // totaldecision: decision.join(" "),
      // decision: decision[currentStepIndex],
      // actions: tableData ? tableData : [],
      totalstepnotes: stepNote,
      note: stepNote,
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${myStepNoteId}/step-note-and-action`,
        // show.showEditor ? postDataNote : postData,
        postDataNote,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        console.log(
          "response-> step note updated successfully",
          response.data.data
        );
        setIsUpdated(false);
        setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
        setShow((prev) => ({ ...prev, showEditor: false })); // Close the see all notes if it's open
        // setShowStepContentEditor(true)
        // setShow((prev) => {
        //   return {
        //     ...prev,
        //     showEditor: !prev.showEditor,
        //   };
        // });
        getMeeting();
      }
    } catch (error) {
      setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    }
  };

  const myLanguage = language === "en" ? "en-US" : "fr-FR";

  const chosenLanguage = language === "en" ? "English" : "French";
  useEffect(() => {
    if (isAutomatic) {
      SpeechRecognition.startListening({
        continuous: true,
        language: myLanguage,
        interimResults: true,
      });
    }
  }, [isAutomatic, myLanguage]);
  // console.log('language',myLanguage)
  const [summarizedNotes, setSummarizedNotes] = useState("");

  // const participants =
  //   meetingData &&
  //   meetingData?.participants
  //     ?.filter((item) => item.isCreator !== 1)
  //     ?.map((item) => `${item.first_name} ${item.last_name} as ${item.post}`);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const summarize = async (step, stepNumber, count2) => {
    console.log("step summarize step->", step);
    // setIsLoading(true);
    if (step.note === "" || step?.note === null) {
      setIsLoading(false);
      return;
    }
    try {
      const max = 500;
      const type = meetingData?.type;
      const title = meetingData?.title;
      const context = meetingData?.description?.replace(/<\/?[^>]+(>|$)/g, "");
      const totalSteps = meetingData?.steps?.length;
      const first_name = meetingData?.user?.name;
      const last_name = meetingData?.user?.last_name;
      const role = meetingData?.user?.post;
      const currentStepTitle = step?.title;
      const notes = step?.note;

      const guides =
        meetingData &&
        meetingData?.guides
          ?.map(
            (item) =>
              `${item.first_name} ${item.last_name} as ${item.post
              } and here is their contribution at this time : ${item?.contribution || "No contribution at this time"
              }`
          )
          .join("\n");

      console.log("guides", guides);

      const maxChars = 8 * count2; // 8 times the duration in seconds
      console.log("count2", count2);

      console.log("maxChar", maxChars);
      console.log("notes", notes);
      const payload = {
        // model: "gpt-3.5-turbo",
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: `We have the moment ${title}
            Here is the issue of the moment: ${context}

            Here is the list of ${type} guests with their roles:
            ${guides}

            This moment is divided into ${totalSteps} stages

            Here is the information regarding step ${stepNumber} which is called ${currentStepTitle}

            The facilitator of this stage is: ${step?.participant?.full_name}  as ${step?.participant?.post}

            Give me a summary of what was said in this step ${stepNumber} which is called ${currentStepTitle} respecting the format ${type}

            The summary of the steps respecting the ${type} format in ${max} words maximum

	          Objective of step ${stepNumber} which is called ${currentStepTitle} (the heading should be in h4 html tag)
	          Description of key points (the heading should be in h4 html tag)
	          Dates if there are any (the heading should be in h4 html tag)
	          Key figures if there are any (the heading should be in h4 html tag)
	          Actions to follow if there are any (the heading should be in h4 html tag)

            The summary of this step must be exactly ${maxChars} characters, and must not exceed ${maxChars} characters.

            The entire summary must be in ${chosenLanguage}
            `,
          },
          {
            role: "user",
            content: notes,
          },
        ],
        temperature: 0.7,
        max_tokens: 2000,
        top_p: 1,
      };

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer sk-proj-mW72jQDqBS22ud6cOasQT3BlbkFJfxePEayqDAzTeLc4YxKk`,
          },
        }
      );
      if (response) {
        setIsLoading(false);
        let content = response?.data?.choices[0]?.message?.content;
        // Normalize the format if needed
        if (content.startsWith("```html")) {
          content = content.slice(7, -3); // Remove ```html and ```
        } else if (content.startsWith("##")) {
          content = content.replace(/^##\s*/gm, ""); // Remove leading ##
        }
        setSummarizedNotes(content);
        return content;
      }
    } catch (error) {
      console.log("error while summarizing transcipt", error);
      setIsLoading(false);
    }
  };

  const [exitCurrentTimeAuto, setExitCurrentTimeAuto] = useState(null);
  const [exitCurrentDateAuto, setExitCurrentDateAuto] = useState(null);
  const next = async () => {
    // setCurrentStepIndex((prevIndex) => prevIndex + 1);
    // setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
    // setButtonDisabled(false);
    // setNextActiveStep();

    // const summarizedNotes = await summarize();
    // resetTranscript();
    // setStoredTranscript(""); // Clears the stored transcript
    // recorderControls.stopRecording();
    setIsLoading(true);
    handlePlayPause(currentStepIndex, false);

    // setShowNextCounter(false);
    const currentStep = inputData.steps[currentStepIndex];
    const nextStep = inputData.steps[currentStepIndex + 1];

    const stepId = currentStep.id;
    const myNextStepId = nextStep?.id;

    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const endTime = new Date();
    const currentTime = new Date();
    const formattedCurrentDate = currentTime.toISOString().split("T")[0];

    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Convert currentDateTime to a string in the user's local time zone
    const localEndTime = endTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    const formattedEndDate = endTime.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      timeZone: userTimeZone,
    });
    const postData = {
      ...currentStep,
      editor_content: optimizedEditorContent ? optimizedEditorContent : null,
      // savedTime: savedTime == 0 ? 0 : savedTime,
      // negative_time:
      //   negativeTimes[activeStepIndex] != 0
      //     ? negativeTimes[activeStepIndex]
      //     : 0,
      // savedTime: savedTime != 0 ? savedTime : 0,
      savedTime:
        currentStep?.savedTime === 0 ? 0 : savedTime != 0 ? savedTime : 0,
      negative_time:
        savedTime === 0
          ? negativeTimes[activeStepIndex] !== 0
            ? negativeTimes[activeStepIndex]
            : 0
          : 0,
      totaldecision: decision.join(" "),
      totalstepnotes: storedTranscript + transcript,
      note: storedTranscript + transcript,
      original_note: storedTranscript + transcript,

      // totalstepnotes: summarizedNotes || "",
      // note: summarizedNotes || "",
      decision: decision[currentStepIndex],
      actions: tableData ? tableData : [],
      url: inputData.steps[currentStepIndex].url
        ? inputData.steps[currentStepIndex].url
        : null,
      meeting_id: id,
      status: "active",
      step_status: "completed",
      end_time: currentStep?.end_time ? currentStep?.end_time : localEndTime,
      end_date: currentStep?.end_date
        ? currentStep?.end_date
        : formattedEndDate,
      next_step_id: myNextStepId,
      delay: currentStep?.negative_time === "99" ? stepDelay?.delay : null,
      // end_date: formattedEndDate,
      real_time: localEndTime,
      real_date: formattedEndDate,
    };
    delete postData?.time_taken;

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        resetTranscript();
        setStoredTranscript("");

        // getMeeting();
        await getMeetingById();
        setTableData(response.data.data?.planDActions); // Set the new actions for the current step
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        // setIsLoading(false);
        setNextStepDelay(response?.data?.next_step_delay);

        const estimate_time = response?.data?.data?.estimate_time;
        if (estimate_time) {
          // Split the date and time correctly
          const [date, timeWithMilliseconds] = estimate_time.split("T");

          // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
          const timeOnly = timeWithMilliseconds.split(".")[0];

          // Format the time to "10h37"
          const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

          console.log(formattedTime); // Output: 10h37

          setEstimateTime(formattedTime);
          setEstimateDate(date);
          setIsLoading(false);

          // setStoredTranscript("")
        }
      }
    } catch (error) {
      setIsLoading(false);

      // toast.error(error.response?.data?.message);
    }
    const formattedCurrentTime = currentTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    try {
      const nextStepId = nextStep.id;
      const stepResponse = await axios.get(
        `${API_BASE_URL}/steps/${nextStepId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (stepResponse.status) {
        const nextStepData = stepResponse.data.data;
        const payload = {
          ...nextStepData,
          step_status: "in_progress",
          // new_current_time: localEndTime,
          // new_current_date: formattedCurrentDate,
          current_time: nextStep.current_time
            ? nextStep.current_time
            : localEndTime,
          current_date: nextStep.current_date
            ? nextStep.current_date
            : formattedCurrentDate,
          real_time: localEndTime,
          real_date: formattedEndDate,
        };

        delete payload.time_taken;

        // Make the second API call with the retrieved step data
        const response = await axios.post(
          `${API_BASE_URL}/play-meetings/steps/${nextStepId}/step-note-and-action`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status) {
          const step = response?.data?.data;
          // Additional logic for handling the response
          await getMeetingById();
          const estimate_time = response?.data?.data?.estimate_time;
          if (estimate_time) {
            // Split the date and time correctly
            const [date, timeWithMilliseconds] = estimate_time.split("T");

            // Extract the time portion (e.g., '10:37:00.000Z') and remove milliseconds if present
            const timeOnly = timeWithMilliseconds.split(".")[0];

            // Format the time to "10h37"
            const formattedTime = timeOnly.slice(0, 5).replace(":", "h");

            console.log(formattedTime); // Output: 10h37

            setEstimateTime(formattedTime);
            setEstimateDate(date);
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      console.log("Error updating next step status:", error);
      setIsLoading(false);
    }
    setIsNext(false);
    setIsLoading(false);
    setCurrentStepIndex((prevIndex) => prevIndex + 1);
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
    setButtonDisabled(false);
    setNextActiveStep();
    // setCurrentStepIndex((prevIndex) => prevIndex + 1);
    // setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
    // setButtonDisabled(false);
    // setNextActiveStep();
  };

  useEffect(() => {
    let timer;
    if (showProgressBar) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 1
        );
      }, 100);
    }
    return () => clearInterval(timer);
  }, [showProgressBar]);

  const saveEndData = async () => {
    setIsLoading(true);
    // const summarizedNotes = await summarize();
    // setStoredTranscript("");
    // setIsLoading(true);
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;

    const endTime = new Date();
    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Convert currentDateTime to a string in the user's local time zone
    const localEndTime = endTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    // Format end_date to dd/mm/yyyy
    const formatDate = (date) => {
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    };
    // ..
    const formattedEndDate = formatDate(endTime);
    const postData = {
      ...currentStep,
      // savedTime: savedTime == 0 ? 0 : savedTime,
      // negative_time:
      //   negativeTimes[activeStepIndex] != 0
      //     ? negativeTimes[activeStepIndex]
      //     : 0,
      savedTime: savedTime != 0 ? savedTime : 0,
      negative_time:
        savedTime === 0
          ? negativeTimes[activeStepIndex] !== 0
            ? negativeTimes[activeStepIndex]
            : 0
          : 0,
      totalstepnotes: storedTranscript + transcript,
      note: storedTranscript + transcript,
      original_note: storedTranscript + transcript,
      // totalstepnotes: summarizedNotes || "",
      // note: summarizedNotes || "",
      decision: decision[currentStepIndex],
      actions: tableData ? tableData : [],
      meeting_id: id,
      url: inputData.steps[currentStepIndex].url
        ? inputData.steps[currentStepIndex].url
        : null,
      status: "active",
      step_status: "completed",
      end_time: localEndTime,
      end_date: formattedEndDate,
      delay: currentStep?.negative_time === "99" ? stepDelay?.delay : null,
      real_time: localEndTime,
      real_date: formattedEndDate,
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        // return response?.data?.data?.note;
        return response?.data?.data;
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  //auto notes functionality
  const close = async () => {
    recorderControls.stopRecording();
    setButtonDisabled(true);
    setIsLoading(true);
    setShowProgressBar(true); // Show progress bar
    setProgress(0); // Reset progress
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const responseNotes = await saveEndData();
    setIsLoading(true);
    SpeechRecognition.stopListening();
    const updatedStepData = [...stepData];
    // updatedStepData[updatedStepData.length - 1].note =
    //   responseNotes?.data?.data?.note;
    updatedStepData[updatedStepData.length - 1] = responseNotes;

    console.log("stepData on close", updatedStepData);
    // setIsLoading(true);
    setButtonDisabled(true);
    localStorage.setItem("lastURL", "/play");

    // Parse the start time string
    const [hour, minute] = meetingData?.start_time?.split(":").map(Number);

    // Add one hour to the hour component
    let endHour = hour + 1;

    // If end hour is greater than or equal to 24, subtract 24
    if (endHour >= 24) {
      endHour -= 24;
    }

    // Format the end time as a string
    const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;

    console.log("endTime str->", endTimeStr);

    const updatedDatWithClosingTime = {
      ...meetingData,
      real_end_time: moment().format("HH:mm:ss"),
      real_start_time: real_start_time,
      _method: "put",
      status: "closed",
      timezone: userTimeZone,
      plan_d_actions: tableData ? tableData : [],
      steps: updatedStepData?.map((step) => ({
        ...step,
        step_status: "completed",
      })),
      step_decisions: decision.filter((decision) => decision != ""),
      end_time: endTimeStr,
      // status: "closed",
      moment_privacy_teams:
        meetingData?.moment_privacy_teams?.map((item) => item.id) || [],
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}`,
        updatedDatWithClosingTime,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const steps = response?.data?.data?.steps;
        console.log("steps", steps);
        setStepData(steps);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(true);
    const realEndTime = moment().format("HH:mm:ss");
    // const updatedSteps = [...stepData];
    // updatedStepData
    for (let i = 0; i < updatedStepData.length; i++) {
      const notes = updatedStepData[i];

      let count2 = notes?.count2; // Get the count2 value
      const timeUnit = notes?.time_unit; // Get the time unit from the step data

      // Convert count2 into seconds based on the time unit
      if (timeUnit === "minutes") {
        count2 *= 60; // Convert minutes to seconds
      } else if (timeUnit === "hours") {
        count2 *= 3600; // Convert hours to seconds
      }

      // try {
      //   const summarizedNotes = await summarize(notes, i + 1, count2);
      //   console.log("summarizedNotes->note", summarizedNotes);
      //   updatedStepData[i].note = summarizedNotes;

      //   const payload = {
      //     ...updatedStepData[i],
      //     note: summarizedNotes,
      //   };
      //   // Update the note in the DB
      //   await axios.post(
      //     `${API_BASE_URL}/play-meetings/steps/${updatedStepData[i]?.id}/step-note-and-action`,
      //     payload,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      //       },
      //     }
      //   );
      // } catch (error) {
      //   console.log("error", error);
      // }
    }

    try {
      const postData = {
        real_end_time: realEndTime,
        status: "closed",
        _method: "put",
      };
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}/status`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data
        setIsLoading(false);

        setProgress(100);
        setTimeout(() => {
          setShowProgressBar(false); // Hide after completion
          resetTranscript();
          setButtonDisabled(false);
          setIsLoading(false);
          navigate(`/present/invite/${data?.id}`, { state: { data, from: "meeting" } });
        }, 500);
      }
    } catch (error) {
      console.log("error ", error);
      setIsLoading(false);
      setShowProgressBar(false); // Hide progress bar on error
    }
  };

  const exitManual = async () => {
    setIsExitManual(true);
    const currentStep = inputData?.steps[currentStepIndex];
    console.log("currentStep", currentStep);
    const stepId = currentStep?.id;
    const realEndTime = moment().format("HH:mm:ss");
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    // setShowNextCounter(false);
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const currentTime = new Date();
    const endTime = new Date();
    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    console.log("endTime->", endTime);
    // Convert currentDateTime to a string in the user's local time zone
    const localEndTime = endTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    // Get the current date and time in the user's local time zone
    const localDate = new Date().toLocaleString("en-GB", {
      timeZone: userTimeZone,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    // Extract the date part
    const [datePart, timePart] = localDate.split(", ");
    const [day, month, year] = datePart.split("/");

    const formattedEndDate = `${day}/${month}/${year}`;

    const formattedCurrentDate = currentTime.toISOString().split("T")[0];
    const formattedCurrentTime = currentTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    // const totalDelay = count2+negative_time
    try {
      const postData = {
        ...currentStep,
        // real_end_time: realEndTime,
        status: "in_progress",
        step_status: "in_progress",
        // _method: "put",
        // end_time: inputData?.delay || inputData?.savedTime === 0 ? localEndTime : inputData?.end_time,
        editor_content: optimizedEditorContent ? optimizedEditorContent : null,
        // savedTime: savedTime == 0 ? 0 : savedTime,
        savedTime: savedTime != 0 ? savedTime : 0,
        // negative_time:
        //   negativeTimes[activeStepIndex] != 0
        //     ? negativeTimes[activeStepIndex]
        //     : 0,
        negative_time:
          savedTime === 0
            ? negativeTimes[activeStepIndex] !== 0
              ? negativeTimes[activeStepIndex]
              : 0
            : 0,
        totalstepnotes: stepNotes[currentStepIndex],
        totaldecision: decision.join(" "),
        note: stepNotes[currentStepIndex],
        decision: decision[currentStepIndex],
        actions: tableData ? tableData : [],
        url: inputData.steps[currentStepIndex].url
          ? inputData.steps[currentStepIndex].url
          : null,
        end_date: currentStep?.end_date
          ? currentStep?.end_date
          : formattedEndDate,
        end_time: currentStep?.end_time ? currentStep?.end_time : localEndTime,
        // end_date: formattedEndDate,
        // end_time: localEndTime,
        meeting_id: id,
        // current_time:exitCurrentTime
        current_time: currentStep?.current_time
          ? currentStep.current_time
          : exitCurrentTime,
        current_date: currentStep?.current_date
          ? currentStep.current_date
          : exitCurrentDate,
        delay: currentStep?.negative_time === "99" ? stepDelay?.delay : null,
        real_time: localEndTime,
        real_date: formattedEndDate,
        // new_current_time:formattedCurrentTime,
        // new_current_date: formattedCurrentDate,
      };
      delete postData?.time_taken;
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        console.log("meeting status changed successfully", response.data);
        const data = response?.data?.data
        navigate(`/invite/${data?.meeting_id}`, { state: { data, from: "meeting" } });
        // toast.success(response.data?.message);
        setIsExitManual(false);

      }
    } catch (error) {
      // console.log("error ", error);
      toast.error(t("error while exit the meeting"));
      setIsExitManual(false);
    }
  };

  useEffect(() => {
    if (meetingData && meetingData?.steps[activeStepIndex]?.note) {
      resetTranscript(); // Clear the current session's transcript
      setStoredTranscript(meetingData?.steps[activeStepIndex]?.note); // Restore previous notes
    }
  }, [activeStepIndex, meetingData]);
  const exitAuto = async () => {
    recorderControls.stopRecording();

    setIsExitAuto(true);

    // const summarizedNotes = await summarize();
    const currentTranscript = transcript;
    const updatedNotes = storedTranscript + currentTranscript;

    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const realEndTime = moment().format("HH:mm:ss");
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    // setShowNextCounter(false);
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const currentTime = new Date();
    const endTime = new Date();
    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert currentDateTime to a string in the user's local time zone
    const localEndTime = endTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    // Format end_date to dd/mm/yyyy
    const formatDate = (date) => {
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    };
    const formattedEndDate = formatDate(endTime);

    const formattedCurrentDate = currentTime.toISOString().split("T")[0];
    const formattedCurrentTime = currentTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    try {
      const postData = {
        ...currentStep,
        // real_end_time: realEndTime,
        status: "in_progress",
        step_status: "in_progress",
        // _method: "put",
        // end_time: inputData?.delay ? localEndTime : inputData?.end_time,
        editor_content: optimizedEditorContent ? optimizedEditorContent : null,
        // savedTime: savedTime == 0 ? 0 : savedTime,
        savedTime: savedTime != 0 ? savedTime : 0,
        // negative_time:
        //   negativeTimes[activeStepIndex] != 0
        //     ? negativeTimes[activeStepIndex]
        //     : 0,
        negative_time:
          savedTime === 0
            ? negativeTimes[activeStepIndex] !== 0
              ? negativeTimes[activeStepIndex]
              : 0
            : 0,
        totalstepnotes: updatedNotes || "",
        totaldecision: decision.join(" "),
        note: updatedNotes || "",
        decision: decision[currentStepIndex],
        actions: tableData ? tableData : [],
        url: inputData.steps[currentStepIndex].url
          ? inputData.steps[currentStepIndex].url
          : null,
        meeting_id: id,
        // end_date: currentStep?.end_date ? currentStep?.end_date : formattedEndDate,
        // end_time: currentStep?.end_time ? currentStep?.end_time : localEndTime,
        end_date: formattedEndDate,
        end_time: localEndTime,
        // current_time: exitCurrentTime,
        current_time: currentStep?.current_time
          ? currentStep.current_time
          : exitCurrentTimeAuto,
        current_date: currentStep?.current_date
          ? currentStep.current_date
          : exitCurrentDateAuto,
        delay: currentStep?.negative_time === "99" ? stepDelay?.delay : null,
        real_time: localEndTime,
        real_date: formattedEndDate,
        // new_current_time:formattedCurrentTime,
        // new_current_date: formattedCurrentDate,
      };
      delete postData?.time_taken;

      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      SpeechRecognition.stopListening();

      if (response.status) {
        // recorderControls.stopRecording();
        console.log("meeting status changed successfully", response.data);
        const data = response?.data?.data
        navigate(`/invite/${data?.meeting_id}`, { state: { data, from: "meeting" } });
        setIsExitAuto(false);
      }
    } catch (error) {
      // console.log("error ", error);
      toast.error(t("error while exit the meeting"));
      setIsExitAuto(false);
    }
  };

  const [iFrameLoad, setIFrameLoad] = useState(true);

  const filterDuplicates = (data) => {
    if (!data) return;
    const uniqueActions = [];
    return data?.filter((row) => {
      const isDuplicate = uniqueActions.some(
        (action) =>
          action.action === row.action &&
          action.order === row.order &&
          action.days === row.days
      );
      if (!isDuplicate) {
        uniqueActions.push(row);
        return true;
      }
      return false;
    });
  };

  const filteredTableData = filterDuplicates(tableData);

  const getYoutubeEmbedUrl = (url) => {
    if (!url) {
      return false;
    }
    if (url.includes("youtube.com/watch")) {
      const videoUrl = new URL(url);
      const videoId = videoUrl.searchParams.get("v");

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    // Handle the shortened YouTube URL (youtu.be)
    if (url.includes("youtu.be/")) {
      const videoId = url.split("youtu.be/")[1];
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }
    return false; // Return false if it's not a valid YouTube URL
  };

  // useEffect(() => {
  //   let timer;
  //   if (currentStepIndex !== inputData?.steps?.length - 1) {
  //     timer = setTimeout(() => {
  //       handlenextPage();
  //     }, 60000); // 60000 ms = 1 minute
  //   }

  //   return () => clearTimeout(timer); // Cleanup the timer on component unmount or when dependencies change
  // }, [currentStepIndex]); // Add dependencies for when to rerun the effect

  // useEffect(() => {
  //   if (isAutomaticNext && stepData[currentStepIndex]?.count2) {
  //     // Convert step time to milliseconds
  //     const stepDuration = stepData[currentStepIndex]?.count2 * 60000; // time is in minutes

  //     // Set a timeout for automatic step progression
  //     const timer = setTimeout(() => {
  //       if (currentStepIndex < stepData.length - 1) {
  //         handlenextPage(); // Automatically move to next step
  //       } else {
  //         closeMeeting(); // If it's the last step, close the meeting
  //       }
  //     }, stepDuration);

  //     return () => clearTimeout(timer); // Clear the timer when step changes or component unmounts
  //   }
  // }, [currentStepIndex, isAutomaticNext, stepData]);

  // useEffect(() => {
  //   const timer = async () => {
  //     if (isAutomaticNext && inputData?.steps?.length > 0) {
  //       const currentStep = inputData?.steps[currentStepIndex];
  //       console.log('currentStep useEffect',currentStep)
  //       const stepDuration = convertToMilliseconds(currentStep); // Function to determine step duration

  //       await new Promise(resolve => setTimeout(resolve, stepDuration));

  //       if (currentStepIndex < inputData?.steps?.length - 1) {
  //         await handlenextPage(); // Ensure this function sends the payload
  //       } else {
  //         closeMeeting(); // Handle closing of the final meeting step
  //       }
  //     }
  //   };

  //   timer();
  // }, [isAutomaticNext, currentStepIndex, inputData, inputData?.steps]);

  // const convertToMilliseconds = (step) => {
  //   if(!step) return
  //   const { count2, time_unit } = step;
  //   switch (time_unit) {
  //     case "seconds":
  //       return count2 * 1000;
  //     case "minutes":
  //       return count2 * 60000;
  //     case "hours":
  //       return count2 * 3600000;
  //     case "days":
  //       return count2 * 86400000;
  //     default:
  //       return 0;
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     isAutomaticNext &&
  //     stepData &&
  //     stepData[currentStepIndex]
  //   ) {
  //     // Convert step time to milliseconds
  //     // const stepDuration = stepData[currentStepIndex]?.count2 * 60000; // time is in minutes

  //     const { count2, time_unit } = stepData[currentStepIndex];

  //     // Convert time to milliseconds based on the time_unit
  //     let stepDuration = 0;
  //     switch (time_unit) {
  //       case "seconds":
  //         stepDuration = count2 * 1000; // seconds to milliseconds
  //         break;
  //       case "minutes":
  //         stepDuration = count2 * 60000; // minutes to milliseconds
  //         break;
  //       case "hours":
  //         stepDuration = count2 * 3600000; // hours to milliseconds
  //         break;
  //       case "days":
  //         stepDuration = count2 * 86400000; // days to milliseconds
  //         break;
  //       default:
  //         console.error("Unknown time unit:", time_unit);
  //         break;
  //     }

  //     // if (stepDuration > 0) {
  //     const timer = setTimeout(async () => {
  //       console.log("Timer triggered for step:", currentStepIndex);

  //       if (currentStepIndex < stepData?.length - 1) {
  //         console.log(
  //           "Calling next or handlenextPage for step:",
  //           currentStepIndex
  //         );
  //         isAutomatic ? next() :  handlenextPage();
  //       } else {
  //         console.log("Closing meeting as this is the last step");
  //         isAutomatic ? close() : closeMeeting();
  //       }
  //     }, stepDuration);

  //     return () => {
  //       console.log("Clearing timer for step:", currentStepIndex);
  //       clearTimeout(timer); // Clear the timeout on cleanup
  //     };
  //     // } else {
  //     //   console.warn('Invalid step duration:', stepDuration);
  //     // }
  //   }
  // }, [isAutomaticNext, currentStepIndex, stepData,isAutomatic]);

  const [emailCampaign, setEmailCampaign] = useState(null);

  useEffect(() => {
    const getNewsLetterStats = async () => {
      if (inputData && inputData.type === "Newsletter") {
        try {
          const response = await axios.get(`${API_BASE_URL}/get-newsletter-stats/${inputData.id}/${inputData.steps[activeStepIndex].id}`);
          if (response?.data?.data) {
            const data = response.data?.data;
            setEmailCampaign(data);
            console.log("New emailCampaign data:", data); // Log new data
          }
        } catch (error) {
          console.log("Error fetching newsletter stats:", error);
        }
      }
    };

    getNewsLetterStats();

    const interval = setInterval(getNewsLetterStats, 20000);
    return () => clearInterval(interval);
  }, [inputData, activeStepIndex]);

  const userTeams = meetingData && meetingData?.steps[activeStepIndex]?.user_teams || [];

  // Dynamically map `userTeams` to the required format
  const teamOptions = userTeams.map((team) => ({
    value: team.team_name, // Use the team_name from the user_teams array
    label: team.team_name, // Same for the label
  }));

  // Combine the static options (Enterprise, Private) with the dynamic options
  const combinedOptions = [
    { value: "Enterprise", label: "Enterprise" },
    { value: "Private", label: "Private" },
    ...teamOptions, // Append dynamic team options here
  ];

  // useEffect(() => {
  //   console.log("emailCampaign updated:", emailCampaign); // Log state updates
  // }, [emailCampaign,savedTime]);

  // const [showStepContentEditor, setShowStepContentEditor] = useState(false);

  const handleToggle = () => {
    setShowStepContentEditor((prev) => !prev);
  };

  const sanitizeContent = (html) => {
    if(!html) return null
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    
    // Find all iframe elements
    const iframes = doc.querySelectorAll("iframe");
    
    iframes.forEach((iframe) => {
      const src = iframe.getAttribute("src");
      if (src) {
        // Replace iframe with a link to the source
        const link = doc.createElement("a");
        link.href = src;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        link.textContent = `${src}`;
        
        iframe.replaceWith(link);
      }
    });
  
    return doc.body.innerHTML;
  };
  const rawContent =
  inputData?.steps &&
  Array.isArray(inputData.steps) &&
  inputData.steps[currentStepIndex] &&
  inputData.steps[currentStepIndex]?.editor_content
    ? inputData.steps[currentStepIndex].editor_content
    : " ";

// Sanitize content
const sanitizedContent = sanitizeContent(rawContent);


const sanitizeIframeContent = (content) => {
  if(!content) return null
  return content.replace(
    /<iframe.*?src="(.*?)".*?<\/iframe>/gi,
    (match, src) => {
      return `<a href="${src}" target="_blank" rel="noopener noreferrer">${src}</a>`;
    }
  );
};
  return (
    <>
      <div className="tektime">
        {loading ? (
          <Spinner
            animation="border"
            role="status"
            className="center-spinner"
          ></Spinner>
        ) : (
          <>
            <div className={showProgressBar ? "blur-container" : ""}>
              <div className="container-fluid mx-auto card py-5">
                <div className="row">
                  <div className="col-md-4"></div>
                  <h4 className="col-md-4 text-center d-flex flex-wrap">
                    {inputData?.objective}&nbsp; {">"} &nbsp; {inputData?.title}
                    &nbsp;
                    {" >"} &nbsp; {inputData?.steps[currentStepIndex]?.title}{" "}
                    &nbsp;{" "}
                  </h4>
                  <div
                    className="col-md-4 d-flex gap-2 justify-content-end play-meeting-btn"
                  // style={{ margin: "1rem 0 2rem 0" }}
                  >
                    {isAutomatic ? (
                      <>
                        {isExitAuto ? (
                          <button
                            className={`btn-exit`}
                            style={{ background: "#0d6efd" }}
                          >
                            <Spinner
                              as="span"
                              variant="light"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                            />
                          </button>
                        ) : (
                          <button
                            className="btn-exit"
                            onClick={() => exitAuto()}
                            style={{ background: "#0d6efd" }}
                          >
                            {t("Exit Moment")}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {isExitManual ? (
                          <button
                            className={`btn-exit`}
                            style={{ background: "#0d6efd" }}
                          >
                            <Spinner
                              as="span"
                              variant="light"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                            />
                          </button>
                        ) : (
                          <button
                            className="btn-exit"
                            onClick={() => exitManual()}
                            style={{ background: "#0d6efd" }}
                          >
                            {t("Exit Moment")}
                          </button>
                        )}
                      </>
                    )}

                    <button
                      className="btn-cancel"
                      // onClick={updateMeetingStatus}
                      onClick={() => setShowConfirmationModal(true)}
                      style={{
                        border: "1px solid red",
                        color: "red",
                        background: "white",
                      }}
                    >
                      {t("Abort Moment")}
                    </button>
                    {showConfirmationModal && (
                      <ConfirmationModal
                        message={t("confirmation")}
                        onConfirm={() => {
                          setShowConfirmationModal(false);
                          updateMeetingStatus();
                        }}
                        onCancel={() => setShowConfirmationModal(false)}
                      />
                    )}
                  </div>
                </div>
                {/* {isAutomatic && <p>{storedTranscript + transcript}</p>} */}

                {inputData?.type === "Newsletter" ? (
                  <div className="text-start mb-4">
                    {inputData?.steps[currentStepIndex]?.assigned_to_team ? (
                      <div>
                        {inputData?.steps[currentStepIndex]?.assigned_team
                          ?.logo ? (
                          inputData?.steps[currentStepIndex]?.assigned_team
                            .logo !== "" ? (
                            <img
                              className="user-img"
                              width={50}
                              height={50}
                              src={
                                Assets_URL +
                                "/" +
                                inputData?.steps[currentStepIndex]
                                  ?.assigned_team.logo
                              }
                              alt="logo"
                            />
                          ) : (
                            <FaUserCircle size={30} />
                          )
                        ) : (
                          <FaUserCircle size={30} />
                        )}
                        <span style={{ margin: "0px 5px 0px 12px" }}>
                          {
                            inputData?.steps[currentStepIndex]?.assigned_team
                              ?.name
                          }
                        </span>
                        {/* <span>{inputData?.user?.last_name}</span> */}
                      </div>
                    ) : (
                      <FaUserCircle size={30} />
                    )}
                  </div>
                ) : (
                  <div className="text-start mb-4">
                    {inputData?.steps[currentStepIndex]?.assigned_to_name ===
                      null ? (
                      <div>
                        {inputData?.steps[currentStepIndex]?.image ? (
                          <img
                            className="user-img"
                            width={50}
                            height={50}
                            src={
                              inputData?.user?.image.startsWith("users/")
                                ? `${Assets_URL}/${inputData?.steps[currentStepIndex]?.image}`
                                : inputData?.steps[currentStepIndex]?.image
                            }
                            alt="logo1"
                          />
                        ) : (
                          <FaUserCircle size={30} />
                        )}
                        <span style={{ margin: "0px 5px 0px 12px" }}>
                          {inputData?.user?.name}
                        </span>
                        <span>{inputData?.user?.last_name}</span>
                      </div>
                    ) : (
                      <div>
                        {inputData?.steps[currentStepIndex]
                          ?.assigned_to_name ? (
                          <img
                            className="user-img"
                            width={50}
                            height={50}
                            // src={`${Assets_URL}/${inputData?.steps[currentStepIndex]?.assigned_to_image}`}
                            src={
                              inputData?.steps[
                                currentStepIndex
                              ]?.image.startsWith("users/")
                                ? `${Assets_URL}/${inputData?.steps[currentStepIndex]?.image}`
                                : inputData?.steps[currentStepIndex]?.image
                            }
                            alt="logo2"
                          />
                        ) : (
                          <FaUserCircle size={30} />
                        )}
                        <span className="mx-2">
                          {inputData.steps[currentStepIndex]?.assigned_to_name}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <div className="row justify-content-center">
                  <div className="col-md-8">
              
                    {editorContent && (
                      <div
                        className={`
                  ${!decisionEditor.showEditor ||
                            !notesEditor.showEditor ||
                            !planDActionEditor.showEditor
                            ? "h-80"
                            : !showStepContentEditor && "card "
                          }
                
                 
                  `}
                      >
                        <div
                          className={`
                    ${!decisionEditor.showEditor ||
                              !notesEditor.showEditor ||
                              !planDActionEditor.showEditor
                              ? ""
                              : "displaycard card-body"
                            }
                   
                    `}
                          style={{
                            height:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "90vh",
                            border:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "2px solid #eee",
                            overflowY:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "auto",
                            padding:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "10px",
                          }}
                        >
                          {decisionEditor.showEditor === false &&
                            showStepContentEditor === false &&
                            notesEditor.showEditor === false &&
                            planDActionEditor.showEditor === false &&
                            show.showEditor === false &&
                            (inputData?.steps[currentStepIndex]?.editor_type ===
                              "File" ||
                              inputData?.steps[currentStepIndex]?.editor_type ===
                              "Video" ||
                              inputData?.steps[currentStepIndex]?.editor_type ===
                              "Photo" ? (
                              <div>
                                <iframe
                                  src={
                                    Assets_URL +
                                    "/" +
                                    inputData?.steps[currentStepIndex]?.file
                                  }
                                  width="100%"
                                  height="630px"
                                />
                              </div>
                            ) : inputData?.steps[currentStepIndex]
                              ?.editor_type === "Excel" ? (
                              <div className="iframe-container">
                                <Spreadsheet
                                  data={excelData || []}
                                // onChange={handleSpreadsheetChange}
                                />
                              </div>
                            ) : inputData?.steps[currentStepIndex]
                              ?.editor_type === "Url" ? (
                              <div className="iframe-container">
                                <iframe
                                  // ref={iframeRef}
                                  // src={inputData?.steps[currentStepIndex]?.url}
                                  src={getYoutubeEmbedUrl(
                                    inputData?.steps[currentStepIndex]?.url
                                  )}
                                  width="100%"
                                  height="630px"
                                  onLoad={() => setIFrameLoad(false)}
                                />
                                {iFrameLoad && <div className="loader"></div>}
                              </div>
                            ) : (
                              <div
                                className="rendered-content"
                                dangerouslySetInnerHTML={{
                                  __html: sanitizedContent
                                }}
                              />
                            ))}

                          {/* NOTES EDITOR */}
                          {notesEditor.showEditor && (
                            <>
                              <Editor
                                onBlur={(value) => {
                                  console.log("value", value);
                                }}
                                key={activeStepIndex}
                                apiKey={TINYMCEAPI}
                                value={stepNotes[activeStepIndex]}
                                init={{
                                  statusbar: false,
                                  branding: false,
                                  height: 600,
                                  menubar: true,
                                  language: "fr_FR",
                                  // language: "en_EN",
                                  plugins:
                                    "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                  toolbar:
                                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                  image_advtab: true,
                                  file_picker_types: "image",

                                  file_picker_callback: function (
                                    callback,
                                    value,
                                    meta
                                  ) {
                                    if (meta.filetype === "image") {
                                      const input =
                                        document.createElement("input");
                                      input.setAttribute("type", "file");
                                      input.setAttribute("accept", "image/*");

                                      input.onchange = function () {
                                        const file = input.files[0];
                                        const reader = new FileReader();

                                        reader.onload = function (e) {
                                          const img = new Image();
                                          img.src = e.target.result;

                                          img.onload = function () {
                                            const canvas =
                                              document.createElement("canvas");
                                            const ctx = canvas.getContext("2d");
                                            const maxWidth = 700;
                                            const maxHeight = 394;

                                            let newWidth = img.width;
                                            let newHeight = img.height;

                                            if (img.width > maxWidth) {
                                              newWidth = maxWidth;
                                              newHeight =
                                                (img.height * maxWidth) /
                                                img.width;
                                            }

                                            if (newHeight > maxHeight) {
                                              newHeight = maxHeight;
                                              newWidth =
                                                (img.width * maxHeight) /
                                                img.height;
                                            }

                                            canvas.width = newWidth;
                                            canvas.height = newHeight;

                                            ctx.drawImage(
                                              img,
                                              0,
                                              0,
                                              newWidth,
                                              newHeight
                                            );

                                            const resizedImageData =
                                              canvas.toDataURL(file.type);

                                            // Pass the resized image data to the callback function
                                            callback(resizedImageData, {
                                              alt: file.name,
                                            });
                                          };

                                          img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                      };

                                      input.click();
                                    }
                                  },
                                }}
                                // onEditorChange={(value) => {
                                //   setNotes(value);
                                // }}
                                onEditorChange={(value) => {
                                  setStepNotes((prev) => {
                                    let newStepNotes = [...prev];
                                    newStepNotes[activeStepIndex] = value;
                                    return newStepNotes;
                                  });
                                }}
                              />
                            </>
                          )}
                          {show.showEditor && (
                            <Modal
                              centered
                              size="lg"
                              show
                              onHide={() =>
                                setShow((prev) => ({
                                  ...prev,
                                  showEditor: false,
                                }))
                              }
                            >
                              <Modal.Header closeButton></Modal.Header>

                              {previousSteps?.map((item, index) => {
                                if (item.note !== null) {
                                  return (
                                    <div className="p-3" key={index}>
                                      <div className="d-flex justify-content-between">
                                        <span style={{ cursor: "pointer" }}>
                                          {item.title}
                                        </span>
                                        <MdOutlineModeEdit
                                          className="eye-icon"
                                          color={
                                            show.showEditor
                                              ? "black"
                                              : "#20acd4"
                                          }
                                          size={18}
                                          style={{ margin: "2px" }}
                                          onClick={() =>
                                            handleStepEditor(item.id)
                                          }
                                        />
                                      </div>
                                      <div
                                        className="h-100"
                                        style={{
                                          overflowY: "auto",
                                          padding: "10px",
                                          borderRadius: "5px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.note,
                                        }}
                                      />
                                    </div>
                                  );
                                }
                                return null; // If the note is null, don't render this step
                              })}
                            </Modal>
                          )}

                          {stepNoteEditor.showEditor && (
                            <Modal
                              centered
                              size="lg"
                              show
                              onHide={() =>
                                setStepEditor((prev) => ({
                                  ...prev,
                                  showEditor: false,
                                }))
                              }
                            >
                              <Modal.Header closeButton></Modal.Header>

                              <div className="d-flex justify-content-center flex-column">
                                <Editor
                                  onBlur={(value) => {
                                    console.log("value", value);
                                  }}
                                  key={activeStepIndex}
                                  apiKey={TINYMCEAPI}
                                  value={stepNote}
                                  init={{
                                    statusbar: false,
                                    branding: false,
                                    height: 500,
                                    menubar: true,
                                    language: "fr_FR",
                                    // language: "en_EN",
                                    plugins:
                                      "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                    toolbar:
                                      "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                    image_advtab: true,
                                    file_picker_types: "image",

                                    file_picker_callback: function (
                                      callback,
                                      value,
                                      meta
                                    ) {
                                      if (meta.filetype === "image") {
                                        const input =
                                          document.createElement("input");
                                        input.setAttribute("type", "file");
                                        input.setAttribute("accept", "image/*");

                                        input.onchange = function () {
                                          const file = input.files[0];
                                          const reader = new FileReader();

                                          reader.onload = function (e) {
                                            const img = new Image();
                                            img.src = e.target.result;

                                            img.onload = function () {
                                              const canvas =
                                                document.createElement(
                                                  "canvas"
                                                );
                                              const ctx =
                                                canvas.getContext("2d");
                                              const maxWidth = 700;
                                              const maxHeight = 394;

                                              let newWidth = img.width;
                                              let newHeight = img.height;

                                              if (img.width > maxWidth) {
                                                newWidth = maxWidth;
                                                newHeight =
                                                  (img.height * maxWidth) /
                                                  img.width;
                                              }

                                              if (newHeight > maxHeight) {
                                                newHeight = maxHeight;
                                                newWidth =
                                                  (img.width * maxHeight) /
                                                  img.height;
                                              }

                                              canvas.width = newWidth;
                                              canvas.height = newHeight;

                                              ctx.drawImage(
                                                img,
                                                0,
                                                0,
                                                newWidth,
                                                newHeight
                                              );

                                              const resizedImageData =
                                                canvas.toDataURL(file.type);

                                              // Pass the resized image data to the callback function
                                              callback(resizedImageData, {
                                                alt: file.name,
                                              });
                                            };

                                            img.src = e.target.result;
                                          };

                                          reader.readAsDataURL(file);
                                        };

                                        input.click();
                                      }
                                    },
                                    // images_upload_handler:image_upload_handler_callback,
                                  }}
                                  onEditorChange={(value) => {
                                    setStepNote(value);
                                  }}

                                // onEditorChange={(value) => {
                                //   setStepNotes((prev) => {
                                //     let newStepNotes = [...prev];
                                //     newStepNotes[activeStepIndex] = value;
                                //     return newStepNotes;
                                //   });
                                // }}
                                />
                                <div className="d-flex justify-content-center p-2">
                                  {isUpdated ? (
                                    <>
                                      <Button
                                        variant="blue"
                                        disabled
                                        style={{
                                          backgroundColor: "#3aa5ed",
                                          border: "none",
                                          width: "18%",
                                        }}
                                      >
                                        <Spinner
                                          as="span"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          animation="border"
                                        />
                                      </Button>
                                    </>
                                  ) : (
                                    <button
                                      style={{
                                        color: "white",
                                        padding: "6px 16px",
                                        fontWeight: 500,
                                        border: "2px solid transparent",
                                        borderRadius: "10px",
                                        background: "#0d6efd",
                                      }}
                                      onClick={updateStepNote}
                                    >
                                      {t("updateStep")}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Modal>
                          )}

                          {/* DECISION EDITOR */}
                          {decisionEditor.showEditor && (
                            <>
                              <Select
                                options={[
                                  { value: "Budget", label: "Budget" },
                                  { value: "Milestone", label: "Milestone" },
                                  { value: "Rule", label: "Rule" },
                                ]}
                                value={
                                  decision[activeStepIndex]?.decision_type
                                    ? {
                                      value:
                                        decision[activeStepIndex]
                                          ?.decision_type,
                                      label:
                                        decision[activeStepIndex]
                                          ?.decision_type,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  handleSelectChange(
                                    selectedOption,
                                    "decision_type"
                                  )
                                }
                                placeholder="Decision type"
                                style={{ margin: "10px 0px" }}
                              />
                              {/* Conditional Input based on decision_type */}
                              {decision[activeStepIndex]?.decision_type ===
                                "Milestone" && (
                                  <input
                                    type="date"
                                    value={
                                      decision[activeStepIndex]?.milestone_date ||
                                      ""
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        { value: event.target.value },
                                        "milestone_date"
                                      )
                                    }
                                    placeholder="Select milestone date"
                                    style={{ margin: "10px 0px", width: "100%" }}
                                  />
                                )}

                              {decision[activeStepIndex]?.decision_type ===
                                "Budget" && (
                                  <input
                                    type="number"
                                    value={
                                      decision[activeStepIndex]?.budget_amount ||
                                      ""
                                    }
                                    onChange={(event) =>
                                      handleSelectChange(
                                        { value: event.target.value },
                                        "budget_amount"
                                      )
                                    }
                                    placeholder="Enter budget amount"
                                    style={{ margin: "10px 0px", width: "100%" }}
                                  />
                                )}

                              {/* Text Area */}
                              <textarea
                                rows="4"
                                cols="50"
                                placeholder="Add decision"
                                value={
                                  decision[activeStepIndex]?.decision || ""
                                }
                                onChange={handleTextChange}
                                style={{
                                  margin: "10px 0px",
                                  width: "100%",
                                  border: "1px solid #cccccc",
                                  borderRadius: "5px",
                                }}
                              />

                              {/* Second Select Field */}
                              <Select
                                options={combinedOptions}
                                value={
                                  decision[activeStepIndex]?.decision_apply
                                    ? {
                                      value:
                                        decision[activeStepIndex]
                                          ?.decision_apply,
                                      label:
                                        decision[activeStepIndex]
                                          ?.decision_apply,
                                    }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  handleSelectChange(
                                    selectedOption,
                                    "decision_apply"
                                  )
                                }
                                placeholder="Apply decision to"
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    margin: "10px 0px",
                                    position: "relative", // Keep this relative
                                    zIndex: 10, // Higher zIndex
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    position: "absolute", // Make sure dropdown is absolute
                                    zIndex: 9999, // Ensure it is on top
                                  }),
                                }}
                              />

                            </>
                          )}

                          {!notesEditor.showEditor &&
                            !decisionEditor.showEditor &&
                            !showStepContentEditor &&
                            planDActionEditor.showEditor && (
                              <section
                                className="row py-1"
                                style={{ height: "600px" }}
                              >
                                <div className="col-md-12 mb-2">
                                  {/* Plan of Action */}
                                  <div
                                    className="card card2 p-3 table-container"
                                    style={{ height: "600px" }}
                                  >
                                    <div
                                      className="cardbody resume"
                                      style={{
                                        overflowX: "hidden",
                                        height: "auto",
                                        // minHeight: "80vh",
                                      }}
                                    >
                                      <div className=" row subtitle  text-body-secondary">
                                        <div className="col-md-2">
                                          <span>{t("presentation.order")}</span>
                                        </div>
                                        <div className=" col-md-3 ">
                                          <span>
                                            {t("presentation.action")}
                                          </span>
                                        </div>
                                        <div className="col-md-3">
                                          <span>
                                            {t("presentation.carrier")}
                                          </span>
                                        </div>
                                        <div className="col-md-3">
                                          <span>
                                            {t("presentation.dueDate")}
                                          </span>
                                        </div>
                                        <div className="col-md-1">
                                          <span></span>
                                        </div>
                                      </div>

                                      {tableData?.map((rowData, index) => {
                                        if (
                                          rowData.step_id !==
                                          inputData.steps[currentStepIndex].id
                                        ) {
                                          return;
                                        }
                                        return (
                                          <div
                                            className="row p-2 text-body-dark mt-3 "
                                            style={{
                                              borderBottom: "1px solid #ccc",
                                            }}
                                            key={index}
                                          >
                                            <div className="col-md-2">
                                              <select
                                                className="form-select form-select-sm"
                                                value={rowData.order}
                                                onChange={(e) => {
                                                  handleTableDataChange(
                                                    e,
                                                    index
                                                  );
                                                }}
                                                name="order"
                                              >
                                                {Array.from({
                                                  length: 11,
                                                }).map((_, i) => (
                                                  <option key={i} value={i}>
                                                    {i}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>

                                            <div className="col-md-3">
                                              <textarea
                                                name="action"
                                                value={rowData.action}
                                                onChange={(e) => {
                                                  handleTableDataChange(
                                                    e,
                                                    index
                                                  );
                                                }}
                                                placeholder="Action"
                                                rows={3}
                                                // maxLength={100}
                                                className="wrapped-textarea txt"
                                              />
                                            </div>

                                            <div className="col-md-3">
                                              {inputData?.type ===
                                                "Newsletter" ? (
                                                <>
                                                  <select
                                                    className="form-select form-select-sm"
                                                    // value={rowData.participant_id || ""}
                                                    value={
                                                      rowData.participant_id
                                                    }
                                                    name="participant_id"
                                                    onChange={(e) =>
                                                      handleTableDataChange(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <option value="" disabled>
                                                      {t("Select Participant")}
                                                    </option>

                                                    {inputData.participants &&
                                                      inputData.participants
                                                        ?.length < 1 && (
                                                        <option value="">
                                                          No Participants Added{" "}
                                                        </option>
                                                      )}
                                                    {inputData &&
                                                      inputData?.participants
                                                        ?.filter(
                                                          (
                                                            participant,
                                                            index,
                                                            self
                                                          ) =>
                                                            index ===
                                                            self.findIndex(
                                                              (p) =>
                                                                p.email ===
                                                                participant.email
                                                            )
                                                        )
                                                        ?.map((item) => {
                                                          return (
                                                            <option
                                                              key={item.id}
                                                              value={item.id}
                                                            >
                                                              {item.first_name}{" "}
                                                              {item.last_name}
                                                            </option>
                                                          );
                                                        })}
                                                  </select>
                                                </>
                                              ) : (
                                                <select
                                                  className="form-select form-select-sm"
                                                  // value={rowData.participant_id || ""}
                                                  value={rowData.participant_id}
                                                  name="participant_id"
                                                  onChange={(e) =>
                                                    handleTableDataChange(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <option value="" disabled>
                                                    {t("Select Participant")}
                                                  </option>

                                                  {inputData.participants &&
                                                    inputData.participants
                                                      ?.length < 1 && (
                                                      <option value="">
                                                        No Participants Added{" "}
                                                      </option>
                                                    )}
                                                  {inputData &&
                                                    inputData?.participants
                                                      ?.filter(
                                                        (
                                                          participant,
                                                          index,
                                                          self
                                                        ) =>
                                                          index ===
                                                          self.findIndex(
                                                            (p) =>
                                                              p.email ===
                                                              participant.email
                                                          )
                                                      )
                                                      ?.map((item) => {
                                                        return (
                                                          <option
                                                            key={item.id}
                                                            value={item.id}
                                                          >
                                                            {item.first_name}{" "}
                                                            {item.last_name}
                                                          </option>
                                                        );
                                                      })}
                                                </select>
                                              )}
                                            </div>

                                            <div className="col-md-3">
                                              <div>
                                                <img
                                                  src="/Assets/minus1.svg"
                                                  alt="minus"
                                                  className="img-fluid "
                                                  width={"15px"}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleDecrementCount(index)
                                                  }
                                                />{" "}
                                                &nbsp; &nbsp;
                                                <span>
                                                  {parseInt(
                                                    rowData.action_days
                                                  )}{" "}
                                                  {t("Day")}
                                                </span>
                                                &nbsp;&nbsp;
                                                <img
                                                  src="/Assets/plus1.svg"
                                                  alt="plus"
                                                  className="img-fluid"
                                                  width={"15px"}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleIncrementCount(index)
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-1">
                                              <button
                                                className="btndel"
                                                onClick={() =>
                                                  handleButtonDelete(index)
                                                }
                                              >
                                                <AiFillDelete
                                                  size={"25px"}
                                                  color="red"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      <div className="d-flex justify-content-center mt-3 gap-2">
                                        <div>
                                          <GoPlusCircle
                                            size="30px"
                                            onClick={handleButtonClick}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                          {
                            // STEP EDITORRRR
                            !notesEditor.showEditor &&
                              !decisionEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              showStepContentEditor ? (
                              <div>
                                {/* FILE UPLOADDD */}
                                <section>
                                  <ShowIF
                                    condition={
                                      inputData.steps[currentStepIndex]
                                        .editor_type === "File" ||
                                      inputData.steps[currentStepIndex]
                                        .editor_type === "Video" ||
                                      inputData.steps[currentStepIndex]
                                        .editor_type === "Photo"
                                    }
                                  >
                                    <label>
                                      {inputData.steps[currentStepIndex].file}
                                    </label>
                                    <input
                                      type="file"
                                      multiple="false"
                                      // value={inputData.steps[currentStepIndex].file}
                                      onChange={async (e) => {
                                        console.log("file", e.target.files[0]);
                                        const file = e.target.files[0];
                                        let allowedFileTypes = [];
                                        // Determine allowed file types based on modalType
                                        if (
                                          inputData.steps[currentStepIndex]
                                            .editor_type === "File"
                                        ) {
                                          allowedFileTypes = [
                                            "application/pdf",
                                            "application/vnd.ms-excel",
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                            "application/vnd.ms-powerpoint",
                                            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                            "application/msword",
                                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                          ];
                                        } else if (
                                          inputData.steps[currentStepIndex]
                                            .editor_type === "Video"
                                        ) {
                                          allowedFileTypes = [
                                            "video/mp4",
                                            "video/x-msvideo",
                                            "video/x-matroska",
                                            "video/mpeg",
                                            "video/quicktime",
                                          ];
                                        } else if (
                                          inputData.steps[currentStepIndex]
                                            .editor_type === "Photo"
                                        ) {
                                          allowedFileTypes = [
                                            "image/jpeg",
                                            "image/png",
                                            "image/gif",
                                            "image/bmp",
                                            "image/webp",
                                            // Add other image MIME types if needed
                                          ];
                                        } else if (
                                          inputData.steps[currentStepIndex]
                                            .editor_type === "Excel"
                                        ) {
                                          allowedFileTypes = [
                                            "application/vnd.ms-excel",
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                          ];
                                        }

                                        // const allowedFileTypes = [
                                        //   "application/pdf",
                                        //   "application/vnd.ms-excel",
                                        //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        //   "application/vnd.ms-powerpoint",
                                        //   "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                        //   "application/msword",
                                        //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        // ];
                                        if (
                                          !file ||
                                          !allowedFileTypes.includes(file.type)
                                        ) {
                                          alert(
                                            "Please select a valid file type"
                                          );
                                        }
                                        // PREPARE THE PAYLOAD
                                        const updatedSteps = [
                                          ...(inputData?.steps || []),
                                        ];
                                        const selectedStep =
                                          updatedSteps[currentStepIndex];
                                        const filePayload = {
                                          ...selectedStep,
                                          title: selectedStep.title,
                                          count1: selectedStep.count1,
                                          count2: selectedStep.count2,
                                          time: selectedStep.count2,
                                          editor_type: selectedStep.editor_type,
                                          file: file,
                                          editor_content: null,
                                          _method: "put",
                                        };
                                        // SEND THE FILE TO THE SERVER
                                        try {
                                          const response = await axios.post(
                                            `${API_BASE_URL}/steps/${selectedStep?.id}`,
                                            filePayload,
                                            {
                                              headers: {
                                                "Content-Type":
                                                  "multipart/form-data",
                                                Authorization: `Bearer ${sessionStorage.getItem(
                                                  "token"
                                                )}`,
                                              },
                                            }
                                          );
                                          // console.log(
                                          //   "response--> file uploaded",
                                          //   response
                                          // );
                                          if (response.status === 200) {
                                            // Update the file in the state
                                            const updatedSteps = [
                                              ...(inputData?.steps || []),
                                            ];
                                            const selectedStep =
                                              updatedSteps[currentStepIndex];
                                            selectedStep.file =
                                              response.data.data.file;
                                            setInputData({
                                              ...inputData,
                                              steps: updatedSteps,
                                            });
                                          }
                                        } catch (error) {
                                          console.log(
                                            "error while uploading file",
                                            error
                                          );
                                        }
                                      }}
                                    />
                                  </ShowIF>
                                </section>

                                <ShowIF
                                  condition={
                                    inputData.steps[currentStepIndex]
                                      .editor_type === "Excel"
                                  }
                                >
                                  <label>
                                    {inputData.steps[currentStepIndex].file}
                                  </label>
                                  <input
                                    type="file"
                                    multiple="false"
                                    // value={inputData.steps[currentStepIndex].file}
                                    onChange={async (e) => {
                                      console.log("file", e.target.files[0]);
                                      const file = e.target.files[0];
                                      let allowedFileTypes = [];
                                      // Determine allowed file types based on modalType
                                      if (
                                        inputData.steps[currentStepIndex]
                                          .editor_type === "Excel"
                                      ) {
                                        allowedFileTypes = [
                                          "application/vnd.ms-excel",
                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        ];
                                      }

                                      if (
                                        !file ||
                                        !allowedFileTypes.includes(file.type)
                                      ) {
                                        alert(
                                          "Please select a valid file type"
                                        );
                                      }
                                      // PREPARE THE PAYLOAD
                                      const updatedSteps = [
                                        ...(inputData?.steps || []),
                                      ];
                                      const selectedStep =
                                        updatedSteps[currentStepIndex];
                                      const filePayload = {
                                        ...selectedStep,
                                        title: selectedStep.title,
                                        count1: selectedStep.count1,
                                        count2: selectedStep.count2,
                                        time: selectedStep.count2,
                                        editor_type: selectedStep.editor_type,
                                        file: file,
                                        editor_content: null,
                                        _method: "put",
                                      };
                                      // SEND THE FILE TO THE SERVER
                                      try {
                                        const response = await axios.post(
                                          `${API_BASE_URL}/steps/${selectedStep?.id}`,
                                          filePayload,
                                          {
                                            headers: {
                                              "Content-Type":
                                                "multipart/form-data",
                                              Authorization: `Bearer ${sessionStorage.getItem(
                                                "token"
                                              )}`,
                                            },
                                          }
                                        );
                                        // console.log(
                                        //   "response--> file uploaded",
                                        //   response
                                        // );
                                        if (response.status === 200) {
                                          // Update the file in the state
                                          const updatedSteps = [
                                            ...(inputData?.steps || []),
                                          ];
                                          const selectedStep =
                                            updatedSteps[currentStepIndex];
                                          selectedStep.file =
                                            response.data.data.file;
                                          setInputData({
                                            ...inputData,
                                            steps: updatedSteps,
                                          });
                                        }
                                      } catch (error) {
                                        console.log(
                                          "error while uploading file",
                                          error
                                        );
                                      }
                                    }}
                                  />
                                </ShowIF>
                                <ShowIF
                                  condition={
                                    inputData.steps[currentStepIndex]
                                      .editor_type === "Editeur"
                                  }
                                >
                                  <Editor
                                    onBlur={(value) => {
                                      console.log("value", value);
                                    }}
                                    key={activeStepIndex}
                                    apiKey={TINYMCEAPI}
                                    value={
                                      inputData?.steps[activeStepIndex]
                                        ?.editor_content
                                    }
                                    init={{
                                      statusbar: false,
                                      branding: false,
                                      height: 900,
                                      menubar: true,
                                      language: "fr_FR",
                                      // language: "en_EN",
                                      plugins:
                                        "print preview paste searchreplace image autolink directionality visualblocks visualchars fullscreen  link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                      toolbar:
                                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | image | imagePicker link media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                      // image_advtab: true,
                                      file_picker_types: "image",
                                      images_upload_handler:
                                        image_upload_handler_callback,
                                    }}
                                    // onEditorChange={(content) => {
                                    //   setInputData((prevData) => ({
                                    //     ...prevData,
                                    //     steps: prevData.steps.map(
                                    //       (step, index) => {
                                    //         if (index === activeStepIndex) {
                                    //           return {
                                    //             ...step,
                                    //             editor_content: content,
                                    //           };
                                    //         }
                                    //         return step;
                                    //       }
                                    //     ),
                                    //   }));
                                    // }}
                                    onEditorChange={(content) => {
                                      const sanitizedContent = sanitizeIframeContent(content);
                                      setInputData((prevData) => ({
                                        ...prevData,
                                        steps: prevData.steps.map((step, index) => {
                                          if (index === activeStepIndex) {
                                            return {
                                              ...step,
                                              editor_content: sanitizedContent,
                                            };
                                          }
                                          return step;
                                        }),
                                      }));
                                    }}
                                    onInit={(evt, editor) => {
                                      editorRef.current = editor;
                                    }}
                                    onNodeChange={(e) => {
                                      if (
                                        e &&
                                        e.element.nodeName.toLowerCase() ==
                                        "img"
                                      ) {
                                        editorRef.current.dom.setAttribs(
                                          e.element,
                                          {
                                            width: "700px",
                                            height: "394px",
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </ShowIF>
                                <ShowIF
                                  condition={
                                    inputData.steps[currentStepIndex]
                                      .editor_type === "Email"
                                  }
                                >
                                  <Editor
                                    onBlur={(value) => {
                                      console.log("value", value);
                                    }}
                                    key={activeStepIndex}
                                    apiKey={TINYMCEAPI}
                                    value={
                                      inputData?.steps[activeStepIndex]
                                        ?.editor_content
                                    }
                                    init={{
                                      statusbar: false,
                                      branding: false,
                                      height: 900,
                                      menubar: true,
                                      language: "fr_FR",
                                      // language: "en_EN",
                                      plugins:
                                        "print preview paste searchreplace image autolink directionality visualblocks visualchars fullscreen  link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                      toolbar:
                                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | image | imagePicker link media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                      // image_advtab: true,
                                      file_picker_types: "image",
                                      images_upload_handler:
                                        image_upload_handler_callback,
                                    }}
                                    onEditorChange={(content) => {
                                      setInputData((prevData) => ({
                                        ...prevData,
                                        steps: prevData.steps.map(
                                          (step, index) => {
                                            if (index === activeStepIndex) {
                                              return {
                                                ...step,
                                                editor_content: content,
                                              };
                                            }
                                            return step;
                                          }
                                        ),
                                      }));
                                    }}
                                    onInit={(evt, editor) => {
                                      editorRef.current = editor;
                                    }}
                                    onNodeChange={(e) => {
                                      if (
                                        e &&
                                        e.element.nodeName.toLowerCase() ==
                                        "img"
                                      ) {
                                        editorRef.current.dom.setAttribs(
                                          e.element,
                                          {
                                            width: "700px",
                                            height: "394px",
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </ShowIF>
                                <ShowIF
                                  condition={
                                    inputData?.steps[currentStepIndex]
                                      .editor_type === "Url"
                                  }
                                >
                                  <label>
                                    {inputData?.steps[currentStepIndex]?.Url}
                                  </label>
                                  <input
                                    type="text"
                                    // placeholder="https://www.google.com"
                                    value={
                                      inputData?.steps[currentStepIndex]?.url
                                    }
                                    // onChange={handleLinkUpload}
                                    onChange={(e) => {
                                      setInputData((prevData) => ({
                                        ...prevData,
                                        steps: prevData.steps.map(
                                          (step, index) => {
                                            if (index === activeStepIndex) {
                                              return {
                                                ...step,
                                                url: e.target.value,
                                              };
                                            }
                                            return step;
                                          }
                                        ),
                                      }));
                                    }}
                                    name="url"
                                    style={{ width: "50%" }}
                                  />
                                </ShowIF>
                              </div>
                            ) : null
                          }
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="">
                      <CounterContainer
                        alarm={meetingData?.alarm || false}
                        progress={showProgressBar}
                        estimateTime={estimateTime}
                        estimateDate={estimateDate}
                      />
                    </div>

                    {!isAutomatic && (
                      <div>
                        {currentStepIndex !== inputData?.steps?.length - 1 ? (
                          <>
                            <div className="d-flex justify-content-center pt-3 mb-3">
                              <br />
                              <div className="d-flex gap-3 prev-btn">
                               
                                {isNext ? (
                                  <>
                                    <button className={`btn btn-primary`}>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className={`btn ${currentStepIndex ===
                                      inputData?.steps?.length - 1
                                      ? "btn-success"
                                      : "btn-primary"
                                      }`}
                                    onClick={async () => {
                                      if (
                                        currentStepIndex ===
                                        inputData?.steps?.length - 1
                                      ) {
                                        await closeMeeting(); // Ensure closeMeeting is awaited
                                      } else {
                                        await handlenextPage(); // Press Next if not on last step
                                      }
                                    }}
                                  >
                                    {currentStepIndex ===
                                      inputData.steps.length - 1
                                      ? t("Close")
                                      : t("Next")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="d-flex justify-content-center pt-3 mb-3">
                            <br />
                            <div className="d-flex  gap-3 prev-btn ">
                             
                              <button
                                className={` btn ${currentStepIndex ===
                                  inputData?.steps?.length - 1
                                  ? "btn-success"
                                  : "btn-primary"
                                  }`}
                                disabled={buttonDisabled}
                                onClick={async () => {
                                  if (
                                    currentStepIndex ===
                                    inputData?.steps?.length - 1
                                  ) {
                                    await closeMeeting(); // Ensure closeMeeting is awaited
                                  } else {
                                    await handlenextPage(); // Press Next if not on last step
                                  }
                                }}
                              >
                                {currentStepIndex ===
                                  inputData.steps.length - 1 ? (
                                  isLoading ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </>
                                  ) : (
                                    buttonText
                                  )
                                ) : (
                                  `${t("Next")}`
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-items-center gap-1 my-3 flex-wrap">
                          <div className="d-flex align-items-center gap-1">
                            <ReactToggle
                              checked={showStepContentEditor}
                              icons={false}
                              className="step-content-toggle toggle-playback"
                              onChange={handleStepContentEditor}
                              aria-label="Toggle Step Content Editor"
                            />
                            <span>
                              {/* steps */}
                              {t("Step")}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            <ReactToggle
                              checked={notesEditor.showEditor}
                              icons={false}
                              className={`toggle-icon toggle-playback ${notesEditor.showEditor ? "active" : ""}`}
                              onChange={handleNotesEditorToggle}
                              aria-label="Toggle Notes Editor"
                              disabled={inputData?.type === "Newsletter"} // Disable toggle when type is "Newsletter"
                            />
                            <span>
                              {t("Notes")}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            <ReactToggle
                              checked={decisionEditor.showEditor}
                              icons={false}
                              className={`toggle-icon toggle-playback ${decisionEditor.showEditor ? "active" : ""}`}
                              onChange={handleDecisionEditorToggle}
                              aria-label="Toggle Decision Editor"
                            />
                            <span>
                              {t("Decision")}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            <ReactToggle
                              checked={planDActionEditor.showEditor}
                              icons={false}
                              className={`toggle-icon toggle-playback ${planDActionEditor.showEditor ? "active" : ""
                                }`}
                              onChange={handlePlanDActionEditor}
                              aria-label="Toggle Plan D'Action Editor"
                            />
                            <span>
                              {t("Strategy")}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {isAutomatic && (
                      <div>
                        {currentStepIndex !== inputData?.steps?.length - 1 ? (
                          <>
                            <div className="d-flex justify-content-center pt-3 mb-3">
                              <br />
                              <div className="d-flex  gap-3 prev-btn ">
                                {isLoading ? (
                                  <>
                                    <button className={`btn btn-primary`}>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => next()}
                                    disabled={isLoading}
                                  // onClick={() => recorderControls.stopRecording()}
                                  >
                                    {t("Next")}
                                  </button>
                                )}
                              </div>
                            </div>
                            
                          </>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center pt-3 mb-3">
                              <br />
                              <div className="d-flex  gap-3 prev-btn ">
                                {isLoading ? (
                                  <>
                                    <button className={`btn btn-success`}>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-success"
                                    onClick={() => close()}
                                    disabled={isLoading}
                                  // onClick={() => prev()}
                                  >
                                    {t("Close")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="d-flex align-items-center gap-2 my-3 flex-wrap">
                              <div className="d-flex align-items-center gap-1">
                                <ReactToggle
                                  checked={showStepContentEditor}
                                  icons={false}
                                  className="step-content-toggle toggle-playback"
                                  onChange={handleStepContentEditor}
                                  aria-label="Toggle Step Content Editor"
                                />
                                <span>
                                  {t("Step")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                <ReactToggle
                                  checked={decisionEditor.showEditor}
                                  icons={false}
                                  className={`toggle-icon toggle-playback ${decisionEditor.showEditor ? "active" : ""}`}
                                  onChange={handleDecisionEditorToggle}
                                  aria-label="Toggle Decision Editor"
                                />
                                <span>
                                  {t("Decision")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                <ReactToggle
                                  checked={planDActionEditor.showEditor}
                                  icons={false}
                                  className={`toggle-icon toggle-playback ${planDActionEditor.showEditor ? "active" : ""
                                    }`}
                                  onChange={handlePlanDActionEditor}
                                  aria-label="Toggle Plan D'Action Editor"
                                />
                                <span>
                                  {t("Strategy")}
                                </span>
                              </div>
                            </div>
                      </div>
                    )}
                    {/* ...................START................ */}
                    {isAutomatic ? (
                      <>
                        {/* -------------------WHEN NOTES AUTOMATIC------------- */}
                        {meetingData?.type === "Newsletter" ? (
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <label className="form-label mb-3">
                                &nbsp; {t("Notes")}
                              </label>
                            </div>
                            {
                              <div style={{ height: "200px" }} className="">
                                <div
                                  key={emailCampaign}
                                  className="h-100"
                                  style={{
                                    overflowY: "auto",
                                    borderBottom: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="row d-flex flex-column">
                                    <div className="d-flex gap-3 fs-4">
                                      <h6>Campaign Name: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.campaign_name
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Sendings: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_sendings
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Recipients: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_recipients
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Opens: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_opens
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total clicks: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_clicks
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Unsubscribes: </h6>
                                      <h6>
                                        {
                                          emailCampaign
                                            ?.total_unsubscribes
                                        }
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        ) : (
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <label className="form-label m-0">
                                &nbsp; {t("Take Notes")}
                              </label>
                            </div>
                            <div
                              style={{ height: "50px", visibility: "hidden" }}
                            >
                              {/* <AudioRecorder
                            onRecordingComplete={(blob) => {
                              if (isLastStep) {
                                lastStepData(blob);
                              } else if (isExit) {
                                ExitMeeting(blob);
                              } else {
                                addAudioElement(blob);
                                recorderControls.startRecording();
                              }
                              recorderControls.stopRecording();
                            }}
                            recorderControls={recorderControls}
                            showVisualizer={true}
                          /> */}
                              <br />
                              {/* <button onClick={recorderControls.stopRecording}>
                            Stop recording
                          </button> */}
                              <br />
                              <AudioRecorder
                                onRecordingComplete={async (blob) => {
                                  await addAudioElement(blob);
                                  // recorderControls.startRecording();
                                  recorderControls.stopRecording();
                                }}
                                recorderControls={recorderControls}
                                downloadOnSavePress={false}
                                showVisualizer={true}
                              />
                            </div>
                          </div>
                        )}

                        {/* ---DECISION */}
                        <div className="">
                          <label className="form-label mb-3">
                            &nbsp; {t("Decision")}
                          </label>
                        </div>
                        {
                          <div style={{ height: "200px" }}>
                            <div
                              key={activeStepIndex}
                              className="h-100"
                              style={{
                                overflowY: "auto",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: decision[activeStepIndex]?.decision,
                              }}
                            />
                          </div>
                        }

                        {/* --------PlanDAction */}
                        <div className="card-body p-0 mt-1">
                          <h5 className="card-title ">
                            <div className="mb-3">
                              <label className="form-label mb-3 strategy-font mt-2">
                                &nbsp; {t("Strategy")}
                              </label>
                            </div>
                          </h5>
                          {
                            <div
                              className="cardbody resume card2"
                              style={{ overflowX: "hidden" }}
                            >
                              {filteredTableData &&
                                filteredTableData?.map((rowData, index) => {
                                  console.log("rowData", rowData);
                                  if (
                                    rowData.step_id !=
                                    inputData.steps[currentStepIndex].id
                                  ) {
                                    return;
                                  }
                                  return (
                                    <div
                                      className="text-body-dark p-2"
                                      style={{ borderBottom: "1px solid #ccc" }}
                                      key={index}
                                    >
                                      <div className="row">
                                        <div className="col-md-12">
                                          <textarea
                                            name="action"
                                            value={rowData?.action}
                                            onChange={(e) =>
                                              handleTableDataChange(e, index)
                                            }
                                            placeholder="Action"
                                            rows={3}
                                            // maxLength={100}
                                            className="wrapped-textarea"
                                            style={{
                                              background: "white",
                                              resize: "none",
                                              width: "100%",
                                              outline: "none",
                                              border: "none",
                                              borderRadius: "0px",
                                            }}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          }
                        </div>
                        {/* -------------------WHEN NOTES AUTOMATIC------------- */}
                      </>
                    ) : (
                      <>
                        {/* -------------------WHEN NOTES MANUAL------------- */}
                        {meetingData?.type === "Newsletter" ? (
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <label className="form-label mb-3">
                                {/* <MdOutlineModeEdit
                                  className="eye-icon"
                                  color={
                                    notesEditor.showEditor ? "#20acd4" : "black"
                                  }
                                  size={18}
                                  style={{
                                    margin: "2px",
                                  }}
                                // onClick={handleNotesEditorToggle}
                                /> */}
                                &nbsp; {t("Notes")}
                              </label>
                            </div>
                            {
                              <div style={{ height: "200px" }} className="">
                                <div
                                  key={activeStepIndex}
                                  className="h-100"
                                  style={{
                                    overflowY: "auto",
                                    borderBottom: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="row d-flex flex-column">
                                    <div className="d-flex gap-3 fs-4">
                                      <h6>Campaign Name: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.campaign_name

                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Sendings: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_sendings
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Recipients: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_recipients
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Opens: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_opens
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total clicks: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_clicks
                                        }
                                      </h6>
                                    </div>
                                    <div className="d-flex ga-3">
                                      <h6>Total Unsubscribes: </h6>
                                      <h6>
                                        {
                                          emailCampaign?.total_unsubscribes
                                        }
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        ) : (
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <label className="form-label mb-3 d-flex">
                                {/* <MdOutlineModeEdit
                                  className="eye-icon"
                                  color={
                                    notesEditor.showEditor ? "#20acd4" : "black"
                                  }
                                  size={18}
                                  style={{
                                    margin: "2px",
                                  }}
                                  onClick={handleNotesEditorToggle}
                                /> */}
                                {/* <ReactToggle
                                  checked={notesEditor.showEditor}
                                  icons={false}
                                  className={`toggle-icon toggle-playback ${notesEditor.showEditor ? "active" : ""}`}
                                  onChange={handleNotesEditorToggle}
                                  aria-label="Toggle Notes Editor"
                                /> */}
                                <span>
                                  &nbsp; {t("Notes")}
                                </span>
                              </label>
                              <span
                                onClick={handleShow}
                                style={{
                                  cursor: "pointer",
                                  color: show.showEditor ? "#20acd4" : "black",
                                  fontWeight: "bold",
                                }}
                              >
                                {t("seeAllNotes")}
                              </span>
                            </div>
                            {
                              <div
                                style={{ height: "200px" }}
                                className="cardbody resume card2"
                              >
                                <div
                                  key={activeStepIndex}
                                  className="h-100"
                                  style={{
                                    overflowY: "auto",
                                    borderBottom: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: stepNotes[activeStepIndex],
                                  }}
                                />
                              </div>
                            }
                          </div>
                        )}
                        <div className="">
                          <label className="form-label mb-3 d-flex">
                            {/* <MdOutlineModeEdit
                              className="eye-icon"
                              color={
                                decisionEditor.showEditor ? "#20acd4" : "black"
                              }
                              size={18}
                              style={{
                                margin: "2px",
                              }}
                              onClick={handleDecisionEditorToggle}
                            /> */}
                            {/* <ReactToggle
                              checked={decisionEditor.showEditor}
                              icons={false}
                              className={`toggle-icon toggle-playback ${decisionEditor.showEditor ? "active" : ""}`}
                              onChange={handleDecisionEditorToggle}
                              aria-label="Toggle Decision Editor"
                            /> */}
                            <span>
                              &nbsp; {t("Decision")}
                            </span>
                          </label>
                        </div>
                        {
                          <div style={{ height: "200px" }}>
                            <div
                              key={activeStepIndex}
                              className="h-100"
                              style={{
                                overflowY: "auto",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: decision[activeStepIndex]?.decision,
                              }}
                            />
                          </div>
                        }
                        <div className="card-body p-0 mt-3">
                          <h5 className="card-title ">
                            <div className="mb-3">
                              <label className="form-label mb-3">
                                {/* <MdOutlineModeEdit
                                  className="eye-icon"
                                  // color="#20acd4"
                                  color={
                                    planDActionEditor.showEditor
                                      ? "#20acd4"
                                      : "black"
                                  }
                                  size={18}
                                  style={{
                                    margin: "2px",
                                  }}
                                  onClick={handlePlanDActionEditor}
                                /> */}
                                {/* <ReactToggle
                                  checked={planDActionEditor.showEditor}
                                  icons={false}
                                  className={`toggle-icon toggle-playback ${planDActionEditor.showEditor ? "active" : ""
                                    }`}
                                  onChange={handlePlanDActionEditor}
                                  aria-label="Toggle Plan D'Action Editor"
                                /> */}
                                &nbsp; {t("Strategy")}
                              </label>
                            </div>
                          </h5>
                          {
                            <div
                              className="cardbody resume card2"
                              style={{ overflowX: "hidden" }}
                            >
                              {tableData?.map((rowData, index) => {
                                if (
                                  rowData.step_id !=
                                  inputData.steps[currentStepIndex].id
                                ) {
                                  return;
                                }
                                return (
                                  <div
                                    className="text-body-dark p-2"
                                    style={{ borderBottom: "1px solid #ccc" }}
                                    key={index}
                                  >
                                    <div className="row">
                                      <div className="col-md-12">
                                        <textarea
                                          name="action"
                                          value={rowData.action}
                                          onChange={(e) =>
                                            handleTableDataChange(e, index)
                                          }
                                          placeholder="Action"
                                          rows={3}
                                          // maxLength={100}
                                          className="wrapped-textarea txt"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            // )
                          }
                        </div>
                        {/* -------------------WHEN NOTES MANUAL------------- */}
                      </>
                    )}
                    {/* ...................END................ */}
                  </div>
                </div>
              </div>
            </div>

            {showProgressBar && (
              <div className="progress-overlay">
                <div style={{ width: "50%" }}>
                  <ProgressBar now={progress} animated />
                  <h5 className="text-center my-3">{t("progressBarText")}</h5>
                </div>
              </div>
            )}
          </>
        )}

        <div>
          {GradientSvg}
          {GradientSvg2}
          {GradientSvg3}
        </div>
      </div>
    </>
  );
};

export default Play;
