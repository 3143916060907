import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ParticipantCard from "../../Meeting/ParticipantCard";
import StepCard from "../../Meeting/StepCard";
import axios from "axios";
import { API_BASE_URL, Assets_URL } from "./../../../Apicongfig";
import HostCard from "../../Meeting/HostCard";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../../../context/HeaderTitleContext";
import { toast } from "react-toastify";
import { FaArrowRight } from "react-icons/fa6";
import { RiEditBoxLine, RiPresentationFill } from "react-icons/ri";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { IoArrowBackSharp, IoCopyOutline } from "react-icons/io5";
import { BiDotsVerticalRounded } from "react-icons/bi";
import moment from "moment";
import { ImCancelCircle } from "react-icons/im";
import { LuLink } from "react-icons/lu";
import copy from "copy-to-clipboard";
import { openLinkInNewTab } from "../../../Utils/openLinkInNewTab";
import InviteStepChart from "../../Meeting/InviteStepChart";
// import StepChart from "./StepChart";
import { useSteps } from "../../../../context/Step";
import { FaChartGantt } from "react-icons/fa6";
import { FaList } from "react-icons/fa";
// import ConfirmationModal from "./../../../../Utils/ConfirmationModal";
import {
  convertCount2ToSeconds,
  convertTimeTakenToSeconds,
  openGoogleMeet,
  parseTimeTaken,
} from "../../../Utils/MeetingFunctions";
// import { useSolutions } from "./../../../context/SolutionsContext";
import { useSolutionFormContext } from "../../../../context/CreateSolutionContext";
// import NewMeetingModal from "../../CreateNewMeeting/NewMeetingModal";
// import StepChart from "../../CreateNewMeeting/StepChart";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { BsPersonWorkspace } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
// import NewMeetingModal from "../../Meeting/CreateNewMeeting/components/AddGuestComponents/AddNewGuest";
// import StepChart from "../../Meeting/CreateNewMeeting/StepChart";
import ConfirmationModal from "../../../Utils/ConfirmationModal";
import { useSolutions } from "../../../../context/SolutionsContext";
import SolutionHostCard from "./Components/SolutionHostCard";
import SolutionStepCard from "./Components/SolutionStepCard";
import SolutionStepGraph from "./Components/SolutionStepGraph";
import StepChart from "../Createnewsolution/StepChart";
import NewSolutionModal from "../Createnewsolution/NewSolutionModal";
import { useFormContext } from "../../../../context/CreateMeetingContext";
import NewMeetingModal from "../BuildMoment/NewMeetingModal";

const Solution = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setStatus } = useSolutions(); // Access setStatus from the context
  const {
    handleShow,
    open,
    setOpen,
    setSolution: setSolutionContext,
    setIsDuplicate,
    setIsUpdated,
    setCheckId,
    setAddParticipant,
    setCall,
    call,
  } = useSolutionFormContext();
  const {
    handleShow: handleShowMeeting,
    open: openModal,

  } = useFormContext();

  console.log("location", location);
  let fromMeeting = false;
  if (location?.state?.from === "meeting") {
    fromMeeting = true;
  }
  const { id } = useParams();
  const [t] = useTranslation("global");
  const { setHeaderTitle } = useHeaderTitle();

  const [meeting, setMeeting] = useState();
  const [meetingStartDate, setMeetingStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime1, setStartTime1] = useState(null);
  const [time, setTime] = useState(null);
  const { solutionSteps, updateSolutionSteps,setSolutionType,setSolutionNote,setSolutionAlarm,setSolutionShareBy,setSolutionTitle } = useSteps();
  const [timeUnitsTotal, setTimeUnitsTotal] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [estimateTime, setEstimateTime] = useState(null);
  const [estimateDate, setEstimateDate] = useState(null);
  const [isDrop, setIsDrop] = useState(false);
  const [view, setView] = useState("list");
  // const [ call,setCall] = useState(false)
  const getRefreshMeeting = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/solutions/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setStatus(response?.data?.data?.status);
        setMeetingStartDate(response?.data?.data?.date);
        setHeaderTitle({ titleText: " " });
        const steps = response.data?.data?.solution_steps;
        // updateSteps(steps);
        const time = response?.data?.data?.start_time;
        setMeeting(response.data?.data);
        // setSolutionContext(response?.data?.data);
        updateSolutionSteps(steps);
        setSolutionType(response?.data?.data?.type)
        setSolutionNote(response?.data?.data?.prise_de_notes)
        setSolutionAlarm(response?.data?.data?.alarm)
        setSolutionShareBy(response?.data?.data?.share_by)
        setSolutionTitle(response?.data?.data?.title)
        setTime(response?.data?.data?.start_time);
        const date = response?.data?.data?.estimate_time;
        const dateOnly = date.split("T")[0];

        // Split the date into year, month, and day
        const [year, month, day] = dateOnly.split("-");

        // Format the date as dd/mm/yyyy
        const formattedDate1 = `${day}/${month}/${year}`;
        const timeOnly = date.split("T")[1].split(".")[0];

        // Convert the time to a Date object for easy 12-hour conversion
        const [hours1, minutes1, seconds1] = timeOnly.split(":");
        // let hours12 = hours1 % 12 || 12; // Convert to 12-hour format, handle 00 as 12
        // const amPm = hours1 >= 12 ? "PM" : "AM";
        console.log("dateOnly", formattedDate);
        console.log("timeOnly", timeOnly);
        const formattedTime = `${hours1}h${minutes1}${
          response?.data?.data?.type === "Quiz" ? `m${seconds1}` : ""
        }`;
        console.log("formattedTime", formattedTime);

        setEstimateTime(formattedTime);
        setEstimateDate(formattedDate1);
        // Calculate the total count2
        const totalCount2 = steps?.reduce((acc, step) => acc + step.count2, 0);
        console.log("totalCount2: ", totalCount2);
        // Convert start time to a Date object
        const [hours, minutes, seconds] = time.split(":").map(Number);
        const startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(seconds);

        // Add totalCount2 minutes to the start date
        startDate.setMinutes(startDate.getMinutes() + totalCount2);

        // Format the new time
        const formattedTimeAfterAddingStepsTime = startDate.toLocaleTimeString(
          "en-US",
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        );
        setStartTime1(formattedTimeAfterAddingStepsTime);
        const totals = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        steps.forEach((step) => {
          switch (step.time_unit) {
            case "days":
              totals.days += step.time;
              break;
            case "hours":
              totals.hours += step.time;
              break;
            case "minutes":
              totals.minutes += step.time;
              break;
            case "seconds":
              totals.seconds += step.time;
              break;
            default:
              break;
          }
        });

        setTimeUnitsTotal(totals);
      }
    } catch (error) {
      console.log("error while fetching meeting data", error);
    } finally {
      // setIsLoading(false);
    }
  };
  const getMeeting = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/solutions/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setStatus(response?.data?.data?.status);
        setMeetingStartDate(response?.data?.data?.date);
        setHeaderTitle({ titleText: " " });
        const steps = response.data?.data?.solution_steps;
        // updateSteps(steps);
        const time = response?.data?.data?.start_time;
        updateSolutionSteps(steps);
        setSolutionType(response?.data?.data?.type)
        setSolutionNote(response?.data?.data?.prise_de_notes)
        setSolutionAlarm(response?.data?.data?.alarm)
        setSolutionShareBy(response?.data?.data?.share_by)
        setSolutionTitle(response?.data?.data?.title)

        setMeeting(response.data?.data);
        // setSolutionContext(response?.data?.data);
        setTime(response?.data?.data?.start_time);
        const date = response?.data?.data?.estimate_time;
        const dateOnly = date.split("T")[0];

        // Split the date into year, month, and day
        const [year, month, day] = dateOnly.split("-");

        // Format the date as dd/mm/yyyy
        const formattedDate1 = `${day}/${month}/${year}`;
        const timeOnly = date.split("T")[1].split(".")[0];

        // Convert the time to a Date object for easy 12-hour conversion
        const [hours1, minutes1, seconds1] = timeOnly.split(":");
        // let hours12 = hours1 % 12 || 12; // Convert to 12-hour format, handle 00 as 12
        // const amPm = hours1 >= 12 ? "PM" : "AM";
        console.log("dateOnly", formattedDate);
        console.log("timeOnly", timeOnly);
        const formattedTime = `${hours1}h${minutes1}${
          response?.data?.data?.type === "Quiz" ? `m${seconds1}` : ""
        }`;
        console.log("formattedTime", formattedTime);

        setEstimateTime(formattedTime);
        setEstimateDate(formattedDate1);
        // Calculate the total count2
        const totalCount2 = steps?.reduce((acc, step) => acc + step.count2, 0);
        console.log("totalCount2: ", totalCount2);
        // Convert start time to a Date object
        const [hours, minutes, seconds] = time.split(":").map(Number);
        const startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(seconds);

        // Add totalCount2 minutes to the start date
        startDate.setMinutes(startDate.getMinutes() + totalCount2);

        // Format the new time
        const formattedTimeAfterAddingStepsTime = startDate.toLocaleTimeString(
          "en-US",
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        );
        setStartTime1(formattedTimeAfterAddingStepsTime);
        const totals = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        steps.forEach((step) => {
          switch (step.time_unit) {
            case "days":
              totals.days += step.time;
              break;
            case "hours":
              totals.hours += step.time;
              break;
            case "minutes":
              totals.minutes += step.time;
              break;
            case "seconds":
              totals.seconds += step.time;
              break;
            default:
              break;
          }
        });

        setTimeUnitsTotal(totals);
      }
    } catch (error) {
      console.log("error while fetching meeting data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMeeting();
    // getMeeting1();
  }, [id, showModal]);
  useEffect(() => {
    getRefreshMeeting();
    // getMeeting1();
  }, [call]);

  const date = new Date(meeting?.date);
  // Get individual components of the date
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date in dd:mm:yyyy
  const formattedDate = `${day}/${month}/${year}`;

  const startTime = meeting?.start_time;
  const formattedTime = new Date(`1970-01-01T${startTime}`).toLocaleTimeString(
    "en-US",
    { hour: "2-digit", minute: "2-digit", hour12: true }
  );

  const calculateTotalDays = (steps) => {
    if (!steps) {
      return;
    }
    return steps?.reduce((total, step) => {
      return total + step.count2;
    }, 0);
  };

  const [loading, setLoading] = useState(false);

  const userId = parseInt(sessionStorage.getItem("user_id"));

  const handleEdit = (item) => {
    console.log("item", item);
    // navigate(`/updateMeeting/${item?.id}`);
    setCheckId(item.id);
    setIsUpdated(true);
    handleShow();
    setSolutionContext(item);
  };
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleCopy = async (item) => {
    console.log("item", item);

    try {
      // Parse the start time string
      const [hour, minute] = startTime.split(":").map(Number);

      // Add one hour to the hour component
      let endHour = hour + 1;

      // If end hour is greater than or equal to 24, subtract 24
      if (endHour >= 24) {
        endHour -= 24;
      }

      // Format the end time as a string
      const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
        minute
      ).padStart(2, "0")}`;
      // Prepare the steps array with null values for specific fields
      const updatedSteps = item?.solution_steps?.map((step) => ({
        ...step,
        end_time: null,
        current_time: null,
        current_date: null,
        end_date: null,
        step_status: null,
        status: "active",
        delay: null,
        time_seconds: null,
        savedTime: null,
        decision: null,
        plan_d_actions: null,
        savedTime: null,
        time_taken: null,
        note: null,
        negative_time: 0,
        new_current_time: null,
        new_current_date: null,
        assigned_to_name: null,
      }));
      const postData = {
        ...item,
        steps: updatedSteps,
        _method: "put",
        duplicate: true,
        status: "active",
        end_time: endTimeStr,
        timezone: userTimeZone,
        delay: null,
        plan_d_actions: null,
        step_decisions: null,
        step_notes: null,
        starts_at: null,
        eventId: null,
        moment_privacy_teams: [],
        newly_created_team: null,
        voice_notes: null,
        voice_blob: null,
      };
      console.log("copy meeting payload--->", postData);
      const response = await axios.post(
        `${API_BASE_URL}/solutions/${item?.id}`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data;
        // console.log(response?.data?.data);
        // navigate(`/copyMeeting/${data?.id}`);
        setCheckId(data?.id);
        setIsDuplicate(true);
        await getMeeting(data?.id);
        handleShow();
        setSolutionContext(data);
        // toast.error("Request failed:", response.status, response.statusText);
      } else {
        toast.error("Échec de la duplication de la réunion");
      }
    } catch (error) {
      toast.error(t("Duplication Failed, Check your Internet connection"));
    } finally {
    }
  };
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationCancelModal, setShowConfirmationCancelModal] =
    useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const handleDelete = async (id) => {
    // const permissionGranted = askPermission(
    //   "Êtes-vous sûr de vouloir supprimer cette réunion ?" ||
    //     "Are you sure you want to delete this meeting?"
    // );

    // if (!permissionGranted) return;

    try {
      const response = await axios.delete(`${API_BASE_URL}/solutions/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });

      // Check for successful deletion (assuming HTTP status 200)
      if (response.status === 200) {
        toast.success(t('solutionDeletedSuccessfullToast'));
        // getMeetings();
        navigate("/solution");
      } else {
        // Handle other status codes (e.g., 4xx, 5xx) appropriately
        throw new Error("Échec de la suppression de la réunion");
      }
    } catch (error) {
      // Improve error handling, provide informative messages
      toast.error(t(error.message));
    }
  };
  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    setItemIdToDelete(id);
    setShowConfirmationModal(true);
  };

  const confirmDelete = (e) => {
    e.stopPropagation();
    setShowConfirmationModal(false);
    handleDelete(itemIdToDelete);
  };

  const updateMeetingStatus = async (e) => {
    e.stopPropagation();
    const realEndTime = moment().format("HH:mm:ss");
    try {
      const postData = {
        ...meeting,
        // real_end_time: realEndTime,
        abort_end_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: "abort",
        _method: "put",
        // plan_d_actions: null,
        // step_notes: stepNotes,
        // step_notes: null,
        // step_decisions: null,
        step_notes: null,
        steps: meeting?.steps?.map((step) => ({
          ...step,
          step_status: "abort",
          status: "abort",
        })),
        moment_privacy_teams:
          meeting?.moment_privacy_teams?.map((item) => item?.id) || [],
      };
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}/status`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        console.log("meeting status changed successfully", response.data);
        // toast.success(response.data?.message);
        navigate("/meeting");
        //
      }
    } catch (error) {
      // console.log("error ", error);
    }
  };

  const [open1, setOpen1] = useState(false);
  const handleToggle = () => {
    setView(view === "list" ? "graph" : "list");
    setOpen1(!open1);
  };
  const [newId, setNewId] = useState(null);
  // const [show2, setShow2] = useState(false); // Assuming repetition is mandatory
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const startDate = new Date(`${meeting?.date}T${meeting?.start_time}`);
  console.log("startDate", startDate);
  const totalDays = calculateTotalDays(meeting?.steps);
  console.log("totalDatas", totalDays);

  let endDateForHour;
  if (meeting?.type === "Action") {
    // If the meeting type is "Active", treat totalDays as total days
    endDateForHour = new Date(
      startDate.getTime() + totalDays * 24 * 60 * 60 * 1000
    );
  } else if (meeting?.type === "Task") {
    // If the meeting type is "Task", treat totalDays as total hours
    endDateForHour = new Date(startDate.getTime() + totalDays * 60 * 60 * 1000);
  } else if (meeting?.type === "Quiz") {
    // If the meeting type is "Quiz", treat totalDays as total seconds
    endDateForHour = new Date(startDate.getTime() + totalDays * 1000);
  } else {
    // Otherwise, treat totalDays as total minutes
    endDateForHour = new Date(startDate.getTime() + totalDays * 60 * 1000);
  }

  function calculateTotalTime(steps) {
    if (!steps) {
      return "";
    }
    let totalSeconds = 0;
    let count2InSeconds = 0;
    let timeTakenInSeconds = 0;
    steps?.forEach((step) => {
      const timeTaken = step.time_taken;
      const stepStatus = step.step_status;
      const count2 = step.count2;
      const timeUnit = step.time_unit;

      console.log("time_taken", timeTaken);
      console.log("stepStatus", stepStatus);
      console.log("count2", count2);
      console.log("timeUnit", timeUnit);

      if (stepStatus === "completed") {
        if (timeTaken) {
          totalSeconds += parseTimeTaken(timeTaken);
        }
      } else if (stepStatus === "in_progress") {
        count2InSeconds = convertCount2ToSeconds(count2, timeUnit);
        timeTakenInSeconds = parseTimeTaken(step?.time_taken);

        if (count2InSeconds > timeTakenInSeconds) {
          totalSeconds += convertCount2ToSeconds(count2, timeUnit);
        } else if (timeTaken) {
          totalSeconds += parseTimeTaken(timeTaken);
        }
      } else {
        // totalSeconds += parseTimeTaken(timeTaken);
        totalSeconds += convertCount2ToSeconds(count2, timeUnit);
      }
    });

    const days = Math.floor(totalSeconds / 86400);
    const hrs = Math.floor((totalSeconds % 86400) / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    console.log("days", days);
    // Retrieve localized time units
    const timeUnits = t("time_unit", { returnObjects: true });

    let result = "";
    // if (days > 0) result += `${days} ${timeUnits["days"] || "days"} `;
    // if (hrs > 0) result += `${hrs} ${timeUnits["hours"] || "hours"} `;
    // if (mins > 0) result += `${mins} ${timeUnits["mins"] || "mins"} `;
    // if (secs > 0) result += `${secs} ${timeUnits["secs"] || "secs"}`;

    if (days > 0) result += `${days} ${timeUnits["days"] || "days"} `;
    if (hrs > 0) result += `${hrs} ${timeUnits["hours"] || "hours"} `;
    if (mins > 0) result += `${mins} ${timeUnits["mins"] || "mins"} `;

    // Only show seconds if no days, hours, or minutes are present
    if (days === 0 && hrs === 0 && mins === 0 && secs > 0) {
      result += `${secs} ${timeUnits["secs"] || "secs"}`;
    }

    console.log("result", result);
    return result.trim();
  }

  const totalTime = calculateTotalTime(meeting?.solution_steps);

  //Host
  const [showHostProfile, setShowHostProfile] = useState(false);
  const handleHostShow = () => {
    setShowHostProfile(true);
  };
  const hideHostShow = () => {
    setShowHostProfile(false);
  };
  return (
    <>
      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          className="center-spinner"
        ></Spinner>
      ) : (
        <div className="tabs-container">
          <div
            className="invite w-100"
            style={{
              position: fromMeeting ? "static" : "relative",
              backgroundColor: fromMeeting ? "white" : "",
              // height: fromMeeting && "100%" ,
              padding: "10px 15px",
            }}
          >
            <div className={""}>
              <div
              // className="content-child"
              // style={{ top: fromMeeting && "30px" }}
              >
                <div className="row child-1">
                  <div className={`col-md-6 ${fromMeeting ? "w-100" : ""}`}>
                    <>
                      <div className="invite-header">
                        <div
                          style={{
                            marginTop: 0,
                            width: "80%",
                          }}
                        >
                          <h5 className="content-heading-title w-100">
                            {meeting?.title}
                            <span
                              style={{
                                verticalAlign: "text-bottom",
                              }}
                            >
                              <span
                                className={`badge ms-2 "future`}
                                style={{ padding: "3px 8px 3px 8px" }}
                              >
                                {t("badge.future")}
                              </span>
                            </span>
                          </h5>
                        </div>

                        <div className="play-btn-container">
                          <>
                            {loading ? (
                              <button
                                className={`btn play-btn`}
                                style={{ padding: "13px 72px" }}

                                // style={{ background: "#0d6efd" }}
                              >
                                <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"
                                />
                              </button>
                            ) : (
                              <Button
                                className="btn play-btn"
                                onClick={() => {
                                  handleShowMeeting();
                                }}
                                disabled={loading}
                              >
                                {t("solution.Build Moment")}

                                <FaArrowRight
                                  size={12}
                                  style={{
                                    marginLeft: ".5rem",
                                    fontWeight: 700,
                                  }}
                                />
                              </Button>
                            )}
                          </>
                          <Dropdown className="dropdown">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <BiDotsVerticalRounded
                                color="black"
                                size={"25px"}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={(e) => {
                                  handleEdit(meeting);
                                }}
                              >
                                <RiEditBoxLine size={"20px"} /> &nbsp;
                                {t("dropdown.To modify")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCopy(meeting);
                                }}
                              >
                                <IoCopyOutline size={"18px"} /> &nbsp;
                                {t("dropdown.Duplicate")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                // onClick={(e) => {
                                //   e.stopPropagation();
                                //   handleDelete(meeting.id);
                                // }}
                                onClick={(e) =>
                                  handleDeleteClick(e, meeting?.id)
                                }
                              >
                                <AiOutlineDelete size={"20px"} color="red" />
                                &nbsp; {t("dropdown.Delete")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      {/* <div>
                        <div className="d-flex align-items-center gap-2 content-body">
                          <div className="d-flex align-items-center gap-2">
                            <img src="/Assets/invite-date.svg" />

                            {meeting?.type === "Action1" ||
                            meeting?.type === "Newsletter" ? (
                              <>
                                {fromMeeting ? (
                                  <>
                                    <span className="fw-bold formate-date">
                                      {formattedDate}
                                    </span>{" "}
                                    -
                                    <span className="fw-bold formate-date">
                                      {estimateDate}
                                    </span>
                                  </>
                                ) : (
                                  <span className="fw-bold formate-date">
                                    {estimateDate}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {fromMeeting ? (
                                  <>
                                    <span className="fw-bold formate-date">
                                      {formattedDate}
                                      &nbsp; {t("at")}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {meeting?.status === "in_progress"
                                        ? convertTo12HourFormat(
                                            meeting?.starts_at,
                                            meeting?.steps
                                          )
                                        : convertTo12HourFormat(
                                            meeting?.start_time,
                                            meeting?.steps
                                          )}{" "}
                                      -{" "}
                                    </span>

                                    <span className="fw-bold formate-date">
                                      {estimateDate}
                                      &nbsp; {t("at")}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {estimateTime}
                                    </span>
                                  </>
                                ) : (
                                  <span className="fw-bold formate-date">
                                    {formattedEndDateInHours}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div> */}

                      <div className="paragraph-parent mt-2 w-100">
                        <span className="paragraph paragraph-images">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: meeting?.description || "",
                            }}
                          />
                        </span>
                      </div>
                      <div className="items">
                        <div className="type">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.33333 2.99984C8.33333 3.3665 8.20289 3.6805 7.942 3.94184C7.68111 4.20317 7.36711 4.33362 7 4.33317C6.85556 4.33317 6.72222 4.31362 6.6 4.2745C6.47778 4.23539 6.35556 4.17162 6.23333 4.08317C5.96667 4.17206 5.75289 4.33317 5.592 4.5665C5.43111 4.79984 5.35044 5.05539 5.35 5.33317H14L13.3333 9.99984H10.0667V8.6665H12.1833C12.2389 8.33317 12.2862 7.99984 12.3253 7.6665C12.3644 7.33317 12.4116 6.99984 12.4667 6.6665H3.53333C3.58889 6.99984 3.63622 7.33317 3.67533 7.6665C3.71444 7.99984 3.76156 8.33317 3.81667 8.6665H5.93333V9.99984H2.66667L2 5.33317H4C4 4.78873 4.15 4.29428 4.45 3.84984C4.75 3.40539 5.15556 3.07762 5.66667 2.8665C5.7 2.52206 5.84444 2.23606 6.1 2.0085C6.35556 1.78095 6.65556 1.66695 7 1.6665C7.36667 1.6665 7.68067 1.79717 7.942 2.0585C8.20333 2.31984 8.33378 2.63362 8.33333 2.99984ZM6.51667 12.6665H9.48333L9.86667 8.6665H6.13333L6.51667 12.6665ZM5.33333 13.9998L4.83333 8.79984C4.78889 8.41095 4.9 8.06939 5.16667 7.77517C5.43333 7.48095 5.76111 7.33362 6.15 7.33317H9.85C10.2389 7.33317 10.5667 7.4805 10.8333 7.77517C11.1 8.06984 11.2111 8.41139 11.1667 8.79984L10.6667 13.9998H5.33333Z"
                              fill="#8590A3"
                            />
                          </svg>
                          <span className="time">{meeting?.type}</span>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
                <div className="cards-section child-2">
                  <div style={{ marginTop: "4rem" }}>
                    <h4
                      className={
                        fromMeeting
                          ? "participant-heading-meeting"
                          : "participant-heading"
                      }
                    >
                      {showHostProfile && (
                        <IoArrowBackSharp
                          onClick={hideHostShow}
                          size={25}
                          style={{
                            cursor: "pointer",
                            marginRight: "1rem",
                          }}
                        />
                      )}
                      Architek
                    </h4>
                    <div
                      className="host"
                      style={{ background: showHostProfile && "white" }}
                    >
                      <SolutionHostCard
                        data={meeting?.solution_creator}
                        fromMeeting={fromMeeting}
                        handleShow={handleHostShow}
                        handleHide={hideHostShow}
                        showProfile={showHostProfile}
                        meeting={meeting}
                      />
                    </div>
                  </div>

                  {/* ------------------------------------------------ Steps */}

                  <div style={{ marginTop: "5rem", marginBottom: "3rem" }}>
                    <h4
                      className={`${
                        fromMeeting
                          ? "participant-heading-meeting"
                          : "participant-heading"
                      } d-flex align-items-center justify-content-between`}
                    >
                      {`${t("step")} `}
                      <span style={{ cursor: "pointer" }}>
                        <div className="toggle-button">
                          <button
                            className={`toggle-button-option ${
                              view === "list" ? "active" : ""
                            }`}
                            onClick={() => handleToggle("list")}
                          >
                            <div className="icon-list" />
                            <FaList size={18} />
                          </button>
                          <button
                            className={`toggle-button-option ${
                              view === "graph" ? "active" : ""
                            }`}
                            onClick={() => handleToggle("graph")}
                          >
                            <div className="icon-graph" />
                            <FaChartGantt size={20} />
                          </button>
                        </div>
                      </span>
                    </h4>
                    {view === "graph" ? (
                      <SolutionStepGraph
                        data={meeting}
                        meetingId={meeting?.id}
                        steps={solutionSteps}
                      />
                    ) : (
                      <SolutionStepCard
                        data={solutionSteps}
                        startTime={formattedTime}
                        users={{
                          ...meeting?.user,
                          firstName: meeting?.user?.name,
                          lastName: meeting?.user?.last_name,
                          image:
                            // meeting?.user?.assigned_to_image ||
                            meeting?.user?.image || "/Assets/avatar.jpeg",
                        }}
                        fromMeeting={fromMeeting}
                        meeting1={meeting}
                      />
                    )}
                    <div
                      // style={{
                      //   display: "flex",
                      //   justifyContent: "start",
                      //   alignItems: "center",
                      //   gap: "5px",
                      // }}
                      className="invite-buttons"
                    >
                      {/* {meeting?.status === "in_progress" && ( */}
                      <button
                        onClick={() => {
                          // stepModal(meeting);
                          setIsDrop(true);

                          setIsUpdated(false);
                          setShowModal(true);
                          // setOpen1(false);
                        }}
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "24px",
                          textAlign: "left",
                          color: " #FFFFFF",
                          background: "#2C48AE",
                          border: 0,
                          outine: 0,
                          padding: "10px 16px",
                          borderRadius: "9px",
                          // marginTop: "1.5rem",
                        }}
                      >
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 0.457031C15.5228 0.457031 20 4.93418 20 10.457C20 15.9798 15.5228 20.457 10 20.457C4.47715 20.457 0 15.9798 0 10.457C0 4.93418 4.47715 0.457031 10 0.457031ZM10 1.95703C5.30558 1.95703 1.5 5.76261 1.5 10.457C1.5 15.1514 5.30558 18.957 10 18.957C14.6944 18.957 18.5 15.1514 18.5 10.457C18.5 5.76261 14.6944 1.95703 10 1.95703ZM10 5.45703C10.4142 5.45703 10.75 5.79282 10.75 6.20703V9.70703H14.25C14.6642 9.70703 15 10.0428 15 10.457C15 10.8712 14.6642 11.207 14.25 11.207H10.75V14.707C10.75 15.1212 10.4142 15.457 10 15.457C9.5858 15.457 9.25 15.1212 9.25 14.707V11.207H5.75C5.33579 11.207 5 10.8712 5 10.457C5 10.0428 5.33579 9.70703 5.75 9.70703H9.25V6.20703C9.25 5.79282 9.5858 5.45703 10 5.45703Z"
                            fill="white"
                          />
                        </svg>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {t("addTask")}
                      </button>

                      <h5 className="estimated-font">
                        {meeting?.solution_steps &&
                          `${t("estimateFieldActive")}: ${totalTime}`}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {open && (
            <NewSolutionModal open={open} closeModal={handleCloseModal} />
          )}
          {openModal && (
            <NewMeetingModal open={openModal} closeModal={handleCloseModal} />
          )}
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          message={t("solutionDeletedToast")}
          onConfirm={(e) => confirmDelete(e)}
          onCancel={(e) => {
            e.stopPropagation();
            setShowConfirmationModal(false);
          }}
        />
      )}
      {showConfirmationCancelModal && (
        <ConfirmationModal
          message={t("confirmation")}
          onConfirm={(e) => updateMeetingStatus(e)}
          onCancel={(e) => {
            e.stopPropagation();
            setShowConfirmationCancelModal(false);
          }}
        />
      )}
      {showModal && (
        <div className="new-meeting-modal tabs-container">
          <StepChart
            meetingId={meeting?.id}
            id={newId}
            show={showModal}
            setId={setNewId}
            closeModal={handleCloseModal}
            isDrop={isDrop}
            setIsDrop={setIsDrop}
            meeting1={meeting}
          />
        </div>
      )}
    </>
  );
};

export default Solution;
