import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./style/LandindPages.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Base from "./Components/Layout/Base";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Signup from "./Components/Elements/Signup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/Elements/Login";
import PrivateRoute from "./Components/Elements/PrivateRoute";
import Profile from "./Components/Elements/Profile/Profile";
import Contract from "./Components/Elements/Contract/Contract";
import Enterprises from "./Components/Elements/Enterprises/Enterprises";
import NewEnterprises from "./Components/Elements/Enterprises/NewEnterprises";
import Team from "./Components/Elements/Team/Team";
import Invities from "./Components/Elements/Invities/Invities";
// import ObjectiveTabs from "./Components/Elements/Objectives/ObjectiveTabs.jsx";
import UpdateContract from "./Components/Elements/Contract/UpdateContract";
import UpdateEntreprises from "./Components/Elements/Enterprises/UpdateEntreprises";
import UpdateTeam from "./Components/Elements/Team/UpdateTeam";
import Users from "./Components/Elements/User/Users";
import ContractLinkEntreprises from "./Components/Elements/LinkPages/ContractLinkEntreprises";
import ContractToTeam from "./Components/Elements/LinkPages/ContractToTeam";
import EntreprisesToTeam from "./Components/Elements/LinkPages/EntreprisesToTeam";
import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import CopyContract from "./Components/Elements/Contract/CopyContract";
import CopyClosedContract from "./Components/Elements/Contract/CopyClosedContract";
import ReadContract from "./Components/Elements/Contract/ReadContract";
import UpdateUser from "./Components/Elements/User/UpdateUser";
import ContractToUser from "./Components/Elements/LinkPages/ContractToUser";
import EnterprisesToUser from "./Components/Elements/LinkPages/EnterprisesToUser";
import MeetingTabs from "./Components/Elements/Meeting/MeetingTabs";
import Play from "./Components/Elements/Meeting/Play";
import ActionPlay from "./Components/Elements/Actions/ActionPlay";
import Invite from "./Components/Elements/Meeting/Invite";
import ViewMeeting from "./Components/Elements/Meeting/ViewMeeting";
import PlayMeeting from "./Components/Elements/Meeting/PlayMeeting/PlayMeeting";
import { CounterContextProvider } from "./Components/Elements/Meeting/context/CounterContext";
import ViewEndMeeting from "./Components/Elements/Meeting/ViewEndMeeting";
import ParticipantToAction from "./Components/Elements/Invities/ParticipantToAction/ParticipantToAction";
import UpdateParticipant from "./Components/Elements/Invities/ParticipantToAction/UpdateParticipant";
import StepDetails from "./Components/Elements/Meeting/StepDetails";
import UpdatePassword from "./Components/Elements/User/UpdatePassword";
import axios from "axios";
import { API_BASE_URL } from "./Components/Apicongfig";
import Report from "./Components/Elements/Meeting/Report";
import CustomerSupport from "./Components/Elements/CustomerSupport";
import CompletedInvite from "./Components/Elements/Meeting/CompletedMeeting/CompletedInvite";
import DestinationToMeetings from "./Components/Elements/Invities/DestinationToMeeting/DestinationToMeetings";
import BasePage from "./Components/Layout/BasePage";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Privacypolicy from "./Pages/Privacypolicy";
import Termsandconditions from "./Pages/Termsandconditions";
import Contactus from "./Pages/Contactus";
import Planandpricing from "./Pages/Planandpricing";
import AOS from "aos";
import "aos/dist/aos.css";
import ProfileInvitePage from "./Components/Elements/Profile/ProfileInvitePage";
import UseCase1 from "./Pages/UseCase1";
import UseCase2 from "./Pages/UseCase2.jsx";
import UseCase3 from "./Pages/UseCase3.jsx";
import UseCase4 from "./Pages/UseCase4.jsx";
import NewsletterTerms from "./Pages/NewsletterTerms.jsx";
import ActionTabs from "./Components/Elements/Actions/ActionTabs.jsx";
import SolutionTabs from "./Components/Elements/Solution/SolutionTabs.jsx";
import Solution from "./Components/Elements/Solution/GetSolution/Solution.jsx";

i18next.init({
  interpolation: { escapevalue: false },
  lng: "fr",
  resources: {
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr, // Corrected key name
    },
  },
});

function App() {
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(() => {
    return localStorage.getItem("isSignedIn") === "true";
  });

  const [removeLogo, setRemoveLogo] = useState(false);

  const signin = () => {
    setIsSignedIn(true);
    setRemoveLogo(true);
  };

  const signout = async () => {
    const logoutTime = new Date().toLocaleString(); // Get logout time in user's timezone
    localStorage.setItem("logoutTime", logoutTime);
    setIsSignedIn(false);
    setRemoveLogo(false);
    localStorage.removeItem("isSignedIn");
    navigate("/");
    await updateUser(); // Here update the database with logout time if possible
    sessionStorage.clear();
  };

  // Periodic syncing of activity time in useEffect
  // useEffect(() => {
  //   const syncActivityTime = async () => {
  //       await updateUser(); // Updates last activity time in the database every 5 minutes
  //   };

  //   const interval = setInterval(syncActivityTime, 5 * 60 * 1000); // Every 5 minutes
  //   return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, []);

  // // Handle final timestamp update on unload
  // useEffect(() => {
  //   const handleUnload = () => {
  //       const logoutTime = new Date().toLocaleString();
  //       localStorage.setItem("logoutTime", logoutTime);
  //       updateUser(true); // Use beacon on unload
  //   };

  //   window.addEventListener("beforeunload", handleUnload);
  //   return () => window.removeEventListener("beforeunload", handleUnload);
  // }, []);

  // Update function with optional beacon support
  const updateUser = async (useBeacon = false) => {
    const id = parseInt(sessionStorage.getItem("user_id"));
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const logoutTime = new Date();
    const formattedLogoutTime = logoutTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    const payload = {
      user_id: id,
      // last_connection: formattedLogoutTime,
    };

    if (useBeacon) {
      // Use sendBeacon as a fallback for final timestamp update on unload
      const url = `${API_BASE_URL}/logout`;
      navigator.sendBeacon(url, JSON.stringify(payload));
    } else {
      // Regular axios POST for periodic updates or logout
      try {
        const response = await axios.post(`${API_BASE_URL}/logout`, payload, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status === 200) {
          console.log("Activity time updated:", response?.data);
        } else {
          console.error("Failed to update activity time:", response?.data?.message);
        }
      } catch (error) {
        console.error("Error updating User:", error);
      }
    }
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     const logoutTime = new Date().toISOString();
  //     localStorage.setItem("logoutTime", logoutTime);
  //     // Optionally send the logoutTime to the backend via an API call
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     // const logoutTime = new Date().toISOString();
  //     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     const logoutTime = new Date();
  //     const formattedLogoutTime = logoutTime.toLocaleString("en-GB", {
  //       timeZone: userTimeZone,
  //       day: "2-digit",
  //       month: "2-digit",
  //       year: "numeric",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //       hour12: false, 
  //     });
  //     localStorage.setItem("logoutTime", formattedLogoutTime);
  //   };

  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "hidden") {
  //       handleBeforeUnload();
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    localStorage.setItem("isSignedIn", isSignedIn.toString());
  }, [isSignedIn]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  function refreshAccessToken() {
    const refreshToken = localStorage.getItem("refresh_token");

    if (!refreshToken) {
      console.error("No refresh token available");
      return;
    }
    const userid = localStorage.getItem("user_id");

    axios
      .post(
        `${API_BASE_URL}/auth/refresh`,
        {
          // refresh_token: refreshToken,
          user_id: userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Refresh API Response:", response.data);
        if (response) {
          const newAccessToken = response.data.access_token;
          const newExpiresIn = response.data.expires_in; // e.g., 3598 seconds

          // Update the stored tokens and expiration time
          sessionStorage.setItem("access_token", newAccessToken);
          localStorage.setItem("access_token", newAccessToken);

          const newExpirationTime = Date.now() + newExpiresIn * 1000;
          sessionStorage.setItem("token_expiration_time", newExpirationTime);
          localStorage.setItem("token_expiration_time", newExpirationTime);

          // Set a new timeout to refresh the access token again
          setTimeout(refreshAccessToken, (newExpiresIn - 60) * 1000); // Refresh 1 minute before expiration
        }
      })
      .catch((error) => {
        console.error("Refresh API Error:", error);
        // Optionally handle token refresh errors (e.g., log out user)
        // If the refresh token is invalid (e.g., 401 Unauthorized), handle re-authentication
        // if (error.response && error.response.status === 401) {
        console.error(
          "Refresh token is invalid or expired. Redirecting to login."
        );
        handleInvalidToken();
        // }
      });
  }

  function handleInvalidToken() {
    sessionStorage.removeItem("access_token");
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token");
    sessionStorage.removeItem("token_expiration_time");
    localStorage.removeItem("token_expiration_time");
  }

  // Function to check token expiration and refresh
  function checkTokenExpiration() {
    const tokenExpirationTime =
      sessionStorage.getItem("token_expiration_time") ||
      localStorage.getItem("token_expiration_time");

    console.log("Checking token expiration", tokenExpirationTime);
    if (!tokenExpirationTime) return;

    const currentTime = Date.now();
    if (currentTime > tokenExpirationTime) {
      console.log("Access token expired. Refreshing...");
      sessionStorage.removeItem("access_token");
      localStorage.removeItem("access_token");
      sessionStorage.removeItem("token_expiration_time");
      localStorage.removeItem("token_expiration_time");
      refreshAccessToken();
    } else {
      console.log("not expired now!");
    }
  }

  // Set an interval to check token expiration every minute
  setInterval(checkTokenExpiration, 60 * 1000);

  return (
    <div>
      {/* <I18nextProvider i18n={i18next}> */}
      <ToastContainer
        style={{
          position: "abosolute",
          zIndex: "101",
        }}
      />
      <Routes>
        <Route path="/login" element={<Login onLogin={signin} />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          element={
            <Base
              isAuthenticated={isSignedIn}
              onLogout={signout}
              onLogin={signin}
              onRemove={removeLogo}
            />
          }
        >
          {sessionStorage.getItem("type") === "MasterAdmin" &&
            sessionStorage.getItem("type") != "SuperAdmin" &&
            sessionStorage.getItem("type") != "Admin" && (
              <Route
                path="/contract"
                element={
                  <PrivateRoute isSignedIn={isSignedIn}>
                    <Contract onLogout={signout} />
                  </PrivateRoute>
                }
              />
            )}
          <Route
            path="/CopyContract/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <CopyContract onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/CopyClosedContract/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <CopyClosedContract onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ModifierContract/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <UpdateContract onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ContractLinkEnterprises/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ContractLinkEntreprises onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/readContract/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ReadContract onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ContractToTeam/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ContractToTeam onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ContractToUser/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ContractToUser onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/EntreprisesToTeam/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <EntreprisesToTeam onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/EntreprisesToUsers/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <EnterprisesToUser onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/Enterprises"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Enterprises onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/NewEnterprises"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <NewEnterprises onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ModifierEnterprises/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <UpdateEntreprises onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/Team"
            exact
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Team onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ModifierTeam/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <UpdateTeam onLogout={signout} />
              </PrivateRoute>
            }
          />

          <Route
            path="/Users/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Users onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/ModifierUser/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <UpdateUser onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/Invities"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Invities onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/invitiesToMeeting/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <DestinationToMeetings onLogout={signout} />
              </PrivateRoute>
            }
          />
          {/* <Route
              path="/objectives"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ObjectiveTabs onLogout={signout} />
                </PrivateRoute>
              }
            /> */}
          <Route
            path="/participantToAction/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ParticipantToAction onLogout={signout} />
              </PrivateRoute>
            }
          />

          <Route
            path="/updateParticipant/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <UpdateParticipant onLogout={signout} />
              </PrivateRoute>
            }
          />

          <Route
            path="/meeting"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <MeetingTabs onLogout={signout} />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Profile onLogout={signout} />
              </PrivateRoute>
            }
          />
          {/* UpdatePassword Route */}
          <Route
            path="/updatepassword"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <UpdatePassword onLogout={signout} />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/meetingcopy/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <MeetingCopy onLogout={signout} />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="/meeting/new"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <NewMeeting onLogout={signout} />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/meetingDetail/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ViewEndMeeting onLogout={signout} />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/presentation/:id"
            element={<Presentation onLogout={signout} />}
          /> */}
          <Route
            path="/participant/:id"
            element={<UpdateParticipant onLogout={signout} />}
          />

          {/* <Route
            path="/presentation/:meetingId"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Presentation onLogout={signout} />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="/preview/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Preview onLogout={signout} />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="/validateMeeting/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                {<ValidateMeeting onLogout={signout} />}
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            path="/updateMeeting/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <UpdateMeeting onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/copyMeeting/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <MeetingCopy onLogout={signout} />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/play/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <CounterContextProvider>
                  <Play onLogout={signout} />
                </CounterContextProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/action/play/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <CounterContextProvider>
                  <ActionPlay onLogout={signout} />
                </CounterContextProvider>
              </PrivateRoute>
            }
          />

          <Route
            path="/PlayMeeting/:id"
            element={
              <CounterContextProvider>
                <PlayMeeting />
              </CounterContextProvider>
            }
          />
          <Route
            path="/view/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ViewMeeting onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/heroes/:nick_name"
            element={<ProfileInvitePage onLogout={signout} />}
          />
          <Route path="/invite/:id" element={<Invite onLogout={signout} />} />
          <Route
            path="/destination/:unqiue_id/:meeting_id"
            element={<Report />}
          // element={<Presentationreport />}
          />
          <Route
            path="/newsletter/terms-and-conditions"
            element={<NewsletterTerms />}
          // element={<Presentationreport />}
          />
          {/* <Route
            path="/draft/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Draft onLogout={signout} />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/present/invite/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <CompletedInvite onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer-support"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <CustomerSupport onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/action"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <ActionTabs onLogout={signout} />
              </PrivateRoute>
            }
          />

          <Route
            path="/solution"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <SolutionTabs onLogout={signout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/solution/:id"
            element={
              <PrivateRoute isSignedIn={isSignedIn}>
                <Solution onLogout={signout} />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route path="/step-details/:id" element={<StepDetails />} />
        {/* <Route path="/invite/:id" element={<Invite />} /> */}
        {/* <Route path="/invite" element={<Invite />} /> */}
      </Routes>
      <BasePage>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/terms&conditions" element={<Termsandconditions />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/pricing" element={<Planandpricing />} />
          <Route path="/useCase/1" element={<UseCase1 />} />
          <Route path="/useCase/2" element={<UseCase2 />} />
          <Route path="/useCase/3" element={<UseCase3 />} />
          <Route path="/useCase/4" element={<UseCase4 />} />
        </Routes>
      </BasePage>
      {/* </I18nextProvider> */}
    </div>
  );
}

export default App;
