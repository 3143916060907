import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Use your preferred icon library

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useFormContext } from "../../../../../context/CreateMeetingContext";
import { toast } from "react-toastify";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaUserGroup } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import { API_BASE_URL, Assets_URL } from "../../../../Apicongfig";
import axios from "axios";
import { getUserRoleID } from "../../../../Utils/getSessionstorageItems";
import { useMeetings } from "../../../../../context/MeetingsContext";

function PrivacyOptions({ setActiveTab, closeModal }) {
  const {
    formState,
    setFormState,
    handleInputBlur,
    // loading,
    isCompleted,
    validate,
    validateAndUpdate,
    meeting,
    isDuplicate,
    isUpdated,
    getMeeting,
    checkId,
    handleCloseModal,
    recurrentMeetingAPI
  } = useFormContext();
  const { getMeetings } = useMeetings();

  console.log("formState", formState);
  const [t] = useTranslation("global");
  const [visibility, setVisibility] = useState("private");
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingQuit, setLoadingQuit] = useState(false); 

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setVisibility(value);
    setFormState((prevState) => ({
      ...prevState,
      moment_privacy: value,
      moment_privacy_teams: selectedTeams?.map((team) => team.value), // Only send IDs in formState
    }));
  };

  useEffect(() => {
    getMeeting(checkId);
  }, [checkId]);
  useEffect(() => {
    if (meeting) {
      setFormState((prevState) => ({
        ...prevState,
        moment_privacy: meeting.moment_privacy || "",
        moment_password: meeting.moment_password || "",
        moment_privacy_teams:
          meeting?.moment_privacy_teams?.map((item) => item.id) || [], // Only send IDs
      }));
      setSelectedTeams(
        meeting?.moment_privacy_teams?.map((team) => ({
          value: team.id,
          label: team.name,
        }))
      );
      setVisibility(meeting.moment_privacy || "private");
    }
  }, [meeting, setFormState]);

  const [user, setUser] = useState(null);
  const [teams, setTeams] = useState([]);
  const [availableTeams, setAvailableTeams] = useState([]);
  const userID = sessionStorage.getItem("user_id");

  const getUserDataFromAPI = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${userID}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const user = response?.data?.data;
      if (user) {
        setUser(user);
      }
    } catch (error) {
      toast.error(t(error?.response?.data?.errors[0] || error?.message));
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    const getTeams = async () => {
      const token = sessionStorage.getItem("token");
      try {
        const response = await axios.get(`${API_BASE_URL}/teams`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const filterredActiveTeams = response?.data?.data?.filter(
            (team) => team.status === "active"
          );

          if (getUserRoleID() === 3) {
            // setAllTeams(response?.data?.data);
            const teams = response.data.data.filter(
              (team) => team?.enterprise?.id === response?.data?.enterprise?.id
            );
            setTeams(teams);
          } else {
            // setAllTeams(response?.data?.data);
            const teams = response.data.data.filter(
              (team) => team?.enterprise?.id === response?.data?.enterprise?.id
            );
            setTeams(teams);
          }
        }
      } catch (error) {
        toast.error(t(error.response?.data?.errors[0] || error?.message));
        // console.log("error message", error);
      } finally {
        // setLoading(false);
      }
    };
    getUserDataFromAPI();
    getTeams();
  }, [userID]);
  useEffect(() => {
    if (user || teams || meeting) {
      setSelectedTeams(
        meeting?.moment_privacy_teams?.map((team) => ({
          value: team.id,
          label: team.name,
        }))
      );

      const userTeams = teams || [];
      setAvailableTeams(userTeams);
    }
  }, [user, teams, meeting]);
  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      moment_privacy_teams: selectedTeams?.map((item) => item.value),
    }));
  }, [selectedTeams, setFormState]);
  const handleTeamSelect = (teamId) => {
    const id = parseInt(teamId);
    const selectedTeam = availableTeams?.find((team) => team.id === id);

    // Add the team object to selectedTeams if it's not already selected
    if (selectedTeam && !selectedTeams?.some((team) => team.value === id)) {
      setSelectedTeams([
        ...selectedTeams,
        { value: id, label: selectedTeam.name },
      ]);
    }
  };

  const removeTeam = (teamId) => {
    // Remove the team from selectedTeams based on the `value` field
    setSelectedTeams(selectedTeams.filter((team) => team.value !== teamId));
  };

  const teamOptions = teams?.map((team) => ({
    value: team.id,
    label: team.name,
  }));
  const handleSaveAndContinue = async () => {
    if (visibility === "password" && !formState.moment_password) {
      toast.error(t("Please enter a password")); // Show toast message
      return; // Prevent further execution
    }

    if (visibility === "team" && selectedTeams?.length === 0) {
      toast.error(t("Please select a team")); // Show toast message
      return; // Prevent further execution
    }

    try {
      if (isDuplicate || isUpdated) {
        await validateAndUpdate();
      } else {
        await validate();
      }
      // closeModal();
    } catch (error) {
      console.error("Error in saving and continuing:", error);
      toast.error("error while validating moment", error);
    }
  };

  const handleSaveAndQuit = async () => {
    // if (validateForm()) {
      setLoadingQuit(true); // Show loader
    await new Promise((resolve) => setTimeout(resolve, 0));

      try {
        const options = { updatedButton: true, quitAndUpdate: true };
        const newformState = { ...meeting };
        await handleInputBlur(newformState, options); 
        // setActiveTab("tab2");
        recurrentMeetingAPI()
        handleCloseModal();
      } catch (error) {
        // Handle error (if any)
        toast.error("Error occurred");
      } finally {
        setLoadingQuit(false); // Hide loader
        await getMeetings();
      }
    // }
  };

  return (
    <div className="col-md-12 p-1 p-4 modal-height">
      <Row className="pt-0">
        <Col xs={12}>
          <p className="text-dark fs-6 fw-medium font-family-IBM Plex Sans mb-3 text-start">
            {t("profile.chooseVisibility")}
          </p>
        </Col>
        <Col xs={12}>
          <Form>
            <Row>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={t("profile.public")}
                    value="public"
                    checked={visibility === "public"}
                    onChange={handleOptionChange}
                    className="privacy-moment"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {t("profile.publicSubText")}
                  </Form.Text>
                </Form.Group>

                {/* <Form.Group className="mt-3">
                  <Form.Check
                    type="radio"
                    label={t("profile.enterprise")}
                    value="enterprise"
                    checked={visibility === "enterprise"}
                    onChange={handleOptionChange}
                    className="privacy-moment"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {t("profile.enterpriseSubText")}
                  </Form.Text>
                </Form.Group> */}

                <Form.Group className="mt-3">
                  <Form.Check
                    type="radio"
                    label={t("profile.team")}
                    value="team"
                    checked={visibility === "team"}
                    onChange={handleOptionChange}
                    className="privacy-moment"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {t("profile.TeamSubText")}
                  </Form.Text>
                </Form.Group>

                {visibility === "team" && (
                  <div className="mt-3 teams">
                    <DropdownButton
                      id="team-dropdown"
                      title={
                        <span className="d-flex align-items-center justify-content-between m-0">
                          <div>
                            <FaUserGroup className="me-2" />{" "}
                            {t("profile.teams")}
                          </div>
                          <span>
                            <GoPlus />
                          </span>
                        </span>
                      }
                      variant="outline-primary"
                      className="mb-3 select_team"
                      onSelect={handleTeamSelect} // Use `onSelect` here
                    >
                      {availableTeams?.map((team, index) => {
                        console.log("available teams:", availableTeams);
                        console.log("selected teams:", selectedTeams);

                        // Check if the current team is selected by matching the `id` with `value`
                        const isSelected = selectedTeams?.some(
                          (selectedTeam) => selectedTeam.value === team.id
                        );

                        return (
                          <Dropdown.Item
                            key={index}
                            eventKey={team?.id?.toString()} // Convert the ID to a string for Dropdown
                            disabled={isSelected} // Disable if already selected
                          >
                            {team?.name}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>

                    <div className="all_teams">
                      <h6>
                        {selectedTeams?.length > 0 && selectedTeams?.length}{" "}
                        {t("profile.teamsAdded")}
                      </h6>
                      {selectedTeams?.length === 0 && (
                        <h5>{t("profile.noTeamsAdded")}</h5>
                      )}

                      {selectedTeams?.map((selectedTeam, index) => {
                        console.log("selectedTeams->", selectedTeams);
                        console.log("selectedTeam->", selectedTeam);
                        console.log("availableTeams->", availableTeams);

                        // Find the team details by ID
                        const team = availableTeams.find(
                          (team) => team.id === selectedTeam.value
                        );

                        console.log("team->", team);
                        // Render the team details
                        return team ? (
                          <React.Fragment key={index}>
                            <Row className="align-items-start mt-4">
                              <Col
                                xs={8}
                                className="d-flex justify-content-start"
                              >
                                <p className="mb-0 team-name">
                                  {index + 1}. &nbsp;
                                  <img
                                    src={
                                      team.logo
                                        ? Assets_URL + "/" + team.logo
                                        : "/Assets/tektime.png"
                                    } // Display the team's logo or fallback to a default image
                                    width="24px"
                                    height="30px"
                                    alt=""
                                    style={{ objectFit: "contain" }}
                                    className="img-fluid"
                                  />
                                  &nbsp; {team.name}
                                </p>
                              </Col>
                              <Col xs={4} className="text-end">
                                <Button
                                  size="sm"
                                  onClick={() => removeTeam(team.id)}
                                  style={{
                                    background: "#BB372F1A",
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                  }}
                                >
                                  <RiDeleteBin5Line
                                    size={22}
                                    color={"#BB372F"}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            <hr></hr>
                          </React.Fragment>
                        ) : null;
                      })}
                    </div>
                  </div>
                )}
                <Form.Group className="mt-3">
                  <Form.Check
                    type="radio"
                    label={t("profile.password")}
                    value="password"
                    checked={visibility === "password"}
                    onChange={handleOptionChange}
                    className="privacy-moment"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {t("profile.passwordSubText")}
                  </Form.Text>
                </Form.Group>
                {visibility === "password" && (
                  <Form.Group className="mt-3 position-relative">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder={t("profile.enterpassword")}
                      value={formState.moment_password}
                      onChange={(e) =>
                        setFormState((prevState) => ({
                          ...prevState,
                          moment_password: e.target.value,
                        }))
                      }
                    />
                    <span
                      className="position-absolute top-50 translate-middle-y"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        right: "18px",
                        cursor: "pointer",
                        padding: "0",
                        margin: "0",
                      }} // Adjusted right for proper horizontal placement
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible size={24} />
                      ) : (
                        <AiOutlineEye size={24} />
                      )}
                    </span>
                  </Form.Group>
                )}
                {/* <Form.Group className="mt-3">
                  <Form.Control
                    type="text"
                    placeholder={t("profile.passwordPlaceholder")}
                  />
                </Form.Group> */}
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group className="mt-3">
                  <Form.Check
                    type="radio"
                    label={t("profile.enterprise")}
                    value="enterprise"
                    checked={visibility === "enterprise"}
                    onChange={handleOptionChange}
                    className="privacy-moment"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {t("profile.enterpriseSubText")}
                  </Form.Text>
                </Form.Group>

                {/* <Form.Group className="mt-3">
                  <Form.Check
                    type="radio"
                    label={t("profile.custom")}
                    value="custom"
                    checked={visibility === "custom"}
                    onChange={handleOptionChange}
                    className="privacy-moment"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {t("profile.customSubText")}
                  </Form.Text>
                </Form.Group> */}
                <Form.Group className="mt-3">
                  <Form.Check
                    type="radio"
                    label={t("profile.private")}
                    value="private"
                    checked={visibility === "private"}
                    onChange={handleOptionChange}
                    className="privacy-moment"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {t("profile.privateSubText")}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <div className={`modal-footer d-flex justify-content-end modal-save-button gap-4`}>
      {isUpdated && (
            <Button
            variant="danger"
              // className="btn "
              onClick={handleSaveAndQuit}
              disabled={loadingQuit}
              style={{ padding: "9px" }}
            >
              {loadingQuit ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "white",
                      margin: "5px 82px",
                    }}
                  />
                </>
              ) : (
                <>
                  &nbsp;{t("meeting.formState.Save and Quit")}
                  {/* <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4707 8.52991C13.397 8.46125 13.3379 8.37845 13.2969 8.28645C13.2559 8.19445 13.2338 8.09513 13.2321 7.99443C13.2303 7.89373 13.2488 7.7937 13.2865 7.70031C13.3243 7.60692 13.3804 7.52209 13.4516 7.45087C13.5228 7.37965 13.6077 7.32351 13.7011 7.28579C13.7945 7.24807 13.8945 7.22954 13.9952 7.23132C14.0959 7.23309 14.1952 7.25514 14.2872 7.29613C14.3792 7.33712 14.462 7.39622 14.5307 7.46991L18.5307 11.4699C18.6711 11.6105 18.75 11.8012 18.75 11.9999C18.75 12.1987 18.6711 12.3893 18.5307 12.5299L14.5307 16.5299C14.462 16.6036 14.3792 16.6627 14.2872 16.7037C14.1952 16.7447 14.0959 16.7667 13.9952 16.7685C13.8945 16.7703 13.7945 16.7518 13.7011 16.714C13.6077 16.6763 13.5228 16.6202 13.4516 16.549C13.3804 16.4778 13.3243 16.393 13.2865 16.2996C13.2488 16.2062 13.2303 16.1062 13.2321 16.0055C13.2338 15.9048 13.2559 15.8055 13.2969 15.7135C13.3379 15.6215 13.397 15.5387 13.4707 15.4699L16.1907 12.7499H6C5.80109 12.7499 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 11.9999C5.25 11.801 5.32902 11.6103 5.46967 11.4696C5.61032 11.329 5.80109 11.2499 6 11.2499H16.1907L13.4707 8.52991Z"
                      fill="white"
                    />
                  </svg>
                </span> */}
                </>
              )}
            </Button>
          )}
        {isCompleted ? (
          <>
            {" "}
            <Button
              variant="dark"
              disabled
              style={{
                backgroundColor: "#3aa5ed",
                border: "none",
              }}
              className="moment-btn"
            >
              <Spinner
                as="div"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
                style={{
                  margin: "2px 12px",
                }}
              />
            </Button>
          </>
        ) : (
          <button className={`btn moment-btn`} onClick={handleSaveAndContinue}>
            &nbsp;{t("meeting.formState.Save & exit")}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4.125 13.125L9.375 18.375L19.875 7.125"
                  stroke="white"
                  stroke-width="2.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>{" "}
          </button>
        )}
      </div>
    </div>
  );
}

export default PrivacyOptions;
