import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import CalendlyLink from "./CalendlyLink";
import { useTranslation } from "react-i18next";

function Trackmoment() {
  const [t] = useTranslation("global");
  const openCalendlyLink = CalendlyLink(
    "https://calendly.com/tektime/tektime-qu-est-ce-que-c-est"
  );
  return (
    <div className="mt-5 pt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mt-3">
            <img
              src="/Assets/landing/Track.png"
              alt=""
              className="img-fluid"
              data-aos="fade-up"
            />
          </div>
          <div className="col-md-6 mt-3" data-aos="fade-down">
            <h4 className="fw-bold trackmoment-mainheading">
              {t("track.title")} <br className="d-none d-md-block" />{" "}
              {t("track.title1")}
            </h4>
            <p className="description mt-4 hero-description">{t("track.subtitle")}</p>
            <p className="description mt-4 hero-description">{t("track.subtitle2")}</p>
            <button onClick={openCalendlyLink} className="btn-demo m-0">
              {t("demo")} <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trackmoment;
