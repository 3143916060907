import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Assets_URL } from "../../Apicongfig";
import { FaArrowRight } from "react-icons/fa";
import ParticipantCardProfile from "../Profile/ParticipantCardProfile";
import { useTranslation } from "react-i18next";

const ParticipantCard = ({
  data,
  fromMeeting,
  guides,
  handleShow,
  handleHide,
  showProfile,
}) => {
  // Create a set of guide emails for fast lookup
  const [t] = useTranslation("global");

  // const guideEmails = new Set(guides?.map((guide) => guide.email));
  // console.log("guides", guides);
  // // Group participants by team name
  // const groupedParticipants =
  //   data?.reduce((acc, participant) => {
  //     if (guideEmails.has(participant.email)) {
  //       return acc; // Skip guides and creators
  //     }

  //     if (participant.team_names?.length > 0) {
  //       participant.team_names.forEach((team) => {
  //         if (!acc[team]) acc[team] = [];
  //         acc[team].push(participant);
  //       });
  //     } else {
  //       if (!acc["NoTeam"]) acc["NoTeam"] = [];
  //       acc["NoTeam"].push(participant);
  //     }

  //     return acc;
  //   }, {}) || {}; // Fallback to an empty object if `data` is undefined

  // // Split participants with teams and without teams
  // const participantsWithTeams = Object?.entries(groupedParticipants).filter(
  //   ([teamName]) => teamName !== "NoTeam"
  // );
  // const participantsWithoutTeams = groupedParticipants["NoTeam"] || [];

  // // const [showProfile, setShowProfile] = useState(false);
  // const [id, setId] = useState(null);
  // const handleShowProfile = (userId) => {
  //   console.log("id", userId);
  //   handleShow();
  //   setId(userId);
  // };

  const guideEmails = new Set(guides?.map((guide) => guide.email));

  // Filter participants that are not guides or creators
  const participants =
    data?.filter((participant) => !guideEmails.has(participant.email)) || [];

  const [id, setId] = useState(null);

  const handleShowProfile = (userId) => {
    handleShow();
    setId(userId);
  };
  // 
  
  return (
    <>
      {showProfile ? (
        <div>
          <ParticipantCardProfile userId={id} handleHide={handleHide} />
        </div>
      ) : (
        <div className="row">
          {participants.map((item, index) => {
            return (
              <div className="col-md-3" key={index}>
                <Card
                  className="participant-card position-relative"
                  style={{ marginTop: "4rem" }}
                >
                  <Card.Body style={{ padding: "20px 0px 20px 0" }}>
                    <div className="d-flex justify-content-center">
                      <div className="participant-card-position">
                        <div className="profile-logo">
                          {item.image ? (
                            <Card.Img
                              className="user-img"
                              src={
                                item?.image?.startsWith("users/")
                                  ? Assets_URL + "/" + item?.image
                                  : item?.image
                              }
                            />
                          ) : item.participant_image ? (
                            <Card.Img
                              className="user-img"
                              src={
                                item.participant_image?.startsWith("users/")
                                  ? Assets_URL + "/" + item.participant_image
                                  : item?.participant_image
                              }
                            />
                          ) : (
                            <Card.Img
                              className="user-img"
                              src="/Assets/avatar.jpeg"
                            />
                          )}
                          <Card.Img
                            className="logout-icon"
                            src="/Assets/Avatar_company.svg"
                            height="20px"
                            width="20px"
                            alt="tektime"
                          />
                        </div>
                      </div>
                    </div>

                    <Card.Title className="text-center mt-4 card-heading">
                      {item.first_name} {item.last_name}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 card-subtext">
                      {item.post} {item?.team_names?.length > 0 && "-"} &nbsp;
                      {item?.team_names?.map((item) => item).join(", ")}
                    </Card.Subtitle>
                    {item?.contribution && (
                      <>
                        <Card.Subtitle className="mb-3 mt-3 card-subtext">
                          {item.contribution}
                        </Card.Subtitle>
                      </>
                    )}
                    {item?.user_id && (
                      <div
                        className="visiting-card-link"
                        onClick={() => handleShowProfile(item?.id)}
                      >
                        {t("viewVisitingCard")} &nbsp; <FaArrowRight />
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ParticipantCard;
