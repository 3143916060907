import React, { useEffect, useRef, useState } from "react";
import { Button, Card, ProgressBar, Spinner, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReportStepCard from "./ReportStepCard";
import DecisionCard from "./DecisionCard";
import * as d3 from "d3";
import StepCard from "./StepCard";
import ParticipantCard from "./ParticipantCard";
import HostCard from "./HostCard";
import Invite from "./Invite";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
import moment from "moment";
import { Avatar } from "antd";
import { IoArrowBackSharp } from "react-icons/io5";
import { FaArrowRight, FaList } from "react-icons/fa";
import ParticipantCardProfile from "../Profile/ParticipantCardProfile";
import SignIn from "../AuthModal/SignIn";
import SignUp from "../AuthModal/SignUp";
import ForgotPassword from "../AuthModal/ForgotPassword";
import VisibilityMessageModal from "../Profile/VisibilityMessageModal";
import VisibilityMeetingMessageModal from "./VisibilityMeetingMessageModal";
import { useSidebarContext } from "../../../context/SidebarContext";
import { Accordion } from "react-bootstrap";
import { FaFlag } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { BsPersonWorkspace } from "react-icons/bs";
import { typeIcons } from "../../Utils/MeetingFunctions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { FaChartGantt, FaRegCalendarDays } from "react-icons/fa6";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Select from "react-select";
import {
  calculateTimeDifference,
  calculateTimeDifferenceDestination,
  calculateTimeDifferencePrepareData,
  convertCount2ToSeconds,
  convertTimeTakenToSeconds,
  openGoogleMeet,
} from "../../Utils/MeetingFunctions";
import { RiHome4Line } from "react-icons/ri";
import { useFormContext } from "../../../context/CreateMeetingContext";
import { useSteps } from "../../../context/Step";
import { useDraftMeetings } from "../../../context/DraftMeetingContext";

const localizer = momentLocalizer(moment);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const Report = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let fromMeeting = false;
  if (location?.state?.from === "meeting") {
    fromMeeting = true;
  }
  const {
    destinationId,
    setDestinationId,
    destinationUniqueId,
    setDestinationUniqueId,
  } = useFormContext();
  console.log("destinationId", destinationId);
  console.log("destinationUniqueId", destinationUniqueId);
  const { id } = useParams();
  const [t, i18n] = useTranslation("global");
  const { unqiue_id, meeting_id: meetId } = useParams();
  const [meeting, setMeeting] = useState();
  console.log("meeting", meeting);

  console.log("meetingid", meetId);
  console.log("unqiue_id", unqiue_id);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime1, setStartTime1] = useState(null);
  const [time, setTime] = useState(null);

  const [objective, setObjective] = useState(null);
  const [userId, setUserID] = useState(null);
  console.log("userId", userId);
  console.log("objective", objective);
  const [checkStatus, setCheckStatus] = useState(null);
  const { setHeaderTitle } = useHeaderTitle();
  const pageId = `${userId}/${meetId}/${objective}`;
  const [pageViews, setPageViews] = useState(0);

  //For Destination Home Page START-------------------------------------------------------------------------------
  const [uniqueId, setUniqueId] = useState(false);
  console.log("uniqueId", uniqueId);
  const [destinationDate, setdestinationData] = useState({});
  console.log("destinationDate", destinationDate);

  const convertTo12HourFormat = (time, steps) => {
    if (!time || !steps) {
      return;
    }
    const hasSeconds = steps.some((step) => step.time_unit === "seconds");

    let [hour, minute, second] = time.split(":").map(Number);

    const formattedHour = hour.toString().padStart(2, "0");
    const formattedMinute = minute.toString().padStart(2, "0");
    const formattedSecond = second.toString().padStart(2, "0");

    const endTime =
      hasSeconds && second !== 0
        ? `${formattedHour}h${formattedMinute}m${formattedSecond}`
        : `${formattedHour}h${formattedMinute}`;

    return endTime;
  };

  const convertTo12HourFormatPrepareData = (time, steps) => {
    if (!time || !steps) {
      return;
    }
    // Check if any step has time unit in seconds
    const hasSeconds = steps?.some((step) => step.time_unit === "seconds");
    console.log("time", time);
    // Extract hours and minutes from the time string
    // let [hour, minute] = time?.split(":").map(Number);

    return time;
  };
  const getSpecialMeeting = async () => {
    setIsModalOpen(false);

    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setIsLoading(true);
    try {
      const response = await axios.get(
        // `${API_BASE_URL}/get-destination-with-meeting/${meetId}?current_time=${formattedTime}&current_date=${formattedDate}`,
        `${API_BASE_URL}/get-destination-meetings/${meetId}?current_time=${formattedTime}&current_date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      // `${API_BASE_URL}/get-destination-meetings/${meetId}?current_time=${formattedTime}&current_date=${formattedDate}`,

      setIsLoading(false);
      setdestinationData(response?.data?.data);
      setDestinationId(response?.data?.data?.id);
      setIsModalOpen(false);

      const uuid = response?.data?.data?.uuid
        ? `${response.data.data.uuid}--es`
        : null;
      if (uuid) {
        setDestinationUniqueId(uuid);
      } else {
        console.warn("UUID is missing in the response:", response);
      }
    } catch (error) {
      setIsModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  const svgRef = useRef();

  const [loading, setLoading] = useState(false);

  function getAllDecisionsFromMeetings(meetings) {
    const allDecisions = [];

    // Assuming you have userTimeZone and currentTime defined as before
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDateTime = new Date().toLocaleString("en-US", {
      timeZone: userTimeZone,
    });
    const currentTime = currentDateTime.split(", ")[1]; // Extract the time part
    const currentDate = currentDateTime.split(", ")[0]; // Extract the time part
    meetings?.forEach((meeting) => {
      meeting?.steps?.forEach((step) => {
        if (step?.decision) {
          allDecisions.push({
            meeting_id: meeting.id,
            step_id: step.id,
            decision_type: step.decision.decision_type,
            decision: step.decision.decision,
            decision_apply: step.decision.decision_apply,
            milestone_date:
              step.decision.decision_type === "Milestone"
                ? step.decision?.milestone_date
                : step.decision?.creation_date,
            creation_time: step?.decision?.creation_time || currentTime,
          });
        }
      });
    });

    return allDecisions;
  }

  const decisions = getAllDecisionsFromMeetings(destinationDate?.meetings);
  console.log("decisions", decisions);
  const sortedMilestones = decisions.sort(
    (a, b) => new Date(a.milestone_date) - new Date(b.milestone_date)
  );
  console.log("sortedMilestones", sortedMilestones);
  function calculateTotalTime(steps) {
    let totalSeconds = 0;
    steps?.forEach((step) => {
      switch (step.time_unit) {
        case "days":
          totalSeconds += step.count2 * 86400;
          break;
        case "hours":
          totalSeconds += step.count2 * 3600;
          break;
        case "minutes":
          totalSeconds += step.count2 * 60;
          break;
        case "seconds":
          totalSeconds += step.count2;
          break;
      }
    });

    const days = Math.floor(totalSeconds / 86400);
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    let result = "";
    // Display logic based on the available time units
    if (days > 0) {
      result += `${days} ${t("time_unit.days")}`;
    } else if (hrs > 0) {
      result += `${hrs} ${t("time_unit.hours")}`;
    } else if (mins > 0) {
      result += `${mins} ${t("time_unit.minutes")}`;
    } else if (secs > 0) {
      result += `${secs} ${t("time_unit.seconds")}`;
    }

    return result.trim();
  }
  const calculateTotalTimeTaken = (steps) => {
    console.log("steps a rhy hain?", steps);
    if (!steps) return;
    // Helper function to convert time string to seconds
    const convertToSeconds = (timeString) => {
      if (!timeString) return 0;
      let totalSeconds = 0;

      // Split by '-' and iterate through parts
      const timeParts = timeString?.split(" - ").map((part) => part.trim());
      timeParts?.forEach((part) => {
        const [value, unit] = part?.split(" ");

        if (unit?.startsWith("sec")) {
          totalSeconds += parseInt(value, 10);
        } else if (unit?.startsWith("min")) {
          totalSeconds += parseInt(value, 10) * 60;
        } else if (unit?.startsWith("hour") || unit?.startsWith("hr")) {
          totalSeconds += parseInt(value, 10) * 3600;
        } else if (unit?.startsWith("day")) {
          totalSeconds += parseInt(value, 10) * 86400;
        }
      });

      return totalSeconds;
    };

    // Calculate total time in seconds
    let totalSeconds = steps?.reduce((acc, step) => {
      return acc + convertToSeconds(step?.time_taken);
    }, 0);

    // Convert total seconds to days, hours, minutes, and seconds
    const days = Math.floor(totalSeconds / 86400);
    totalSeconds %= 86400;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Determine the most significant units to display
    let timeDisplay = "";
    if (days > 0) {
      timeDisplay =
        hours > 0
          ? // ? `${days} ${t("time_unit.days")} - ${hours} ${t("time_unit.hours")} `
            `${days} ${t("time_unit.days")} `
          : `${days} ${t("time_unit.days")} `;
    } else if (hours > 0) {
      timeDisplay =
        minutes > 0
          ? `${hours} ${t("time_unit.hours")} `
          : `${hours} ${t("time_unit.hours")}`;
    } else if (minutes > 0) {
      timeDisplay =
        seconds > 0
          ? `${minutes} ${t("time_unit.minutes")}`
          : `${minutes} ${t("time_unit.minutes")}`;
    } else {
      timeDisplay = `${seconds} ${t("time_unit.seconds")}`;
    }

    console.log("timeDisplay", timeDisplay);
    return timeDisplay;
  };
  // const calculateSpecialTotalTimeTaken = (steps) => {
  //   console.log("steps a rhy hain?", steps);
  //   if (!steps) return;
  //   // Helper function to convert time string to seconds
  //   const convertToSeconds = (timeString) => {
  //     if (!timeString) return 0;
  //     let totalSeconds = 0;

  //     // Split by '-' and iterate through parts
  //     const timeParts = timeString?.split(" - ").map((part) => part.trim());
  //     timeParts?.forEach((part) => {
  //       const [value, unit] = part?.split(" ");

  //       if (unit?.startsWith("sec")) {
  //         totalSeconds += parseInt(value, 10);
  //       } else if (unit?.startsWith("min")) {
  //         totalSeconds += parseInt(value, 10) * 60;
  //       } else if (unit?.startsWith("hour") || unit?.startsWith("hr")) {
  //         totalSeconds += parseInt(value, 10) * 3600;
  //       } else if (unit?.startsWith("day")) {
  //         totalSeconds += parseInt(value, 10) * 86400;
  //       }
  //     });

  //     return totalSeconds;
  //   };

  //   // Calculate total time in seconds
  //   let totalSeconds = steps?.reduce((acc, step) => {
  //     return acc + convertToSeconds(step?.time_taken);
  //   }, 0);

  //   // Convert total seconds to days, hours, minutes, and seconds
  //   const days = Math.floor(totalSeconds / 86400);
  //   totalSeconds %= 86400;
  //   const hours = Math.floor(totalSeconds / 3600);
  //   totalSeconds %= 3600;
  //   const minutes = Math.floor(totalSeconds / 60);
  //   const seconds = totalSeconds % 60;

  //   // Determine the most significant units to display
  //   let timeDisplay = "";
  //   if (days > 0) {
  //     timeDisplay =
  //       hours > 0
  //         ? // ? `${days} ${t("time_unit.days")} - ${hours} ${t("time_unit.hours")} `
  //           `${days} ${t("time_unit.days")} `
  //         : `${days} ${t("time_unit.days")} `;
  //   } else if (hours > 0) {
  //     timeDisplay =
  //       minutes > 0
  //         ? `${hours} ${t("time_unit.hours")} `
  //         : `${hours} ${t("time_unit.hours")}`;
  //   } else if (minutes > 0) {
  //     timeDisplay =
  //       seconds > 0
  //         ? `${minutes} ${t("time_unit.minutes")}`
  //         : `${minutes} ${t("time_unit.minutes")}`;
  //   } else {
  //     timeDisplay = `${seconds} ${t("time_unit.seconds")}`;
  //   }

  //   console.log("timeDisplay", timeDisplay);
  //   return timeDisplay;
  // };
  const totalTime = calculateTotalTime(meeting?.steps);
  const inProgressTime = calculateTimeDifferencePrepareData(
    meeting?.steps,
    meeting?.starts_at,
    meeting?.current_date,
    t
  );
  console.log("inProgressTime", inProgressTime);
  const completedTime = calculateTotalTimeTaken(meeting?.steps);
  const prepareData = destinationDate?.meetings
    ?.filter((meeting) => meeting?.status !== "abort")
    ?.map((meeting, index) => {
      const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
      const meetingDateTime = new Date(
        new Date(meeting.date).getTime() - userTimezoneOffset
      );
      const currentDateTime = new Date();

      // moment().isAfter(
      //   moment(
      //     `${meeting?.date} ${meeting?.start_time}`,
      //     "YYYY-MM-DD HH:mm"
      //   )
      // )
      const color = moment().isAfter(
        moment(`${meeting?.date} ${meeting?.start_time}`, "YYYY-MM-DD HH:mm")
      )
        ? "#F12D2B"
        : "#4C49E3";

      let totalCompletedTime = 0;
      let totalAllTime = 0;
      let totalCount2 = 0;
      let timeUnit = "";
      const totalTime = calculateTotalTime(meeting?.steps);
      const inProgressTime = calculateTimeDifferencePrepareData(
        meeting?.steps,
        meeting?.starts_at,
        meeting?.current_date,
        t
      );
      console.log("inProgressTime", inProgressTime);
      const completedTime = calculateTotalTimeTaken(meeting?.steps);
      const totalTimeTaken = calculateTotalTime(meeting?.steps);

      const convertToSeconds = (timeString) => {
        if (!timeString) return 0;
        let totalSeconds = 0;

        const timeParts = timeString.split(" - ").map((part) => part.trim());
        timeParts.forEach((part) => {
          const [value, unit] = part.split(" ");

          if (unit?.includes("sec")) {
            totalSeconds += parseInt(value, 10);
          } else if (unit?.includes("min")) {
            totalSeconds += parseInt(value, 10) * 60;
          } else if (unit?.includes("hour") || unit?.includes("hr")) {
            totalSeconds += parseInt(value, 10) * 3600;
          } else if (unit?.includes("day")) {
            totalSeconds += parseInt(value, 10) * 86400;
          }
        });

        return totalSeconds;
      };

      const getStepColor = (step) => {
        const stepTimeInSeconds = convertToSeconds(step?.time_taken);
        const count2InSeconds = convertToSeconds(
          `${step?.count2} ${step?.time_unit || ""}`
        );
        return stepTimeInSeconds > count2InSeconds ? "#F12D2B" : "#FFDB01";
      };

      meeting?.steps?.forEach((step) => {
        const stepTimeInSeconds = convertToSeconds(step?.time_taken);
        totalAllTime += stepTimeInSeconds;

        if (meeting?.status === "in_progress") {
          totalCount2 += step?.count2 ?? 0;
        }

        if (step?.step_status === "completed") {
          totalCompletedTime += stepTimeInSeconds;
        }

        if (step?.time_unit === "seconds") {
          totalCount2 += step?.count2 || 0;
        } else if (step?.time_unit === "minutes") {
          totalCount2 += step?.count2 || 0;
        } else if (step?.time_unit === "hours") {
          totalCount2 += step?.count2 || 0;
        }

        timeUnit = step?.time_unit || timeUnit;
      });

      let percentage = 0;
      if (meeting?.status === "closed") {
        percentage =
          totalAllTime > 0 ? (totalCompletedTime / totalAllTime) * 100 : 0;
      }

      if (meeting?.status === "in_progress") {
        const totalSteps = meeting?.steps?.length || 1;
        const completedSteps = meeting?.steps?.filter(
          (step) => step?.step_status === "completed"
        ).length;
        percentage =
          totalSteps > 0
            ? ((completedSteps + totalCompletedTime / totalAllTime) /
                totalSteps) *
              100
            : 0;
      }

      let start;
      // Ensure valid start time for meetings
      if (meeting?.status === "in_progress" && meeting?.starts_at) {
        const formattedStartTime = convertTo12HourFormatPrepareData(
          meeting?.starts_at,
          meeting?.steps
        );
        console.log("inProgress");
        const startDateStringInProgress = `${meeting?.date}T${formattedStartTime}`;
        console.log("startDateStringInProgress", startDateStringInProgress);
        start = new Date(startDateStringInProgress);
        console.log("start", start);
      } else {
        const startDateString = `${meeting?.date}T${
          meeting?.start_time || "00:00:00"
        }`; // Add a fallback for undefined start_time
        start = new Date(startDateString);
      }

      const estimateTime = meeting?.estimate_time || new Date().toISOString();
      const end = new Date(estimateTime.split(".")[0]);
      return {
        task: meeting?.title,
        start: start,
        end: end,
        // start: meeting?.date,
        // end: meeting?.estimate_time?.split("T")[0],
        color:
          meeting?.status === "closed"
            ? "#0A8634"
            : meeting?.status === "in_progress"
            ? meeting?.steps?.some((step) => getStepColor(step) === "#F12D2B")
              ? "#F12D2B"
              : "#FFDB01"
            : meeting?.status === "active"
            ? color
            : "#4C49E3",
        // ? meeting?.status === "abort" ? color,
        progress: Math.floor(percentage),
        status: meeting?.status,
        url: `/destination/${meeting?.unique_id}/${meeting?.id}`,
        inProgressUrl: `/play/${meeting?.id}`,
        totalCount2:
          meeting?.status === "active"
            ? totalTime
            : meeting?.status === "in_progress"
            ? inProgressTime
            : meeting?.type === "Special" || meeting?.type === "Law"
            ? totalTimeTaken
            : completedTime,
        timeUnit,
        order: index + 1,
        originalMeeting: meeting, // Save the full meeting data here
        createAvatar: meeting?.user?.image?.startsWith("users/")
          ? Assets_URL + "/" + meeting?.user?.image
          : meeting?.user?.image,
      };
    })
    ?.sort((a, b) => new Date(a.start) - new Date(b.start));
  console.log("prepareData", prepareData);

  const startDate1 = new Date(
    destinationDate?.meeting_start_date?.split("/")?.reverse()?.join("/")
  ); // Convert to MM/DD/YYYY
  const endDate1 = new Date(
    destinationDate?.meeting_end_date?.split("/")?.reverse()?.join("/")
  ); // Convert to MM/DD/YYYY

  // Calculate the difference in milliseconds
  const differenceInMillis = endDate1 - startDate1;

  // Convert milliseconds to days
  const totalDays2 = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));
  console.log("totalDays2", totalDays2);
  // Format the result
  let result;
  if (totalDays2 > 0) {
    result = `${totalDays2} ${
      totalDays2 > 1 ? t("time_unit.days") : t("time_unit.day")
    }`;
  } else {
    // result = t("time_unit.no_days"); // Handle cases where the end date is before the start date
    result = `1 ${t("time_unit.day")}`;
  }

  // Output the result
  console.log(result);

  console.log("Total Count2:", result);

  const events = (destinationDate?.meetings || [])
    ?.filter((meeting) => meeting?.status !== "abort")
    ?.map((meeting) => {
      const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
      const meetingDateTime = new Date(
        new Date(meeting.date).getTime() - userTimezoneOffset
      );
      const currentDateTime = new Date();

      const color = meetingDateTime < currentDateTime ? "#ff4d4d" : "#4C49E3";

      let totalCompletedTime = 0;
      let totalAllTime = 0;
      let totalCount2 = 0;
      let timeUnit = "";

      const convertToSeconds = (timeString) => {
        if (!timeString) return 0;
        let totalSeconds = 0;

        const timeParts = timeString.split(" - ").map((part) => part.trim());
        timeParts.forEach((part) => {
          const [value, unit] = part.split(" ");

          if (unit?.includes("sec")) {
            totalSeconds += parseInt(value, 10);
          } else if (unit?.includes("min")) {
            totalSeconds += parseInt(value, 10) * 60;
          } else if (unit?.includes("hour") || unit?.includes("hr")) {
            totalSeconds += parseInt(value, 10) * 3600;
          } else if (unit?.includes("day")) {
            totalSeconds += parseInt(value, 10) * 86400;
          }
        });

        return totalSeconds;
      };

      const getStepColor = (step) => {
        const stepTimeInSeconds = convertToSeconds(step?.time_taken);
        const count2InSeconds = convertToSeconds(
          `${step?.count2} ${step?.time_unit || ""}`
        );
        return stepTimeInSeconds > count2InSeconds ? "#F12D2B" : "#FFDB01";
      };

      meeting?.steps?.forEach((step) => {
        const stepTimeInSeconds = convertToSeconds(step?.time_taken);
        totalAllTime += stepTimeInSeconds;

        if (meeting?.status === "in_progress") {
          totalCount2 += step?.count2 ?? 0;
        }

        if (step?.step_status === "completed") {
          totalCompletedTime += stepTimeInSeconds;
        }

        if (step?.time_unit === "seconds") {
          totalCount2 += step?.count2 || 0;
        } else if (step?.time_unit === "minutes") {
          totalCount2 += step?.count2 || 0;
        } else if (step?.time_unit === "hours") {
          totalCount2 += step?.count2 || 0;
        }

        timeUnit = step?.time_unit || timeUnit;
      });

      let percentage = 0;
      if (meeting?.status === "closed") {
        percentage =
          totalAllTime > 0 ? (totalCompletedTime / totalAllTime) * 100 : 0;
      }

      if (meeting?.status === "in_progress") {
        const totalSteps = meeting?.steps?.length || 1;
        const completedSteps = meeting?.steps?.filter(
          (step) => step?.step_status === "completed"
        ).length;
        percentage =
          totalSteps > 0
            ? ((completedSteps + totalCompletedTime / totalAllTime) /
                totalSteps) *
              100
            : 0;
      }

      let start;
      // Ensure valid start time for meetings
      if (meeting?.status === "in_progress" && meeting?.starts_at) {
        const formattedStartTime = convertTo12HourFormatPrepareData(
          meeting?.starts_at,
          meeting?.steps
        );
        console.log("inProgress");
        const startDateStringInProgress = `${meeting?.date}T${formattedStartTime}`;
        console.log("startDateStringInProgress", startDateStringInProgress);
        start = new Date(startDateStringInProgress);
        console.log("start", start);
      } else {
        const startDateString = `${meeting?.date}T${
          meeting?.start_time || "00:00:00"
        }`; // Add a fallback for undefined start_time
        start = new Date(startDateString);
      }

      const estimateTime = meeting?.estimate_time || new Date().toISOString();
      const end = new Date(estimateTime.split(".")[0]);
      return {
        title: meeting?.title,
        start: start,
        end: end,
        color:
          meeting?.status === "closed"
            ? "#0A8634"
            : meeting?.status === "in_progress"
            ? meeting?.steps?.some((step) => getStepColor(step) === "#F12D2B")
              ? "#F12D2B"
              : "#FFDB01"
            : meeting?.status === "active"
            ? color
            : "#4C49E3",
        // progress: percentage.toFixed(2),
        progress: Math.floor(percentage),
        status: meeting?.status,
        url: `/destination/${meeting?.unique_id}/${meeting?.id}`,
        allDay: true,
        totalCount2,
        timeUnit,
      };
    });

  const decisionEvents = sortedMilestones?.map((decision, index) => ({
    start: new Date(decision.milestone_date),
    end: new Date(decision.milestone_date),
    title: `Milestone ${index + 1}: ${decision.decision}`,
    color: "#000000",
    status: "decision",
  }));

  const allEvents = [...events, ...decisionEvents];
  console.log("allevents", allEvents);

  const minDate = new Date(Math.min(...allEvents.map((event) => event.start)));
  const maxDate = new Date(Math.max(...allEvents.map((event) => event.end)));
  const dateDiff = maxDate - minDate; // Difference in milliseconds
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const oneWeek = 7 * oneDay; // One week in milliseconds
  const oneYear = 365 * oneDay; // Approximate year in milliseconds

  // Determine the appropriate view based on date difference
  let defaultView;
  let views;

  if (dateDiff < oneDay) {
    defaultView = "day"; // Show day view if less than a day (hour view)
    views = {
      day: true, // Only show the agenda view
    };
  } else if (dateDiff < oneWeek) {
    defaultView = "week"; // Show week view if less than a week
    views = {
      day: false, // Only show the day view
      week: true, // Show the week view
      month: false, // Show the week view
    };
  } else if (dateDiff < oneYear) {
    defaultView = "month"; // Default to month view for less than a year
    views = {
      day: false,
      week: false,
      month: true,
    };
  } else {
    defaultView = "agenda"; // Show agenda view if a year or more
    views = {
      day: false,
      week: false,
      month: false,
      agenda: true, // Only show agenda view
    };
  }

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.9,
      color: "white",
      border: "0px",
      display: "block",
      height: "40px",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      fontSize: "16px",
    };
    return { style };
  };

  const handleEventSelect = (event) => {
    console.log("event", event);
    if (event?.status !== "decision") {
      setUniqueId((prev) => !prev);
      // window.location.href = event?.url;
      navigate(event?.url);
    } else {
      return;
    }
  };

  let timeDiff;
  useEffect(() => {
    if (!prepareData || !svgRef.current) return;

    const svgContainer = svgRef.current;
    if (!svgContainer) return;

    const margin = { top: 30, right: 150, bottom: 40, left: 250 }; // Adjust these values
    // const svgWidth = 1200; // Set to a specific width

    const containerWidth = svgContainer.getBoundingClientRect().width;
    const svgWidth = containerWidth - margin.left - margin.right;
    const svgHeight = 600 - margin.top - margin.bottom;
    const barHeight = 30;

    d3.select(svgContainer).selectAll("*").remove();

    const svg = d3
      .select(svgContainer)
      .append("svg")
      .attr("width", svgWidth + margin.left + margin.right)
      .attr("height", svgHeight + margin.top + margin.bottom)
      // .attr("overflow", "initial")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    prepareData.forEach((d, index) => {
      d.startDate = d.start;
      d.endDate = d.end;
      d.duration = (d.endDate - d.startDate) / (1000 * 60 * 60 * 24);
      d.uniqueTask = `${d.task}-${index}`;
    });

    // Calculate the minimum start date by subtracting one day
    let minStartDate = d3.min(prepareData, (d) => d.startDate);
    let maxEndDate = d3.max(prepareData, (d) => d.endDate);
    // Add 1 day to the maxEndDate
    // maxEndDate.setDate(maxEndDate.getDate() + 1);

    // If there’s only one meeting, extend the range by one day on each side
    if (prepareData.length === 1) {
      minStartDate = new Date(minStartDate);
      maxEndDate = new Date(maxEndDate);
      minStartDate.setDate(minStartDate.getDate() - 1);
      maxEndDate.setDate(maxEndDate.getDate() + 1);
    }

    // Round `minStartDate` to the start of the day (08 Nov 00:00) for consistency
    const adjustedMinStartDate = d3.timeDay.floor(minStartDate);
    // Calculate time difference in milliseconds
    timeDiff = maxEndDate - adjustedMinStartDate;

    // Choose time format and ticks based on the range
    let timeFormat;
    let tickInterval;
    let secondaryTickInterval;
    if (timeDiff <= 1000 * 60 * 60 * 24) {
      //---------------------------------------------------------------------HOUR GAPP (less than day)
      timeFormat = d3.timeFormat("%H:%M");
      secondaryTickInterval = null;
      // secondaryTickInterval = d3.timeHour.every(2);
    } else if (timeDiff <= 1000 * 60 * 60 * 24 * 7) {
      //---------------------------------------------------------------------DAYS GAPP (less than week)
      timeFormat = d3.timeFormat("%d/%m/%Y");
      tickInterval = d3.timeDay.every(1);
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      if (daysDiff >= 5) {
        secondaryTickInterval = d3.timeHour.every(6);
      } else {
        secondaryTickInterval = d3.timeHour.every(4);
      }
    } else if (timeDiff <= 1000 * 60 * 60 * 24 * 30) {
      //--------------------------------------------------------------------WEEK GAPP (less than month)
      timeFormat = d3.timeFormat("%d/%m/%Y");
      tickInterval = d3.timeDay.every(3); // Show every 3 days
      secondaryTickInterval = null;
    } else {
      // -------------------------------------------------------------------MONTH GAPP
      timeFormat = d3.timeFormat("%d/%m/%Y");
      tickInterval = d3.timeMonth.every(1);
    }
    const hasInProgress = prepareData?.some((d) => d.status === "in_progress");

    // Set the maximum date based on the presence of 'in_progress' meetings
    const adjustedMaxEndDate = hasInProgress
      ? new Date(maxEndDate?.getTime() + 86400000) // Add 1 day to max endDate
      : new Date(Date.now() + 86400000); // Set to current date + 1 day

    // Create the xScale with adjusted dates
    const xScale = d3
      .scaleTime()
      .domain([
        // adjustedMinStartDate,
        minStartDate,
        maxEndDate,
        // adjustedMaxEndDate, // Use the adjusted max end date
      ])
      .range([0, svgWidth]);

    // const xTicks = xScale.ticks(5);
    // Generate ticks based on the interval
    const xTicks = xScale.ticks(tickInterval);

    xTicks.forEach((tick) => {
      const x = xScale(tick);
      svg
        .selectAll(".x-grid")
        .data(xScale.ticks(10))
        .enter()
        .append("line")
        .attr("x1", x)
        .attr("y1", 0)
        .attr("x2", x)
        .attr("y2", svgHeight)
        .attr("stroke", "#ccc")
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "2 2");

      svg
        .append("text")
        .attr("x", x)
        .attr("y", -10)
        // .text(d3.timeFormat("%b %Y")(tick))
        .text(timeFormat(tick))
        .style("fill", "black")
        .attr("text-anchor", "middle");
      // .attr("dx", "-1.5em");
    });

    // Generate secondary hourly ticks when timeDiff is in days
    if (secondaryTickInterval && timeDiff <= 1000 * 60 * 60 * 24 * 7) {
      const hourlyTicks = xScale.ticks(secondaryTickInterval);
      hourlyTicks.forEach((tick) => {
        const x = xScale(tick);
        svg
          .append("line")
          .attr("class", "hourly-grid")
          .attr("x1", x)
          .attr("y1", 0)
          .attr("x2", x)
          .attr("y2", svgHeight)
          .attr("stroke", "#e0e0e0")
          .attr("stroke-width", 0.5)
          .attr("stroke-dasharray", "1 1");

        svg
          .append("text")
          .attr("x", x)
          .attr("y", 0)
          .text(d3.timeFormat("%H:%M")(tick)) // Show hours for secondary ticks
          .style("fill", "gray")
          .style("font-size", "10px")
          .attr("text-anchor", "middle");
      });
    }

    // X scale for the days of the month
    const days = d3.timeDays(
      d3.min(prepareData, (d) => d.startDate),
      d3.max(prepareData, (d) => d.endDate)
    );

    days.forEach((day) => {
      const dayNum = day.getDate();
      if (dayNum % 3 === 0) {
        const dayX = xScale(day);

        const textElement = svg
          .append("text")
          .attr("x", dayX)
          .attr("y", 15)
          .style("font-size", "7px")
          .style("fill", "#000")
          .attr("text-anchor", "middle");

        // Only add the date text if timeDiff is greater than 7 days
        if (timeDiff <= 1000 * 60 * 60 * 24 * 30) {
          //nothing...
        } else {
          textElement.text(d3.timeFormat("%d")(day));
        }
      }
    });

    const yScale = d3
      .scaleBand()
      .domain(prepareData.map((d) => d.uniqueTask))
      .range([0, svgHeight])
      .padding(0.2);

    const yTicks = yScale.domain();
    const marginTop = 15;

    d3.selectAll(".tooltip").remove();

    yTicks.forEach((tick, index) => {
      // let cleanedTick = tick;

      // if (cleanedTick.length > 1) {
      //   cleanedTick = cleanedTick.slice(0, -1);
      //   if (cleanedTick[cleanedTick.length - 1] === "-") {
      //     cleanedTick = cleanedTick.slice(0, -1);
      //   }
      // }
      // const orderText = `${prepareData[index].order}. `; // Include the order number here

      // const lines = cleanedTick.split(" ");
      // const lineCount = Math.ceil(lines.join(" ").length / 15);
      // const totalHeight = lineCount * 1.2 * 14 + marginTop;
      let cleanedTick = tick;

      if (cleanedTick.length > 1) {
        cleanedTick = cleanedTick.slice(0, -1);
        if (cleanedTick[cleanedTick.length - 1] === "-") {
          cleanedTick = cleanedTick.slice(0, -1);
        }
      }

      const orderText = `${prepareData[index].order}. `; // Include the order number here
      const maxChars = 10; // Maximum characters to display before truncating
      const fullText = `${orderText}${cleanedTick}`;
      const truncatedText =
        fullText.length > maxChars
          ? `${fullText.slice(0, maxChars)}...`
          : fullText;

      const y = yScale(tick) + barHeight / 2 + marginTop;

      svg
        .append("line")
        .attr("class", "y-grid")
        .attr("x1", 0)
        .attr("y1", y)
        .attr("x2", svgWidth)
        .attr("y2", y)
        .attr("stroke", "#ccc")
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "2 2");

      // Tooltip setup
      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("background", "#fff")
        .style("border", "1px solid #ccc")
        .style("border-radius", "4px")
        .style("padding", "8px")
        .style("pointer-events", "none")
        .style("opacity", 0);

      const textGroup = svg
        .append("text")
        .attr("x", -20)
        .attr("y", y + 20)
        .attr("text-anchor", "end")
        .attr("transform", `translate(0, 0)`)
        .style("fill", "black")
        .style("font-size", "14px")
        .style("cursor", "pointer")
        .text(truncatedText)
        .on("mouseover", (event) => {
          tooltip
            .style("opacity", 1)
            .html(fullText) // Display the full text in the tooltip
            .style("left", `${event.pageX + 5}px`)
            .style("top", `${event.pageY - 28}px`);
        })
        .on("mousemove", (event) => {
          tooltip
            .style("left", `${event.pageX + 5}px`)
            .style("top", `${event.pageY - 28}px`);
        })
        .on("mouseout", () => {
          tooltip.style("opacity", 0);
        });

      // Add tooltip
      // textGroup.append("title").text(fullText);

      // const wrapText = (text, maxCharsPerLine) => {
      //   const words = text.split(" ");
      //   let line = "";
      //   let tspan;
      //   let isFirstLine = true;
      //   let lineCounter = 0;
      //   let stopAppending = false;

      //   words.forEach((word) => {
      //     if (stopAppending) return; // Stop appending once ellipsis is added

      //     if (line.length + word.length > maxCharsPerLine) {
      //       lineCounter += 1;

      //       // After 3 lines, add ellipsis and stop further text processing
      //       if (lineCounter === 3) {
      //         tspan = textGroup
      //           .append("tspan")
      //           .attr("x", -20)
      //           .attr("dy", "1.2em")
      //           .text(
      //             isFirstLine
      //               ? orderText + line.trim() + "..."
      //               : line.trim() + "..."
      //           );
      //         stopAppending = true; // Set flag to stop further text processing
      //         return;
      //       }

      //       tspan = textGroup
      //         .append("tspan")
      //         .attr("x", -20)
      //         .attr("dy", "1.2em")
      //         .text(isFirstLine ? orderText + line.trim() : line.trim());
      //       line = word;
      //       isFirstLine = false;
      //     } else {
      //       line += " " + word;
      //     }
      //   });

      //   // If less than 3 lines, add the remaining line as usual
      //   if (lineCounter < 3 && !stopAppending) {
      //     tspan = textGroup
      //       .append("tspan")
      //       .attr("x", -20)
      //       .attr("dy", "1.2em")
      //       .text(isFirstLine ? orderText + line.trim() : line.trim());
      //   }
      // };

      // wrapText(cleanedTick, 15);

      const currentData = prepareData[index];
      if (currentData) {
        console.log("currentData", currentData);
        svg
          .append("text")
          .attr("class", "count-label")
          .attr("x", -20 - 160)
          .attr("y", y + 20)
          .attr("text-anchor", "end")
          .style("fill", "black")
          .style("font-size", "14px")
          .text(`${currentData?.totalCount2}  `);

        const avatarX = -20 - 140;
        const avatarY = y + 20 - 20;

        // Tooltip for the avatar image
        const tooltip = d3
          .select("body")
          .append("div")
          .attr("class", "tooltip") // Ensure this CSS class is defined
          .style("position", "absolute")
          .style("background", "#fff")
          .style("border", "1px solid #ccc")
          .style("border-radius", "4px")
          .style("padding", "8px")
          .style("pointer-events", "none")
          .style("opacity", 0); // Initially invisible
        // Append the avatar image
        svg
          .append("image")
          .attr("xlink:href", currentData?.createAvatar)
          .attr("x", avatarX)
          .attr("y", avatarY)
          .attr("width", 20)
          .attr("height", 20)
          .style("cursor", "pointer")
          // .on("mouseover", (event) => {
          //   tooltip
          //     .style("opacity", 1)
          //     .html(
          //       `${currentData?.originalMeeting?.user?.full_name || "N/A"} - ${
          //         currentData?.originalMeeting?.user?.post
          //       }`
          //     )
          //     .style("left", `${event.pageX + 5}px`)
          //     .style("top", `${event.pageY - 28}px`);
          // })
          .on("mouseover", (event) => {
            const userName =
              currentData?.originalMeeting?.user?.full_name || "N/A";
            const userPost = currentData?.originalMeeting?.user?.post || "N/A";
            const userImage =
              currentData?.originalMeeting?.user?.image?.startsWith("users/")
                ? Assets_URL + "/" + currentData?.originalMeeting?.user?.image
                : currentData?.originalMeeting?.user?.image; // Provide a fallback image

            tooltip
              .style("opacity", 1)
              .html(
                `
                <div style="display: flex; align-items: center;">
                  <img src="${userImage}" alt="User Image" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 10px; object-fit: cover; object-position: top">
                  <div>
                    <div>${userName}</div>
                    <div>${userPost}</div>
                  </div>
                </div>
              `
              )
              .style("left", `${event.pageX + 5}px`)
              .style("top", `${event.pageY - 28}px`);
          })
          .on("mousemove", (event) => {
            tooltip
              .style("left", `${event.pageX + 5}px`)
              .style("top", `${event.pageY - 28}px`);
          })
          .on("mouseout", () => {
            tooltip.style("opacity", 0);
          });
      }
    });

    // Create tooltip div
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip") // add tooltip class for styling
      .style("position", "absolute")
      .style("background", "#fff")
      .style("border", "1px solid #ccc")
      .style("border-radius", "4px")
      .style("padding", "8px")
      .style("pointer-events", "none")
      .style("opacity", 0); // initially invisible
    // Add the bars for the Gantt chart
    svg
      .selectAll(".bar")
      .data(prepareData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.startDate))
      .attr("y", (d) => yScale(d.uniqueTask))
      .attr("transform", `translate(0, ${30})`)

      .attr("width", (d) => {
        const barWidth = xScale(d.endDate) - xScale(d.startDate);
        console.log("barWidth", barWidth);
        return barWidth >= 1 ? barWidth : barWidth < 1 ? 2 : 0;
      })
      .attr("height", barHeight)
      .attr("fill", (d) => d.color)
      .style("cursor", "pointer")
      .on("mouseover", (event, d) => {
        const date = new Date(d?.originalMeeting?.date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        // Convert start time to 12-hour format
        const startTimeFormatted =
          d?.originalMeeting?.status === "in_progress"
            ? convertTo12HourFormat(
                d.originalMeeting?.starts_at,
                d?.originalMeeting.steps
              )
            : convertTo12HourFormat(
                d.originalMeeting?.start_time,
                d?.originalMeeting?.steps
              ); // Fallback if undefined

        const estimateDateOnly =
          d?.originalMeeting?.estimate_time?.split("T")[0] || "";
        const estimateTimeOnly =
          d?.originalMeeting?.estimate_time?.split("T")[1]?.split(".")[0] || "";

        const formattedEstimateDate = estimateDateOnly
          ?.split("-")
          .reverse()
          .join("/");

        const [hours1, minutes1, seconds1] = estimateTimeOnly?.split(":");
        // const hours12 = hours1 % 12 || 12;
        // const amPm = hours1 >= 12 ? "PM" : "AM";

        const formattedEstimateTime = `${hours1}h${minutes1}${
          d?.originalMeeting?.type === "Quiz" ? `m${seconds1}` : ""
        }`;

        const formattedEndTime = specialMeetingEndTime(
          d?.originalMeeting?.start_time,
          d?.originalMeeting?.steps
        );
        console.log("formattedEndTime", formattedEndTime);
        // Show tooltip
        tooltip
          .style("opacity", 1)
          .html(
            `${d.task} <br/> ${formattedDate} ${t(
              "at"
            )} ${startTimeFormatted}  - ${formattedEstimateDate} ${t("at")} ${
              d.originalMeeting?.type === "Special" ||
              d.originalMeeting?.type === "Law"
                ? formattedEndTime
                : formattedEstimateTime
            }`
          )
          .style("left", `${event.pageX + 5}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mousemove", (event) => {
        // Move tooltip with mouse
        tooltip
          .style("left", `${event.pageX + 5}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        // Hide tooltip
        tooltip.style("opacity", 0);
      })
      .on("click", (event, d) => {
        // Open new tab if status is "completed"
        setUniqueId((prev) => !prev);
        if (d.status === "in_progress") {
          navigate(d?.url);
          // window.location.href = d?.url;
        } else {
          navigate(d?.url);
        }
      });

    // Add labels to the bars
    svg
      .selectAll(".label")
      .data(prepareData)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => xScale(d.startDate) + 5)
      .attr("y", (d) => yScale(d.uniqueTask) + barHeight / 2)
      .attr("dy", ".35em")
      .attr("transform", `translate(0, ${30})`)
      .attr("clip-path", "url(#clip-text)")
      .text((d) => {
        const taskText = `${d.task}`;
        return taskText.length > 15
          ? `${taskText.substring(0, 12)}...${d.progress}%`
          : `${taskText} ${d.progress}%`;
      })
      .style("fill", "#fff")
      .style("font-size", "12px")
      .style("text-anchor", "start")
      .style("cursor", "pointer")
      .on("mouseover", (event, d) => {
        const date = new Date(d?.originalMeeting?.date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        // Convert start time to 12-hour format
        const startTimeFormatted =
          d?.originalMeeting?.status === "in_progress"
            ? convertTo12HourFormat(
                d.originalMeeting?.starts_at,
                d?.originalMeeting.steps
              )
            : convertTo12HourFormat(
                d.originalMeeting?.start_time,
                d?.originalMeeting?.steps
              ); // Fallback if undefined

        const estimateDateOnly =
          d?.originalMeeting?.estimate_time?.split("T")[0] || "";
        const estimateTimeOnly =
          d?.originalMeeting?.estimate_time?.split("T")[1]?.split(".")[0] || "";

        const formattedEstimateDate = estimateDateOnly
          ?.split("-")
          .reverse()
          .join("/");

        const [hours1, minutes1, seconds1] = estimateTimeOnly?.split(":");
        // const hours12 = hours1 % 12 || 12;
        // const amPm = hours1 >= 12 ? "PM" : "AM";

        const formattedEstimateTime = `${hours1}h${minutes1}${
          d?.originalMeeting?.type === "Quiz" ? `m${seconds1}` : ""
        }`;

        const formattedEndTime = specialMeetingEndTime(
          d?.originalMeeting?.start_time,
          d?.originalMeeting?.steps
        );
        console.log("formattedEndTime", formattedEndTime);
        // Show tooltip
        tooltip
          .style("opacity", 1)
          .html(
            `${d.task} <br/> ${formattedDate} ${t(
              "at"
            )} ${startTimeFormatted}  - ${formattedEstimateDate} ${t("at")}  ${
              d.originalMeeting?.type === "Special" ||
              d.originalMeeting?.type === "Law"
                ? formattedEndTime
                : formattedEstimateTime
            }`
          )
          .style("left", `${event.pageX + 5}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mousemove", (event) => {
        // Move tooltip with mouse
        tooltip
          .style("left", `${event.pageX + 5}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        // Hide tooltip
        tooltip.style("opacity", 0);
      })
      .on("click", (event, d) => {
        setUniqueId((prev) => !prev);
        if (d.status === "in_progress") {
          // window.location.href = d?.url;
          navigate(d?.url);
        } else {
          navigate(d?.url);
        }
      });

    // const milestoneDates = decisions.map((d) => new Date(d.milestone_date));
    // const milestoneDates = decisions.map((d) => {
    //   return new Date(`${d.milestone_date} ${d.creation_time}`);
    // });
    // const minMilestoneDate = d3.min(milestoneDates);
    // const maxMilestoneDate = d3.max(milestoneDates);

    // // Adjust minMilestoneDate to midnight (00:00:00) of the start date
    // minMilestoneDate?.setHours(0, 0, 0, 0); // Set to midnight
    // const xMilestoneScale = d3
    //   .scaleTime()
    //   .domain([minMilestoneDate, maxMilestoneDate])
    //   .range([0, svgWidth]);

    // Group decisions by date to create a nested structure
    const groupedDecisions = d3.groups(decisions, (d) => d.milestone_date);

    console.log("groupedDecisions", groupedDecisions);
    // Log min and max dates, and ensure the scale is set correctly
    // console.log("Min Milestone Date:", minMilestoneDate);
    // console.log("Max Milestone Date:", maxMilestoneDate);

    const labelYPositions = new Map(); // To track the y positions of labels
    const startingYPosition = 15;
    const labelVerticalSpacing = 15;
    const diamondSize = 15;

    groupedDecisions.forEach(([date, milestones]) => {
      const dateObj = new Date(date); // Parses date in UTC
      dateObj.setHours(0, 0, 0, 0); // Set time explicitly to 00:00:00
      const xPosition = xScale(dateObj);
      // const xPosition = xMilestoneScale(dateObj);
      // Log the calculated x position
      console.log(`X Position for date ${date}: ${xPosition}`);

      let currentYPosition = startingYPosition;

      milestones.forEach((milestone, i) => {
        while (labelYPositions.has(currentYPosition)) {
          currentYPosition += labelVerticalSpacing;
        }

        // Log the y position before appending
        console.log(
          `Appending milestone: "${milestone.decision}" at Y Position: ${currentYPosition}`
        );

        svg
          .append("text")
          .attr("class", "milestone-label")
          .attr("x", xPosition)
          .attr("y", currentYPosition)
          .attr("text-anchor", "middle")
          .text(milestone.decision)
          .style("fill", "black")
          .style("font-weight", "400px")
          .style("font-family", "Roboto")
          .style("font-size", "14px");

        labelYPositions.set(currentYPosition, true);
        currentYPosition += labelVerticalSpacing;
      });

      const diamondYPosition = currentYPosition;

      svg
        .append("polygon")
        .attr(
          "points",
          `${xPosition - diamondSize},${diamondYPosition} 
             ${xPosition},${diamondYPosition + diamondSize} 
             ${xPosition + diamondSize},${diamondYPosition} 
             ${xPosition},${diamondYPosition - diamondSize}`
        )
        .attr("fill", "#4C4C4C");

      svg
        .append("line")
        .attr("class", "milestone-line")
        .attr("x1", xPosition)
        .attr("y1", diamondYPosition + diamondSize)
        .attr("x2", xPosition)
        .attr("y2", svgHeight)
        .attr("stroke", "#4C4C4C")
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "4 2");
    });

    // Draw a vertical line for the current date
    const currentDate = new Date();
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // Set to midnight
    const destinationEndDate = new Date(maxEndDate); // Set this according to your data
    if (destinationEndDate < todayDate) {
      // If destination_end_date is less than current_date, do not draw the current date line
      return;
    }
    svg
      .append("line")
      .attr("class", "current-date-line")
      .attr("x1", xScale(currentDate))
      .attr("y1", 0)
      .attr("x2", xScale(currentDate))
      .attr("y2", svgHeight)
      .attr("stroke", "red")
      .attr("stroke-width", 2);

    return () => {
      tooltip.remove();
    };
  }, [prepareData]);

  //For Destination Home Page END-------------------------------------------------------------------------------

  useEffect(() => {
    const incrementPageView = async () => {
      // setIsLoading(true)
      try {
        const response = await axios.get(
          `${API_BASE_URL}/page-views/${pageId}`
        );
        console.log("response views", response);
        if (response.status === 200) {
          setPageViews(response?.data?.data?.views);
          // setIsLoading(false);
        }
      } catch (error) {
        console.error("Error incrementing page view:", error);
      }
    };
    incrementPageView();
  }, [pageId]);
  const [estimateTime, setEstimateTime] = useState(null);
  const [estimateDate, setEstimateDate] = useState(null);
  const [meetingData, setMeetingData] = useState();
  const [selectedMeeting, setSelectedMeeting] = useState(null); // State for the selected meeting
  console.log("selectedMeeting", selectedMeeting);

  console.log("meetingData", meetingData);
  const [visibilityMessage, setVisibilityMessage] = useState(null);
  console.log("visibilityMessage", visibilityMessage);

  useEffect(() => {
    if (!meetingData) return;

    const { estimate_time, type, steps, start_time } = meetingData;
    // Ensure estimate_time exists before splitting
    if (estimate_time) {
      const estimateDateOnly = estimate_time?.split("T")[0] || "";
      const estimateTimeOnly =
        estimate_time?.split("T")[1]?.split(".")[0] || "";

      const formattedEstimateDate = estimateDateOnly
        ?.split("-")
        .reverse()
        .join("/");

      if (estimateTimeOnly) {
        const [hours1, minutes1, seconds1] = estimateTimeOnly?.split(":");
        // const hours12 = hours1 % 12 || 12;
        // const amPm = hours1 >= 12 ? "PM" : "AM";

        const formattedEstimateTime = `${hours1}h${minutes1}${
          type === "Quiz" ? `m${seconds1}` : ""
        }`;

        setEstimateTime(formattedEstimateTime);
        setEstimateDate(formattedEstimateDate);
      }
    }

    // Ensure start_time exists before manipulating
    if (start_time) {
      const totalCount2 = steps?.reduce((acc, step) => acc + step.count2, 0);
      const [hours, minutes, seconds] = start_time.split(":").map(Number);

      if (!isNaN(hours) && !isNaN(minutes)) {
        const startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(seconds || 0); // Handle case where seconds might be missing
        startDate.setMinutes(startDate.getMinutes() + totalCount2);

        const formattedStartTime = startDate.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        setStartTime1(formattedStartTime);
      }
    }
  }, [meetingData, id, meetId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log("isModalOpen", isModalOpen);
  useEffect(() => {
    if (visibilityMessage) {
      setIsModalOpen(true);
    }
  }, [visibilityMessage, unqiue_id]);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const dateActive = new Date(meeting?.date);
  const formattedDateActive = dateActive.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const startTimeActive = meeting?.start_time;
  const formattedTimeActive = new Date(
    `1970-01-01T${startTimeActive}`
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  // const meetingById = async () => {
  //   if (uniqueId) {
  //     return false;
  //   }
  //   const currentTime = new Date();
  //   const hours = currentTime.getHours();
  //   const minutes = currentTime.getMinutes();
  //   const seconds = currentTime.getSeconds();
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   const formattedHours = hours % 12 || 12;
  //   const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  //   const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
  //   const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  //   // Format date
  //   const year = currentTime.getFullYear();
  //   const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
  //   const day = currentTime.getDate().toString().padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}`;
  //   setIsLoading(true);
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const REQUEST_URL = `${API_BASE_URL}/meetingView/${meetId}?current_time=${formattedTime}&current_date=${formattedDate}`;
  //     const response = await axios.get(REQUEST_URL, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.status) {
  //       setObjective(response.data?.data?.objective);
  //       setUserID(response.data?.data?.user.id);
  //       setCheckStatus(response?.data?.data?.status);
  //       return response?.data?.data;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const desId = destinationId || null;

  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const getMeetingReport = async () => {
    if (uniqueId) return false;

    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    const formattedDate = currentTime.toISOString().split("T")[0]; // ISO date format (YYYY-MM-DD)

    setIsLoading(true);
    setIsModalOpen(false);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-meeting-report/${meetId}?current_time=${formattedTime}&current_date=${formattedDate}&destination_id=${desId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const meeting = response.data.data;

        setObjective(meeting?.objective);
        setUserID(meeting?.user?.id);
        const steps = meeting?.steps || [];
        const startTime = meeting?.start_time;
        const estimateTime = meeting?.estimate_time?.split("T")[1] || "";

        setMeeting(meeting);
        setHeaderTitle({ titleText: " " });
        setTime(startTime);
        setEstimateDate(meeting?.estimate_time.split("T")[0]);
        setEstimateTime(estimateTime);

        const totalMinutes = steps.reduce((acc, step) => acc + step.count2, 0);
        const [hours, minutes] = startTime.split(":").map(Number);

        const startDate = new Date();
        startDate.setHours(hours, minutes + totalMinutes);
        setStartTime1(
          startDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        );

        setSelectedMeeting({ value: meeting?.id, label: meeting?.title });
        setMeetingData(meeting);

        setIsLoading(false);

        // Check if all notes are summarized
        // const areNotesSummarized = steps.every(
        //   (step) => step.note?.trim() === step.original_note?.trim()
        // );
        const allEqual = steps.every(
          (step) => step.note?.trim() === step.original_note?.trim()
        );
        
        const someEqual = steps.some(
          (step) => step.note?.trim() === step.original_note?.trim()
        );
        
        const areNotesSummarized = allEqual || someEqual; // true if all or some equal, false if none

        const areStepNotesSummarized = steps ? (
          steps.every(step => step.note === null) || // All notes are null
          steps.some(step => step.note === null) && steps.some(step => step.note !== null) // One note is null and one is not
        ) : undefined; // Handle the case when `steps` is null or undefined
        
        console.log("areStepNotesSummarized",areStepNotesSummarized)
        if (
          (meeting?.status === "closed" &&
            meeting?.prise_de_notes === "Automatic" &&
            areNotesSummarized) ||
            (meeting?.type === "Special" && areStepNotesSummarized)
        ) {
          setShowProgressBar(true);
          // Call the second API
          await handleSecondApiCall(meeting.id); // Pass any required data
        }
        return { status: response.status, meeting };
      }
    } catch (error) {
      console.log("error a gya");
      const status = error?.response?.status || 500;
      const message = error?.response?.data?.message || "An error occurred.";
      console.log("message", message);
      setVisibilityMessage(status === 403 ? message : message);
      setIsModalOpen(true);

      // return { status, message };
    } finally {
      setIsLoading(false);
    }
  };

  const handleSecondApiCall = async (meetingId) => {
    setShowProgressBar(true); // Show progress bar
    setProgress(0); // Reset progress

     // Simulate progress incrementally
     const simulateProgress = () => {
      setProgress((prev) => {
        if (prev >= 95) return prev; // Cap progress at 95%
        return prev + 1; // Increment progress
      });
    };

    // Start a progress simulation interval
    const interval = setInterval(simulateProgress, 100); // Increment every 100ms
    try {
      const response = await axios.get(
        `${API_BASE_URL}/summarize-meeting-notes/${meetingId}`
        // {
        //   headers: {
        //     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        //   },
        // }
      );

        // Clear interval when the request completes
        clearInterval(interval);
      if (response.status) {
        console.log("Second API call successful:", response.data);
        const steps = response?.data?.data?.steps;
        // Update only the steps in meetingData while keeping other properties intact
        setMeetingData((prevMeetingData) => ({
          ...prevMeetingData,
          steps: steps,
        }));

        setProgress(100);

        // Hide progress bar after a short delay
        setTimeout(() => {
          setShowProgressBar(false);
        }, 500);
      } else {
        console.log("Second API call failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error in second API call:", error);
    }
  };

  // useEffect(() => {
  //   let timer;
  //   if (showProgressBar) {
  //     timer = setInterval(() => {
  //       setProgress((prevProgress) =>
  //         prevProgress >= 100 ? 100 : prevProgress + 1
  //       );
  //     }, 100);
  //   }
  //   return () => clearInterval(timer);
  // }, [showProgressBar]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportResponse = await getMeetingReport();

        if (reportResponse?.status === 200 && reportResponse?.meeting) {
          const { meeting } = reportResponse;

          if (meeting.status === "closed") {
            getMeetingByID(meeting.objective, meeting.user.id);
          } else if (["active", "in_progress"].includes(meeting.status)) {
            getActiveMeetingByID(meeting.objective, meeting.user.id);
          }
        } else if (reportResponse?.message || reportResponse?.status === 403) {
          console.error(
            "Visibility message or Forbidden status found:",
            reportResponse?.message
          );
          // setVisibilityMessage(reportResponse?.message || "Access Denied");
          // setIsModalOpen(true);
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };

    if (unqiue_id?.endsWith("--es")) {
      setUniqueId(true);
      getSpecialMeeting();
    } else {
      fetchData();
    }
  }, [unqiue_id]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [allMeetings, setAllMeetings] = useState([]);
  console.log("allMeetings", allMeetings);

  const getMeetingByID = async (objective, userId) => {
    if (uniqueId) {
      return false;
    }

    try {
      const token = sessionStorage.getItem("token");
      const REQUEST_URL = `${API_BASE_URL}/get-public-meetings/${userId}/${objective}?destination_id=${desId}`;

      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const meetings = response.data?.data;
        setAllMeetings(meetings);
      } else {
        toast.error("Échec de la récupération du rapport");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getActiveMeetingByID = async (objective, userId) => {
    if (uniqueId) {
      return false;
    }

    try {
      const token = sessionStorage.getItem("token");
      const REQUEST_URL = `${API_BASE_URL}/get-public-active-meetings/${userId}/${objective}?destination_id=${desId}`;

      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const meetings = response.data?.data;

        setAllMeetings(meetings);
      } else {
        toast.error("Échec de la récupération du rapport");
      }
    } catch (error) {
    } finally {
    }
  };
  const { updateSteps } = useSteps();

  // const getMeeting = async (meetingId) => {
  //   if (uniqueId) {
  //     return false;
  //   }
  //   try {
  //     // setIsLoading(true);
  //     const token = sessionStorage.getItem("token");
  //     const REQUEST_URL = `${API_BASE_URL}/meetingView/${meetingId}?destination_id=${desId}`;

  //     const response = await axios.get(REQUEST_URL, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.status) {
  //       const meetings = response.data?.data;

  //       const date = response?.data?.data?.estimate_time;
  //       const dateOnly = date?.split("T")[0];

  //       // Split the date into year, month, and day
  //       const [year, month, day] = dateOnly?.split("-");

  //       // Format the date as dd/mm/yyyy
  //       const formattedDate1 = `${day}/${month}/${year}`;
  //       const timeOnly = date?.split("T")[1]?.split(".")[0];

  //       // Convert the time to a Date object for easy 12-hour conversion
  //       const [hours1, minutes1, seconds1] = timeOnly?.split(":");
  //       // let hours12 = hours1 % 12 || 12; // Convert to 12-hour format, handle 00 as 12
  //       // const amPm = hours1 >= 12 ? "PM" : "AM";
  //       console.log("dateOnly", formattedDate);
  //       console.log("timeOnly", timeOnly);
  //       const formattedTime = `${hours1}h${minutes1}${response?.data?.data?.type === "Quiz" ? `m${seconds1}` : ""
  //         }`;
  //       console.log("formattedTime", formattedTime);

  //       setEstimateTime(formattedTime);
  //       setEstimateDate(formattedDate1);
  //       setMeetingData(meetings);
  //       updateSteps(meetings.steps);
  //     } else {
  //       toast.error("Échec de la récupération du rapport");
  //       // setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const date = new Date(meetingData?.date);
  // Get individual components of the date
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date in dd:mm:yyyy
  const formattedDate = `${day}/${month}/${year}`;

  const startTime = meetingData?.starts_at;
  const formattedTime = new Date(`1970-01-01T${startTime}`).toLocaleTimeString(
    "en-US",
    { hour: "2-digit", minute: "2-digit", hour12: true }
  );
  const endTime = meetingData?.real_end_time;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedTitleOrder, setSelectedTitleOrder] = useState(
    meetingData?.title_order
  );
  const dropdownRef = useRef(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const applyFilter = async (item) => {
    try {
      setIsLoading(true);
      // await getMeeting(item.id); // Fetch meeting data
      setSelectedTitleOrder(item.title_order);
      setDropdownVisible(!dropdownVisible);
      navigate(`/destination/${item.unique_id}/${item.id}`);
      setSelectedMeeting({
        value: item?.id,
        label: item?.title,
      });
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false); // Hide loader whether successful or not
    }
  };
  const applyHeaderFilter = async (item) => {
    try {
      setIsLoading(true);
      // await getMeeting(item.id); // Fetch meeting data
      setSelectedTitleOrder(item.title_order);
      // setDropdownVisible(!dropdownVisible);
      navigate(`/destination/${item.unique_id}/${item.id}`);
      setSelectedMeeting({
        value: item?.id,
        label: item?.title,
      });
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false); // Hide loader whether successful or not
    }
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };
  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  // Calculate total action days
  const totalDays = meetingData?.plan_d_actions?.reduce(
    (acc, item) => acc + item.action_days,
    0
  );

  const chartEndDate = new Date(meetingData?.date);
  chartEndDate.setDate(chartEndDate.getDate() + totalDays);
  const formattedChartEndDate = `${chartEndDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${chartEndDate.toLocaleString("default", {
    month: "long",
  })}-${chartEndDate.getFullYear()}`;

  const data = meetingData?.plan_d_actions;
  // Sort data by order
  const sortedData = data?.sort((a, b) => a.order - b.order);

  useEffect(() => {
    if (!sortedData) {
      return;
    }

    const svgWidth = 1000;
    const svgHeight = 500;
    const margin = { top: 70, right: 20, bottom: 0, left: 50 };
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    const topBarMargin = 30; // Add margin for the top of the bars

    // Calculate total action days
    const totalActionDays = sortedData?.reduce(
      (acc, item) => acc + item.action_days,
      0
    );

    // Add an extra day to the totalActionDays
    const extendedTotalActionDays = totalActionDays + 1;

    // Define a color scale for the orders
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Clear any existing svg
    d3.select("#gantt-chart").selectAll("*").remove();

    const svg = d3
      .select("#gantt-chart")
      .append("svg")
      .attr("width", svgWidth)
      .attr("height", svgHeight);

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3
      .scaleLinear()
      .domain([1, extendedTotalActionDays])
      .range([0, width]);

    const yScale = d3
      .scaleBand()
      .domain(sortedData?.map((d) => d.action + d.startDate))
      .range([height, 0]);

    const oddTickValues = Array.from(
      { length: extendedTotalActionDays },
      (_, i) => i + 1
    ).filter((d) => d % 2 !== 0);

    const xAxis = d3
      .axisTop(xScale)
      .tickSize(-height)
      .tickFormat((d) => `D${d}`)
      .ticks(sortedData.length <= 3 ? extendedTotalActionDays : undefined)
      .tickValues(oddTickValues);

    const calculateDx = (d) => {
      if (d >= 11) {
        return "-3em";
      } else {
        return "-1.7em";
      }
    };

    chart
      .append("g")
      .call(xAxis)
      .selectAll("text")
      .style("font-size", "12px")
      .style("color", "#B9B9C7")
      .style("text-anchor", "middle")
      .attr("dx", (d) => calculateDx(d))
      .attr("dy", "-2.5em");

    chart.selectAll(".domain").remove();

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background", "#fff")
      .style("border", "1px solid #ccc")
      .style("padding", "10px")
      .style("display", "none");

    let cumulativeDays = {};
    let nextAvailableDay = 1;
    const ordersWithOverlap = new Set();

    sortedData?.forEach((d) => {
      if (!cumulativeDays[d.order]) {
        cumulativeDays[d.order] = nextAvailableDay;
      } else {
        ordersWithOverlap.add(d.order);
      }
      nextAvailableDay = Math.max(
        nextAvailableDay,
        cumulativeDays[d.order] + d.action_days
      );
    });

    chart
      .selectAll(".bar")
      .data(sortedData)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(cumulativeDays[d.order]))
      .attr(
        "y",
        (d, i) =>
          yScale(d.action + d.startDate) +
          (ordersWithOverlap.has(d.order) ? 10 * (i % 2) : 0) +
          topBarMargin
      )
      .attr("width", (d) => xScale(d.action_days) - xScale(0))
      .attr(
        "height",
        sortedData?.length > 3
          ? yScale.bandwidth() / 2
          : sortedData.length === 1
          ? yScale.bandwidth() / 13
          : sortedData.length === 3
          ? yScale.bandwidth() / 4
          : yScale.bandwidth() / 8
      )
      .attr("fill", (d) => colorScale(d.order))
      .attr(
        "rx",
        sortedData?.length >= 3
          ? yScale.bandwidth() / 5
          : yScale.bandwidth() / 20
      )
      .attr(
        "ry",
        sortedData?.length >= 3
          ? yScale.bandwidth() / 5
          : yScale.bandwidth() / 20
      );

    svg
      .append("defs")
      .append("clipPath")
      .attr("id", "clip-path-rounded")
      .append("rect")
      .attr("width", 25)
      .attr("height", 25)
      .attr("rx", 5)
      .attr("ry", 5);

    // chart
    //   .selectAll(".bar")
    //   .data(sortedData)
    //   .enter()
    //   .append("image")
    //   .attr("x", (d) =>
    //     sortedData.length === 1
    //       ? xScale(cumulativeDays[d.order]) + 5
    //       : xScale(cumulativeDays[d.order]) - 3
    //   )
    //   .attr("y", (d, i) =>
    //     yScale(d.action + d.startDate) + (ordersWithOverlap.has(d.order) ? 10 * (i % 2) : 0) + yScale.bandwidth() / 5.9 - 12
    //   )
    //   .attr("width", 30)
    //   // .attr("width", (d) => xScale(d.action_days) - xScale(0))

    //   .attr("height", 30)
    //   .attr("xlink:href", (d) => `${d.assigned_to_image.startsWith("users") ?  Assets_URL + '/'+ d.assigned_to_image : d.assigned_to_image}`)
    //   .attr("clip-path", "circle(50%)");

    chart
      .selectAll(".bar")
      .data(sortedData)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(cumulativeDays[d.order]) + 35)
      .attr(
        "y",
        (d, i) =>
          yScale(d.action + d.startDate) +
          (ordersWithOverlap.has(d.order) ? 10 * (i % 2) : 0) +
          yScale.bandwidth() / 4
      )
      .attr("dy", ".35em")
      .style("fill", "white")
      .style("font-size", "12px")
      .style("text-anchor", "start");

    chart.selectAll(".tick text").attr("x", function () {
      return (this.getBoundingClientRect().width / 2) * +6;
    });

    sortedData?.forEach((d) => {
      cumulativeDays[d.order] += d.action_days;
    });

    // Step 1: Filter out duplicate action names
    const uniqueActions = sortedData.filter(
      (action, index, self) =>
        index === self.findIndex((t) => t.action === action.action)
    );
    const textContainer = d3.select("#text-container").html(""); // Clear any existing content

    uniqueActions?.forEach((d, i) => {
      const textGroup = textContainer
        .append("div")
        .attr("class", "text-group")
        .style("display", "flex")
        .style("align-items", "center")
        .style("gap", `10px`)
        .style("color", colorScale(d.order))
        .style("justify-content", "space-between")
        .style("font-weight", "700")
        .style("font-size", "14px")
        .style(
          "height",
          `${
            sortedData?.length >= 3
              ? `${yScale.bandwidth()}px`
              : `${yScale.bandwidth()}px`
          }`
        );
      textGroup
        .append("img")
        .attr(
          "src",
          d.assigned_to_image?.startsWith("users")
            ? Assets_URL + "/" + d.assigned_to_image
            : d.assigned_to_image
        )
        .attr("alt", "Assigned to image")
        .style("height", "40px")
        .style("width", "40px")
        .style("border-radius", "50px");
      textGroup
        .append("p")
        .text(
          `${d.action_days} ${t("actions.day")}${d.action_days > 1 ? "s" : ""}`
        );
      textGroup.append("p").text(d.action);
    });
  }, [sortedData]);

  const steps = meetingData?.steps || [];

  const lastStep = steps[steps.length - 1];
  const lastStepTime = lastStep?.end_time;
  const lastStepFormattedTime = new Date(
    `1970-01-01T${lastStepTime}`
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const calculateTotalDays = (steps) => {
    if (!steps) {
      return;
    }
    return steps?.reduce((total, step) => {
      return total + step.count2;
    }, 0);
  };
  const addDaysToDate = (date, days) => {
    if (!date || !days) {
      return;
    }
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const formatDateInFrench = (dateString) => {
    if (!dateString) {
      return;
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // const startDate = new Date(meetingData?.date);
  const startDate = new Date(`${meetingData?.date}T${meetingData?.start_time}`);

  const totalDays1 = calculateTotalDays(meetingData?.steps);

  let endDateForHour;
  if (meetingData?.type === "Action") {
    // If the meeting type is "Active", treat totalDays as total days
    endDateForHour = new Date(
      startDate.getTime() + totalDays1 * 24 * 60 * 60 * 1000
    );
  } else if (meetingData?.type === "Task") {
    // If the meeting type is "Task", treat totalDays as total hours
    endDateForHour = new Date(
      startDate.getTime() + totalDays1 * 60 * 60 * 1000
    );
  } else if (meetingData?.type === "Quiz") {
    // If the meeting type is "Quiz", treat totalDays as total seconds
    endDateForHour = new Date(startDate.getTime() + totalDays1 * 1000);
  } else {
    // Otherwise, treat totalDays as total minutes
    endDateForHour = new Date(startDate.getTime() + totalDays1 * 60 * 1000);
  }

  const formattedEndDateInHours = formatDateInFrench(endDateForHour);

  const parseTimeTaken = (timeTaken) => {
    if (!timeTaken) {
      return;
    }
    let totalSeconds = 0;

    const parts = timeTaken.split(" - ");

    parts.forEach((part) => {
      const [value, unit] = part?.split(" ");

      switch (unit) {
        case "days":
        case "day":
          totalSeconds += parseInt(value, 10) * 86400; // 1 day = 86400 seconds
          break;
        case "hours":
        case "hour":
          totalSeconds += parseInt(value, 10) * 3600;
          break;
        case "mins":
        case "min":
          totalSeconds += parseInt(value, 10) * 60;
          break;
        case "secs":
        case "sec":
          totalSeconds += parseInt(value, 10);
          break;
        default:
          totalSeconds += parseInt(value, 10) * 60;
          break;
      }
    });

    return totalSeconds;
  };

  const endDate = addDaysToDate(startDate, totalDays1);
  const formattedEndDate = formatDateInFrench(endDate);

  const calculateRealEndTime = (steps) => {
    if (steps?.length === 0 || !steps) return null;

    const convertToSeconds = (timeTaken) => {
      if (!timeTaken) return 0;
      let totalSeconds = 0;
      const parts = timeTaken.split(" - ");
      parts.forEach((part) => {
        if (part.includes("day")) {
          totalSeconds += parseInt(part) * 86400; // 1 day = 86400 seconds
        } else if (part.includes("hour")) {
          totalSeconds += parseInt(part) * 3600; // 1 hour = 3600 seconds
        } else if (part.includes("min")) {
          totalSeconds += parseInt(part) * 60; // 1 minute = 60 seconds
        } else if (part.includes("sec")) {
          totalSeconds += parseInt(part); // seconds
        }
      });
      return totalSeconds;
    };

    const totalTimeInSeconds = steps.reduce(
      (acc, step) => acc + convertToSeconds(step.time_taken),
      0
    );

    const firstStepCurrentTime = steps[0].current_time
      ? moment(steps[0]?.current_time, "HH:mm:ss")
      : moment(meeting?.starts_at, "HH:mm:ss");
    if (!firstStepCurrentTime) return null;

    const realEndTime = firstStepCurrentTime
      .add(totalTimeInSeconds, "seconds")
      .format("HH:mm:ss");

    return realEndTime;
  };

  const convertTimeToMinutes = (timeTaken) => {
    if (!timeTaken) return;
    const parts = timeTaken.split(" - ");
    let totalMinutes = 0;

    parts.forEach((part) => {
      const [amount, unit] = part.split(" ");
      const value = parseInt(amount, 10);
      switch (unit) {
        case "days":
        case "day":
          totalMinutes += value * 24 * 60;
          break;
        case "hours":
        case "hour":
          totalMinutes += value * 60;
          break;
        case "minutes":
        case "min":
        case "mins":
          totalMinutes += value;
          break;
        case "seconds":
        case "sec":
        case "secs":
          totalMinutes += value / 60;
          break;
        default:
          break;
      }
    });

    return totalMinutes;
  };

  // Function to calculate end time
  const calculateLastStepEndTime = (meeting) => {
    if (
      !meeting ||
      !meeting.start_time ||
      !meeting.steps ||
      meeting.steps.length === 0
    ) {
      return "";
    }

    // Convert start time to a moment object
    let endTime = moment(
      `${meeting.date} ${meeting.start_time}`,
      "YYYY-MM-DD HH:mm:ss"
    );

    // Add each step's time_taken to the endTime
    meeting.steps.forEach((step) => {
      const stepMinutes = convertTimeToMinutes(step.time_taken);
      endTime = endTime.add(stepMinutes, "minutes");
    });

    // Format endTime to 12-hour format with leading zeros and AM/PM
    return endTime.format("hh:mm A");
  };

  const filteredActions = meetingData?.plan_d_actions?.reduce((acc, item) => {
    // Find if an entry with the same action, order, and action_days already exists
    const existing = acc.find(
      (obj) =>
        obj.action === item.action &&
        obj.order === item.order &&
        obj.action_days === item.action_days
    );

    // If it exists, we skip adding the current item to the accumulator
    if (!existing) {
      acc.push(item);
    }

    return acc;
  }, []);

  const guideEmails = new Set(meetingData?.guides?.map((guide) => guide.email));

  //Participant
  const [showProfile, setShowProfile] = useState(false);
  const handleShow = () => {
    setShowProfile(true);
  };
  const hideShow = () => {
    setShowProfile(false);
  };

  //Host
  const [showHostProfile, setShowHostProfile] = useState(false);
  const handleHostShow = () => {
    setShowHostProfile(true);
  };
  const hideHostShow = () => {
    setShowHostProfile(false);
  };

  //Closed Report Participant
  const [uuid, setUuid] = useState(null);
  const [show, setShow] = useState(false);
  const handleProfile = (userId) => {
    setShow(true);
    setUuid(userId);
  };
  const handleHide = () => {
    setShow(false);
  };

  //Closed Report Host
  const [hostUuid, setHostUuid] = useState(null);
  const [showHost, setShowHost] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const handleHostProfile = (userId) => {
    setShowHost(true);
    setHostUuid(userId);
  };
  const handleHostHide = () => {
    setShowHost(false);
  };

  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgot, setShowForgot] = useState(false);

  const handleShowSignIn = () => {
    setShowSignUp(false);
    setShowSignIn(true);
  };

  const handleShowSignUp = () => {
    setShowSignIn(false);
    setShowSignUp(true);
  };

  const handleShowForgot = () => {
    setShowSignIn(false);
    setShowSignUp(false);
    setShowForgot(true);
  };

  const handleCloseSignIn = () => setShowSignIn(false);
  const handleCloseSignUp = () => setShowSignUp(false);
  const handleCloseForgot = () => setShowForgot(false);

  const { toggle } = useSidebarContext();
  const handleClose = () => {
    console.log("handleClose");
    toggle(true);
    setIsModalOpen(false);
    // onClose();
  };

  const handleLogin = () => {
    navigate("/login", {
      state: {
        fromInvitePage: true,
        invitePageURL: window.location.pathname + window.location.search,
      },
    });
  };

  const [password, setPassword] = useState(null);
  const checkPassword = async () => {
    const payload = {
      password: password,
      meeting_id: meetId,
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/check-meeting-password`,
        payload
      );
      console.log("password checking response", response);
      if (response?.status) {
        const meeting = response?.data?.data;
        toggle(true);
        handleClose();
        setMeetingData(meeting);
        if (meeting.status === "closed") {
          getMeetingByID(meeting.objective, meeting.user.id);
        } else if (["active", "in_progress"].includes(meeting.status)) {
          getActiveMeetingByID(meeting.objective, meeting.user.id);
        }
        setSelectedMeeting({
          value: meeting?.id,
          label: meeting?.title,
        });
        setIsModalOpen(false);
        // getRefreshMeeting()
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Incorrect password");
    }
  };

  const [view, setView] = useState("graph");
  const [open1, setOpen1] = useState(false);
  const handleToggle = () => {
    setView(view === "list" ? "graph" : "list");
    setOpen1(!open1);
  };

  const [activeEventKey, setActiveEventKey] = useState(null);

  const copyToClipboard = (nick_name) => {
    if (!nick_name) return;
    const visitingCardUrl =
      window.location.origin + "/heroes" + "/" + nick_name;

    navigator.clipboard.writeText(visitingCardUrl).then(
      () => {},
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
    // navigate("/" + "heros" + "/" + user?.uuid);
    window.open("/" + "heroes" + "/" + nick_name, "_blank");
  };

  const viewPresentation = (data) => {
    setSelectedMeeting({
      value: data?.id,
      label: data?.title,
    });
    const url = `/destination/${data?.unique_id}/${data?.id}`;
    if (data?.status === "closed" || data?.status === "abort") {
      // window.location.href = url;
      navigate(url);
    } else {
      navigate(url);
    }
  };

  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    if (destinationDate?.meetings) {
      // Set all keys open initially once destinationDate is available
      setOpenKeys(destinationDate.meetings.map((_, index) => index.toString()));
    }
  }, [destinationDate]);

  const handleToggleAccoridon = (key) => {
    setOpenKeys(
      (prevKeys) =>
        prevKeys.includes(key)
          ? prevKeys.filter((k) => k !== key) // Close if open
          : [...prevKeys, key] // Open if closed
    );
  };

  const [activeLink, setActiveLink] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  console.log("selectedMeeting", selectedMeeting);
  const { updateLanguage } = useDraftMeetings();

  const handleChangeLanguage = (lang) => {
    console.log("lang..", lang);
    i18n.changeLanguage(lang);
    console.log("lang", lang);
    updateLanguage(lang);
  };

  const handleClick = () => {
    setSelectedMeeting(null);
    setIsModalOpen(false);

    const url =
      meetingData?.status === "closed" || meetingData?.status === "in_progress"
        ? `/destination/${meetingData?.destination_unique_id}--es/${meetingData?.destination_id}`
        : meetingData?.status === "active" ||
          meetingData?.status === "in_progress"
        ? `/destination/${meetingData?.destination_unique_id}--es/${meetingData?.destination_id}`
        : `/destination/${destinationDate?.uuid}--es/${destinationDate?.id}`;

    navigate(url);
  };
  useEffect(() => {
    const path = location.pathname;

    const destinationPattern =
      /^\/destination\/([a-f0-9\-]+)--es\/([a-f0-9\-]+)$/;
    if (destinationPattern.test(path)) {
      setActiveLink("/destination");
    } else {
      setActiveLink(path);
    }
  }, [location]);

  const renderIcon = () => {
    const type = meetingData?.type;
    return typeIcons[type] || null;
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    position: isSticky ? "fixed" : "relative",
    top: isSticky ? "0" : "auto",
    zIndex: 10000000000,
    boxShadow: isSticky ? "0 4px 2px -2px white" : "none",
  };

  // Prepare options for react-select
  const options = destinationDate?.meetings
    ?.filter((moment) => moment.status !== "abort")
    ?.map((item, index) => ({
      value: item.id,
      label: `${index + 1}. ${item.title}`, // Format as '1. title'
    }));

  // Handle change event
  const handleChange = (selectedOption) => {
    setSelectedMeeting(selectedOption);

    if (selectedOption) {
      const selectedItem = destinationDate?.meetings?.find(
        (item) => item.id === selectedOption.value
      );
      console.log("selectedItem->graph", selectedItem);

      if (selectedItem) {
        setUniqueId((prev) => !prev); // Assuming setUniqueId is defined elsewhere
        viewPresentation(selectedItem);
      }
    }
  };

  // Map the meetings to options
  const optionsMeetings = allMeetings
    ?.filter((item) => item.id.toString() !== meetId.toString())
    ?.map((item, index) => ({
      value: item.id,
      label: `${index + 1}. ${item.title}`, // Format as '1. title'
    }));

  const handleChangeMeetings = (selectedOption) => {
    setSelectedMeeting(selectedOption);

    console.log("selectedId", selectedOption.value);

    const selectedItem = allMeetings?.find(
      (item) => item.id === selectedOption.value
    );

    console.log("selectedItem", selectedItem);

    if (selectedItem) {
      applyHeaderFilter(selectedItem);
    }
  };

  const [dropdownWidth, setDropdownWidth] = useState(250); // Initial width

  const calculateDropdownWidth = (options) => {
    // Calculate width based on the longest label in the options
    const maxLabelLength = options.reduce((maxLength, option) => {
      const optionLength = option.label.length;
      return optionLength > maxLength ? optionLength : maxLength;
    }, 0);
    // You can adjust the multiplier to add padding or margins if needed
    setDropdownWidth(maxLabelLength * 8); // Adjusting width dynamically based on text length
  };

  useEffect(() => {
    if (optionsMeetings && optionsMeetings.length > 0) {
      calculateDropdownWidth(optionsMeetings);
    }
  }, [optionsMeetings]);

  const specialMeetingEndTime = (startTime, steps) => {
    if (!startTime || !steps) return 0;
    // Convert start_time to a moment object
    let meetingStartTime = moment(startTime, "HH:mm:ss");

    steps.forEach((step) => {
      const { count2, time_unit } = step;

      if (time_unit === "seconds") {
        meetingStartTime.add(count2, "seconds");
      } else if (time_unit === "minutes") {
        meetingStartTime.add(count2, "minutes");
      } else if (time_unit === "hours") {
        meetingStartTime.add(count2, "hours");
      } else if (time_unit === "days") {
        meetingStartTime.add(count2, "days");
      }
    });

    // Return the updated time in 12-hour format
    return meetingStartTime.format("HH[h]mm");
  };
  const newTime = specialMeetingEndTime(
    meetingData?.start_time,
    meetingData?.steps
  );

  return (
    <>
      {isLoading ? (
        <>
          <Spinner
            animation="border"
            role="status"
            className="center-spinner"
          ></Spinner>
        </>
      ) : (
        <>
          <div className="home-header">
            <nav
              id="navbar"
              className="container-fluid navbar bg-white navbar-expand-lg py-3"
              style={navbarStyle}
            >
              <div className="container">
                <Link to="#" className="navbar-brand">
                  <img
                    src="/Assets/landing/logo.png"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <button
                  className="navbar-toggler bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse "
                  id="navbarSupportedContent"
                >
                  <div className="navbar-nav ms-auto d-flex right-dropdown-profile w-100">
                    <ul className="navbar-nav mb-2 mb-lg-0 align-items-center justify-content-between w-100 ">
                      <div className="d-flex align-items-center ms-5">
                        <li
                          className={`nav-item ${
                            activeLink === "/destination" ? "active" : ""
                          }`}
                        >
                          <span
                            className="nav-link pb-1"
                            onClick={handleClick}
                            style={{ cursor: "pointer" }}
                          >
                            {t("navbar.home")}
                          </span>
                        </li>

                        <li
                          className={`nav-item dropdown ${
                            selectedMeeting ? "active" : ""
                          }`} // Add 'active' class if selectedMeeting exists
                        >
                          {!uniqueId ? (
                            <>
                              <Select
                                options={optionsMeetings}
                                value={
                                  selectedMeeting
                                    ? {
                                        value: selectedMeeting.value,
                                        label: selectedMeeting.label,
                                      }
                                    : null
                                } // Set selected value
                                onChange={handleChangeMeetings} // Handle change
                                placeholder="Moments"
                                isSearchable={false}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    width: "290px",
                                    border: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                    borderBottom: selectedMeeting
                                      ? "3px solid rgba(255, 186, 0, 1)"
                                      : "none",
                                  }),
                                  menu: (base) => ({
                                    ...base,
                                    width: "290px",
                                  }),
                                  menuList: (base) => ({
                                    ...base,
                                    width: "290px",
                                  }),
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <Select
                                options={options}
                                onChange={handleChange}
                                placeholder="Moments"
                                value={
                                  selectedMeeting
                                    ? {
                                        value: selectedMeeting.value,
                                        label: selectedMeeting.label,
                                      }
                                    : null
                                } // Set selected value
                                // isSearchable
                                // className="nav-link pb-1"
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    width: "290px",
                                    border: "none",
                                    boxShadow: "none",
                                    cursor: "pointer",
                                    borderBottom: selectedMeeting
                                      ? "3px solid rgba(255, 186, 0, 1)"
                                      : "none", // Add border-bottom if an option is selected
                                  }),
                                  menu: (base) => ({
                                    ...base,
                                    width: "290px",
                                  }),
                                  menuList: (base) => ({
                                    ...base,
                                    width: "290px",
                                  }),
                                }}
                              />
                            </>
                          )}
                        </li>
                      </div>

                      <div>
                        <li className="nav-item">
                          <div className="mt-1 swtich">
                            <label
                              className="form-check-label mr-2"
                              htmlFor="languageSwitch"
                              style={{
                                fontFamily: "Inter",
                                fontSize: "13px",
                                lineHeight: "15.73px",
                                textAlign: "left",
                                color: "#4C4C4C",
                                fontWeight:
                                  i18n.language === "fr" ? 200 : "bold",
                              }}
                            >
                              {/* {i18n.language === "en" && "En"} */}
                              En
                            </label>

                            <div
                              className="form-check form-switch p-0"
                              style={{ minHeight: "0px" }}
                            >
                              <input
                                className="form-check-input m-0"
                                type="checkbox"
                                id="languageSwitch"
                                role="switch"
                                checked={i18n.language === "fr"} // Set the checked state based on the current language
                                onChange={() =>
                                  handleChangeLanguage(
                                    i18n.language === "fr" ? "en" : "fr"
                                  )
                                }
                              />
                            </div>
                            <label
                              className="form-check-label"
                              htmlFor="languageSwitch"
                              style={{
                                fontFamily: "Inter",
                                fontSize: "13px",
                                lineHeight: "15.73px",
                                textAlign: "left",
                                color: "#4C4C4C",
                                fontWeight:
                                  i18n.language === "en" ? 200 : "bold",
                              }}
                            >
                              {/* {i18n.language === "fr" && "Fr"} */}
                              Fr
                            </label>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          {!uniqueId ? (
            <>
              {/* feedbact location */}
              {meetingData?.status === "closed" ? (
                <>
                  <div className={showProgressBar ? "blur-container" : ""}>
                    <div
                      className="email-invite report w-100"
                      style={{ position: fromMeeting ? "static" : "relative" }}
                    >
                      {!fromMeeting && (
                        <>
                          {meetingData?.destination_banner ? (
                            <img
                              src={meetingData?.destination_banner}
                              alt="Destination Banner"
                              style={{
                                width: "100%",
                                height: "338px",
                                objectFit: "cover",
                                filter: "opacity(0.5)",
                              }}
                            />
                          ) : (
                            <div className="gradient-header">
                              <svg
                                width="100%"
                                height="338"
                                viewBox="0 0 1453 338"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g filter="url(#filter0_f_902_7997)">
                                  <rect
                                    width="100%"
                                    height="271"
                                    fill="url(#paint0_linear_902_7997)"
                                  />
                                </g>
                                <defs>
                                  <filter
                                    id="filter0_f_902_7997"
                                    x="-66.5"
                                    y="-66.5"
                                    width="1573"
                                    height="404"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                  >
                                    <feFloo
                                      floodOpacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      stdDeviation="33.25"
                                      result="effect1_foregroundBlur_902_7997"
                                    />
                                  </filter>
                                  <linearGradient
                                    id="paint0_linear_902_7997"
                                    x1="856"
                                    y1="281.934"
                                    x2="863.131"
                                    y2="-138.913"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#76C3EE" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </div>
                          )}
                        </>
                      )}
                      <div className="content">
                        {!fromMeeting && (
                          <div
                            className="row report-row"
                            style={{ height: "35vh" }}
                          >
                            <div className="col-md-10 d-flex flex-column">
                              <h5 className="content-heading fw-bold">
                                {meetingData?.objective}
                              </h5>
                            </div>

                            <div className="col-md-2">
                              <div className="position-relative">
                                {/* <HostCard data={meeting?.user} /> */}
                                <Card className="mt-3 host-card tektime-dev-team">
                                  <Card.Body>
                                    <div className="d-flex justify-content-center">
                                      <div className="profile-logo">
                                        <Card.Img
                                          className="avatar-img"
                                          src={
                                            meetingData?.user?.enterprise?.logo
                                              ? Assets_URL +
                                                "/" +
                                                meetingData?.user?.enterprise
                                                  ?.logo
                                              : "/Assets/logo2.png"
                                          }
                                        />
                                      </div>
                                    </div>

                                    <Card.Title className="text-center mt-3 card-heading">
                                      {meetingData?.user?.enterprise?.name}
                                    </Card.Title>
                                  </Card.Body>
                                </Card>
                              </div>
                            </div>

                            {/* ------------------------------------------------ HOST */}
                          </div>
                        )}
                        <div className="row mt-5 mt-lg-0 pt-5 pt-md-0 report-row">
                          <div
                            className={`col-md-6 ${fromMeeting ? "w-100" : ""}`}
                          >
                            <div
                              className="dropdown-container"
                              ref={dropdownRef}
                            >
                              <h5 className="content-heading-title d-flex">
                                <div style={{ cursor: "pointer" }}>
                                  {renderIcon()}
                                </div>
                                &nbsp;
                                {selectedTitleOrder && (
                                  <>{selectedTitleOrder}&nbsp;</>
                                )}
                                {meetingData.title}
                                {allMeetings?.length > 1 ? (
                                  <span>
                                    <MdKeyboardArrowDown
                                      size={30}
                                      onClick={toggleDropdown}
                                      style={{
                                        cursor: "pointer",
                                        // marginLeft: ".6rem",
                                        marginBottom: "6px",
                                      }}
                                    />
                                  </span>
                                ) : null}
                              </h5>
                              {dropdownVisible && (
                                <div className="dropdown-content-filter">
                                  <div className="dropdown-section">
                                    {allMeetings
                                      // ?.filter((item) => item.id !== meetId)
                                      ?.filter(
                                        (item) =>
                                          item.id.toString() !==
                                          meetId.toString()
                                      )
                                      ?.map((item, index) => {
                                        return (
                                          <>
                                            <div
                                              key={index}
                                              onClick={() => applyFilter(item)}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <b>{item.title_order}</b>.&nbsp;{" "}
                                              {item.title}
                                            </div>
                                          </>
                                        );
                                      })}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-2 content-body">
                              <div className="d-flex align-items-center gap-2 ps-1">
                                <img src="/Assets/invite-date.svg" />

                                {meetingData?.type === "Action" ||
                                meetingData?.type === "Newsletter" ? (
                                  <>
                                    <span className="fw-bold formate-date">
                                      {formattedDate}
                                      &nbsp; -
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {formattedEndDateInHours}
                                    </span>
                                  </>
                                ) : meetingData?.type === "Special" ||
                                  meetingData?.type === "Law" ? (
                                  <>
                                    <span className="fw-bold formate-date">
                                      {formattedDate}
                                      &nbsp; {t("at")}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {convertTo12HourFormat(
                                        meetingData?.start_time,
                                        meetingData?.steps
                                      )}{" "}
                                      -{" "}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {estimateDate}
                                      &nbsp; {t("at")}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {newTime}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="fw-bold formate-date">
                                      {formattedDate}
                                      &nbsp; {t("at")}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {convertTo12HourFormat(
                                        meetingData?.starts_at,
                                        meetingData?.steps
                                      )}{" "}
                                      -{" "}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {formattedEndDateInHours}
                                      &nbsp; {t("at")}
                                    </span>
                                    <span className="fw-bold formate-date">
                                      {estimateTime}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            {/* <div className="paragraph-parent">
                            <h3 className="moment-summary">Moments Summary</h3>
                            <span className="paragraph">{meetingData?.description}</span>
                          </div> */}
                            <div className="ps-2 ps-md-4 ps-lg-0">
                              {meetingData?.location &&
                                meetingData?.location !== "None" && (
                                  <p className="mt-2 d-flex gap-2 justify-content-start ps-0 fw-bold d-flex align-items-center">
                                    <span>
                                      <svg
                                        width="25px"
                                        height="25px"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z"
                                            fill="white"
                                          ></path>{" "}
                                          <path
                                            d="M5 23.5601C5 24.3557 5.64998 25.0001 6.45081 25.0001H6.47166C5.65857 25.0001 5 24.3557 5 23.5601Z"
                                            fill="#FBBC05"
                                          ></path>{" "}
                                          <path
                                            d="M17.4678 12.4V16.1596L22.5364 12.0712V8.43999C22.5364 7.6444 21.8864 7 21.0856 7H10.1045L10.0947 12.4H17.4678Z"
                                            fill="#FBBC05"
                                          ></path>{" "}
                                          <path
                                            d="M17.4671 19.9207H10.0818L10.0732 25.0003H21.085C21.887 25.0003 22.5358 24.3559 22.5358 23.5603V20.2819L17.4671 16.1611V19.9207Z"
                                            fill="#34A853"
                                          ></path>{" "}
                                          <path
                                            d="M10.1042 7L5 12.4H10.0956L10.1042 7Z"
                                            fill="#EA4335"
                                          ></path>{" "}
                                          <path
                                            d="M5 19.9204V23.56C5 24.3556 5.65857 25 6.47166 25H10.0736L10.0821 19.9204H5Z"
                                            fill="#1967D2"
                                          ></path>{" "}
                                          <path
                                            d="M10.0956 12.3999H5V19.9203H10.0821L10.0956 12.3999Z"
                                            fill="#4285F4"
                                          ></path>{" "}
                                          <path
                                            d="M26.9926 22.2796V9.9197C26.7068 8.27931 24.9077 10.1597 24.9077 10.1597L22.5371 12.0713V20.2804L25.9305 23.0392C27.1557 23.2 26.9926 22.2796 26.9926 22.2796Z"
                                            fill="#34A853"
                                          ></path>{" "}
                                          <path
                                            d="M17.4678 16.1594L22.5377 20.2814V12.0723L17.4678 16.1594Z"
                                            fill="#188038"
                                          ></path>{" "}
                                        </g>
                                      </svg>
                                    </span>
                                    <span className="text-primary">
                                      <a
                                        href={`${meetingData?.meet_link}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary text-decoration-none"
                                      >
                                        Join meeting <FaArrowRight />
                                      </a>
                                    </span>
                                  </p>
                                )}
                              <div className="ps-0">
                                {meetingData?.street ||
                                meetingData?.city ||
                                meetingData?.country ||
                                meetingData?.zip ? (
                                  <p className="d-flex gap-2 align-items-center justify-content-start ps-0 ms-0 mt-2">
                                    <FaLocationDot size={25} />
                                    <span>
                                      {meetingData.street &&
                                        `${meetingData.street}, `}
                                      {meetingData.city &&
                                        `${meetingData.city}, `}
                                      {meetingData.country &&
                                        `${meetingData.country}, `}
                                      {meetingData.zip && `${meetingData.zip}`}
                                    </span>
                                  </p>
                                ) : null}
                              </div>
                              {meetingData?.room_details ? (
                                <div>
                                  <p className="d-flex gap-2 mt-2 justify-content-start align-items-top ps-0">
                                    <BsPersonWorkspace size={25} />
                                    <p className="m-0">
                                      {meetingData?.room_details &&
                                        meetingData?.room_details}
                                    </p>
                                  </p>
                                </div>
                              ) : null}
                              {meetingData?.phone ? ( // Check if the phone field exists
                                <div>
                                  <p className="d-flex gap-2 align-items-center justify-content-start ps-0 mt-2">
                                    <span>
                                      <FaPhoneAlt size={25} />
                                    </span>
                                    <a
                                      href={`tel:${meetingData.phone}`}
                                      className="text-decoration-none"
                                    >
                                      <span className="">
                                        {meetingData.phone}
                                      </span>{" "}
                                      {/* Display the phone number */}
                                    </a>
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row px-3 px-lg-0 report-row">
                          {show ? (
                            <div
                              style={{
                                background: "white",
                                padding: "29px 13px",
                              }}
                            >
                              <div className="paragraph-parent">
                                <h3 className="moment-summary">
                                  <IoArrowBackSharp
                                    onClick={handleHide}
                                    size={25}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "1rem",
                                    }}
                                  />
                                  {t("momentsSummary")}
                                </h3>
                              </div>
                              <ParticipantCardProfile
                                userId={uuid}
                                handleHide={handleHide}
                                isUser={isUser}
                              />
                            </div>
                          ) : (
                            <>
                              <div className="col-md-8">
                                <div className="paragraph-parent">
                                  <h3 className="moment-summary">
                                    {t("momentsSummary")}
                                  </h3>
                                  <p className="paragraph paragraph-images">
                                    {/* {meetingData?.description} */}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: meetingData?.description || "",
                                      }}
                                    />
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div style={{ marginTop: "6rem" }}>
                                  <span className="participant-heading">
                                    Guide
                                  </span>
                                  {meetingData?.type === "Newsletter" && (
                                    <Card className="mt-4 participant-card">
                                      <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center gap-3">
                                          <div className="profile-logo">
                                            <Card.Img
                                              className="user-img"
                                              src={
                                                meetingData?.user?.image?.startsWith(
                                                  "users/"
                                                )
                                                  ? Assets_URL +
                                                    "/" +
                                                    meetingData?.user?.image
                                                  : meetingData?.user?.image
                                              }
                                            />
                                            <Card.Img
                                              className="logout-icon"
                                              src="/Assets/Avatar_company.svg"
                                              height="15px"
                                              width="15px"
                                              alt="tektime"
                                            />
                                          </div>

                                          <div className="d-flex flex-column align-items-start">
                                            <Card.Title className="text-center mt-4 card-heading">
                                              {meetingData?.user?.name +
                                                " " +
                                                meetingData?.user?.last_name}
                                            </Card.Title>
                                            <Card.Subtitle className="mb-2 card-subtext">
                                              {meetingData?.user?.post}
                                            </Card.Subtitle>
                                            <Card.Subtitle className="mb-2 card-subtext">
                                              {meetingData?.user?.teams?.map(
                                                (item) => item.name
                                              )}
                                            </Card.Subtitle>
                                            <div
                                              className="visiting-card-link"
                                              onClick={() => {
                                                handleProfile(
                                                  meetingData?.user?.nick_name
                                                );
                                                setIsUser(true);
                                              }}
                                            >
                                              {t("viewVisitingCard")} &nbsp;{" "}
                                              <FaArrowRight />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <svg
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M15.4965 9.99542C18.534 9.99542 20.9965 12.4578 20.9965 15.4954C20.9965 18.5329 18.534 20.9954 15.4965 20.9954C12.4589 20.9954 9.99649 18.5329 9.99649 15.4954C9.99649 12.4578 12.4589 9.99542 15.4965 9.99542ZM10.0187 11.9947C9.37159 13.0051 8.99649 14.2064 8.99649 15.4954C8.99649 17.1257 9.59669 18.6158 10.5882 19.7569C9.79269 19.917 8.92779 19.9964 7.99649 19.9964C4.57549 19.9964 2.05252 18.9243 0.51036 16.7599C0.1784 16.294 0 15.7362 0 15.1641V14.2442C0 13.0021 1.00685 11.9953 2.24887 11.9953L10.0187 11.9947ZM12.85 15.1418C12.6548 14.9465 12.3382 14.9465 12.1429 15.1418C11.9476 15.3371 11.9476 15.6537 12.1429 15.8489L14.1429 17.8489C14.3382 18.0442 14.6548 18.0442 14.85 17.8489L18.85 13.8489C19.0453 13.6537 19.0453 13.3371 18.85 13.1418C18.6548 12.9465 18.3382 12.9465 18.1429 13.1418L14.4965 16.7882L12.85 15.1418ZM7.99649 0C10.7579 0 12.9965 2.23858 12.9965 5C12.9965 7.76143 10.7579 10 7.99649 10C5.23503 10 2.99645 7.76143 2.99645 5C2.99645 2.23858 5.23503 0 7.99649 0Z"
                                              fill="#58985B"
                                            />
                                          </svg>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  )}
                                </div>
                                <div className="">
                                  {meetingData?.type !== "Newsletter" &&
                                    meetingData?.guides
                                      // ?.filter((guide) => guide.isCreator !== 1)
                                      ?.map((guide, index) => (
                                        <Card
                                          key={index}
                                          className="mt-4 participant-card"
                                        >
                                          <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center gap-3">
                                              <div className="profile-logo">
                                                <Card.Img
                                                  className="user-img"
                                                  src={
                                                    guide.participant_image.startsWith(
                                                      "users/"
                                                    )
                                                      ? Assets_URL +
                                                        "/" +
                                                        guide.participant_image
                                                      : guide.participant_image
                                                  }
                                                />
                                                <Card.Img
                                                  className="logout-icon"
                                                  src="/Assets/Avatar_company.svg"
                                                  height="15px"
                                                  width="15px"
                                                  alt="tektime"
                                                />
                                              </div>

                                              <div className="d-flex flex-column align-items-start">
                                                <Card.Title className="text-center mt-4 card-heading">
                                                  {guide?.first_name +
                                                    " " +
                                                    guide?.last_name}
                                                </Card.Title>
                                                <Card.Subtitle className="mb-2 card-subtext">
                                                  {guide?.post}
                                                </Card.Subtitle>
                                                <Card.Subtitle className="mb-2 card-subtext">
                                                  {guide?.team_names
                                                    ?.map((item) => item)
                                                    .join(", ")}
                                                </Card.Subtitle>
                                                {guide?.user_id && (
                                                  <div
                                                    className="visiting-card-link"
                                                    onClick={() => {
                                                      handleProfile(guide?.id);
                                                      setIsUser(false);
                                                    }}
                                                  >
                                                    {t("viewVisitingCard")}{" "}
                                                    &nbsp; <FaArrowRight />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div>
                                              <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M15.4965 9.99542C18.534 9.99542 20.9965 12.4578 20.9965 15.4954C20.9965 18.5329 18.534 20.9954 15.4965 20.9954C12.4589 20.9954 9.99649 18.5329 9.99649 15.4954C9.99649 12.4578 12.4589 9.99542 15.4965 9.99542ZM10.0187 11.9947C9.37159 13.0051 8.99649 14.2064 8.99649 15.4954C8.99649 17.1257 9.59669 18.6158 10.5882 19.7569C9.79269 19.917 8.92779 19.9964 7.99649 19.9964C4.57549 19.9964 2.05252 18.9243 0.51036 16.7599C0.1784 16.294 0 15.7362 0 15.1641V14.2442C0 13.0021 1.00685 11.9953 2.24887 11.9953L10.0187 11.9947ZM12.85 15.1418C12.6548 14.9465 12.3382 14.9465 12.1429 15.1418C11.9476 15.3371 11.9476 15.6537 12.1429 15.8489L14.1429 17.8489C14.3382 18.0442 14.6548 18.0442 14.85 17.8489L18.85 13.8489C19.0453 13.6537 19.0453 13.3371 18.85 13.1418C18.6548 12.9465 18.3382 12.9465 18.1429 13.1418L14.4965 16.7882L12.85 15.1418ZM7.99649 0C10.7579 0 12.9965 2.23858 12.9965 5C12.9965 7.76143 10.7579 10 7.99649 10C5.23503 10 2.99645 7.76143 2.99645 5C2.99645 2.23858 5.23503 0 7.99649 0Z"
                                                  fill="#58985B"
                                                />
                                              </svg>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      ))}
                                </div>

                                {meetingData?.type !== "Newsletter" && (
                                  <>
                                    {meetingData?.participants?.filter(
                                      // (item) => item.isCreator === 0
                                      (item) =>
                                        // item.isCreator === 0 &&
                                        !guideEmails.has(item.email)
                                    )?.length > 0 && (
                                      <div className="mt-4">
                                        <span className="participant-heading">
                                          {t("invite")}
                                        </span>{" "}
                                        <span
                                          style={{
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "18.75px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {"(" +
                                            meetingData?.participants?.filter(
                                              (item) =>
                                                // item.attandance === 0 &&
                                                item.isCreator === 0 &&
                                                !guideEmails.has(item.email)
                                            ).length +
                                            "/" +
                                            meetingData?.participants?.filter(
                                              (item) =>
                                                // item.isCreator === 0 &&
                                                !guideEmails.has(item.email)
                                            ).length +
                                            ")"}
                                        </span>
                                        {meetingData?.participants
                                          ?.filter(
                                            // (item) => item.isCreator === 0
                                            (item) =>
                                              // item.isCreator === 0 &&
                                              !guideEmails.has(item.email)
                                          )
                                          ?.map((item, index) => {
                                            // if (item.isCreator === 1) {
                                            //   return null;
                                            // }
                                            return (
                                              <>
                                                <Card className="mt-4 participant-card">
                                                  <Card.Body className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center gap-3">
                                                      <div className="profile-logo">
                                                        {item.participant_image?.startsWith(
                                                          "users/"
                                                        ) ? (
                                                          <Card.Img
                                                            className="user-img"
                                                            src={`${Assets_URL}/${item?.participant_image}`}
                                                          />
                                                        ) : item.participant_image ? (
                                                          <Card.Img
                                                            className="user-img"
                                                            src={
                                                              item?.participant_image
                                                            }
                                                          />
                                                        ) : (
                                                          <Card.Img
                                                            className="user-img"
                                                            src="/Assets/avatar.jpeg"
                                                          />
                                                        )}
                                                        <Card.Img
                                                          className="logout-icon"
                                                          src="/Assets/Avatar_company.svg"
                                                          height="15px"
                                                          width="15px"
                                                          alt="tektime"
                                                        />
                                                      </div>

                                                      <div className="d-flex flex-column align-items-start">
                                                        <Card.Title className="text-center mt-4 card-heading">
                                                          {item.first_name +
                                                            " " +
                                                            item.last_name}
                                                        </Card.Title>
                                                        <Card.Subtitle className="mb-2 card-subtext">
                                                          {item?.post}
                                                        </Card.Subtitle>
                                                        <Card.Subtitle className="mb-2 card-subtext">
                                                          {item?.team_names
                                                            ?.map(
                                                              (item) => item
                                                            )
                                                            .join(", ")}
                                                        </Card.Subtitle>
                                                        {item?.user_id && (
                                                          <div
                                                            className="visiting-card-link"
                                                            onClick={() =>
                                                              handleProfile(
                                                                item?.id
                                                              )
                                                            }
                                                          >
                                                            {t(
                                                              "viewVisitingCard"
                                                            )}{" "}
                                                            &nbsp;{" "}
                                                            <FaArrowRight />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div>
                                                      {item.attandance == 1 ? (
                                                        <svg
                                                          width="21"
                                                          height="21"
                                                          viewBox="0 0 21 21"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M15.4965 9.99542C18.534 9.99542 20.9965 12.4578 20.9965 15.4954C20.9965 18.5329 18.534 20.9954 15.4965 20.9954C12.4589 20.9954 9.99649 18.5329 9.99649 15.4954C9.99649 12.4578 12.4589 9.99542 15.4965 9.99542ZM10.0187 11.9947C9.37159 13.0051 8.99649 14.2064 8.99649 15.4954C8.99649 17.1257 9.59669 18.6158 10.5882 19.7569C9.79269 19.917 8.92779 19.9964 7.99649 19.9964C4.57549 19.9964 2.05252 18.9243 0.51036 16.7599C0.1784 16.294 0 15.7362 0 15.1641V14.2442C0 13.0021 1.00685 11.9953 2.24887 11.9953L10.0187 11.9947ZM12.85 15.1418C12.6548 14.9465 12.3382 14.9465 12.1429 15.1418C11.9476 15.3371 11.9476 15.6537 12.1429 15.8489L14.1429 17.8489C14.3382 18.0442 14.6548 18.0442 14.85 17.8489L18.85 13.8489C19.0453 13.6537 19.0453 13.3371 18.85 13.1418C18.6548 12.9465 18.3382 12.9465 18.1429 13.1418L14.4965 16.7882L12.85 15.1418ZM7.99649 0C10.7579 0 12.9965 2.23858 12.9965 5C12.9965 7.76143 10.7579 10 7.99649 10C5.23503 10 2.99645 7.76143 2.99645 5C2.99645 2.23858 5.23503 0 7.99649 0Z"
                                                            fill="#58985B"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          width="24"
                                                          height="24"
                                                          viewBox="0 0 24 24"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M17.5004 12.0003C20.5379 12.0003 23.0004 14.4627 23.0004 17.5003C23.0004 20.5378 20.5379 23.0003 17.5004 23.0003C14.4628 23.0003 12.0004 20.5378 12.0004 17.5003C12.0004 14.4627 14.4628 12.0003 17.5004 12.0003ZM12.023 13.999C11.3757 15.0096 11.0004 16.2111 11.0004 17.5003C11.0004 19.144 11.6105 20.6452 12.6166 21.7898C11.8153 21.9316 10.9423 22.0013 10.0004 22.0013C7.11087 22.0013 4.87205 21.3447 3.30918 20.001C2.48056 19.2887 2.00391 18.2503 2.00391 17.1575V16.2502C2.00391 15.0075 3.01127 14.0002 4.25391 14.0002L12.023 13.999ZM20.8096 15.2525L15.2526 20.8095C15.8932 21.2454 16.667 21.5003 17.5004 21.5003C19.7095 21.5003 21.5004 19.7094 21.5004 17.5003C21.5004 16.6669 21.2455 15.8931 20.8096 15.2525ZM17.5004 13.5003C15.2912 13.5003 13.5004 15.2911 13.5004 17.5003C13.5004 18.3336 13.7552 19.1074 14.1912 19.748L19.7481 14.1911C19.1075 13.7551 18.3337 13.5003 17.5004 13.5003ZM10.0004 2.00488C12.7618 2.00488 15.0004 4.24346 15.0004 7.00488C15.0004 9.76631 12.7618 12.0049 10.0004 12.0049C7.23894 12.0049 5.00036 9.76631 5.00036 7.00488C5.00036 4.24346 7.23894 2.00488 10.0004 2.00488Z"
                                                            fill="#ED2F2F"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>
                                                  </Card.Body>
                                                </Card>
                                              </>
                                            );
                                          })}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        {meetingData?.steps?.length > 0 && (
                          <div className="cards-section">
                            <div>
                              <span className="participant-heading">
                                {`${t("meeting.newMeeting.labels.Program")} `}
                              </span>
                              <ReportStepCard
                                data={meetingData?.steps}
                                meeting={meetingData}
                                startTime={formattedTime}
                                users={{
                                  ...meetingData?.user,
                                  firstName: meetingData?.user?.name,
                                  lastName: meetingData?.user?.last_name,
                                  image:
                                    meetingData?.user?.assigned_to_image ||
                                    meetingData?.user?.image ||
                                    "/Assets/avatar.jpeg",
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {meetingData.step_decisions &&
                          meetingData.step_decisions.some(
                            (decision) => decision !== null
                          ) && (
                            <div className="decision-section px-3 px-lg-0">
                              <div style={{ marginTop: "2rem" }}>
                                <span className="participant-heading">
                                  {`${t(
                                    "meeting.newMeeting.labels.decisions"
                                  )} `}
                                </span>
                                <DecisionCard
                                  data={meetingData?.step_decisions}
                                  meeting={meetingData}
                                />
                              </div>
                            </div>
                          )}
                        {meetingData?.plan_d_actions?.length > 0 && (
                          <div className="chart-section mx-0 px-3 px-lg-0">
                            <div style={{ margin: "5rem 0 5rem 0" }}>
                              <span className="participant-heading">
                                {`${t("Strategy")} `}
                              </span>
                            </div>

                            <div className="col-md-12">
                              <Table bordered className="action-table">
                                <thead>
                                  <tr className="table-row">
                                    {/* <th className="table-row-head"></th> */}
                                    <th
                                      className="table-row-head"
                                      style={{
                                        paddingLeft: "38px",
                                      }}
                                    >
                                      {t("tasks")}
                                    </th>

                                    <th className="table-row-head text-center">
                                      {t("duration")}
                                      {/* &nbsp; ({t("actions.days")}) */}
                                    </th>
                                    <th className="table-row-head text-center">
                                      {t("participant")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredActions?.map((user, index) => (
                                    <tr className="table-data" key={user?.id}>
                                      {/* <td
                                style={{
                                  paddingLeft: "1rem",
                                  textAlign: "center",
                                }}
                                className="table-data-row"
                              >
                                <label className="custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={!!selectedUsers[user.id]}
                                    onChange={() =>
                                      handleCheckboxChange(user.id)
                                    }
                                  />
                                </label>
                              </td> */}
                                      <td
                                        style={{
                                          width: "70%",
                                          paddingLeft: "28px",
                                        }}
                                        className="table-data-row"
                                      >
                                        {user?.action}
                                      </td>
                                      {/* <td className="text-center table-data-row">
                             
                              </td>
                              <td className="text-center table-data-row">
                                {user?.status}
                              </td> */}
                                      <td className="text-center table-data-row ">
                                        <span className="duree">
                                          {
                                            String(user?.action_days).split(
                                              "."
                                            )[0]
                                          }
                                        </span>
                                        {/* {t('days')} */}
                                      </td>
                                      <td className="text-center table-data-row">
                                        <Avatar
                                          src={
                                            user?.participant_image?.includes(
                                              "users"
                                            )
                                              ? Assets_URL +
                                                "/" +
                                                user?.participant_image
                                              : user?.participant_image
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}

                        <div className="d-flex justify-content-center align-items-center view-count">
                          <div className="d-flex flex-column align-items-center">
                            <span>
                              <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
                                  stroke="black"
                                  stroke-width="2"
                                />
                                <path
                                  d="M21 12.5C21 12.5 20 4.5 12 4.5C4 4.5 3 12.5 3 12.5"
                                  stroke="black"
                                  stroke-width="2"
                                />
                              </svg>
                            </span>
                            <p className="page-count">{pageViews} page views</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showProgressBar && (
                    <div className="progress-overlay">
                      <div style={{ width: "50%" }}>
                        <ProgressBar now={progress} animated />
                        <h5 className="text-center my-3">
                          {t("progressBarText")}
                        </h5>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* feedback location curent moment */}
                  <div className="email-invite w-100">
                    {meetingData?.destination_banner ? (
                      <img
                        src={meetingData?.destination_banner}
                        alt="Destination Banner"
                        style={{
                          width: "100%",
                          height: "338px",
                          objectFit: "cover",
                          filter: "opacity(0.5)",
                        }}
                      />
                    ) : (
                      <div className="gradient-header">
                        <svg
                          width="100%"
                          height="338"
                          viewBox="0 0 1453 338"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_f_902_7997)">
                            <rect
                              width="100%"
                              height="271"
                              fill="url(#paint0_linear_902_7997)"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_f_902_7997"
                              x="-66.5"
                              y="-66.5"
                              width="1573"
                              height="404"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feGaussianBlur
                                stdDeviation="33.25"
                                result="effect1_foregroundBlur_902_7997"
                              />
                            </filter>
                            <linearGradient
                              id="paint0_linear_902_7997"
                              x1="856"
                              y1="281.934"
                              x2="863.131"
                              y2="-138.913"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="white" />
                              <stop offset="1" stop-color="#76C3EE" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    )}
                    <div className="content">
                      <div
                        className="row report-row"
                        style={{ height: "35vh" }}
                      >
                        <div className="col-12 col-md-10 d-flex flex-column flex-md-row align-items-center align-items-md-start text-center text-md-start">
                          <h5 className="content-heading fw-bold">
                            {meetingData?.objective}
                          </h5>
                        </div>

                        <div className="col-md-2">
                          <div className="position-relative">
                            <Card className="mt-3 host-card tektime-dev-team">
                              <Card.Body>
                                <div className="d-flex justify-content-center">
                                  <div className="profile-logo">
                                    <Card.Img
                                      className="avatar-img"
                                      src={
                                        meetingData?.user?.enterprise?.logo
                                          ? Assets_URL +
                                            "/" +
                                            meetingData?.user?.enterprise?.logo
                                          : "/Assets/logo2.png"
                                      }
                                    />
                                  </div>
                                </div>

                                <Card.Title className="text-center mt-3 card-heading">
                                  {/* {meeting?.user?.name + " " + meeting?.user?.last_name} */}
                                  {meetingData?.user?.enterprise?.name}
                                </Card.Title>
                                {/* <Card.Subtitle className="mb-2 card-subtext">
                        {meeting?.user?.post} -{" "}
                        {meeting?.user?.teams?.map((item) => item.name)}
                      </Card.Subtitle> */}
                              </Card.Body>
                            </Card>
                          </div>
                        </div>

                        {/* ------------------------------------------------ HOST */}
                      </div>
                      <div className="row align-items-center report-row mt-5 mt-lg-0">
                        <div className="col-md-8">
                          <div className="dropdown-container" ref={dropdownRef}>
                            <h5 className="content-heading-title d-flex ms-0">
                              <div
                                style={{ cursor: "pointer" }}
                                // onClick={(e) => {
                                //   e.stopPropagation();
                                //   navigate(
                                //     `/destination/${meetingData?.destination_unique_id}--es/${meetingData.destination_id}`
                                //   );
                                // }}
                              >
                                {renderIcon()}
                              </div>
                              &nbsp;
                              {selectedTitleOrder}&nbsp;
                              {meetingData?.title}
                              {/* {allMeetings && allMeetings?.length < 2 ? null : ( */}
                              {allMeetings?.length > 1 ? (
                                <span>
                                  <MdKeyboardArrowDown
                                    size={30}
                                    onClick={toggleDropdown}
                                    style={{
                                      cursor: "pointer",
                                      // marginLeft: ".6rem",
                                      marginBottom: "6px",
                                    }}
                                  />
                                </span>
                              ) : null}
                            </h5>
                            {dropdownVisible && (
                              <div className="dropdown-content-filter">
                                <div className="dropdown-section">
                                  {allMeetings
                                    // ?.filter((item) => item.id !== meetId)
                                    ?.filter(
                                      (item) =>
                                        item.id.toString() !== meetId.toString()
                                    )
                                    ?.map((item, index) => {
                                      console.log("item", item);
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            onClick={() => applyFilter(item)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <b>{item.title_order}.</b>&nbsp;
                                            {item.title}
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-2 content-body stepcard-content ps-2 ps-md-4 ps-lg-0">
                            <div className="d-flex align-items-center gap-2">
                              {meetingData?.type === "Action" ||
                              meetingData?.type === "Newsletter" ? (
                                <>
                                  <img src="/Assets/invite-date.svg" />
                                  <span className="fw-bold formate-date">
                                    {formattedDate}
                                    &nbsp; -
                                  </span>
                                  <span className="fw-bold formate-date">
                                    {/* {formattedDateActive} */}
                                    {formattedEndDate}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img src="/Assets/invite-date.svg" />

                                  <span className="fw-bold formate-date">
                                    {formattedDate}
                                    &nbsp; {t("at")}
                                  </span>
                                  <span className="fw-bold formate-date">
                                    {/* {formattedTime} */}
                                    {/* {convertTo12HourFormat(
                                  meetingData?.start_time,
                                  meetingData?.steps
                                )}{" "} */}
                                    {meetingData?.status === "in_progress"
                                      ? convertTo12HourFormat(
                                          meetingData?.starts_at,
                                          meetingData?.steps
                                        )
                                      : convertTo12HourFormat(
                                          meetingData?.start_time,
                                          meetingData?.steps
                                        )}{" "}
                                    -{" "}
                                  </span>

                                  <span className="fw-bold formate-date">
                                    {/* {formattedEndDateInHoursForEmailInvite} */}
                                    {estimateDate}
                                    &nbsp; {t("at")}
                                  </span>
                                  <span className="fw-bold formate-date">
                                    {/* {lastStepEndTime} */}
                                    {estimateTime}
                                  </span>
                                  {/* <span className="fw-bold formate-date">
                                {convertTo12HourFormat1(
                                  meetingData?.start_time,
                                  meetingData?.steps
                                )}{" "}
                                -{" "}
                              </span>
                              <span className="fw-bold formate-date">
                                {calculateEndTime(
                                  meetingData?.start_time?.slice(0, 5),
                                  meetingData?.steps
                                )}
                              </span> */}
                                </>
                              )}
                            </div>

                            <div className="d-flex align-items-center gap-2 content-meet-section">
                              {/* <img src="/Assets/invite-link.svg" alt="" />
                          <span className="fw-bold formate-date">
                            Google Meet
                          </span>
                          <Link
                            to={meetingData?.meet_link}
                            style={{ color: "#3873CC" }}
                            className="formate-link"
                          >
                            {meetingData?.meet_link}
                          </Link> */}
                            </div>
                          </div>
                          <div className="ps-2 ps-md-4 ps-lg-0">
                            {meetingData?.location &&
                              meetingData?.location !== "None" && (
                                <p className="mt-2 d-flex gap-2 justify-content-start ps-0 fw-bold d-flex align-items-center">
                                  <span>
                                    <svg
                                      width="25px"
                                      height="25px"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                          d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z"
                                          fill="white"
                                        ></path>{" "}
                                        <path
                                          d="M5 23.5601C5 24.3557 5.64998 25.0001 6.45081 25.0001H6.47166C5.65857 25.0001 5 24.3557 5 23.5601Z"
                                          fill="#FBBC05"
                                        ></path>{" "}
                                        <path
                                          d="M17.4678 12.4V16.1596L22.5364 12.0712V8.43999C22.5364 7.6444 21.8864 7 21.0856 7H10.1045L10.0947 12.4H17.4678Z"
                                          fill="#FBBC05"
                                        ></path>{" "}
                                        <path
                                          d="M17.4671 19.9207H10.0818L10.0732 25.0003H21.085C21.887 25.0003 22.5358 24.3559 22.5358 23.5603V20.2819L17.4671 16.1611V19.9207Z"
                                          fill="#34A853"
                                        ></path>{" "}
                                        <path
                                          d="M10.1042 7L5 12.4H10.0956L10.1042 7Z"
                                          fill="#EA4335"
                                        ></path>{" "}
                                        <path
                                          d="M5 19.9204V23.56C5 24.3556 5.65857 25 6.47166 25H10.0736L10.0821 19.9204H5Z"
                                          fill="#1967D2"
                                        ></path>{" "}
                                        <path
                                          d="M10.0956 12.3999H5V19.9203H10.0821L10.0956 12.3999Z"
                                          fill="#4285F4"
                                        ></path>{" "}
                                        <path
                                          d="M26.9926 22.2796V9.9197C26.7068 8.27931 24.9077 10.1597 24.9077 10.1597L22.5371 12.0713V20.2804L25.9305 23.0392C27.1557 23.2 26.9926 22.2796 26.9926 22.2796Z"
                                          fill="#34A853"
                                        ></path>{" "}
                                        <path
                                          d="M17.4678 16.1594L22.5377 20.2814V12.0723L17.4678 16.1594Z"
                                          fill="#188038"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                  </span>
                                  <span className="text-primary">
                                    <a
                                      href={`${meetingData?.meet_link}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-primary text-decoration-none"
                                    >
                                      Join meeting <FaArrowRight />
                                    </a>
                                  </span>
                                </p>
                              )}
                            <div className="ps-0">
                              {meetingData?.street ||
                              meetingData?.city ||
                              meetingData?.country ||
                              meetingData?.zip ? (
                                <p className="d-flex gap-2 align-items-center justify-content-start ps-0 ms-0 mt-2">
                                  <FaLocationDot size={25} />
                                  <span>
                                    {meetingData.street &&
                                      `${meetingData.street}, `}
                                    {meetingData.city &&
                                      `${meetingData.city}, `}
                                    {meetingData.country &&
                                      `${meetingData.country}, `}
                                    {meetingData.zip && `${meetingData.zip}`}
                                  </span>
                                </p>
                              ) : null}
                            </div>
                            {meetingData?.room_details ? (
                              <div>
                                <p className="d-flex gap-2 mt-2 justify-content-start align-items-top ps-0">
                                  <BsPersonWorkspace size={25} />
                                  <p className="m-0">
                                    {meetingData?.room_details &&
                                      meetingData?.room_details}
                                  </p>
                                </p>
                              </div>
                            ) : null}
                            {meetingData?.phone ? ( // Check if the phone field exists
                              <div>
                                <p className="d-flex gap-2 align-items-center justify-content-start ps-0 mt-2">
                                  <span>
                                    <FaPhoneAlt size={25} />
                                  </span>
                                  <a
                                    href={`tel:${meetingData.phone}`}
                                    className="text-decoration-none"
                                  >
                                    <span className="">
                                      {meetingData.phone}
                                    </span>{" "}
                                    {/* Display the phone number */}
                                  </a>
                                </p>
                              </div>
                            ) : null}
                          </div>
                          <div className="paragraph-parent  mt-3 ps-2 ps-md-4 ps-lg-0">
                            <span className="paragraph paragraph-images">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: meetingData?.description || "",
                                }}
                              />
                            </span>
                          </div>
                        </div>

                        {meetingData?.type === "Newsletter" && (
                          <>
                            <div className="col-md-4">
                              <Card>
                                <Card.Body className="d-flex flex-column">
                                  <h4
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: "20px",
                                      fontWeight: 600,
                                      lineHeight: "20px",
                                      textAlign: "left",
                                      color: "#344054",
                                    }}
                                  >
                                    {t("Join Now")}
                                  </h4>
                                  <h6
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "16.41px",
                                      textAlign: "left",
                                      color: "#92929D",
                                    }}
                                  >
                                    {t("Sign up to be a part of this moment.")}
                                  </h6>
                                  <div className="subscribe-button mt-2">
                                    <button
                                      className="btn moment-btn w-100"
                                      style={{
                                        padding: "12px 20px 12px 20px",
                                        gap: "8px",
                                        borderRadius: "8px",
                                        background: "#2C48AE",
                                        border: "1px solid #E2E2E2",
                                        color: "white",
                                      }}
                                      onClick={() => handleShowSignUp()}
                                    >
                                      {t("Subscribe")}{" "}
                                    </button>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="cards-section">
                        <div style={{ marginTop: "5rem" }}>
                          <span className="participant-heading">
                            {showHostProfile && (
                              <IoArrowBackSharp
                                onClick={hideHostShow}
                                size={25}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "1rem",
                                }}
                              />
                            )}
                            Guide
                          </span>
                          <div
                            className="host"
                            style={{
                              background: showHostProfile && "white",
                            }}
                          >
                            <HostCard
                              data={meetingData?.user}
                              guides={meetingData?.guides}
                              handleShow={handleHostShow}
                              handleHide={hideHostShow}
                              showProfile={showHostProfile}
                              meeting={meetingData}
                            />
                          </div>
                        </div>
                        {/* ------------------------------------------------ Participants */}
                        {meetingData?.type !== "Newsletter" && (
                          <>
                            {meetingData?.participants?.filter(
                              (item) =>
                                // item.isCreator === 0 &&
                                !guideEmails.has(item.email)
                            )?.length > 0 && (
                              <div style={{ marginTop: "5rem" }}>
                                <span className="participant-heading">
                                  {showProfile && (
                                    <IoArrowBackSharp
                                      onClick={hideShow}
                                      size={25}
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "1rem",
                                      }}
                                    />
                                  )}
                                  {t("invite")}
                                </span>
                                <div className="row">
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    background: showProfile && "white",
                                  }}
                                  className="participant"
                                >
                                  <ParticipantCard
                                    data={meetingData?.participants}
                                    guides={meetingData?.guides}
                                    handleShow={handleShow}
                                    handleHide={hideShow}
                                    showProfile={showProfile}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {/* ------------------------------------------------ Steps */}

                        {
                          <div style={{ marginTop: "5rem" }}>
                            <span className="participant-heading">
                              {`${t("meeting.newMeeting.labels.Program")} `}
                            </span>
                            {/* <StepCard data={meeting?.steps} startTime={formattedTime} users={{ ...meeting?.user, image: meeting?.user?.image || '/Assets/avatar.jpeg' }}  /> */}
                            <StepCard
                              data={meetingData?.steps}
                              startTime={formattedTime}
                              users={{
                                ...meetingData?.user,
                                firstName: meetingData?.user?.name,
                                lastName: meetingData?.user?.last_name,
                                image:
                                  meetingData?.user?.assigned_to_image ||
                                  meetingData?.user?.image ||
                                  "/Assets/avatar.jpeg",
                              }}
                              meeting1={meetingData?.id}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* SignIn Modal */}
                  {/* <SignIn
                show={showSignIn}
                handleClose={handleCloseSignIn}
                handleShowSignUp={handleShowSignUp}
                handleShowForgot={handleShowForgot}
              /> */}

                  {/* SignUp Modal */}
                  <SignUp
                    show={showSignUp}
                    handleClose={handleCloseSignUp}
                    handleShowSignIn={handleShowSignIn}
                    meetingId={meetId}
                  />
                  {/* Forgot Password Modal */}
                  <ForgotPassword
                    show={showForgot}
                    handleClose={handleCloseForgot}
                    handleShowForgot={handleShowForgot}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {/* graph destination */}
              <div
                className="email-invite report w-100 "
                style={{ position: uniqueId ? "static" : "relative" }}
              >
                {uniqueId && (
                  <div className="">
                    {destinationDate?.banner ? (
                      <img
                        src={destinationDate?.banner}
                        alt="Destination Banner"
                        style={{
                          width: "100%",
                          height: "338px",
                          objectFit: "cover",
                          filter: "opacity(0.5)",
                        }}
                      />
                    ) : (
                      <div className="gradient-header">
                        <svg
                          width="100%"
                          height="338"
                          viewBox="0 0 1453 338"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_f_902_7997)">
                            <rect
                              width="100%"
                              height="271"
                              fill="url(#paint0_linear_902_7997)"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_f_902_7997"
                              x="-66.5"
                              y="-66.5"
                              width="1573"
                              height="404"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFloo
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feGaussianBlur
                                stdDeviation="33.25"
                                result="effect1_foregroundBlur_902_7997"
                              />
                            </filter>
                            <linearGradient
                              id="paint0_linear_902_7997"
                              x1="856"
                              y1="281.934"
                              x2="863.131"
                              y2="-138.913"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="white" />
                              <stop offset="1" stopColor="#76C3EE" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    )}
                  </div>
                )}
                <div className="participant px-0">
                  <div className="content">
                    <div
                      className="row align-items-center report-row"
                      style={{ minHeight: "35vh" }}
                    >
                      <div className="col-md-10 d-flex flex-column ps-2 mt-5">
                        <h5 className="content-heading fw-bold text-start ps-2 mt-5 mt-md-0">
                          {destinationDate?.destination_name}
                        </h5>

                        <div className="ps-2 content-body mt-3">
                          <div className="d-flex align-items-center gap-2">
                            <>
                              <img src="/Assets/invite-date.svg" />
                              <span className="fw-bold formate-date">
                                {destinationDate?.meeting_start_date}
                                &nbsp; -
                              </span>
                              <span className="fw-bold formate-date">
                                {destinationDate?.meeting_end_date}
                              </span>
                            </>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2 d-flex justify-content-end d-none d-md-block">
                        <div className="position-relative">
                          {/* <HostCard data={meeting?.user} /> */}
                          <Card
                            className=" host-card graph-tektime-dev-team tektime-dev-team"
                            style={{ top: "-125px" }}
                          >
                            <Card.Body>
                              <div className="d-flex justify-content-center">
                                <div className="profile-logo">
                                  <Card.Img
                                    className="avatar-img"
                                    src={
                                      destinationDate?.user?.enterprise?.logo
                                        ? `${Assets_URL}/${destinationDate?.user?.enterprise?.logo}`
                                        : "/Assets/logo2.png"
                                    }
                                  />
                                </div>
                              </div>

                              <Card.Title className="text-center mt-3 card-heading">
                                {destinationDate?.user?.enterprise?.name}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* remove moment-list */}
                  <div className="d-block d-md-none">
                    <Card className="border-0 w-100">
                      <Card.Body>
                        <div className="d-flex justify-content-center">
                          <div className="profile-logo">
                            <Card.Img
                              className="avatar-img"
                              src={
                                destinationDate?.user?.enterprise?.logo
                                  ? `${Assets_URL}/${destinationDate?.user?.enterprise?.logo}`
                                  : "/Assets/logo2.png"
                              }
                            />
                          </div>
                        </div>

                        <Card.Title className="text-center mt-3 card-heading">
                          {destinationDate?.user?.enterprise?.name}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="row align-items-start moment-list graphpage-margin justify-content-between pt-5 mt-0">
                    <div className="col-lg-7 col-md-7 p-0">
                      {destinationDate?.destination_description &&
                        destinationDate?.destination_description !== "null" && (
                          <div className="paragraph-parent m-1">
                            <p className="paragraph paragraph-images mt-1 p-1">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    destinationDate.destination_description,
                                }}
                              />
                            </p>
                          </div>
                        )}

                      <div className="moments-section mt-4">
                        <h2 className="participant-heading mb-3">Moments</h2>
                        <Accordion
                          // defaultActiveKey="0"
                          activeKey={openKeys}
                          className="my-custom-accordion"
                        >
                          {destinationDate?.meetings
                            ?.filter((moment) => moment.status !== "abort")
                            ?.sort(
                              (a, b) => new Date(a.date) - new Date(b.date)
                            )
                            ?.map((item, index) => {
                              const destinationDate = new Date(item?.date);
                              // Get individual components of the date
                              const desDay = String(
                                destinationDate.getDate()
                              ).padStart(2, "0");
                              const desMonth = String(
                                destinationDate.getMonth() + 1
                              ).padStart(2, "0"); // Months are zero-based
                              const desYear = destinationDate.getFullYear();

                              // Format the date in dd:mm:yyyy
                              const formattedDesDate = `${desDay}/${desMonth}/${desYear}`;

                              const endTime = specialMeetingEndTime(
                                item?.start_time,
                                item?.steps
                              );
                              return (
                                <Accordion.Item
                                  eventKey={index.toString()}
                                  key={item.id}
                                  className="mb-4 shadow "
                                  onClick={() =>
                                    handleToggleAccoridon(index.toString())
                                  }
                                >
                                  <Accordion.Header className="my-custom-header">
                                    <p className="moment-title graphpage-momenttitle">
                                      {index < 9 ? "0" : " "}
                                      {index + 1}
                                      &nbsp;
                                      {item?.type}: &nbsp;{item?.title}{" "}
                                      &nbsp;&nbsp;{" "}
                                      {item?.status === "active" && (
                                        <span
                                          className={`badge ms-2 ${
                                            moment().isAfter(
                                              moment(
                                                `${item?.date} ${item?.start_time}`,
                                                "YYYY-MM-DD HH:mm"
                                              )
                                            )
                                              ? "late"
                                              : "future"
                                          }`}
                                          // style={{ padding: "3px 8px 3px 8px" }}
                                        >
                                          {moment().isAfter(
                                            moment(
                                              `${item?.date} ${item?.start_time}`,
                                              "YYYY-MM-DD HH:mm"
                                            )
                                          )
                                            ? t("badge.late")
                                            : t("badge.future")}
                                        </span>
                                      )}
                                      {item?.status === "in_progress" && (
                                        // <span className="mx-2 badge status-badge-inprogress1">
                                        <span
                                          className={`${
                                            item?.steps?.some(
                                              (moment) =>
                                                //  item?.delay >= "00d:00h:00m:01s"
                                                convertTimeTakenToSeconds(
                                                  moment?.time_taken
                                                ) >
                                                convertCount2ToSeconds(
                                                  moment?.count2,
                                                  moment?.time_unit
                                                )
                                            )
                                              ? "status-badge-red1"
                                              : "status-badge-inprogress1"
                                          } mx-2 badge`}
                                        >
                                          {t("badge.inprogress")}
                                        </span>
                                      )}
                                      {item?.status === "closed" && (
                                        <span className="mx-2 badge inprogrss">
                                          {t("badge.finished")}
                                        </span>
                                      )}
                                      {item?.status === "abort" && (
                                        <span className="mx-2 badge late">
                                          {t("badge.cancel")}
                                        </span>
                                      )}
                                      {item?.status === "in_progress" ||
                                      item?.status === "closed" ? (
                                        <>
                                          <span className="fw-bold formate-date">
                                            <span className="fw-bold formate-date">
                                              {formattedDesDate}
                                              &nbsp; {t("at")} &nbsp;
                                            </span>
                                            <>
                                              {item?.type === "Special" ||
                                              item?.type === "Law" ? (
                                                <>{endTime}</>
                                              ) : (
                                                <>
                                                  {convertTo12HourFormat(
                                                    item?.starts_at,
                                                    item?.steps
                                                  )}
                                                </>
                                              )}
                                            </>
                                          </span>
                                        </>
                                      ) : (
                                        <span className="ms-2 fw-bold formate-date">
                                          <span className="fw-bold formate-date">
                                            {formattedDesDate}
                                            &nbsp; {t("at")} &nbsp;
                                          </span>
                                          {convertTo12HourFormat(
                                            item?.start_time,
                                            item?.steps
                                          )}
                                        </span>
                                      )}
                                      {/* {start time of the moment} */}
                                      &nbsp;&nbsp;
                                      <span
                                        style={{
                                          color: "#4C49E3",
                                          marginLeft: "5px",
                                          textDecoration: "none",
                                          fontSize: "14px",
                                          fontFamily: "Roboto",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setIsModalOpen(false);
                                          setUniqueId((prev) => !prev);
                                          viewPresentation(item);
                                        }}
                                      >
                                        {t("meeting.See More")}
                                      </span>
                                    </p>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {/* <p>
                                {moment.description.replace(
                                  /<\/?[^>]+(>|$)/g,
                                  ""
                                )}
                              </p> */}
                                    {/* {moment.description.length > 100 // Set length threshold for truncation
                                  ? `${moment.description
                                      .slice(0, 100)
                                      .replace(/<\/?[^>]+(>|$)/g, "")}...`
                                  : moment.description.replace(
                                      /<\/?[^>]+(>|$)/g,
                                      ""
                                    )} */}
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item?.description?.replace(
                                          /<img /g,
                                          '<img style="width: 100%;" '
                                        ),
                                      }}
                                    ></p>
                                    {/* <p>

                                {
                                  <span
                                    style={{
                                      color: "#4C49E3",
                                      marginLeft: "5px",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                      fontFamily: "Roboto",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      viewPresentation(moment);
                                    }}
                                  >
                                    See More
                                  </span>
                                }
                              </p> */}
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            })}
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-4 p-0">
                      <div className="casting-section">
                        <h2 className="participant-heading mb-5">Casting</h2>
                        <div className="casting-list">
                          <Accordion
                            className="casting-accordian"
                            onSelect={(key) => setActiveEventKey(key)}
                            activeKey={activeEventKey}
                          >
                            {destinationDate?.participant?.map(
                              (member, index) => (
                                <Accordion.Item
                                  eventKey={index.toString()}
                                  key={member.id}
                                  className="mb-2"
                                >
                                  <Accordion.Header className="casting-accordian">
                                    <div className="d-flex align-items-center gap-3 w-100">
                                      <Avatar
                                        src={
                                          member?.participant_image?.startsWith(
                                            "users/"
                                          )
                                            ? Assets_URL +
                                              "/" +
                                              member?.participant_image
                                            : member?.participant_image
                                        }
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          objectPosition: "top",
                                          objectFit: "cover",
                                        }}
                                        className="custom-avatar"
                                      />
                                      <div className="d-flex flex-column gap-2 w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5 className="mb-0 casting-member">
                                            {member.full_name}
                                          </h5>

                                          {activeEventKey ===
                                            index.toString() && (
                                            <div
                                              className="visiting-card-link mt-0"
                                              //  style={{marginLeft:'25px'}}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                copyToClipboard(
                                                  member?.user?.nick_name ||
                                                    member?.nick_name
                                                );
                                              }}
                                            >
                                              {t("viewVisitingCard")} &nbsp;{" "}
                                              <FaArrowRight />
                                            </div>
                                          )}
                                        </div>

                                        <p className="mb-0">{member.post}</p>
                                        <p className="mb-0">
                                          {member?.user?.enterprise?.name ||
                                            member?.enterprise?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </Accordion.Header>

                                  <Accordion.Body>
                                    {member?.user?.bio && (
                                      <div>
                                        <h6>
                                          {" "}
                                          {t("profile.inviteProfile.bio")}
                                        </h6>

                                        {member?.user?.bio}
                                      </div>
                                    )}

                                    {member?.meetings?.length > 0 && (
                                      <div>
                                        <div className="table-responsive">
                                          <table
                                            className="table  mt-3"
                                            style={{
                                              borderCollapse: "separate",
                                              borderSpacing: 0,
                                              border: "1px solid #BACBED", // Outer border
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "aliceblue",
                                                  }}
                                                >
                                                  {t("time_unit.moment_name")}
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "aliceblue",
                                                  }}
                                                >
                                                  {t("time_unit.moment_delay")}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody
                                              style={{
                                                border: "0 solid white",
                                              }}
                                            >
                                              {member?.meetings?.map(
                                                (item, idx) => (
                                                  <tr key={idx}>
                                                    <td>{item.title}</td>
                                                    <td
                                                      style={{
                                                        color:
                                                          item.status ===
                                                          "in_progress"
                                                            ? item?.steps?.some(
                                                                (step) =>
                                                                  convertTimeTakenToSeconds(
                                                                    step?.time_taken
                                                                  ) >
                                                                  convertCount2ToSeconds(
                                                                    step?.count2,
                                                                    step?.time_unit
                                                                  )
                                                              )
                                                              ? "#FFDB01"
                                                              : "#FFDB01"
                                                            : item?.status ===
                                                              "active"
                                                            ? moment().isAfter(
                                                                moment(
                                                                  `${item.date} ${item.start_time}`,
                                                                  "YYYY-MM-DD HH:mm"
                                                                )
                                                              )
                                                              ? "#F12D2B"
                                                              : "#4C49E3"
                                                            : item?.status ===
                                                              "closed"
                                                            ? "#0A8634"
                                                            : "",
                                                      }}
                                                    >
                                                      {item?.status ===
                                                      "in_progress"
                                                        ? calculateTimeDifferencePrepareData(
                                                            item?.steps,
                                                            item?.starts_at,
                                                            item?.current_date,
                                                            t
                                                          )
                                                        : item?.status ===
                                                          "closed"
                                                        ? calculateTotalTimeTaken(
                                                            item?.steps
                                                          )
                                                        : calculateTotalTime(
                                                            item?.steps
                                                          )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            )}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                    <Card className="roadmap-container shadow mt-5">
                      <div>
                        <h4
                          className={`${
                            fromMeeting
                              ? "participant-heading-meeting"
                              : "participant-heading"
                          } d-flex align-items-center justify-content-between`}
                        >
                          {view === "graph"
                            ? `${t("time_unit.Roadmap")} `
                            : `${t("time_unit.Calendar")} `}
                          <span style={{ cursor: "pointer" }}>
                            <div className="toggle-button">
                              <button
                                className={`toggle-button-option ${
                                  view === "list" ? "active" : ""
                                }`}
                                onClick={() => handleToggle("list")}
                              >
                                <div className="icon-list" />
                                <FaRegCalendarDays size={18} />
                              </button>
                              <button
                                className={`toggle-button-option ${
                                  view === "graph" ? "active" : ""
                                }`}
                                onClick={() => handleToggle("graph")}
                              >
                                <div className="icon-graph" />
                                <FaChartGantt size={20} />
                              </button>
                            </div>
                          </span>
                        </h4>

                        {/* <h2 className="participant-heading mb-3">Roadmap</h2> */}
                        <Card.Body className="text-center">
                          {/* <div className="d-flex">
                          <div 
                            style={{ width: "20%" }}
                            >
                            <p className="roadmap-p">End Date</p>
                            <p className="roadmap-date">25-Oct-2024</p>
                            <div className="milestones">
                              <div
                                className="milestone-item position-absolute"
                                style={{ left: "7rem" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  Milestones
                                </span>
                              </div>

                            </div>
                          </div>
                          <div
                            className="chart-container"
                            style={{ width: "80%" }}
                          >
                            <div ref={svgRef}></div>
                          </div>
                        </div> */}

                          {view === "graph" ? (
                            <>
                              <div
                                className="roadmap-container"
                                style={{
                                  // display: "grid",
                                  // gridTemplateColumns: "1fr 4fr",
                                  // gap: "1rem",
                                  // border:'1px solid red',
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="d-flex flex-wrap justify-content-evenly mb-2"
                                  style={{ gap: "75px" }}
                                >
                                  <div>
                                    <p className="roadmap-p">
                                      {t("time_unit.Start Date")}
                                    </p>
                                    <p className="roadmap-date">
                                      {/* {formattedLargestDate} */}
                                      {destinationDate?.meeting_start_date}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="roadmap-p">
                                      {t("time_unit.Total Days")}
                                    </p>
                                    <p className="roadmap-date">{result}</p>
                                  </div>
                                  <div>
                                    <p className="roadmap-p">
                                      {t("time_unit.End Date")}
                                    </p>
                                    <p className="roadmap-date">
                                      {/* {formattedLargestDate} */}
                                      {destinationDate?.meeting_end_date}
                                    </p>
                                  </div>
                                  {/* <div
                                    style={{
                                      position: "absolute",
                                      // right: "73px",
                                      // top: "208px",
                                      right: "92px",
                                      top: "169px",
                                    }}
                                  >
                                    <p className="roadmap-date ">
                                      {destinationDate?.meeting_end_date}
                                    </p>
                                  </div> */}
                                </div>
                                {/* <div
                                  style={{ width: "100%" }}
                                  className="chart-container"
                                >
                                  <p className="roadmap-p">End Date</p>
                                  <p className="roadmap-date">25-Oct-2024</p>
                                  <div className="milestones">
                                    <table
                                      style={{
                                        width: "100%",
                                        marginTop: "1rem",
                                        borderCollapse: "collapse",
                                      }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              borderBottom: "2px solid #000",
                                              padding: "0.5rem",
                                            }}
                                          >
                                            Milestone
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {prepareData?.map((item, index) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                borderBottom: "1px solid #ddd",
                                                padding: "0.5rem",
                                              }}
                                            >
                                              {item.totalCount2 +
                                                " " +
                                                item.timeUnit}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div> */}

                                <div
                                  style={{
                                    overflowX: "auto",
                                    maxWidth: "100%",
                                    padding: "10px",
                                  }}
                                >
                                  {loading ? (
                                    <div
                                      style={{
                                        maxWidth: "100%",
                                        padding: "10px",
                                        height: "700px",
                                      }}
                                    >
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        className="center-spinner"
                                      ></Spinner>
                                    </div>
                                  ) : (
                                    <>
                                      <div ref={svgRef}></div>
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="legend flex-wrap">
                                <div className="d-flex gap-3 flex-wrap">
                                  <div className="legend-item">
                                    <div
                                      className="color-box"
                                      style={{ backgroundColor: "#F12D2B" }}
                                    ></div>
                                    <span>{t("time_unit.Late")}</span>
                                  </div>
                                  <div className="legend-item">
                                    <div
                                      className="color-box"
                                      style={{ backgroundColor: "#FFDB01" }}
                                    ></div>
                                    <span>{t("time_unit.Ongoing")}</span>
                                  </div>
                                  <div className="legend-item">
                                    <div
                                      className="color-box"
                                      style={{ backgroundColor: "#0A8634" }}
                                    ></div>
                                    <span>{t("time_unit.Completed")}</span>
                                  </div>
                                  <div className="legend-item">
                                    <div
                                      className="color-box"
                                      style={{ backgroundColor: "#4C49E3" }}
                                    ></div>
                                    <span>{t("time_unit.Upcoming")}</span>
                                  </div>
                                </div>

                                <div>
                                  <div className="legend-item mt-3">
                                    <FaFlag className="milestone-icon" />
                                    <span>{t("time_unit.Milestones")}</span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <Calendar
                                localizer={localizer}
                                events={allEvents}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 600 }}
                                eventPropGetter={eventStyleGetter}
                                views={views} // Set views based on date difference
                                defaultView={defaultView} // Set default view dynamically
                                selectable
                                onSelectEvent={handleEventSelect} // Add the event handler here
                              />
                            </>
                          )}
                        </Card.Body>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {isModalOpen && (
        <div className="confirmation-modal">
          <div className="confirmation-modal-content">
            <p>{visibilityMessage}</p>
            {visibilityMessage === "Please log in to view meeting." ? (
              <button className="btn btn-primary" onClick={handleLogin}>
                Login
              </button>
            ) : visibilityMessage ===
              "Please enter password to view meeting." ? (
              <div className="d-flex flex-column gap-2">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      checkPassword();
                    }
                  }}
                />
                <button className="btn btn-primary" onClick={checkPassword}>
                  Submit
                </button>
              </div>
            ) : null}
            {!window.location.href.includes("destination") && (
              <button className="btn-no" onClick={handleClose}>
                {t("confirmationModal.close")}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Report;
