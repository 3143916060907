import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../Components/Apicongfig";
import { askPermission } from "../Components/Utils/askPermission";

const MeetingsContext = createContext();

export const useMeetings = () => useContext(MeetingsContext);

export const MeetingsProvider = ({ children }) => {
  const [allMeetings, setAllMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allClosedMeetings, setAllClosedMeetings] = useState([]);
  const [allDraftMeetings, setAllDraftMeetings] = useState([]);
  const [status, setStatus] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("TODAY"); 
  const [selectedClosedFilter, setSelectedClosedFilter] = useState("TODAY"); 
  const [offset, setOffset] = useState(0);
  const [limit] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  console.log('offset', offset);
  const getMeetingsCalculations = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    try {
      const response = await axios.get(`${API_BASE_URL}/calculate-meetings-time?current_time=${formattedTime}&current_date=${formattedDate}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        // setAllMeetings(response?.data?.data);
        // setIsLoading(false);
      }
    } catch (error) {
    }
  };
  const getMeetings = async () => {
    try {
      setIsLoading(true);
      setLoading(true)
      const response = await axios.get(`${API_BASE_URL}/meetings`, {
        // params: {
        //   // filter: selectedFilter,
        //   // offset: offset,
        //   // limit: limit,
        // },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      // 
      if (response.status) {
        const data = response?.data?.data
        setHasMore(data?.length === limit);

        setAllMeetings(response?.data?.data);
        setIsLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getClosedMeetings = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/closed/meetings`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setAllClosedMeetings(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getDraftMeetings = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/draft/meetings`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setAllDraftMeetings(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleDelete = async (id) => {
    const permissionGranted = askPermission(
      "Êtes-vous sûr de vouloir supprimer cette réunion ?" ||
      "Are you sure you want to delete this meeting?"
    );

    if (!permissionGranted) return;

    try {
      const response = await axios.delete(`${API_BASE_URL}/meetings/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });

      if (response.status === 200) {
        toast.success("Réunion supprimée avec succès");
        getMeetings();
      } else {
        throw new Error("Échec de la suppression de la réunion");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <MeetingsContext.Provider
      value={{
        allMeetings,
        isLoading,
        getMeetings,
        allClosedMeetings,
        allDraftMeetings,
        setAllDraftMeetings,
        getClosedMeetings,
        handleDelete,
        status,
        setStatus,
        getMeetingsCalculations,

        selectedFilter,
        setSelectedFilter,
        selectedClosedFilter,
        setSelectedClosedFilter,

        offset,
        setOffset,
        limit,
        hasMore,
        setHasMore,
        loading,
        setLoading,

        getDraftMeetings

      }}
    >
      {children}
    </MeetingsContext.Provider>
  );
};
