import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../Apicongfig";
import { Button, Dropdown, Spinner, Tab } from "react-bootstrap";
import { useHeaderTitle } from "../../../../context/HeaderTitleContext";
import { useTranslation } from "react-i18next";
import Moments from "./Moments";
import Participants from "./Participants";
import AddDestination from "../AddDestination";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { openLinkInNewTab } from "../../../Utils/openLinkInNewTab";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { RiEditBoxLine } from "react-icons/ri";
import { useFormContext } from "../../../../context/CreateMeetingContext";
import { useMeetings } from "../../../../context/MeetingsContext";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Utils/ConfirmationModal";

const Tabs = () => {
  const { id } = useParams();
  const tabsRef = useRef(null);
  const { searchTerm } = useOutletContext();
  const {
    open,
    handleShow,
    handleCloseModal,
    setFromDestination,
    setFromDestinationName,
    callDestination,
  } = useFormContext();
  const {
    getMeetingsCalculations
  } = useMeetings();

  const { resetHeaderTitle } = useHeaderTitle();
  const navigate = useNavigate();
  React.useEffect(() => {
    resetHeaderTitle();
  }, []);
  const location = useLocation();
  const [t] = useTranslation("global");
  const [isSticky, setIsSticky] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [destination, setDestination] = useState();
  console.log("destination", destination);
  const [participants, setParticipants] = useState([]);
  console.log("meetings by destination id-->", meetings);
  const [isLoading, setIsLoading] = useState(false);
  console.log("meetings by participants id-->", participants);
  const [show, setShow] = useState(false);
  const [firstMeetingDate, setFirstMeetingDate] = useState(null);
  const [lastMeetingDate, setLastMeetingDate] = useState(null);
  const toggle = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const getMeetingsByDestinationId = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-destination-with-meeting/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data;
        console.log("data", data);

        setDestination(data);
        setMeetings(data?.meetings);

        // Sort meetings by created_at date
        const sortedMeetings = data?.meetings?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        // Get the first created meeting
        const firstMeeting = sortedMeetings[0];
        setFirstMeetingDate(firstMeeting?.created_at);

        // Sort meetings by created_at date in descending order for newest meeting
        const sortedMeetingsDesc = data?.meetings?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        // Get the newest created meeting
        const newestMeeting = sortedMeetingsDesc[0];
        setLastMeetingDate(newestMeeting?.created_at);

        // Extract participants from each meeting and filter out creators
        // const allParticipants = data?.meetings?.flatMap((meeting) =>
        //   meeting.participants.filter(
        //     (participant) => participant.isCreator !== 1
        //   )
        // );

        // // Combine participants for meetings with the same objective and remove duplicates
        // const combinedParticipants = allParticipants?.reduce(
        //   (acc, participant) => {
        //     const key = `${participant.email}`;
        //     if (!acc[key]) {
        //       acc[key] = { ...participant, meeting_count: 1 };
        //     } else {
        //       acc[key].meeting_count += 1;
        //     }
        //     return acc;
        //   },
        //   {}
        // );
        // Check if any meeting has a status of "draft"
        // const hasDraftMeeting = data?.meetings?.some(meeting => meeting.status === "draft");
        // const allParticipants = data?.participant?.filter((participant) => participant.isCreator !== 1);
        const allParticipants = data?.participant;
        console.log("allParticipants", allParticipants);
        setParticipants(allParticipants);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const refreshedParticipants = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    // setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-destination-with-meeting/${id}?current_time=${formattedTime}&current_date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const data = response?.data?.data;
        console.log("data", data);
        setDestination(data);
        setMeetings(data?.meetings);

        // Sort meetings by created_at date
        const sortedMeetings = data?.meetings?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        // Get the first created meeting
        const firstMeeting = sortedMeetings[0];
        setFirstMeetingDate(firstMeeting?.created_at);

        // Sort meetings by created_at date in descending order for newest meeting
        const sortedMeetingsDesc = data?.meetings?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        // Get the newest created meeting
        const newestMeeting = sortedMeetingsDesc[0];
        setLastMeetingDate(newestMeeting?.created_at);

        // Extract participants from each meeting and filter out creators
        // const allParticipants = data?.meetings?.flatMap((meeting) =>
        //   meeting.participants.filter(
        //     (participant) => participant.isCreator !== 1
        //   )
        // );

        // // Combine participants for meetings with the same objective and remove duplicates
        // const combinedParticipants = allParticipants?.reduce(
        //   (acc, participant) => {
        //     const key = `${participant.email}-${participant.post}`;
        //     if (!acc[key]) {
        //       acc[key] = { ...participant, meeting_count: 1 };
        //     } else {
        //       acc[key].meeting_count += 1;
        //     }
        //     return acc;
        //   },
        //   {}
        // );
        const allParticipants = data?.participant?.filter(
          (participant) => participant.isCreator !== 1
        );
        setParticipants(allParticipants);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getMeetingsByDestinationId();
    // refreshedParticipants()
  }, [id, callDestination]);

  useEffect(()=>{
    getMeetingsCalculations()
  },[id])

  const [activeTab, setActiveTab] = useState("Moments");
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  // Filter out meetings that are not closed
  const openMeetings = meetings?.filter(
    (meeting) =>
      meeting.status !== "closed" &&
      meeting.status !== "abort" &&
      meeting.status !== "draft"
  );

  // Determine if any meetings are late

  // const isAnyMeetingLate = openMeetings?.some((meeting) =>
  //   moment().isAfter(
  //     moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
  //   )
  // );

  // Determine if any meetings are in progress
  const isAnyMeetingInProgress = openMeetings?.some(
    (meeting) => meeting.status === "in_progress"
  );
  const isAnyMeetingClosed = meetings?.some(
    (meeting) => meeting.status === "closed"
  );

  // Determine if all meetings are closed or aborted
  const areAllMeetingsClosed = meetings?.every(
    (meeting) => meeting.status === "closed" || meeting.status === "abort"
  );

  // Determine if there is only one meeting that is in progress
  const isOnlyOneMeetingInProgress =
    openMeetings?.length === 1 && openMeetings[0].status === "in_progress";

  // Determine if any meetings are late
  const isAnyMeetingLate =
    !isAnyMeetingInProgress &&
    openMeetings?.some((meeting) =>
      moment().isAfter(
        moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
      )
    );

  // Determine the overall badge to show
  const overallBadge = areAllMeetingsClosed
    ? "active"
    : isAnyMeetingInProgress || isAnyMeetingClosed
    ? "inProgress"
    : isAnyMeetingLate
    ? "late"
    : "future";

  const badgeText = areAllMeetingsClosed
    ? t("badge.finished")
    : isAnyMeetingInProgress || isAnyMeetingClosed
    ? t("badge.inprogress")
    : isAnyMeetingLate
    ? t("badge.late")
    : t("badge.future");
  // const overallBadge = areAllMeetingsClosed
  //   ? "finished"
  //   : isAnyMeetingLate
  //   ? "late"
  //   : "active";

  // const badgeText = areAllMeetingsClosed
  //   ? t("badge.finished")
  //   : isAnyMeetingLate
  //   ? t("badge.late")
  //   : t("badge.active");
  // // Determine if any meetings are late
  // const isAnyMeetingLate = meetings?.some((meeting) =>
  //   moment().isAfter(
  //     moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
  //   )
  // );

  // // Determine the overall badge to show
  // const overallBadge = isAnyMeetingLate ? "late" : "active";
  // const badgeText = isAnyMeetingLate ? t("badge.late") : t("badge.active");

  const meetingsCount = meetings?.filter(
    (item) => item.status !== "draft"
  )?.length;
  const participantsCount = participants?.length;

  // Determine if there is only one meeting and it is in draft
  const isSingleDraftMeeting =
    meetings?.length === 1 && meetings[0].status === "draft";


    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    //Delete Destination
    const handleDelete = async (id) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/destinations/${id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
  
        // Check for successful deletion (assuming HTTP status 200)
        if (response.status === 200) {
          toast.success(t("destination.destinationDeleteMsg"));
          // getDestinations();
          navigate('/Invities')
        } else {
          // Handle other status codes (e.g., 4xx, 5xx) appropriately
          throw new Error("Échec de la suppression de la réunion");
        }
      } catch (error) {
        // Improve error handling, provide informative messages
        toast.error(t(error.message));
      }
    };
    const handleDeleteClick = (e, id) => {
      e.stopPropagation();
      setItemIdToDelete(id); // Store the ID of the item to be deleted
      setShowConfirmationModal(true); // Show confirmation modal
    };
  
    
    const confirmDelete = (e) => {
      e.stopPropagation();
      setShowConfirmationModal(false);
      if (itemIdToDelete) {
        handleDelete(itemIdToDelete);
      }
    };
  
  return (
    <>
      {isLoading ? (
        <>
          <Spinner
            animation="border"
            role="status"
            className="center-spinner"
          ></Spinner>
        </>
      ) : (
        <div className="destination-to-meeting-container container-fluid">
          <div className="destination-data">
            <div className="row first-row">
              <div className="col-md-8">
                <span className="d-flex align-items-center">
                  {destination?.destination_name}
                  {meetingsCount > 0 && (
                    <>
                      {/* // <span className="destination_status"> */}
                      <span
                        className={`badge ms-2 ${overallBadge}`}
                        // style={{ padding: "3px 8px 3px 8px" }}
                      >
                        {badgeText}
                      </span>
                    </>

                    // {/* </span> */}
                  )}
                </span>
              </div>
              {/* Wrapper around button and dropdown for flex layout */}
              <div className="col-md-4 d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center">
                  <Button
                    className="btn play-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      const currentURL = `/destination/${destination?.uuid}--es/${destination?.id}`;
                      copy(currentURL);
                      openLinkInNewTab(currentURL);
                    }}
                  >
                    {t("presentation.Open the map")}
                    <FaArrowRight
                      size={12}
                      style={{
                        marginLeft: ".5rem",
                        fontWeight: 700,
                      }}
                    />
                  </Button>

                  {/* Add margin to create a gap between button and dropdown */}
                  <div style={{ marginLeft: "10px" }}>
                    <Dropdown className="dropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <BiDotsVerticalRounded color="black" size={"25px"} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => {
                            toggle(destination);
                          }}
                        >
                          <RiEditBoxLine size={"20px"} /> &nbsp;
                          {t("dropdown.To modify")}
                        </Dropdown.Item>

                      {meetingsCount === 0 &&  <Dropdown.Item
                                                    onClick={(e) => handleDeleteClick(e, id)}

                        >
                          <AiOutlineDelete size={"20px"} color="red" />  &nbsp;
                          {t("dropdown.Delete")}
                        </Dropdown.Item>}
                      </Dropdown.Menu>
                     
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row second-row">
              <div className="col-md-12">
                {/* {(destination?.meeting_start_date || destination?.meeting_end_date) &&  <span>
                  {destination?.meeting_start_date} -{" "}
                  {destination?.meeting_end_date}
                </span>} */}
                {!isSingleDraftMeeting &&
                  (destination?.meeting_start_date ||
                    destination?.meeting_end_date) && (
                    <span>
                      {destination?.meeting_start_date} -{" "}
                      {destination?.meeting_end_date}
                    </span>
                  )}
              </div>
            </div>
            <div className="row third-row">
              <div className="col-md-12">
                <p>
                  {destination?.destination_description !== "null" &&
                    destination?.destination_description}
                </p>
              </div>
            </div>
          </div>

          <div className="destination-tabs-container">
            <div
              ref={tabsRef}
              className={`tabs-header ${isSticky ? "sticky" : ""}`}
            >
              {/* <h4 className="meeting-title">{t("destination.title")}</h4> */}
              <div className="row">
                <div
                  className="col-md-12 col-12 destination-tab-row"
                  style={{ borderBottom: "2px solid #F2F2F2" }}
                >
                  <div className="tabs">
                    <div>
                      <button
                        className={`tab ${
                          activeTab === "Moments" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("Moments")}
                      >
                        {t("destination.destinationToMeeting.meetings")}
                        <span
                          className={
                            activeTab === "Moments" ? "future" : "draft"
                          }
                        >
                          {meetingsCount}
                        </span>
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "Participants" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("Participants")}
                      >
                        {t("destination.destinationToMeeting.participants")}
                        <span
                          className={
                            activeTab === "Participants" ? "future" : "draft"
                          }
                        >
                          {participantsCount}
                        </span>
                      </button>
                      {/* <button
                    className={`tab ${
                      activeTab === "Actions" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Actions")}
                  >
                    {t("destination.destinationToMeeting.actions")}
                  </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              {activeTab === "Moments" && (
                <div>
                  {
                    <Moments
                      meetings={meetings}
                      isLoading={isLoading}
                      refreshedMeetings={getMeetingsByDestinationId}
                      // allCurrentDestinations={filteredCurrentDestinations}
                    />
                  }
                  <div className="invite-buttons mt-3 ms-1">
                    <button
                      onClick={() => {
                        setFromDestination(true);
                        setFromDestinationName(destination?.destination_name);
                        handleShow();
                      }}
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                        color: " #FFFFFF",
                        background: "#2C48AE",
                        border: 0,
                        outine: 0,
                        padding: "10px 16px",
                        borderRadius: "9px",
                        // marginTop: "1.5rem",
                      }}
                    >
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.457031C15.5228 0.457031 20 4.93418 20 10.457C20 15.9798 15.5228 20.457 10 20.457C4.47715 20.457 0 15.9798 0 10.457C0 4.93418 4.47715 0.457031 10 0.457031ZM10 1.95703C5.30558 1.95703 1.5 5.76261 1.5 10.457C1.5 15.1514 5.30558 18.957 10 18.957C14.6944 18.957 18.5 15.1514 18.5 10.457C18.5 5.76261 14.6944 1.95703 10 1.95703ZM10 5.45703C10.4142 5.45703 10.75 5.79282 10.75 6.20703V9.70703H14.25C14.6642 9.70703 15 10.0428 15 10.457C15 10.8712 14.6642 11.207 14.25 11.207H10.75V14.707C10.75 15.1212 10.4142 15.457 10 15.457C9.5858 15.457 9.25 15.1212 9.25 14.707V11.207H5.75C5.33579 11.207 5 10.8712 5 10.457C5 10.0428 5.33579 9.70703 5.75 9.70703H9.25V6.20703C9.25 5.79282 9.5858 5.45703 10 5.45703Z"
                          fill="white"
                        />
                      </svg>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {t("addMoment")}
                    </button>
                  </div>
                </div>
              )}
              {activeTab === "Participants" && (
                <div>
                  <Participants
                    participants={participants}
                    refreshedParticipants={refreshedParticipants}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </div>
          </div>

          {
            <AddDestination
              show={show}
              currentItem={destination}
              handleClose={handleClose}
              refreshedDestination={getMeetingsByDestinationId}
            />
          }
            {showConfirmationModal && (
        <ConfirmationModal
          message={t("destination.destinationDeleteConfirmMsg")}
          onConfirm={(e) => confirmDelete(e)}
          onCancel={(e) => {
            e.stopPropagation();
            setShowConfirmationModal(false);
          }}
        />
      )}
        </div>
      )}
    </>
  );
};

export default Tabs;
