import React, { useEffect, useRef, useState } from "react";
import { Assets_URL } from "../../Apicongfig";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
const DecisionCard = ({ data,meeting }) => {
  const [t] = useTranslation("global");
  console.log('data',data)
  return (
    <div className="d-flex gap-3 reportdecisioncard flex-wrap">
      {data?.map((item, index) => {
          if (!item) {
            return null;
          }
        return (
          <Card
            key={index}
            style={{ width: "416px" }}
            className="mt-4 participant-card moment-decision-card"
          >
            <Card.Body>
              <div className="d-flex flex-column gap-3 ">
                <div className="numbers ">
                  <div className="number">{index + 1}</div>
                </div>

                <div className="d-flex flex-column align-items-start">
                  {/* <div className="text-center mt-4 card-heading">{item}</div> */}
                  <div
                 className="decision-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: (() => {
                        let filteredDecisions = [];
                        if (item != null) {
                          filteredDecisions.push(item.decision);
                        }
                        return filteredDecisions.join("\n");
                      })(),
                    }}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <div></div>
                  <div className="team-btn">
                    {/* {meeting.user?.teams?.map(item=>item.name)} */}
                    {item?.decision_apply}


                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};

export default DecisionCard;
